import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { reducers } from './reducers';
import { usersMdl } from './middleware/users';
import { userMdl } from './middleware/user';
import { productsMdl } from './middleware/products';
import { productMdl } from './middleware/product';
import { bakeryAdditionalInformationsMdl } from './middleware/bakeryAdditionalInformations';
import { bakeryAdditionalInformationMdl } from './middleware/bakeryAdditionalInformation';
import { rolesMdl } from './middleware/roles';
import { roleMdl } from './middleware/role';
import { modulesMdl } from './middleware/modules';
import { moduleMdl } from './middleware/module';
import { companiesMdl } from './middleware/companies';
import { companyMdl } from './middleware/company';
import { competitorsMdl } from './middleware/competitors';
import { competitorMdl } from './middleware/competitor';
import { groupsMdl } from './middleware/groups';
import { groupMdl } from './middleware/group';
import { loggedUserMdl } from './middleware/loggedUser';
import { passwordMdl } from './middleware/password';
import { api } from './middleware/api';
import { paymentMachinesMdl } from './middleware/paymentMachines';
import { paymentMachineMdl } from './middleware/paymentMachine';
import { paymentMachineRegistersMdl } from './middleware/paymentMachineRegisters';
import { picPayOperatorsMdl } from './middleware/picPayOperators';
import { picPayOperatorMdl } from './middleware/picPayOperator';
import { extractsMdl } from './middleware/extracts';
import { commissioningsMdl } from './middleware/commissionings';
import { commissioningMdl } from './middleware/commissioning';
import { transfersMdl } from './middleware/transfers';
import { transferMdl } from './middleware/transfer';
import { embeddedResourcesMdl } from './middleware/embeddedResources';
import { suppliersMdl } from './middleware/suppliers';
import { supplierMdl } from './middleware/supplier';
import { offersMdl } from './middleware/offers';
import { offerMdl } from './middleware/offer';
import { weeklyTaxesMdl } from './middleware/weeklyTaxes';
import { weeklyTaxMdl } from './middleware/weeklyTax';
import { auditMdl } from './middleware/audit';
import { reportSplitValuesMdl } from './middleware/reportSplitValues';
import { acquirersMdl } from './middleware/acquirers';
import { receivedPaymentsMdl } from './middleware/receivedPayments';
import { transitoryValuesMdl } from './middleware/transitoryValues';
import { ordersMdl } from './middleware/orders';
import { orderMdl } from './middleware/order';
import { paymentConditionsMdl } from './middleware/paymentConditions';
import { paymentConditionMdl } from './middleware/paymentCondition';
import { paymentMethodsMdl } from './middleware/paymentMethods';
import { paymentMethodMdl } from './middleware/paymentMethod';
import { paymentPlansMdl } from './middleware/paymentPlans';
import { paymentPlanMdl } from './middleware/paymentPlan';
import { cashClubSettingsMdl } from './middleware/cashClubSettings';
import { cashClubSettingMdl } from './middleware/cashClubSetting';
import { sellersMdl } from './middleware/sellers';
import { apiKeysMdl } from './middleware/apiKeys';
import { apiKeyMdl } from './middleware/apiKey';

// dev tool
const composeEnhancers =
  (process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

export const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(
      ...usersMdl,
      ...userMdl,
      ...bakeryAdditionalInformationsMdl,
      ...bakeryAdditionalInformationMdl,
      ...rolesMdl,
      ...roleMdl,
      ...modulesMdl,
      ...moduleMdl,
      ...loggedUserMdl,
      ...companiesMdl,
      ...companyMdl,
      ...competitorsMdl,
      ...competitorMdl,
      ...groupsMdl,
      ...groupMdl,
      ...passwordMdl,
      ...paymentMachinesMdl,
      ...paymentMachineMdl,
      ...paymentMachineRegistersMdl,
      ...picPayOperatorsMdl,
      ...picPayOperatorMdl,
      ...extractsMdl,
      ...commissioningsMdl,
      ...commissioningMdl,
      ...transfersMdl,
      ...transferMdl,
      ...embeddedResourcesMdl,
      ...productMdl,
      ...productsMdl,
      ...suppliersMdl,
      ...supplierMdl,
      ...offersMdl,
      ...offerMdl,
      ...weeklyTaxesMdl,
      ...weeklyTaxMdl,
      ...auditMdl,
      ...reportSplitValuesMdl,
      ...acquirersMdl,
      ...receivedPaymentsMdl,
      ...transitoryValuesMdl,
      ...ordersMdl,
      ...orderMdl,
      ...paymentConditionMdl,
      ...paymentConditionsMdl,
      ...paymentMethodMdl,
      ...paymentMethodsMdl,
      ...paymentPlanMdl,
      ...paymentPlansMdl,
      ...cashClubSettingMdl,
      ...cashClubSettingsMdl,
      ...sellersMdl,
      ...apiKeyMdl,
      ...apiKeysMdl,
      api,
      thunk,
    ),
  ),
);
