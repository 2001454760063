import moment from 'moment';
import { WEEKDAYS } from '../config/consts';
import PATHS from '../config/routePaths';
import { store } from '../redux/store';
import { adminGridItems } from '../sharedComponents/Navigation/components/MenuDrawer/menuItems';

export const reverseMapping = (o) =>
  Object.keys(o || {}).reduce(
    (r, k) => Object.assign(r, { [o[k]]: (r[o[k]] || []).concat(k) }),
    {},
  );

export const capitalizeFirstLetter = (str) => {
  if (!str || typeof str !== 'string') {
    return '';
  }
  return str[0]?.toUpperCase() + str.slice(1);
};

export const getCapitalizedString = (str) => {
  if (!str || typeof str !== 'string') {
    return '';
  }
  const lowerCase = str.toLowerCase();
  return (
    lowerCase[0]?.toUpperCase() +
    lowerCase
      .split('')
      .splice(1)
      .join('')
  );
};

export const isNumber = (num, options = {}) => {
  if (!num && parseFloat(num) !== 0) {
    return false;
  }
  let formattedNum = num.toString();
  if (!options.preventCommaConversion) {
    formattedNum = formattedNum.replace(/,/g, '.');
  }
  const regex = /^-?\d+\.?\d*$/;
  return (
    !Number.isNaN(formattedNum) &&
    !!formattedNum.match(regex) &&
    typeof parseFloat(formattedNum) === 'number'
  );
};

export const arraySum = (arr, key) => {
  if (!arr || !arr.length) {
    return 0;
  }
  return [...arr].reduce((accumVariable, curValue) => {
    let valueToSum = 0;
    if (curValue && curValue[key] && isNumber(curValue[key])) {
      valueToSum = curValue[key];
    } else if (isNumber(curValue)) {
      valueToSum = curValue;
    }
    return accumVariable + valueToSum;
  }, 0);
};

export const arrayAvg = (array, key) => {
  if (!array || !array.length) {
    return 0;
  }
  const sum = arraySum(array, key);
  const count = array.length;
  return sum / count;
};

// This function splits 'values' array into two arrays by half and compare them
export const analysePeriods = (values) => {
  const firstPeriod = [...values];
  const lastPeriod = firstPeriod.splice(firstPeriod.length / 2);

  const firstPeriodSum = arraySum(firstPeriod);
  const lastPeriodSum = arraySum(lastPeriod);
  const absolute =
    lastPeriodSum && firstPeriodSum ? lastPeriodSum - firstPeriodSum : 0;
  const percent = firstPeriodSum !== 0 ? (absolute / firstPeriodSum) * 100 : 0;
  return {
    firstPeriod,
    lastPeriod,
    firstPeriodSum,
    lastPeriodSum,
    absolute,
    percent,
  };
};

export const getLabelsFromDate = (refDate, length) =>
  [...new Array(length)]
    .map((_, index) => {
      const currMoment = moment(refDate).subtract(index, 'days');
      return `${WEEKDAYS[currMoment.day()]}, ${currMoment.format('DD/MM')}`;
    })
    .reverse();

const defaultFormatter = (value) => {
  const currMoment = moment(value).utc();
  return `${WEEKDAYS[currMoment.day()]}, ${currMoment.format('DD/MM')}`;
};

export const formatLabels = (
  labels,
  startIndex,
  formatter = defaultFormatter,
) => {
  const validLabels = [...labels].splice(startIndex);
  return validLabels.map(formatter);
};

// From https://stackoverflow.com/a/40774906/11244160
export const aggregateArrayOfDicts = (array, key) => {
  if (!array || !array.length || !key) {
    return {};
  }
  return [...array].reduce((r, a) => {
    const _r = { ...r };
    if (a[key]) {
      _r[a[key]] = _r[a[key]] || [];
      _r[a[key]].push(a);
    }
    return _r;
  }, Object.create(null));
};

export const getFormattedDataPerDay = (array, key, reducerFunction) => {
  const aggregatedData = aggregateArrayOfDicts(array, key);
  const formattedData = {};
  Object.keys(aggregatedData).forEach((day) => {
    const formattedDay = day.split('T')[0]; // format: YYYY-MM-DD
    formattedData[formattedDay] = reducerFunction(aggregatedData[day]);
  });
  return formattedData;
};

// From https://stackoverflow.com/questions/40710628/how-to-convert-snake-case-to-camelcase-in-my-app
export const snakeToCamel = (str) => {
  if (!str || typeof str !== 'string') {
    // If not string, return nothing
    return '';
  }
  if (!str.includes('_')) {
    // If not snake_case, return untouched
    return str;
  }
  return str.toLowerCase().replace(/([-_][a-z])/g, (group) =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', ''),
  );
};

export const isObject = (obj) =>
  !(!obj || obj.length || typeof obj !== 'object');

export const isEmptyObject = (obj) =>
  obj &&
  Object.keys(obj).length === 0 &&
  Object.getPrototypeOf(obj) === Object.prototype;

export const api2FrontInterface = (obj) => {
  // Convert snake case from api to camel case for front
  if (!isObject(obj)) {
    return {};
  }
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    newObj[snakeToCamel(key)] = obj[key];
  });
  return newObj;
};

export const array2Map = (array, key) => {
  if (!array || !array.length || !key) {
    return {};
  }
  return [...array].reduce((map, obj) => {
    const _map = { ...map };
    if (obj[key]) {
      _map[obj[key]] = obj;
    }
    return _map;
  }, {});
};

export const userIsAdmin = (user) => user.role?.isAdmin;

export const getCompanyDocumentFilter = (company, loggedUser) =>
  (company?.document && [company?.document]) ||
  (loggedUser?.companies || []).map((relatedCompany) => relatedCompany.document);

export const obj2QueryString = (obj) => {
  const queryString = [];
  Object.keys(obj).forEach((paramKey) => {
    let paramToAdd = obj[paramKey];
    if (!paramToAdd || paramToAdd.length === 0) {
      return;
    }
    if (typeof paramToAdd === 'object') {
      paramToAdd = JSON.stringify(paramToAdd);
    }
    queryString.push(`${paramKey}=${paramToAdd}`);
  });
  return `${queryString.join('&')}`;
};

export const scrollToTop = (event) => {
  const anchor = (event?.target?.ownerDocument || document).querySelector(
    '#back-to-top-anchor',
  );

  if (anchor) {
    anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

function isAdminModule(modulePath) {
  const index = adminGridItems.findIndex(
    (module) => module.path === modulePath,
  );
  return index > -1;
}

export const hasPermission = (modulePath, permission = 'read') => {
  const { loggedUser, modules } = store.getState();

  // If user is not logged
  if (!loggedUser) {
    return false;
  }

  // If module is unactive) {
  const pathToCheck = modulePath || window.location.pathname;

  const module = (modules?.docs || modules)?.find((_module) => _module.key === pathToCheck);
  if (module && module.active === false) {
    return false;
  }

  if (userIsAdmin(loggedUser)) {
    // If is settings
    if (modulePath === PATHS.SETTINGS) {
      return true;
    }
    if (!isAdminModule(modulePath)) return true;

    return loggedUser.role?.permissions?.[modulePath]?.[permission];
  }

  // If is settings
  if (modulePath === PATHS.SETTINGS) {
    return loggedUser.role?.isAdmin;
  }

  // If path is not registered
  if (Object.values(PATHS).indexOf(modulePath) < 0) {
    return true;
  }

  // If has some related group
  if (loggedUser.group && loggedUser.group.permissions?.[modulePath]?.[permission]) {
    return true;
  }

  // If has some custom permission
  return loggedUser.role?.permissions?.[modulePath]?.[permission];
};

export const sortByAlphabeticalOrder = (fieldToBeSorted) => (a, b) =>
  (fieldToBeSorted
    ? a[fieldToBeSorted].localeCompare(b[fieldToBeSorted])
    : a.localeCompare(b));

// Adapted from: https://gist.github.com/alexbruno/6623b5afa847f891de9cb6f704d86d02
export const cnpjValidation = (value) => {
  if (!value) return false;

  const isString = typeof value === 'string';
  const validTypes =
    isString || Number.isInteger(value) || Array.isArray(value);

  if (!validTypes) return false;

  if (isString) {
    if (value.length > 18) return false;
    const digitsOnly = /^\d{14}$/.test(value);
    const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);
    if (!(digitsOnly || validFormat)) return false;
  }

  const match = value.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];

  if (numbers.length !== 14) return false;

  const items = [...new Set(numbers)];
  if (items.length === 1) return false;

  const calc = (x) => {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += n * factor--;
      if (factor < 2) factor = 9;
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };

  const digits = numbers.slice(12);
  const digit0 = calc(12);
  if (digit0 !== digits[0]) return false;
  const digit1 = calc(13);
  return digit1 === digits[1];
};

export const emailValidator = (email) =>
  email
    .toLocaleLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

// Adapted from: https://www.horadecodar.com.br/2021/02/10/mascara-para-formatar-cnpj-com-javascript/
export const maskCNPJ = (cnpj) =>
  cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

export const sanitizeCNPJ = (cnpj) => {
  const cleanValue = cnpj
    .replace(/\./g, '')
    .replace(/-/g, '')
    .replace(/\//g, '');

  return cleanValue;
};

export const sanitizeCPF = (cpf) => {
  const cleanValue = cpf
    .replace(/\./g, '')
    .replace(/-/g, '');

  return cleanValue;
};
