import axios from 'axios';
import { store } from '../../redux/store';
import { obj2QueryString, userIsAdmin } from '../../utils/globalFunctions';
import Service from '../Service';

async function fetchBanks() {
  // docs: https://brasilapi.com.br/docs#tag/BANKS
  const response = await axios.get('https://brasilapi.com.br/api/banks/v1');
  if (response.status !== 200) {
    throw Error(response.statusText);
  }
  return {
    data: response.data,
    ok: true,
    status: response.status,
  };
}

async function fetchAllCompanies() {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const queryString = obj2QueryString({
    listAll: true,
    showUnactiveCompanies: true,
    isAdmin,
  });
  const apiService = new Service({
    route: `/companies?${queryString}`,
    apiName: 'apiV2',
  });
  const response = await apiService.get({});
  if (!response.ok) {
    throw Error(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: 200,
  };
}

export const importFile = ({ file, isAdmin }) => {
  const api = new Service({
    route: '/companies/import',
    apiName: 'apiV2',
  });
  const form = new FormData();
  form.append('file', file);
  return api
    .post(form, { isAdmin: `${isAdmin}` })
    .then(({ data, ok, message }) => ({
      ok,
      data,
      error: message,
    }))
    .catch((e) => ({ ok: false, error: e?.response?.data?.message }));
};

export default {
  fetchBanks,
  fetchAllCompanies,
};
