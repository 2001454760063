import { Icon } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { KeyboardArrowDown } from '@material-ui/icons';
import React from 'react';
import { InputProps } from './interfaces';
import { Field, labelStyle, selectStyle } from './styles';

const Input: React.FC<React.PropsWithChildren<InputProps>> = ({
  style,
  value: controlledValue,
  onChange,
  fullWidth,
  disableClearing,
  label,
  select,
  name,
  children,
  icon: AdornmentIcon,
  error,
  helperText,
  required,
  type,
  step,
  multiple,
  renderValue,
}) => {
  const selectClasses = selectStyle();
  const labelClasses = labelStyle();
  const handleClear = () => {
    onChange({ target: { name, value: multiple ? [] : null } });
  };

  const IconWithDisable = () => (
    <>
      <KeyboardArrowDown />
      <IconButton onClick={handleClear}>
        <Icon>close</Icon>
      </IconButton>
    </>
  );

  const StartIcon = () => {
    if (typeof AdornmentIcon === 'string') {
      return (
        <Icon
          style={{
            color: '#192D4D',
            marginRight: '0.7rem',
          }}
          className="material-icons-outlined"
        >
          {AdornmentIcon}
        </Icon>
      );
    }
    const Component = AdornmentIcon as () => JSX.Element;
    return <Component />;
  };
  return (
    <Field
      name={name}
      variant="outlined"
      select={select}
      value={controlledValue}
      onChange={onChange}
      type={type}
      label={
        controlledValue === undefined ||
        controlledValue === null ||
        controlledValue === '' ||
        (typeof controlledValue === 'object' && !controlledValue)
          ? label
          : ''
      }
      SelectProps={{
        classes: selectClasses,
        IconComponent:
          controlledValue && !disableClearing
            ? IconWithDisable
            : KeyboardArrowDown,
        multiple,
        renderValue,
      }}
      InputLabelProps={{ shrink: false, classes: labelClasses }}
      style={style}
      fullWidth={fullWidth}
      inputProps={{ step }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        startAdornment: AdornmentIcon && <StartIcon />,
      }}
      error={!!error}
      helperText={error || helperText}
      required={required}
    >
      {children}
    </Field>
  );
};

export default Input;
