import {
  ADD_COMPANY_ADDRESS,
  ADD_MANY_COMPANY_ADDRESSES,
  REMOVE_COMPANY_ADDRESS,
  UPDATE_COMPANY_ADDRESS } from '../actions/address';

export function addressesReducer(addresses = [], action) {
  switch (action.type) {
    case ADD_COMPANY_ADDRESS: {
      const companyAdressesCopy = [...addresses];
      companyAdressesCopy.push(action.payload);
      return companyAdressesCopy;
    }

    case REMOVE_COMPANY_ADDRESS: {
      const companyAdressesCopy = [...addresses];
      companyAdressesCopy.splice(action.payload, 1);
      return companyAdressesCopy;
    }

    case UPDATE_COMPANY_ADDRESS: {
      const companyAdressesCopy = [...addresses];
      const { index, data } = action.payload;
      companyAdressesCopy[index] = data;
      return companyAdressesCopy;
    }

    case ADD_MANY_COMPANY_ADDRESSES: {
      return action.payload;
    }

    default:
      return addresses;
  }
}
