import {
  Button,
  Paper,
  Table as MuiTable,
  TableRow,
  TableCell,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';

export const Container = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    overflow: 'hidden',
  },
}))(Paper);

export const OutlinedButton = withStyles({
  root: {
    backgroundColor: '#F3F2F7',
    borderRadius: 8,
    border: '1px solid #133DA5',
  },
  label: {
    textTransform: 'none',
    color: '#133DA5',
  },
})(Button);

export const Table = withStyles({})(MuiTable);
export const TableBody = withStyles({})(MuiTableBody);
export const TableHead = withStyles({
  root: {
    '& th': { fontSize: 15, fontWeight: 600, textAlign: 'center' },
    '& tr': { background: '#000 !important' },
  },
})(MuiTableHead);
export const Row = withStyles({
  root: {
    background: '#FFF',
  },
})(TableRow);
export const Cell = withStyles({
  root: {
    color: '#3B3F41',
    fontSize: 12,
    borderBottom: 0,
    textAlign: 'center',
    '&.wide': {
      minWidth: 304,
    },
    '&.with-sub-value': {
      fontSize: 15,
      fontWeight: 600,
      textAlign: 'left',
    },
  },
})(TableCell);

export const HighlightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 24px;
  padding-left: 24px;
  span {
    text-align: center;
  }
`;

export const Highlight = styled.div<{ backgroundColor?: string; textColor?: string }>`
  display: flex;
  flex-direction: row;
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: ${({ backgroundColor }) => backgroundColor ?? '#133da5'};
  color: ${({ textColor }) => textColor ?? '#fff'};
  &::before {
    content: '';
    height: 100%;
    border: 1px solid #d1d4d6;
  }
  &:first-of-type::before {
    content: '';
    border: none;
  }
`;

export const CommonData = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  padding: 24px 32px 24px 8px;
`;

export const ProductsContainer = withStyles({
  root: {
    marginTop: -50,
    borderRadius: 16,
    marginRight: 8,
    marginLeft: 8,
    paddingTop: 40,
    paddingBottom: 8,
    position: 'relative',
    zIndex: -10,
  },
})(Paper);

export const ProductRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 16px;
`;

export const ProductName = styled.span`
  font-weight: 600;
  font-size: 15px;
  width: 312px;
  margin: auto 0;
`;

export const Column = styled.span`
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`;

export const SupplierName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;
