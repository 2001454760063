import React from 'react';
import { useSelector } from 'react-redux';
import { DialogContentText, Icon, IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import { RootState } from '../../../../interfaces/redux';
import { ApiKey } from '../../../../interfaces/apiKeys';

const Success = () => {
  const apiKey = useSelector((state: RootState) => state.apiKey) as ApiKey;
  const [tooltip, setTooltip] = React.useState('Copiar API Key');

  const handleMouseLeave = () => {
    setTooltip('Copiar API Key');
  };

  const handleClick = (key: 'key' | 'publicKey') => () => {
    navigator.clipboard.writeText(apiKey?.[key]);
    setTooltip('Copiado!');
  };

  return (
    <DialogContentText>
      <Typography>
        A chave <b>{apiKey?.name}</b> foi criada com sucesso.
      </Typography>
      <Typography>
        Esta é a única vez que a chave estará acessível, recomendamos fortemente armazenar a chave gerada de forma segura com um gerenciador de senhas e segredos.
      </Typography>
      <TextField
        style={{ marginTop: 16 }}
        label="Public Key"
        fullWidth
        disabled
        value={apiKey.publicKey}
        InputProps={{ endAdornment: (
          <Tooltip title={tooltip}>
            <IconButton onMouseLeave={handleMouseLeave} onClick={handleClick('publicKey')}>
              <Icon>content_copy</Icon>
            </IconButton>
          </Tooltip>),
        }}
      />
      <TextField
        style={{ marginTop: 16 }}
        label="API Key"
        fullWidth
        disabled
        value={apiKey.key}
        InputProps={{ endAdornment: (
          <Tooltip title={tooltip}>
            <IconButton onMouseLeave={handleMouseLeave} onClick={handleClick('key')}>
              <Icon>content_copy</Icon>
            </IconButton>
          </Tooltip>),
        }}
      />
    </DialogContentText>
  );
};

export default Success;
