import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Cancel, CheckCircle, Edit } from '@material-ui/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentMethod } from '../../interfaces/paymentMethods';
import { RootState } from '../../interfaces/redux';
import { showModal } from '../../redux/actions/paymentMethod';
import { getPaymentMethods } from '../../redux/actions/paymentMethods';
import Container from '../../sharedComponents/Container';
import Pagination from '../../sharedComponents/Pagination';
import SectionContent from '../../sharedComponents/SectionContent';
import SectionHeader from '../../sharedComponents/SectionHeader';
import PaymentMethodModal from '../Modals/PaymentMethodModal';
import { AddButton, AddIcon, Paper } from './styles';

const PaymentMethods: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [editing, setEditing] = React.useState<undefined | number>();
  const dispatch = useDispatch();
  const { docs: paymentMethods, total, pages, limit } = useSelector(
    (state: RootState) => state.paymentMethods,
  );

  React.useEffect(() => {
    dispatch(getPaymentMethods({ page, limit: 10 }));
  }, [page]);

  const handleClick = () => {
    setEditing(undefined);
    dispatch(showModal());
  };

  const handleEditClick = (id: number) => () => {
    setEditing(id);
    dispatch(showModal());
  };

  return (
    <Container>
      <SectionHeader
        title={
          <span>
            Métodos de Pagamento{' '}
            <Tooltip title="Novo Método de Pagamento">
              <AddButton size="small" onClick={handleClick}>
                <AddIcon />
              </AddButton>
            </Tooltip>
          </span>
        }
        hideCompanySelector
        hideDatePicker
      />
      <SectionContent>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ativo</TableCell>
                <TableCell>Método</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentMethods.map(({ name, active, id }: PaymentMethod) => (
                <TableRow key={id}>
                  <TableCell>
                    {active ? (
                      <CheckCircle htmlColor="#48B748" />
                    ) : (
                      <Cancel htmlColor="#F95F52" />
                    )}
                  </TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>
                    <Tooltip title="Editar">
                      <IconButton onClick={handleEditClick(id as number)}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            page={page}
            pages={pages}
            total={total}
            limit={limit}
            onChangePage={setPage}
            showing={paymentMethods.length}
          />
        </Paper>
      </SectionContent>
      <PaymentMethodModal editing={editing} />
    </Container>
  );
};

export default PaymentMethods;
