export const API_REQUEST = '[app] API Request';

export const apiRequest = (
  method,
  url,
  body,
  onSuccess,
  onError,
  params,
  apiName,
  ignoreAdminCheck,
  options,
) => ({
  type: API_REQUEST,
  payload: body,
  meta: {
    method,
    url,
    payload: body,
    onSuccess,
    onError,
    params,
    apiName,
    ignoreAdminCheck,
    options,
  },
});
