import {
  FETCH_PICPAY_OPERATORS_SUCCESS,
  FETCH_PICPAY_OPERATORS_ERROR,
  GET_PICPAY_OPERATORS,
  updatePicPayOperators,
  showSpinner,
  hideSpinner,
} from '../actions/picPayOperators';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'picPayOperators';

export const getPicPayOperatorsFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_PICPAY_OPERATORS) {
          dispatch(
            apiRequest(
              'GET',
              URL,
              action.payload,
              FETCH_PICPAY_OPERATORS_SUCCESS,
              FETCH_PICPAY_OPERATORS_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processPicPayOperatorsCollection =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_PICPAY_OPERATORS_SUCCESS) {
          dispatch(updatePicPayOperators(action.payload));
          dispatch(hideSpinner());
        }
        if (action.type === FETCH_PICPAY_OPERATORS_ERROR) {
          Toastify.addError(
            'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
          );
          dispatch(hideSpinner());
        }
      };

export const picPayOperatorsMdl = [
  getPicPayOperatorsFlow,
  processPicPayOperatorsCollection,
];
