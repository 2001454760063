import React from 'react';
import { IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createCashClubSetting } from '../../../../redux/actions/cashClubSetting';
import DialogContainer from '../../../../sharedComponents/DialogContainer';
import {
  Container,
  Title,
  ConfirmButton,
  CancelButton,
  ButtonContainer,
  CloseIcon,
  Label,
  EffectiveDateFormGroup,
  Header,
  Input,
} from '../styles';
import { CashClubSetting } from '../../../../interfaces/cashClubSettings';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FormContainer } from './styles';

interface DataAction {
  field: keyof CashClubSetting;
  value: unknown;
}

const dataReducer: React.Reducer<CashClubSetting, DataAction> = (
  state,
  { field, value },
) => ({ ...state, [field]: value });

const CashClubSettingModal: React.FC<{
  lastRecord: CashClubSetting;
  open: boolean;
  onClose: () => void;
}> = ({ lastRecord, open, onClose }) => {
  const [data, setData] = React.useReducer<
    React.Reducer<CashClubSetting, DataAction>
  >(dataReducer, {
    effectiveDate: null,
    activityType: 'LEAVE',
    companyId: lastRecord.companyId,
  });
  const [buttonEnabled, setButtonEnabled] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!data.effectiveDate) {
      setButtonEnabled(false);
    } else {
      setButtonEnabled(true);
    }
  }, [data]);

  const handleEffectiveDate = (value: MaterialUiPickersDate) =>
    setData({ field: 'effectiveDate', value });

  const handleSave: React.FormEventHandler = (e) => {
    e.preventDefault();
    dispatch(createCashClubSetting(data));
    onClose();
  };

  return (
    <DialogContainer open={open} onClose={onClose}>
      <Container>
        <Header>
          <Title>Deseja inativar este cliente?</Title>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Header>
        <form onSubmit={handleSave}>
          <FormContainer>
            <EffectiveDateFormGroup>
              <Label>Informe a data de saída</Label>
              <DatePicker
                required
                name="effectiveDate"
                onChange={handleEffectiveDate}
                value={data.effectiveDate}
                placeholder={'  /  /    '}
                InputProps={{ disableUnderline: true }}
                format="dd/MM/yyyy"
                TextFieldComponent={(props) => <Input {...props} />}
                minDate={new Date(lastRecord.effectiveDate as string)}
                maxDate={new Date()}
              />
            </EffectiveDateFormGroup>
          </FormContainer>
          <ButtonContainer>
            <CancelButton onClick={onClose}>Cancelar</CancelButton>
            <ConfirmButton disabled={!buttonEnabled} type="submit">
              Inativar
            </ConfirmButton>
          </ButtonContainer>
        </form>
      </Container>
    </DialogContainer>
  );
};

export default CashClubSettingModal;
