/* eslint-disable indent */
import {
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_ERROR,
  FETCH_GROUP_SUCCESS,
  FETCH_GROUP_ERROR,
  GET_GROUP,
  CREATE_GROUP,
  UPDATE_GROUP,
  updateObjectGroup,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/group';
import { getGroups } from '../actions/groups';
import Service from '../../services/Service';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';
import { getLoggedUser } from '../actions/loggedUser';
import { api2FrontInterface } from '../../utils/globalFunctions';

const groupsApiUrl = 'groups';
const permissionsApiUrl = 'permissions';

export const createGroupFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_GROUP) {
    const { permissions, ...data } = action.payload;
    const groupsService = new Service({
      route: groupsApiUrl,
      apiName: 'auth',
    });
    groupsService.post(api2FrontInterface(data))
      .then((response) => {
        if (response.ok) {
          const { id } = response.data.resources;
          dispatch(
            apiRequest(
              'POST',
              permissionsApiUrl,
              { id, permissions, type: 'group' },
              UPDATE_GROUP_SUCCESS,
              UPDATE_GROUP_ERROR,
              {},
              'apiV2',
            ),
          );
        } else {
          dispatch({ type: UPDATE_GROUP_ERROR, payload: response.message });
        }
      })
      .catch((error) => {
        dispatch({ type: UPDATE_GROUP_ERROR, payload: error });
      });
    dispatch(showSpinner());
  }
};

export const updateGroupFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_GROUP) {
    const { permissions, id, ...data } = action.payload;
    const groupsService = new Service({
      route: groupsApiUrl,
      apiName: 'auth',
    });
    groupsService.put(id, api2FrontInterface(data))
      .then((response) => {
        if (response.ok) {
          dispatch(
            apiRequest(
              'PUT',
              permissionsApiUrl,
              { id, permissions, type: 'group' },
              UPDATE_GROUP_SUCCESS,
              UPDATE_GROUP_ERROR,
              {},
              'apiV2',
            ),
          );
        } else {
          dispatch({ type: UPDATE_GROUP_ERROR, payload: response.message });
        }
      })
      .catch((error) => {
        dispatch({ type: UPDATE_GROUP_ERROR, payload: error });
      });
    dispatch(showSpinner());
  }
};

export const getGroupFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_GROUP) {
          const { id } = action.payload;
          const groupsService = new Service({
            route: `${groupsApiUrl}/${id}`,
            apiName: 'auth',
          });
          const permissionsService = new Service({
            route: `${permissionsApiUrl}/${id}`,
            apiName: 'apiV2',
          });
          groupsService.get()
            .then((response) => {
              if (response.ok) {
                permissionsService.get({ type: 'group' })
                  .then(({ data }) => {
                    const { permissions } = data;
                    dispatch({
                      type: FETCH_GROUP_SUCCESS,
                      payload: {
                        ...response.data.resources,
                        permissions,
                      },
                    });
                  })
                  .catch((error) => {
                    dispatch({ type: FETCH_GROUP_ERROR, payload: error });
                  });
              } else {
                dispatch({ type: FETCH_GROUP_ERROR, payload: response.message });
              }
            })
            .catch((error) => {
              dispatch({ type: FETCH_GROUP_ERROR, payload: error });
            });
          dispatch(showSpinner());
        }
      };

export const processGroupCollection =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_GROUP_SUCCESS) {
          dispatch(updateObjectGroup(action.payload));
          dispatch(hideSpinner());
        } else if (action.type === FETCH_GROUP_ERROR) {
          dispatch(hideSpinner());
          dispatch(hideModal());
          Toastify.addError('Erro ao carregar grupo. Por favor, entre em contato com o suporte');
        } else if (action.type === UPDATE_GROUP_SUCCESS) {
          dispatch(updateObjectGroup(action.payload));
          dispatch(hideModal());
          dispatch(hideSpinner());
          dispatch(getLoggedUser());
          const { page, limit } = getState().groups;
          dispatch(getGroups({ page, limit }));
          Toastify.addSuccess('Ação realizada com sucesso.');
        } else if (action.type === UPDATE_GROUP_ERROR) {
          dispatch(hideSpinner());
          Toastify.addError(
            action.payload || 'Ocorreu um erro durante o salvamento.',
          );
        }
      };

export const groupMdl = [
  createGroupFlow,
  updateGroupFlow,
  getGroupFlow,
  processGroupCollection,
];
