import { Collapse } from '@material-ui/core';
import React from 'react';
import {
  SupplierOrder,
  SupplierOrderStatus,
  supplierStatusColorMap,
} from '../../../../interfaces/orders';
import Block from '../../../../sharedComponents/OrderCard/components/Block';
import {
  ActionContainer,
  ExpandIcon,
  Title,
  Value,
} from '../../../../sharedComponents/OrderCard/styles';
import {
  Container,
  Highlight,
  CommonData,
  SupplierName,
  HighlightContent,
  Table,
  TableHead,
  Row,
  Cell,
  TableBody,
  ProductsContainer,
} from './styles';

const supplierOrderFields: {
  [key in keyof Partial<SupplierOrder>]:
    | string
    | { label: string; ifEmpty?: string; hideIfEmpty?: boolean };
} = {
  supplierOrderNumber: 'Número do pedido',
  formattedDeliveryDate: { label: 'Data de entrega', hideIfEmpty: true },
  formattedEstimatedDeliveryDate: {
    label: 'Previsão de entrega',
    ifEmpty: 'deliveryDate',
  },
};

const productFields: { [key: string]: string } = {
  eanCode: 'Código EAN',
  quantity: 'Quantidade',
  price: 'Preço',
  formattedNegotiatedTotalValue: 'Total',
};

const SupplierCard: React.FC<{
  supplierOrder: SupplierOrder;
  corporateNamePrefix?: string | null;
  isOpen?: boolean;
}> = ({ supplierOrder, corporateNamePrefix, isOpen }) => {
  const [open, setOpen] = React.useState<boolean>(isOpen || false);
  const toggle = () => {
    setOpen((currentState) => !currentState);
  };

  const accessibleSupplierOrder: { [key: string]: unknown } = {
    ...supplierOrder,
  };

  return (
    <>
      <Container onClick={toggle}>
        <Highlight
          backgroundColor={
            supplierStatusColorMap[supplierOrder.status as SupplierOrderStatus]
          }
          textColor="#3B3F41"
        >
          <HighlightContent>
            <Title>{corporateNamePrefix ?? 'Fornecedor'}</Title>
            <Value
              title={
                supplierOrder.supplier?.document === '0'
                  ? 'Fornecedor'
                  : supplierOrder.supplier?.tradingName ?? 'Fornecedor'
              }
            >
              <SupplierName>
                {supplierOrder.supplier?.document === '0'
                  ? 'Fornecedor'
                  : supplierOrder.supplier?.tradingName ?? 'Fornecedor'}
              </SupplierName>
            </Value>
          </HighlightContent>
        </Highlight>
        <Highlight
          backgroundColor={
            supplierStatusColorMap[supplierOrder.status as SupplierOrderStatus]
          }
          textColor="#3B3F41"
        >
          <HighlightContent>
            <Title>Status</Title>
            <Value title={supplierOrder.status}>
              <SupplierName>{supplierOrder.companyStatus}</SupplierName>
            </Value>
          </HighlightContent>
        </Highlight>
        <CommonData>
          {(Object.keys(supplierOrderFields) as (keyof SupplierOrder)[]).map(
            (field) => {
              const item = supplierOrderFields[field];
              if (typeof item === 'string') {
                return (
                  <Block key={field}>
                    <Title>{item as string}</Title>
                    <Value>{accessibleSupplierOrder[field] as string}</Value>
                  </Block>
                );
              }
              if (item?.hideIfEmpty && !accessibleSupplierOrder[field]) {
                return null;
              }
              if (item?.ifEmpty && accessibleSupplierOrder[item?.ifEmpty]) {
                return null;
              }
              return (
                <Block key={field}>
                  <Title>{item?.label}</Title>
                  <Value>{accessibleSupplierOrder[field] as string}</Value>
                </Block>
              );
            },
          )}
          <ActionContainer open={open}>
            <ExpandIcon className="expandIcon">expand_more</ExpandIcon>
          </ActionContainer>
        </CommonData>
      </Container>
      <Collapse in={open}>
        <ProductsContainer>
          <Table stickyHeader>
            <TableHead>
              <Row>
                <Cell key="productDescription">Descrição</Cell>
                {Object.keys(productFields).map((field) => (
                  <Cell key={field}>{productFields[field]}</Cell>
                ))}
              </Row>
            </TableHead>
            <TableBody>
              {supplierOrder.orderProducts.map((product) => {
                const accessibleProduct: { [key: string]: unknown } = {
                  ...product,
                };
                return (
                  product.status == null && (
                    <Row key={product.id}>
                      <Cell>{product.productDescription}</Cell>
                      {Object.keys(productFields).map((field) => (
                        <Cell key={field}>
                          {accessibleProduct[field] as string}
                        </Cell>
                      ))}
                    </Row>
                  )
                );
              })}
            </TableBody>
          </Table>
        </ProductsContainer>
      </Collapse>
    </>
  );
};

export default SupplierCard;
