/* eslint-disable indent */
import Service from '../../services/Service';
import { userIsAdmin } from '../../utils/globalFunctions';
import { API_REQUEST } from '../actions/api';
import { logout } from '../actions/loggedUser';

const authErrorStatusCodes = [401, 403];

export const api = ({ dispatch, getState }) => (next) => (action) => {
  if (action.type === API_REQUEST) {
    const {
      method,
      url,
      onSuccess,
      onError,
      params,
      apiName,
      options,
    } = action.meta;
    const { payload = {} } = action;

    switch (method) {
      case 'POST': {
        const apiService = new Service({
          route: url,
          apiName,
        });
        apiService.store(payload, params).then((response) => {
          if (response.ok) {
            dispatch({ type: onSuccess, payload: response.data });
          } else {
            dispatch({ type: onError, payload: response.message });
          }
        });
        break;
      }
      case 'PUT': {
        const apiService = new Service({ route: url, apiName });
        apiService.put(payload.id || payload._id, payload).then((response) => {
          if (response.ok) {
            dispatch({ type: onSuccess, payload: response.data });
          } else {
            dispatch({ type: onError, payload: response.message });
          }
        });
        break;
      }
      case 'GET-id': {
        const apiService = new Service({
          route: `${url}/${payload.id || payload._id}`,
          apiName,
        });
        apiService
          .get(payload)
          .then((response) =>
            dispatch({ type: onSuccess, payload: response.data }),
          )
          .catch((error) => {
            dispatch({ type: onError, payload: error });
          });
        break;
      }
      default: {
        payload.isAdmin = userIsAdmin(getState().loggedUser);
        const apiService = new Service({ route: url, apiName });
        apiService.get(payload, params, options).then((response) => {
          if (response.ok) {
            dispatch({ type: onSuccess, payload: response.data });
          } else if (authErrorStatusCodes.includes(response.status)) {
            dispatch(logout());
          } else {
            dispatch({ type: onError, payload: response.message });
          }
        });
        break;
      }
    }
  }
  return Promise.resolve(next(action));
};
