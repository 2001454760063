import { Collapse, IconButton, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { CashClubSetting } from '../../../../interfaces/cashClubSettings';
import { getCashClubRecords } from '../../../../services/CashClubSettings';
import { CashClubSettingUpdateModal } from '../../../Modals/CashClubSettingModal';
import { CompanyColumn } from '../../interfaces';
import RecordList from '../RecordList';
import { CollapsibleCell, EditIcon, ExpandIcon, Row } from './style';

const CompanyRow: React.FC<{
  company: CashClubSetting;
  columns: CompanyColumn[];
}> = ({ company, columns }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [records, setRecords] = React.useState<CashClubSetting[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadError, setLoadError] = React.useState<boolean>(false);
  const [updateModalOpen, setUpdateModalOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!open || !!records.length) return;
    setLoading(true);
    setLoadError(false);
    getCashClubRecords(company.companyId as number)
      .then((result) => {
        if (!result.ok) return setLoadError(true);
        setRecords(
          (result.data?.docs || result.data || []) as CashClubSetting[],
        );
      })
      .catch(() => setLoadError(true))
      .finally(() => {
        setLoading(false);
      });
  }, [open, records]);

  const toggle = () => setOpen((currentState) => !currentState);
  const toggleUpdateModal = () => {
    setUpdateModalOpen((currentState) => !currentState);
  };

  return (
    <>
      <Row open={open}>
        {columns.map(({ value, label, highlight }) => (
          <TableCell key={label} className={highlight ? 'highlight' : ''}>
            {value(company)}
          </TableCell>
        ))}
        <TableCell>
          <IconButton disabled={company.activityType === 'LEAVE'}>
            <EditIcon
              onClick={toggleUpdateModal}
              disabled={company.activityType === 'LEAVE'}
            />
          </IconButton>
          <IconButton onClick={toggle}>
            <ExpandIcon className="expand-icon" />
          </IconButton>
        </TableCell>
      </Row>
      <TableRow>
        <CollapsibleCell colSpan={columns.length + 1}>
          <Collapse in={open}>
            <RecordList loading={loading} error={loadError} records={records} />
          </Collapse>
        </CollapsibleCell>
      </TableRow>
      <CashClubSettingUpdateModal
        open={updateModalOpen}
        onClose={toggleUpdateModal}
        lastRecord={company}
      />
    </>
  );
};

export default CompanyRow;
