import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
} from '@material-ui/core';
import Alert from '../../../sharedComponents/Alert/index';
import MenuButton from '../../../sharedComponents/MenuButton';
import Spinner from '../../../sharedComponents/Spinner/index';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
  StyledPaper,
  FormButton,
} from './styles';

import {
  getGroup,
  createGroup,
  updateGroup,
} from '../../../redux/actions/group';

const GroupModal = ({
  id,
  dispatch,
  group,
  groupModal,
  modules,
  handleClose,
  groupSpinner,
  groups,
}) => {
  const [active, setActive] = useState(true);
  const [name, setName] = useState('');
  const [fidelimaxToken, setFidelimaxToken] = useState('');
  const [nameError, setNameError] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [metadata, setMetadata] = useState({});
  const [warning, setWarning] = useState(false);
  const [warningError, setWarningError] = useState(false);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getGroup(id));
    }
  }, [id]);

  useEffect(() => {
    if (group && id !== 'new') {
      setActive(group.active);
      setName(group.name);
      setFidelimaxToken(group.fidelimaxToken || '');
      setPermissions(group.permissions || {});
      setWarning(!!group.metadata?.warningText);
      setMetadata(group.metadata);
    }
  }, [group]);

  useEffect(() => {
    // Close the modal after update the groups list
    if (!groupModal) {
      handleClose();
    }
  }, [groupModal]);

  const handleChangePermission = (value, path, field) => {
    const clone = JSON.parse(JSON.stringify(permissions));
    if (clone[path] && clone[path][field]) {
      clone[path][field] = value;
    } else {
      clone[path] = {
        ...clone[path],
        [field]: value,
      };
    }
    setPermissions(clone);
  };

  const moduleIsSettings = (modulePath) => modulePath.startsWith('/settings');

  const handleChangeWarning = (status) => {
    setWarning(status);
    if (!status) {
      setMetadata((prevMetadata) => {
        const { warningText, ...otherMetadata } = prevMetadata;
        return otherMetadata;
      });
    }
  };

  const handleSave = async () => {
    let err = false;
    setNameError(!name);
    setWarningError(warning && !metadata?.warningText);
    err = !name || (warning && !metadata?.warningText);

    if (!err) {
      const response = await Alert();
      if (response?.confirm) {
        if (id === 'new') {
          dispatch(
            createGroup({
              active,
              name,
              permissions,
              fidelimaxToken,
              metadata,
            }),
          );
        } else {
          dispatch(
            updateGroup({
              id,
              active,
              name,
              permissions,
              fidelimaxToken,
              metadata,
            }),
          );
        }
      }
    }
  };

  return (
    <>
      {groupSpinner && <Spinner overlay />}
      <StyledPaper
        elevation={2}
        fullWidth
        style={{ maxWidth: '90vw', height: 'fit-content' }}
      >
        <Container style={{ opacity: groupSpinner ? 0.5 : 1 }}>
          <ModalTitle>{id === 'new' ? 'Adicionar' : 'Editar'} Grupo</ModalTitle>
          <FormGroup>
            <CheckboxContent>
              <FormControlLabel
                control={
                  <Switch
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                  />
                }
                label="Ativo"
                labelPlacement="start"
              />
            </CheckboxContent>
            <InputContent>
              <TextField
                id="outlined-password-input"
                label="Nome"
                type="text"
                error={nameError}
                helperText={nameError && 'Nome é obrigatório'}
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </InputContent>
            <InputContent>
              <TextField
                id="outlined-password-input"
                label="Token de Acesso ao Fidelimax"
                type="text"
                variant="outlined"
                value={fidelimaxToken}
                onChange={(e) => setFidelimaxToken(e.target.value)}
                fullWidth
              />
            </InputContent>
            <CheckboxContent>
              <FormControlLabel
                control={
                  <Switch
                    checked={warning}
                    onChange={(e) => handleChangeWarning(e.target.checked)}
                  />
                }
                label="Aviso"
                labelPlacement="start"
              />
            </CheckboxContent>
            {warning && (
              <InputContent>
                <TextField
                  id="warning-message"
                  label="Mensagem"
                  type="text"
                  error={warningError}
                  helperText={warningError && 'Mensagem de aviso é obrigatória'}
                  variant="outlined"
                  value={metadata?.warningText}
                  onChange={(e) => setMetadata((prevMetadata) =>
                    ({ ...prevMetadata, warningText: e.target.value }))}
                  fullWidth
                />
              </InputContent>
            )}
            <InputContent>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        Módulo
                        <MenuButton
                          endIcon={() => null}
                          CustomButton={(props) => (
                            <IconButton {...props}>
                              <span className="material-icons">more_vert</span>
                            </IconButton>
                          )}
                          menuItems={[
                            {
                              label: 'Copiar permissões',
                              subItems: groups.map((_group) => ({
                                onClick: () => {
                                  setPermissions(_group.permissions);
                                },
                                label: _group.name,
                              })),
                            },
                          ]}
                        />
                      </TableCell>
                      <TableCell align="center">Leitura</TableCell>
                      <TableCell align="center">Criação</TableCell>
                      <TableCell align="center">Edição</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {modules.docs?.map(
                      (module) =>
                        module.active &&
                        !moduleIsSettings(module.key) && (
                          <TableRow key={module.key}>
                            <TableCell component="th" scope="row">
                              {module.name}
                            </TableCell>
                            <TableCell align="center">
                              <FormControlLabel
                                control={
                                  <Switch
                                    key={permissions[module.key]}
                                    checked={permissions[module.key]?.read}
                                    onChange={(e) =>
                                      handleChangePermission(
                                        e.target.checked,
                                        module.key,
                                        'read',
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell>
                            <TableCell align="center">
                              <FormControlLabel
                                control={
                                  <Switch
                                    key={permissions[module.key]}
                                    checked={permissions[module.key]?.create}
                                    onChange={(e) =>
                                      handleChangePermission(
                                        e.target.checked,
                                        module.key,
                                        'create',
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell>
                            <TableCell align="center">
                              <FormControlLabel
                                control={
                                  <Switch
                                    key={permissions[module.key]}
                                    checked={permissions[module.key]?.update}
                                    onChange={(e) =>
                                      handleChangePermission(
                                        e.target.checked,
                                        module.key,
                                        'update',
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </InputContent>
            <ButtonContainer>
              <FormButton
                color="secondary"
                onClick={handleClose}
                variant="contained"
              >
                Cancelar
              </FormButton>
              <FormButton
                color="primary"
                onClick={handleSave}
                variant="contained"
              >
                Salvar
              </FormButton>
            </ButtonContainer>
          </FormGroup>
        </Container>
      </StyledPaper>
    </>
  );
};

export default connect((state) => ({
  group: state.group,
  modules: state.modules,
  groupModal: state.ui.groupModal,
  groupSpinner: state.ui.groupLoading,
}))(GroupModal);
