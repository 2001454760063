import { UPDATE_SELLERS } from '../actions/sellers';

const initState = {
  results: [],
  page: 1,
  totalPages: 0,
  totalResults: 0,
};

export function sellersReducer(sellers = initState, action) {
  switch (action.type) {
    case UPDATE_SELLERS:
      return action.payload;

    default:
      return sellers;
  }
}
