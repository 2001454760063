import { withStyles } from '@material-ui/styles';
import MuiInput from '@material-ui/core/Input';
import MuiSelect from '@material-ui/core/Select';
import styled from 'styled-components';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import TextField from '@material-ui/core/TextField';

export const basicInputStyles = withStyles((theme) => ({
  root: {
    border: '1px solid #D1D4D6',
    borderRadius: 8,
    borderShadow: '1px 1px 4px rgba(0, 0, 0, 0.08)',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 2,
    paddingBottom: 2,
    backgroundColor: '#FFF',
  },
  multiline: {
    height: '100%',
    alignItems: 'flex-start',
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  error: {
    border: '1px solid #f44336',
  }
}));

export const Input = basicInputStyles(MuiInput);

export const Select = styled(MuiSelect)<{error?: boolean}>`
    border: 1px solid ${({error}) => error ? '#f44336' : '#D1D4D6'};
    border-radius: 8px;
    padding: 2px 16px 2px 8px;
    background-color: #FFF;
  `;

export const DatePickerTextField = basicInputStyles(TextField);

export const Text = styled.span`
  font-size: 14px;
  line-height: 130%;
  color: #252729;
`;

export const SubText = styled(Text)<{color: string}>`
  font-size: 10px;
  color: ${({ color }) => color};
  display: block;
`;

export const CurrencyInput = styled(CurrencyTextField)<{error?: boolean}>`
  & .MuiInputBase-root {
    border: 1px solid ${({error}) => error ? '#f44336' : '#D1D4D6'};
    border-radius: 8px;
    padding: 2px 16px;
    background-color: #FFF;
    &::after, &::before, &:hover::after, &:hover::before {
    border: 0;}
  }
`;
