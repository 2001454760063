import { OrderProduct } from '../../interfaces/orders';
import Service from '../Service';

const api = new Service({ apiName: 'apiV2', route: '/orderProducts' });

export const update = (productData: Partial<OrderProduct>) =>
  api
    .put(productData.id, productData)
    .then(({ data, ok, message }) => ({
      ok,
      data,
      error: message,
    }))
    .catch((e) => ({ ok: false, error: e?.response?.data?.message }));

export const markAsLookingForSupplier = (id: number) =>
  api
    .put(`${id}/lookingForSupplier`)
    .then(({ data, ok, message }) => ({
      ok,
      data,
      error: message,
    }))
    .catch((e) => ({ ok: false, error: e?.response?.data?.message }));
    
export const markAsShortage = (id: number) =>
  api
    .put(`${id}/shortage`)
    .then(({ data, ok, message }) => ({
      ok,
      data,
      error: message,
    }))
    .catch((e) => ({ ok: false, error: e?.response?.data?.message }));

export const create = (
  supplierOrderId: number,
  dataToSave: Partial<OrderProduct>,
) => {
  const createApi = new Service({
    apiName: 'apiV2',
    route: `/supplierOrders/${supplierOrderId}/attachOrderProducts`,
  });
  return createApi
    .post(dataToSave)
    .then(({ data, ok, message }) => ({
      ok,
      data,
      error: message,
    }))
    .catch((e) => ({ ok: false, error: e?.response?.data?.message }));
};

export const checkIfOfferIsValid = (offerId: number) => {
  const offersApi = new Service({
    apiName: 'marketplace',
    route: `/offers/${offerId}`,
  });
  return offersApi
    .get()
    .then(({ data, ok, message, status }) => ({
      ok,
      data: data.resources,
      error: message,
      status,
    }))
    .catch((e) => ({ ok: false, error: e?.error, status: e?.status, data: e?.message }));
};
