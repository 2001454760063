import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import { IconButton } from '@material-ui/core';
import { AddRounded, CloudDownloadRounded } from '@material-ui/icons';
import SectionContent from './components/SectionContent';
import { Container } from './styles';
import { getDailyExpectedPayments } from '../../redux/actions/audit';
import AuditCreateExpectedPaymentModal from '../Modals/AuditCreateExpectedPaymentModal';
import { download } from '../../services/Download';
import { userIsAdmin } from '../../utils/globalFunctions';
import SectionHeader from '../../sharedComponents/SectionHeader';

const numberFormatter = new Intl.NumberFormat('pt-BR');
const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const AuditDailyPayments = ({ loading, loggedUser }) => {
  const audit = useSelector((state) => state.audit);

  const [formattedExpectedDailyPayments, setFormattedDailyPayments] = useState(
    [],
  );

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [shouldUpdateItem, setShouldUpdateItem] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [dateRange, setDateRange] = useState({
    startDate: new Date(
      moment()
        .startOf('day')
        .toString(),
    ),
    endDate: new Date(
      moment()
        .endOf('day')
        .toString(),
    ),
  });

  const [selectedAcquirer, setSelectedAcquirer] = useState(null);

  const dispatch = useDispatch();

  const handleFetchValues = () => {
    dispatch(
      getDailyExpectedPayments({
        page: page + 1,
        limit,
        dateGte: dateRange.startDate.toDateString(),
        dateLte: dateRange.endDate.toDateString(),
        acquirerId: selectedAcquirer?.id || null,
      }),
    );
  };

  const handleFilter = () => {
    handleFetchValues();
  };

  useEffect(() => {
    handleFetchValues();
  }, [page, limit]);

  useEffect(() => {
    if (audit && audit.docs) {
      setTotalDocs(audit.total);
      setTotalValue(audit.totalValue);
      setFormattedDailyPayments(
        audit.docs.map((item) => ({
          ...item,
          formattedValue: currencyFormatter.format(item.value),
          formattedDailyTransactionsSum: currencyFormatter.format(
            item.expected_daily_ammount,
          ),
          acquirerName: item.acquirer.name,
          referenceDate: moment(item.initial_date)
            .utc()
            .format('DD/MM/YYYY'),
        })),
      );
    }
  }, [audit]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDateRange = (field) => (date) => {
    setDateRange((currentRange) => ({ ...currentRange, [field]: date }));
  };

  const createCallBack = () => {
    setIsCreateModalOpen(true);
  };

  const handleUpdateItem = (id) => {
    setShouldUpdateItem(true);
    setSelectedItem(id);
    setIsCreateModalOpen(true);
  };

  const callBackCloseCreateOrEditModal = () => {
    setIsCreateModalOpen(false);
    setShouldUpdateItem(false);
    setSelectedItem(null);
    setTimeout(() => {
      handleFetchValues();
    }, 1000);
  };

  const handleSelectAcquirer = (data) => {
    setSelectedAcquirer(data);
  };
  const downloadCallback = () =>
    download(
      'audit/dailyExpectedPayments',
      {
        dateGte: dateRange.startDate.toISOString(),
        dateLte: dateRange.endDate.toISOString(),
        isAdmin: userIsAdmin(loggedUser),
      },
      {
        filename: `expectedDailyPayments-${new Date().toISOString()}`,
        fileExtension: 'xlsx',
        callback: () =>
          formattedExpectedDailyPayments.map((item) => ({
            Id: item.id,
            'Data de referência': item.referenceDate,
            Adquirente: item.acquirerName,
            Valor: item.value,
            'Soma Transações': item.expected_daily_ammount,
          })),
      },
    );

  return (
    <>
      <Container>
        <SectionHeader
          title={
            <span>
              Previsão de Pagamentos Diários
              <IconButton
                onClick={createCallBack}
                size="small"
                style={{ marginLeft: '0.5rem' }}
              >
                <AddRounded />
              </IconButton>
              <IconButton
                onClick={downloadCallback}
                size="small"
                style={{ marginLeft: '0.5rem' }}
              >
                <CloudDownloadRounded />
              </IconButton>
            </span>
          }
          hideCompanySelector
          iconButtonStyle={{ padding: '10px' }}
          refDate={dateRange}
          onSelectDateCallback={handleDateRange}
          loggedUser={loggedUser}
          showDateRange
          style={{ marginBottom: 0 }}
          defaultComponentsContainerStyle={{ width: '100%' }}
          showAcquirers
          handleSelectAcquirer={handleSelectAcquirer}
          handleFilter={handleFilter}
        />

        <AuditCreateExpectedPaymentModal
          open={isCreateModalOpen}
          handleCloseModal={callBackCloseCreateOrEditModal}
          dateGte={dateRange.startDate}
          dateLte={dateRange.finalDate}
          shouldUpdateItem={shouldUpdateItem}
          selectedItem={selectedItem}
        />

        <SectionContent
          subtitle={`${numberFormatter.format(
            totalDocs,
          )} transações - Valor bruto total: ${currencyFormatter.format(
            totalValue,
          )}`}
          loading={loading}
          columnsMap={{
            Id: 'id',
            'Data de referência': 'referenceDate',
            Adquirente: 'acquirerName',
            'Valor Informado': 'formattedValue',
            'Valor Transações': 'formattedDailyTransactionsSum',
          }}
          rows={formattedExpectedDailyPayments}
          totalValue={totalValue}
          total={totalDocs}
          limit={limit}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          updateCallBack={handleUpdateItem}
        />
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedUser: state.loggedUser,
  loading: state.ui.dailyExpectedPaymentsLoading,
});

export default connect(mapStateToProps)(AuditDailyPayments);
