import {
  UPDATE_OBJECT_PAYMENT_PLAN,
} from '../actions/paymentPlan';

export function paymentPlanReducer(paymentPlan = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_PAYMENT_PLAN:
      return action.payload;

    default:
      return paymentPlan;
  }
}
