export const GET_PAYMENT_PLANS = '[payment plans] GET';
export const FETCH_PAYMENT_PLANS_SUCCESS = '[payment plans] Fetch success';
export const FETCH_PAYMENT_PLANS_ERROR = '[payment plans] Fetch Error';
export const UPDATE_PAYMENT_PLANS = '[payment plans] Update';
export const SHOW_PAYMENT_PLANS_SPINNER = '[payment plans - ui] show spinner';
export const HIDE_PAYMENT_PLANS_SPINNER = '[payment plans - ui] hide spinner';

export const getPaymentPlans = (filter) => ({
  type: GET_PAYMENT_PLANS,
  payload: {
    ...filter,
  },
});

export const updatePaymentPlans = (data) => ({
  type: UPDATE_PAYMENT_PLANS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_PAYMENT_PLANS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_PAYMENT_PLANS_SPINNER,
});
