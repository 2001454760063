import {
  FETCH_CASH_CLUB_SETTINGS_SUCCESS,
  FETCH_CASH_CLUB_SETTINGS_ERROR,
  GET_CASH_CLUB_SETTINGS,
  updateCashClubSettings,
  showSpinner,
  hideSpinner,
} from '../actions/cashClubSettings';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';
import { URL as CompanyURL } from './company';

const URL = 'cashClubSettings';

export const getCashClubSettingsFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_CASH_CLUB_SETTINGS) {
          dispatch(
            apiRequest(
              'GET',
              `${URL}/last`,
              action.payload,
              FETCH_CASH_CLUB_SETTINGS_SUCCESS,
              FETCH_CASH_CLUB_SETTINGS_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processCashClubSettingsCollection =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_CASH_CLUB_SETTINGS_SUCCESS) {
          dispatch(
            updateCashClubSettings(
              action.payload,
            ),
          );
          dispatch(hideSpinner());
        } else if (action.type === FETCH_CASH_CLUB_SETTINGS_ERROR) {
          Toastify.addError(
            'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
          );
          dispatch(hideSpinner());
        }
      };

export const cashClubSettingsMdl = [getCashClubSettingsFlow, processCashClubSettingsCollection];
