import {
  UPDATE_COMPETITOR_SUCCESS,
  UPDATE_COMPETITOR_ERROR,
  FETCH_COMPETITOR_SUCCESS,
  FETCH_COMPETITOR_ERROR,
  GET_COMPETITOR,
  CREATE_COMPETITOR,
  UPDATE_COMPETITOR,
  updateObjectCompetitor,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/competitor';
import { getCompetitors } from '../actions/competitors';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'competitors';

export const createCompetitorFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === CREATE_COMPETITOR) {
          dispatch(
            apiRequest(
              'POST',
              URL,
              action.payload,
              UPDATE_COMPETITOR_SUCCESS,
              UPDATE_COMPETITOR_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const updateCompetitorFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === UPDATE_COMPETITOR) {
          dispatch(
            apiRequest(
              'PUT',
              URL,
              action.payload,
              UPDATE_COMPETITOR_SUCCESS,
              UPDATE_COMPETITOR_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const getCompetitorFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_COMPETITOR) {
          const { id } = action.payload;
          dispatch(
            apiRequest(
              'GET-id',
              URL,
              { id },
              FETCH_COMPETITOR_SUCCESS,
              FETCH_COMPETITOR_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processCompetitorCollection =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_COMPETITOR_SUCCESS) {
          dispatch(updateObjectCompetitor(action.payload));
          dispatch(hideSpinner());
        } else if (action.type === UPDATE_COMPETITOR_SUCCESS) {
          dispatch(updateObjectCompetitor(action.payload));
          dispatch(hideModal());
          dispatch(hideSpinner());
          // To reload the page
          const { page, limit } = getState().competitors;
          dispatch(getCompetitors({ page, limit }));
          Toastify.addSuccess('Competitor saved.');
        } else if (action.type === UPDATE_COMPETITOR_ERROR) {
          dispatch(hideSpinner());
          Toastify.addError(action.payload || 'Error to save the competitor');
        }
      };

export const competitorMdl = [
  createCompetitorFlow,
  updateCompetitorFlow,
  getCompetitorFlow,
  processCompetitorCollection,
];
