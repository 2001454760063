/* eslint-disable indent */
import {
  FETCH_EMBEDDED_RESOURCES_SUCCESS,
  FETCH_EMBEDDED_RESOURCES_ERROR,
  GET_EMBEDDED_RESOURCES,
  showSpinner,
  hideSpinner,
  storeEmbeddedResources,
} from '../actions/embeddedResources';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'embeddedResources';

export const fetchEmbeddedResources =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === GET_EMBEDDED_RESOURCES) {
      dispatch(
        apiRequest(
          'GET',
          URL,
          action.payload,
          FETCH_EMBEDDED_RESOURCES_SUCCESS,
          FETCH_EMBEDDED_RESOURCES_ERROR,
          {},
          'apiV2',
        ),
      );
      dispatch(showSpinner());
    }
  };

export const handleEmbeddedResourcesResponses =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === FETCH_EMBEDDED_RESOURCES_SUCCESS) {
      dispatch(storeEmbeddedResources(action.payload));
      dispatch(hideSpinner());
    }
    if (action.type === FETCH_EMBEDDED_RESOURCES_ERROR) {
      Toastify.addError(
        'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
      );
      dispatch(hideSpinner());
    }
  };

export const embeddedResourcesMdl = [
  fetchEmbeddedResources,
  handleEmbeddedResourcesResponses,
];
