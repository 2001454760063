import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import toast from '../utils/Toastify';
import Service from './Service';

const defaultDownloader = (data, filename) =>
  FileSaver.saveAs(data, filename || `export-${new Date().getTime()}.txt`);

const downloadXls = (data, filename) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'sheet');
  const buf = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' }); // generate a nodejs buffer
  const str = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' }); // generate a binary string in web browser
  XLSX.writeFile(wb, filename);
};

const fileSaverMap = {
  xlsx: downloadXls,
};

export function download(route, params, options = {}) {
  const {
    callback,
    filename,
    fileExtension,
    responseType,
    forceDefaultDownloaded,
  } = options;
  toast.addInfo('O download deve começar em instantes.');

  const apiService = new Service({ route, apiName: 'apiV2' });
  apiService
    .get({ ...params, listAll: true }, {}, { responseType })
    .then((result) => {
      let { data } = result;
      if (callback && typeof callback === 'function') {
        data = callback(data);
      }
      const fileSaver = forceDefaultDownloaded
        ? defaultDownloader
        : fileSaverMap[fileExtension] || defaultDownloader;
      fileSaver(
        data,
        `${filename || `export-${new Date().getTime()}`}.${fileExtension ||
          'txt'}`,
      );
    })
    .catch((error) => {
      if (!error?.response?.data) return toast.addError(error?.message);
      const reader = new FileReader();
      reader.onload = function() {
        const result = JSON.parse(reader.result);
        toast.addError(result?.error || result?.message || error?.message);
      };
      reader.onerror = function() {
        toast.addError(error?.message);
      };
      reader.readAsText(error?.response?.data);
    });
}
