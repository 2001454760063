import { Button } from '@material-ui/core';
import { Link as LinkRouterDOM } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;

export const PrimaryButton = withStyles({
  root: {
    backgroundColor: '#1A2D78',
    borderRadius: 8,
  },
  label: {
    textTransform: 'none',
    color: '#FFF',
  },
})(Button);

export const SecondaryButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#F28444',
    borderRadius: 8,
    marginLeft: theme.spacing(1),
  },
  label: {
    textTransform: 'none',
    color: '#FFF',
  },
}))(Button);

export const Link = styled(LinkRouterDOM)`
  text-decoration: none;
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: '1rem',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '1rem',
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
  },
  addButton: {
    border: '2px solid var(--primary-text-color)',
    marginLeft: '1rem',
    color: 'var(--primary-text-color)',
  },
}));

export const FilterArea = styled.div`
  margin-top: 24px;
  gap: 16px;
  display: flex;
`;

export const WarningPanel = styled.blockquote`
  background-color: #f4e18c;
  border-color: #ebcd0c;
  width: 100%;
  border-left-width: 8px;
  border-left-style: solid;
  padding: 16px;
  margin-top: 8px;
  color: #4F5253
;
  h4 {
    font-weight: 500;
    font-size: 18px;
  }
  p {
    font-size: 14px;
    font-weight: 300;
  }
`;
