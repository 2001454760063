import { Button, TableCell, TableRow } from '@material-ui/core';
import {
  CompareArrows,
  DeleteOutline,
  Sync,
  SyncProblem,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

export const Row = withStyles({
  root: {
    background: '#FFF',
    '&:nth-of-type(odd)': {
      background: '#F3F2F7',
    },
  },
})(TableRow);
export const Cell = withStyles({
  root: {
    color: '#3B3F41',
    fontSize: 12,
    borderBottom: 0,
    textAlign: 'center',
    minWidth: 152,
    '&.actions': {
      display: 'flex',
      gap: 4,
    },
    '&.with-sub-value': {
      fontSize: 15,
      fontWeight: 600,
      textAlign: 'left',
    },
  },
})(TableCell);

export const ActionButton = withStyles({
  root: {
    borderRadius: 8,
    padding: 8,
    minWidth: 0,
  },
})(Button);
export const ChangeOrderIcon = withStyles({
  root: { color: '#133DA5' },
})(CompareArrows);

export const CancelIcon = withStyles({
  root: { color: '#F95F52' },
})(DeleteOutline);

export const CancelButton = withStyles({
  root: {
    backgroundColor: '#F8BCAE',
    borderRadius: 8,
    padding: 8,
  },
})(ActionButton);

export const RetryIcon = withStyles({
  root: { color: '#F28444' },
})(SyncProblem);

export const SavingIcon = withStyles({
  root: { color: '#133DA5', animation: '$rotation 1s linear infinite' },
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': { transform: 'rotate(360deg)' },
  },
})(Sync);

export const RetryButton = withStyles({
  root: {
    backgroundColor: '#F4E18C',
    borderRadius: 8,
    padding: 8,
  },
})(ActionButton);

export const ChangeOrderButton = withStyles({})(ActionButton);
