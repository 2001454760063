import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import DialogContainer from '../../../sharedComponents/DialogContainer';
import {
  createApiKey,
  hideModal,
  updateObjectApiKey,
} from '../../../redux/actions/apiKey';
import { RootState } from '../../../interfaces/redux';
import Input from '../../../sharedComponents/Input';
import Success from './components/Success';

const ApiKeyModal: React.FC = () => {
  const dispatch = useDispatch();
  const open = useSelector((state: RootState) => state.ui.apiKeyModal);
  const loggedUser = useSelector((state: RootState) => state.loggedUser);
  const apiKey = useSelector((state: RootState) => state.apiKey);
  const loading = useSelector((state: RootState) => state.ui.apiKeyLoading);

  const [name, setName] = React.useState('');
  const [error, setError] = React.useState(false);

  const handleClose = () => {
    dispatch(hideModal());
    dispatch(updateObjectApiKey(null));
  };

  const handleChange = (e: {target: {value: string}}) => {
    setError(false);
    setName(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!name) {
      setError(true);
    }

    dispatch(createApiKey({ name, userId: loggedUser.id }));
  };

  return (
    <DialogContainer open={open}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {apiKey ? 'API Key criada com sucesso!' : 'Criar API Key'}
        </DialogTitle>
        <DialogContent>
          {
            apiKey
              ? <Success />
              : (
                <Input
                  onChange={handleChange}
                  value={name}
                  label="Identificador"
                  icon="tag"
                  error={error ? 'Informe um identificador.' : ''}
                />
              )
          }
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={handleClose}
            disabled={loading}
          >
            {apiKey ? 'Fechar' : 'Cancelar'}
          </Button>
          {!apiKey && (
            <Button
              color="primary"
              type="submit"
              startIcon={
                loading && <CircularProgress size={12} color="inherit" />
              }
            >
              Criar
            </Button>
          )}
        </DialogActions>
      </form>
    </DialogContainer>
  );
};

export default ApiKeyModal;
