import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import {
  CheckCircle,
  CancelRounded,
  EditRounded,
  AddRounded,
} from '@material-ui/icons';
import { useMediaQuery } from '@material-ui/core';
import { getUsers } from '../../redux/actions/users';
import { showModal, hideModal } from '../../redux/actions/user';
import ModalContainer from '../../sharedComponents/ModalContainer/index';
import EditModal from '../Modals/Users';
import Spinner from '../../sharedComponents/Spinner/index';
import { Container, useStyles } from './styles';
import SubHeader from '../../sharedComponents/SubHeader';
import { hasPermission } from '../../utils/globalFunctions';
import PATHS from '../../config/routePaths';
import { getRoles } from '../../redux/actions/roles';

const Users = ({ dispatch, users, userModal, usersLoading }) => {
  const classes = useStyles();
  const [usersFormatted, setUsersFormatted] = useState([]);
  const [modalContentId, setModalContentId] = useState('new');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');
  const roles = useSelector(state => state.roles?.docs || []);


  const isTablet = useMediaQuery('(max-width:812px)');
  const isMobile = useMediaQuery('(max-width:480px)');

  useEffect(() => {
    dispatch(getRoles({listAll: true}));
  }, [])

  useEffect(() => {
    dispatch(getUsers({ page: page + 1, limit }));
  }, [page, limit]);

  useEffect(() => {
    if (users.docs) {
      setUsersFormatted(users.docs.map(user => ({...user, roles: roles.find(role => role.id === user.roleId)})));
      setTotal(users.total);
    }
  }, [users, roles]);

  const handleOpenModal = () => {
    dispatch(showModal());
  };

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  const handleClick = (id) => {
    setModalContentId(id);
    handleOpenModal();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getUsers({ page: 1, limit, query: searchText }));
  };

  return (
    <>
      <ModalContainer open={userModal} handleClose={handleCloseModal}>
        <EditModal id={modalContentId} handleClose={handleCloseModal} />
      </ModalContainer>
      <Container>
        <SubHeader
          title={
            <span>
              Usuários
              <IconButton
                className={classes.addButton}
                onClick={() => handleClick('new')}
                disabled={!hasPermission(PATHS.SETTINGS_USERS, 'create')}
                size="small"
              >
                <AddRounded />
              </IconButton>
            </span>
          }
        >
          <span
            style={{
              paddingTop: 4,
              width: isMobile ? '100%' : undefined,
            }}
          >
            <TextField
              style={{
                width: isTablet ? '100%' : 300,
              }}
              label={searchText ? '' : 'Pesquisar'}
              type="text"
              size="small"
              variant="outlined"
              value={searchText}
              name="vendor_name"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={(e) => (e.key === 'Enter' ? handleSearch(e) : null)}
              fullWidth
              InputProps={{
                style: {
                  borderRadius: '1.5rem',
                  border: '1px solid var(--primary-text-color)',
                  color: 'var(--primary-text-color)',
                },
              }}
              InputLabelProps={{
                shrink: false,
                style: { color: 'var(--primary-text-color)' },
              }}
            />
          </span>
        </SubHeader>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Ativo</TableCell>
                    <TableCell colSpan={2}>Nome</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>Perfil</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersLoading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="7" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!usersLoading &&
                    usersFormatted.map((rr) => (
                      <TableRow className={classes.row}>
                        <TableCell className={classes.cell}>
                          {rr.active ? (
                            <CheckCircle className={classes.checkButton} />
                          ) : (
                            <CancelRounded className={classes.closeButton} />
                          )}
                        </TableCell>
                        <TableCell colSpan={2} className={classes.cell}>
                          {rr.name}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {rr.email}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {rr.roles && rr.roles.name}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <IconButton
                            onClick={() => handleClick(rr.id)}
                            disabled={
                              !hasPermission(PATHS.SETTINGS_USERS, 'update')
                            }
                          >
                            <EditRounded />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Resultados por página"
            />
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  usersLoading: state.ui.usersLoading,
  users: state.users,
  userModal: state.ui.userModal,
});

export default connect(mapStateToProps)(Users);
