import axios from 'axios';
import {
  apiUrlV2,
  authApiUrl,
  marketplaceApiUrl,
  sessionStorageKey,
} from '../config/consts';

export const apiV2 = axios.create({
  baseURL: apiUrlV2,
});

export const authApi = axios.create({
  baseURL: authApiUrl,
});

export const marketplaceApi = axios.create({
  baseURL: marketplaceApiUrl,
});

const getToken = () => {
  const { token } = localStorage.getItem(sessionStorageKey)
    ? JSON.parse(localStorage.getItem(sessionStorageKey))
    : {};
  return token;
};

export const setupAuthentication = (previousToken) => {
  const token = previousToken || getToken();
  authApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  apiV2.defaults.headers.common.Authorization = `Bearer ${token}`;
  marketplaceApi.defaults.headers.common.Authorization = `Bearer ${token}`;
};

setupAuthentication();
