export const GET_CASH_CLUB_SETTING = '[cash club setting] GET';
export const UPDATE_CASH_CLUB_SETTING = '[cash club setting] UPDATE';
export const CREATE_CASH_CLUB_SETTING = '[cash club setting] CREATE';
export const DELETE_CASH_CLUB_SETTING = '[cash club setting] DELETE';
export const UPDATE_OBJECT_CASH_CLUB_SETTING = '[cash club setting] UPDATE OBJECT CASH_CLUB_SETTING';
// UI
export const SHOW_CASH_CLUB_SETTING_MODAL = '[cash club setting] SHOW MODAL';
export const HIDE_CASH_CLUB_SETTING_MODAL = '[cash club setting] HIDE MODAL';
// Spinner
export const UPDATE_CASH_CLUB_SETTING_SUCCESS = '[cash club setting] Update success';
export const UPDATE_CASH_CLUB_SETTING_ERROR = '[cash club setting] Update Error';
export const FETCH_CASH_CLUB_SETTING_SUCCESS = '[cash club setting] Fetch success';
export const FETCH_CASH_CLUB_SETTING_ERROR = '[cash club setting] Fetch Error';
export const SHOW_CASH_CLUB_SETTING_SPINNER = '[cash club setting - ui] show spinner';
export const HIDE_CASH_CLUB_SETTING_SPINNER = '[cash club setting - ui] hide spinner';

export const getCashClubSetting = (id) => ({
  type: GET_CASH_CLUB_SETTING,
  payload: { _id: id },
});

export const createCashClubSetting = (data) => ({
  type: CREATE_CASH_CLUB_SETTING,
  payload: data,
});

export const updateCashClubSetting = (data) => ({
  type: UPDATE_CASH_CLUB_SETTING,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_CASH_CLUB_SETTING_MODAL,
});

export const hideModal = () => ({
  type: HIDE_CASH_CLUB_SETTING_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_CASH_CLUB_SETTING_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_CASH_CLUB_SETTING_SPINNER,
});

export const updateObjectCashClubSetting = (data) => ({
  type: UPDATE_OBJECT_CASH_CLUB_SETTING,
  payload: data,
});
