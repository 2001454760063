import { UPDATE_PAYMENT_METHODS } from '../actions/paymentMethods';

const initState = {
  docs: [],
  page: 1,
  limit: 50,
  totalDocs: 0,
  searchText: '',
};

export function paymentMethodsReducer(paymentMethods = initState, action) {
  switch (action.type) {
    case UPDATE_PAYMENT_METHODS:
      return action.payload;

    default:
      return paymentMethods;
  }
}
