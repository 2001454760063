/* eslint-disable react/require-default-props */
import {
  FormHelperText,
  IconButton,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React from 'react';
import { InputType } from '../interfaces';
import {
  Input,
  SubText,
  Text,
  CurrencyInput,
  DatePickerTextField,
  Select,
} from './styles';

const FormField: React.FC<{
  name: string;
  value: string;
  input?: InputType;
  multiline?: boolean;
  subValue?: string;
  disabled?: boolean;
  options?: { label: string; value: string }[];
  onChange?: (
    e:
      | React.ChangeEvent<{ name?: string; value: unknown }>
      | { target: { name?: string; value: unknown } },
  ) => void;
  subValueColor?: string;
  placeholder?: string;
  error?: string | boolean;
  tooltip?: string | JSX.Element;
}> = ({
  input,
  multiline,
  value,
  subValue,
  name,
  onChange,
  options,
  disabled,
  subValueColor = '#9ea3a5',
  placeholder,
  error,
  tooltip,
}) => {
  const handleCurrency = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
    numberValue: number,
  ) => {
    if (!onChange) return;

    onChange({ ...e, target: { ...e.target, name, value: numberValue } });
  };

  const handleDate = (date: MaterialUiPickersDate) => {
    if (!onChange) return;

    onChange({ target: { name, value: date?.toISOString() } });
  };

  switch (input) {
    case 'date':
      return (
        <DatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          value={value}
          name={name}
          onChange={handleDate}
          InputLabelProps={{
            hidden: true,
          }}
          fullWidth
          InputProps={{ disableUnderline: true }}
          autoOk
          TextFieldComponent={(props) => <DatePickerTextField {...props} />}
          placeholder={placeholder}
        />
      );
    case 'select':
      return (
        <>
          <Select
            multiline={multiline}
            disableUnderline
            fullWidth
            disabled={disabled}
            value={value}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            error={!!error}
            startAdornment={
              tooltip && (
                <Tooltip title={tooltip}>
                  <IconButton size="small">
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              )
            }
          >
            {options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {!!error && typeof error === 'string' && (
            <FormHelperText error>{error}</FormHelperText>
          )}
        </>
      );
    case 'string':
      return (
        <Input
          multiline={multiline}
          disableUnderline
          fullWidth
          disabled={disabled}
          value={value}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          error={!!error}
        />
      );
    case 'currency':
      return (
        <CurrencyInput
          value={value}
          name={name}
          onChange={handleCurrency}
          currencySymbol="R$"
          outputFormat="number"
          decimalCharacter=","
          digitGroupSeparator="."
          placeholder={placeholder}
          error={!!error}
        />
      );
    case 'number':
      return (
        <CurrencyInput
          value={value}
          name={name}
          onChange={handleCurrency}
          currencySymbol=""
          decimalPlaces={3}
          outputFormat="number"
          decimalCharacter=","
          digitGroupSeparator="."
          placeholder={placeholder}
          error={!!error}
        />
      );
    case 'integer':
      return (
        <CurrencyInput
          value={value}
          name={name}
          onChange={handleCurrency}
          currencySymbol=""
          decimalPlaces={0}
          outputFormat="number"
          decimalCharacter=","
          digitGroupSeparator="."
          placeholder={placeholder}
          error={!!error}
        />
      );
    default:
      return (
        <>
          {subValue && <SubText color={subValueColor}>{subValue}</SubText>}
          <Text>{value || '---'}</Text>
        </>
      );
  }
};

export default FormField;
