import currency from 'currency.js';
import { Company } from './companies';
import { PaymentPlans } from './paymentMethods';
import { Supplier } from './suppliers';

export type OrderStatus =
  | 'IN_QUESTION'
  | 'REVIEWED'
  | 'AWAITING_ADJUSTS'
  | 'AWAITING_PAYMENT'
  | 'REQUESTING'
  | 'REQUESTED'
  | 'PARTIALLY_DELIVERED'
  | 'DELIVERED'
  | 'FINISHED'
  | 'CANCELED';

export type SanitizedOrderStatus =
  | 'Em análise'
  | 'Analisado'
  | 'Aguardando ajustes'
  | 'Aguardando pagamento'
  | 'Solicitando com o fornecedor'
  | 'Solicitado'
  | 'Envio iniciado'
  | 'Envio concluído'
  | 'Finalizado'
  | 'Cancelado';

export const statusMap: { [key in OrderStatus]: SanitizedOrderStatus } = {
  IN_QUESTION: 'Em análise',
  REVIEWED: 'Analisado',
  AWAITING_ADJUSTS: 'Aguardando ajustes',
  AWAITING_PAYMENT: 'Aguardando pagamento',
  REQUESTING: 'Solicitando com o fornecedor',
  REQUESTED: 'Solicitado',
  PARTIALLY_DELIVERED: 'Envio iniciado',
  DELIVERED: 'Envio concluído',
  FINISHED: 'Finalizado',
  CANCELED: 'Cancelado',
};

export type SupplierOrderStatus =
  | 'IN_QUESTION'
  | 'BUDGETED'
  | 'REQUESTED'
  | 'INVOICE_GENERATED'
  | 'CHECKED'
  | 'CANCELED';

export type SanitizedSupplierOrderStatus =
  | 'Em análise'
  | 'Orçado'
  | 'Solicitado'
  | 'Nota gerada'
  | 'Conferido'
  | 'Cancelado';

export type CompanySupplierOrderStatus =
  | 'Buscando fornecedor'
  | 'Solicitado'
  | 'Enviado'
  | 'Cancelado';

export const supplierStatusMap: {
  [key in SupplierOrderStatus]: SanitizedSupplierOrderStatus;
} = {
  IN_QUESTION: 'Em análise',
  BUDGETED: 'Orçado',
  REQUESTED: 'Solicitado',
  INVOICE_GENERATED: 'Nota gerada',
  CHECKED: 'Conferido',
  CANCELED: 'Cancelado',
};

export const supplierStatusColorMap: {
  [key in SupplierOrderStatus]: string;
} = {
  IN_QUESTION: '#9EA3A5',
  BUDGETED: '#9EA3A5',
  REQUESTED: '#AFCCE3',
  INVOICE_GENERATED: '#AFD49E',
  CHECKED: '#AFD49E',
  CANCELED: '#F8BCAE',
};

export const completeSupplierStatusColorMap: {
  [key in SupplierOrderStatus]: { main: string; divider: string };
} = {
  IN_QUESTION: { main: '#D1D4D6', divider: '#9EA3A5' },
  BUDGETED: { main: '#D1D4D6', divider: '#9EA3A5' },
  REQUESTED: { main: '#AFCCE3', divider: '#2A96C1' },
  INVOICE_GENERATED: { main: '#AFD49E', divider: '#48B748' },
  CHECKED: { main: '#AFD49E', divider: '#48B748' },
  CANCELED: { main: '#F8BCAE', divider: '#F95F52' },
};

export type OrderProductStatus =
  | 'PRE_SHORTAGE'
  | 'POS_SHORTAGE'
  | 'CANCELED'
  | null;

export type SanitizedOrderProductStatus =
  | 'Pré-faturamento'
  | 'Pós-faturamento'
  | 'Cancelado';

export const orderProductStatusMap: {
  [key in Exclude<OrderProductStatus, null>]: SanitizedOrderProductStatus;
} = {
  PRE_SHORTAGE: 'Pré-faturamento',
  POS_SHORTAGE: 'Pós-faturamento',
  CANCELED: 'Cancelado',
};

export const shortageColorStatus = '#F8BCAE';

export const statusColorMap: { [key in OrderStatus]: string } = {
  IN_QUESTION: '#9EA3A5',
  REVIEWED: '#9EA3A5',
  AWAITING_ADJUSTS: '#9EA3A5',
  AWAITING_PAYMENT: '#9EA3A5',
  REQUESTING: '#9EA3A5',
  REQUESTED: '#AFCCE3',
  PARTIALLY_DELIVERED: '#F4E18C',
  DELIVERED: '#AFD49E',
  FINISHED: '#AFD49E',
  CANCELED: '#F8BCAE',
};

export const completeStatusColorMap: {
  [key in OrderStatus]: { main: string; divider: string };
} = {
  IN_QUESTION: { main: '#D1D4D6', divider: '#9EA3A5' },
  REVIEWED: { main: '#D1D4D6', divider: '#9EA3A5' },
  AWAITING_ADJUSTS: { main: '#D1D4D6', divider: '#9EA3A5' },
  AWAITING_PAYMENT: { main: '#D1D4D6', divider: '#9EA3A5' },
  REQUESTING: { main: '#D1D4D6', divider: '#9EA3A5' },
  REQUESTED: { main: '#AFCCE3', divider: '#2A96C1' },
  PARTIALLY_DELIVERED: { main: '#F4E18C', divider: '#EBCD0C' },
  DELIVERED: { main: '#AFD49E', divider: '#48B748' },
  FINISHED: { main: '#AFD49E', divider: '#48B748' },
  CANCELED: { main: '#F8BCAE', divider: '#F95F52' },
};

export const companyStatusMap: {
  [key in OrderStatus]: SanitizedOrderStatus;
} = {
  IN_QUESTION: 'Em análise',
  REVIEWED: 'Em análise',
  AWAITING_ADJUSTS: 'Aguardando ajustes',
  AWAITING_PAYMENT: 'Aguardando pagamento',
  REQUESTING: 'Solicitando com o fornecedor',
  REQUESTED: 'Solicitado',
  PARTIALLY_DELIVERED: 'Envio iniciado',
  DELIVERED: 'Envio concluído',
  FINISHED: 'Finalizado',
  CANCELED: 'Cancelado',
};

export const companySupplierStatusMap: {
  [key in SupplierOrderStatus]: CompanySupplierOrderStatus;
} = {
  IN_QUESTION: 'Buscando fornecedor',
  BUDGETED: 'Buscando fornecedor',
  REQUESTED: 'Solicitado',
  INVOICE_GENERATED: 'Enviado',
  CHECKED: 'Enviado',
  CANCELED: 'Cancelado',
};

export const paymentConditionMap: { [key: string]: string } = {
  boleto_a_vista: 'Boleto - À Vista - 2,00%',
  boleto_7: 'Boleto - 7 - 1,50%',
  'boleto_7/14': 'Boleto - 7/14 - 1,00%',
  'boleto_14/21': 'Boleto - 14/21 - 0,00%',
  'boleto_7/14/21/28': 'Boleto - 7/14/21/28 - +3,00%',
  boleto_21_28: 'Boleto - 21/28 - +3,00%',
  boleto_21: 'Boleto - 21 + 2,00%',
  split_proximas_4_segundas: 'Split - Próximas 4 segundas-feiras (+3%)',
  pix_a_vista: 'Pix - à vista - 2,00%',
  club_cash: 'Clube Cash - 3,00%',
};

export interface OrderProduct {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  wordpressProductId?: number;
  supplierOrderId: number;
  supplierOrder?: SupplierOrder;
  eanCode?: string;
  productDescription: string;
  quantity: number | string;
  unitPlatformPrice: number | string;
  formattedUnitPlatformPrice: string;
  unitNegotiatedPrice?: number;
  unitTypedPrice?: number;
  unitCostPrice?: number;
  offerId?: number;
  status: OrderProductStatus;
  formattedOriginalTotalValue?: string;
  formattedNegotiatedTotalValue?: string;
  sanitizedStatus: SanitizedOrderProductStatus;
  calculations: {
    originalTotalValue: number;
    negotiatedTotalValue: number;
  };
  unitNegotiatedSupplier?: string;
}

export interface Seller {
  id: number;
  createdAt: string;
  updatedAt: string;
  active?: boolean;
  companyName?: string;
  tradingName?: string;
  document: string;
  documentType?: string;
  stateTaxNumber?: string;
  partnershipFinishedAt?: string;
  partnershipStartedAt?: string;
  street?: string;
  streetNumber?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  deliveryTimeInDays?: number;
  deliveryOnlyOnWorkingDays?: boolean;
  pixKey?: string;
  bankCode?: string;
  bankName?: string;
  bankBranch?: string;
  bankAccount?: string;
  defaultPaymentMethod?: string;
  defaultPaymentOption?: string;
  comissionValue?: number;
  isFlexTable?: boolean;
  image?: string;
}

export interface SupplierOrder {
  id: number;
  code: number;
  createdAt: Date;
  updatedAt: Date;
  orderId: number;
  order?: Order;
  supplierDocument: string;
  deliveryValueCents?: number;
  status: SupplierOrderStatus;
  formattedStatus: SanitizedSupplierOrderStatus;
  orderProducts: OrderProduct[];
  deliveryDate?: string | Date;
  supplier?: Seller;
  supplierOrderNumber?: string;
  typedTotal?: string;
  supplierOrderNo?: string;
  paymentPlanId?: number;
  formattedDeliveryDate?: string;
  paymentMethod?: number;
  receipt?: string;
  observation?: string;
  invoiceNumber?: string;
  invoiceKey?: string;
  ticket?: string;
  paymentPlan?: PaymentPlans;
  formattedOriginalTotalValue?: string;
  formattedNegotiatedTotalValue?: string;
  formattedNegotiatedDiscount?: string;
  formattedPreShortageDiscount?: string;
  formattedTotalValue?: string;
  formattedEstimatedDeliveryDate?: string;
  companyStatus?: CompanySupplierOrderStatus;
  calculations: {
    originalTotalValue: number;
    negotiatedTotalValue: number;
    negotiatedDiscount: number;
    preShortageDiscount: number;
    totalValue: number;
    estimatedDeliveryDate: string;
  };
}

export interface Order {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  wordpressOrderId: number;
  wordpressOrderDate: Date | string;
  formattedOrderDate?: string;
  companyDocument: string;
  paymentMethod?: string;
  paymentCondition?: string;
  paymentMethodId?: number;
  paymentPlanId?: number | string;
  paymentPlan?: PaymentPlans;
  discountCoupon?: string;
  invoice?: string;
  receipt?: string;
  observation?: string;
  status: OrderStatus;
  formattedStatus: SanitizedOrderStatus;
  supplierOrders: SupplierOrder[];
  company?: Company | string;
  formattedTotalValue?: string;
  subTotal?: string;
  discount?: number | string;
  orderNumber?: number;
  formattedEstimatedDeliveryDate: string;
  deliveryDate?: string | Date;
  formattedNegotiatedDiscount?: string;
  formattedOriginalTotalValue?: string;
  formattedNegotiatedTotalValue?: string;
  formattedDeliveryDate?: string;
  documentWithCorporateName?: string;
  formattedPreShortageDiscount?: string;
  formattedTax?: string;
  formattedFinalTotalValue?: string;
  formattedDiscount?: string;
  companyStatus?: SanitizedOrderStatus;
  calculations: {
    originalTotalValue: number;
    negotiatedTotalValue: number;
    negotiatedDiscount: number;
    preShortageDiscount: number;
    totalValue: number;
    tax: number;
    finalTotalValue: number;
    estimatedDeliveryDate: string;
  };
}
