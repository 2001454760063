import React from 'react';
import {
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import currency from 'currency.js';
import { RootState } from '../../../interfaces/redux';
import {
  createPaymentPlan,
  getPaymentPlan,
  hideModal,
  updatePaymentPlan,
} from '../../../redux/actions/paymentPlan';
import DialogContainer from '../../../sharedComponents/DialogContainer';
import Input from '../../../sharedComponents/Input';
import { Props } from './interfaces';
import {
  Container,
  Title,
  Divider,
  ConfirmButton,
  CancelButton,
  ButtonContainer,
} from './styles';
import { getPaymentMethods } from '../../../redux/actions/paymentMethods';
import {
  PaymentCondition,
  PaymentMethod,
} from '../../../interfaces/paymentMethods';
import { getPaymentConditions } from '../../../redux/actions/paymentConditions';

const PaymentPlanModal: React.FC<Props> = ({ editing }) => {
  const [displayName, setDisplayName] = React.useState<string>('');
  const [enabledToBakeries, setEnabledToBakeries] = React.useState<boolean>(
    true,
  );
  const [enabledToSuppliers, setEnabledToSuppliers] = React.useState<boolean>(
    true,
  );
  const [usesGateway, setUsesGateway] = React.useState<boolean>(true);
  const [taxFees, setTaxFees] = React.useState<string>('');
  const [paymentConditionId, setPaymentConditionId] = React.useState<number>();
  const [paymentMethodId, setPaymentMethodId] = React.useState<number>();

  const planToEdit = useSelector((state: RootState) => state.paymentPlan);

  const open = useSelector((state: RootState) => state.ui.paymentPlanModal);

  const methods = useSelector(
    (state: RootState) => state.paymentMethods?.docs || [],
  );
  const conditions = useSelector(
    (state: RootState) => state.paymentConditions?.docs || [],
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getPaymentMethods({ listAll: true }));
    dispatch(getPaymentConditions({ listAll: true }));
  }, []);

  React.useEffect(() => {
    if (!open) {
      setDisplayName('');
      setEnabledToBakeries(true);
      setEnabledToSuppliers(true);
      setTaxFees('');
      setPaymentConditionId(undefined);
      setPaymentMethodId(undefined);
      setUsesGateway(true);
      return;
    }
    dispatch(getPaymentPlan(editing));
  }, [editing, open]);

  React.useEffect(() => {
    if (!planToEdit.id) return;
    setDisplayName(planToEdit.displayName);
    setEnabledToBakeries(planToEdit.enabledToBakeries);
    setEnabledToSuppliers(planToEdit.enabledToSuppliers);
    setTaxFees(
      currency(planToEdit.taxFees, { precision: 4 })
        .multiply(100)
        .value.toString(),
    );
    setPaymentConditionId(planToEdit.paymentConditionId);
    setPaymentMethodId(planToEdit.paymentMethodId);
    setUsesGateway(planToEdit.usesGateway);
  }, [planToEdit]);

  const handleClose = () => {
    dispatch(hideModal());
  };

  const handleChange = (e: {
    target: {
      name?: string | undefined;
      value: string | number;
      checked?: boolean;
    };
  }) => {
    const { name: key, value, checked } = e.target;
    if (!key) return;

    switch (key) {
      case 'displayName':
        setDisplayName(value as string);
        break;
      case 'taxFees':
        setTaxFees(value as string);
        break;
      case 'paymentConditionId':
        setPaymentConditionId(value as number);
        break;
      case 'paymentMethodId':
        setPaymentMethodId(value as number);
        break;
      case 'enabledToBakeries':
        setEnabledToBakeries(checked || false);
        break;
      case 'enabledToSuppliers':
        setEnabledToSuppliers(checked || false);
        break;
      case 'usesGateway':
        setUsesGateway(checked || false);
        break;
      default:
        break;
    }
  };

  const handleSave: React.FormEventHandler = (e) => {
    e.preventDefault();
    if (editing === undefined) {
      dispatch(
        createPaymentPlan({
          displayName,
          taxFees: currency(taxFees, { precision: 4 }).divide(100),
          enabledToBakeries,
          enabledToSuppliers,
          paymentConditionId,
          paymentMethodId,
          usesGateway,
        }),
      );
    } else {
      dispatch(
        updatePaymentPlan({
          id: editing,
          displayName,
          taxFees: currency(taxFees, { precision: 4 }).divide(100),
          enabledToBakeries,
          enabledToSuppliers,
          paymentConditionId,
          paymentMethodId,
          usesGateway,
        }),
      );
    }
  };

  return (
    <DialogContainer open={open} onClose={handleClose}>
      <Container>
        <Title>
          {editing === undefined ? 'Novo' : 'Editar'} Plano de Pagamento
        </Title>
        <Divider />
        <form onSubmit={handleSave}>
          <Box display="flex" style={{ gap: 16 }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={enabledToBakeries}
                    onChange={handleChange}
                    name="enabledToBakeries"
                  />
                }
                label="Padarias"
              />
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={enabledToSuppliers}
                    onChange={handleChange}
                    name="enabledToSuppliers"
                  />
                }
                label="Fornecedores"
              />
            </FormGroup>
          </Box>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={usesGateway}
                  onChange={handleChange}
                  name="usesGateway"
                />
              }
              label="Este plano de pagamento utiliza o gateway de pagamento?"
            />
          </FormGroup>
          <Input
            style={{ marginBottom: 8 }}
            fullWidth
            name="displayName"
            label="Apelido"
            icon="account_tree"
            value={displayName}
            onChange={handleChange}
          />
          <Input
            style={{ marginBottom: 8 }}
            fullWidth
            name="paymentMethodId"
            label="Método"
            icon="payments"
            value={paymentMethodId}
            onChange={handleChange}
            select
          >
            {methods.map(({ id, name }: PaymentMethod) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Input>
          <Input
            style={{ marginBottom: 8 }}
            fullWidth
            name="paymentConditionId"
            label="Condições"
            icon="currency_exchange"
            value={paymentConditionId}
            onChange={handleChange}
            select
          >
            {conditions.map(({ id, name }: PaymentCondition) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Input>

          <Input
            style={{ marginBottom: 8 }}
            fullWidth
            name="taxFees"
            step={0.01}
            label="Taxa"
            type="number"
            value={taxFees}
            onChange={handleChange}
            icon="percent"
            helperText="Insira um valor positivo para acréscimos e negativo para descontos e use ponto (.) para casas decimais."
          />
          <ButtonContainer>
            <CancelButton onClick={handleClose}>Cancelar</CancelButton>
            <ConfirmButton type="submit">Salvar</ConfirmButton>
          </ButtonContainer>
        </form>
      </Container>
    </DialogContainer>
  );
};

export default PaymentPlanModal;
