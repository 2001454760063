import { UPDATE_PAYMENT_PLANS } from '../actions/paymentPlans';

const initState = {
  docs: [],
  page: 1,
  limit: 50,
  totalDocs: 0,
  searchText: '',
};

export function paymentPlansReducer(paymentPlans = initState, action) {
  switch (action.type) {
    case UPDATE_PAYMENT_PLANS:
      return action.payload;

    default:
      return paymentPlans;
  }
}
