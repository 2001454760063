import createTheme from '@material-ui/core/styles/createTheme';
import { ptBR } from '@material-ui/core/locale';

export const colors = {
  primary: { main: '#133DA5' },
  secondary: { main: '#F28444', contrastText: '#5b4f41' },
  tertiary: { main: '#EF9A91' },
  quaternary: { main: '#F1E6B9' },
  success: { main: '#48B748' },
  danger: { main: '#F95F52' },
  warning: { main: '#EBCD0C', light: '#F4E18C' },
  info: { main: '#2A96C1' },
  white: { main: '#FFFFFF' },
  gray1: { main: '#FCFCFC' },
  gray2: { main: '#F3F2F7' },
  gray3: { main: '#D0D6DE' },
  gray4: { main: '#B9BBBD' },
  gray5: { main: '#A3A3A3' },
  body: { main: '#464255' },
  dark: { main: '#464255' },
  background: { main: '#f3f2f7' },
};

export const theme = createTheme(
  {
    typography: {
      fontFamily: ['Poppins', 'sans-serif'],
    },
    palette: colors,
  },
  ptBR,
);
