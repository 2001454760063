import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import HomeCard from './components/HomeCard';
import SectionHeader from '../../sharedComponents/SectionHeader';
import { Container } from './styles';
import iconic from '../../assets/images/home-iconic.jpg';
import credit from '../../assets/images/home-credit.jpg';
import extract from '../../assets/images/home-extract.jpg';
import notable from '../../assets/images/home-notable.jpg';
import shopping from '../../assets/images/home-shopping.jpg';
import transfer from '../../assets/images/home-transfer.jpg';
import payments from '../../assets/images/home-payments.jpg';
import orders from '../../assets/images/orders.jpg';
import apiKey from '../../assets/images/apiKey.png';
import receipts from '../../assets/images/receipts.jpg';
import { hasPermission } from '../../utils/globalFunctions';
import PATHS, { INTEGRATIONS } from '../../config/routePaths';
import Spinner from '../../sharedComponents/Spinner';

const cards = [
  {
    title: 'Compras',
    subtitle: 'Conheça nosso Marketplace!',
    image: shopping,
    color: 'rgba(88, 205, 255, 0.78)',
    subColor: 'rgba(88, 205, 255, 0.32)',
    pathPermission: PATHS.MARKETPLACE,
    link: INTEGRATIONS.MARKETPLACE,
  },
  {
    title: 'Precificação de notáveis',
    subtitle: 'Clique aqui e veja nossas sugestões.',
    image: notable,
    color: 'rgba(239, 154, 145, 0.78)',
    subColor: 'rgba(239, 154, 145, 0.32)',
    pathPermission: PATHS.SALES_INTELLIGENCE_NOTABLES,
    link: PATHS.SALES_INTELLIGENCE_NOTABLES,
  },
  {
    title: 'Precificação icônicos',
    subtitle: 'Clique aqui e veja nossas sugestões.',
    image: iconic,
    color: 'rgba(249, 184, 112, 0.78)',
    subColor: 'rgba(249, 184, 112, 0.32)',
    pathPermission: PATHS.SALES_INTELLIGENCE_PRICES,
    link: PATHS.SALES_INTELLIGENCE_PRICES,
  },
  {
    title: 'Extratos',
    subtitle: 'Acompanhe as transações da sua padaria.',
    image: extract,
    color: 'rgba(249, 184, 112, 0.78)',
    subColor: 'rgba(249, 184, 112, 0.32)',
    pathPermission: PATHS.FINANCE_EXTRACTS,
    link: PATHS.FINANCE_EXTRACTS,
  },
  {
    title: 'Transferências',
    subtitle: 'Acompanhe aqui os repasses feitos para a sua padaria.',
    image: transfer,
    color: 'rgba(0, 163, 137, 0.78)',
    subColor: 'rgba(0, 163, 137, 0.32)',
    pathPermission: PATHS.FINANCE_TRANSFERS,
    link: PATHS.FINANCE_TRANSFERS,
  },
  {
    title: 'Crédito',
    subtitle: 'Acompanhe as suas próximas parcelas.',
    image: credit,
    color: 'rgba(25, 45, 77, 0.78)',
    subColor: 'rgba(25, 45, 77, 0.32)',
    pathPermission: PATHS.FINANCE_LOANS,
    link: PATHS.FINANCE_LOANS,
  },
  {
    title: 'Boletos e Pagamentos',
    subtitle: 'Cheque os pagamentos pendentes e concluídos.',
    image: payments,
    color: 'rgba(88, 205, 255, 0.78)',
    subColor: 'rgba(88, 205, 255, 0.32)',
    pathPermission: PATHS.FINANCE_PAYMENTS,
    link: PATHS.FINANCE_PAYMENTS,
  },
  {
    title: 'Pedidos Realizados',
    subtitle:
      'Clique aqui e veja todos os pedidos realizados em nossa plataforma.',
    image: orders,
    color: 'rgba(239, 154, 145, 0.78)',
    subColor: 'rgba(239, 154, 145, 0.32)',
    pathPermission: PATHS.ORDERS,
    link: PATHS.ORDERS,
  },
  {
    title: 'Importação de Notas Fiscais',
    subtitle: 'Importe notas fiscais dos clientes e acompanhe as últimas importações.',
    image: receipts,
    color: 'rgba(249, 184, 112, 0.78)',
    subColor: 'rgba(249, 184, 112, 0.32)',
    pathPermission: PATHS.JOBS,
    link: PATHS.JOBS,
  },
  {
    title: 'API Keys',
    subtitle: 'Gerencie as chaves utilizadas para integração com nossa API.',
    image: apiKey,
    color: 'rgba(0, 163, 137, 0.78)',
    subColor: 'rgba(0, 163, 137, 0.32)',
    pathPermission: PATHS.API_KEYS,
    link: PATHS.API_KEYS,
  },
];

const Home = () => {
  const filterByPermission = ({ pathPermission }) =>
    hasPermission(pathPermission);

  const loadingModules = useSelector((state) => state.ui.modulesLoading);

  return (
    <Container>
      <SectionHeader
        title="Bem vindo ao painel Bakery"
        hideDatePicker
        hideCompanySelector
      />
      <Grid container spacing={4}>
        {loadingModules ? (
          <Spinner color="#000" />
        ) : (
          cards.filter(filterByPermission).map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item.pathPermission}>
              <HomeCard {...item} />
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  );
};

export default Home;
