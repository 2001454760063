import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Add, CancelOutlined, CheckCircleOutline } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { RootState } from '../../interfaces/redux';
import { getApiKeys } from '../../redux/actions/apiKeys';
import ApiKeyModal from '../Modals/ApiKey';
import { invalidateApiKey, showModal } from '../../redux/actions/apiKey';
import { ApiKey } from '../../interfaces/apiKeys';
import Pagination from '../../sharedComponents/Pagination';

const columns: { key: keyof ApiKey; label: string; formatter?: (value: unknown) => React.ReactNode }[] = [
  {
    key: 'active',
    label: 'Ativo',
    formatter: (value: unknown) => (
      value as boolean
        ? <CheckCircleOutline htmlColor="#4caf50" />
        : <CancelOutlined color="error" />
    ),
  },
  { key: 'name', label: 'Identificador' },
  { key: 'publicKey', label: 'Public Key' },
  { key: 'lastDigits', label: 'API Key', formatter: (value: unknown) => (value as string).padStart(32, '*') },
];

const ApiKeys = () => {
  const dispatch = useDispatch();
  const apiKeys = useSelector((state: RootState) => state.apiKeys.resources.docs || []);
  const loading = useSelector((state: RootState) => state.ui.apiKeysLoading);
  const pages = useSelector((state: RootState) => state.apiKeys.resources.pages);
  const total = useSelector((state: RootState) => state.apiKeys.resources.total);
  const userId = useSelector((state: RootState) => state.loggedUser.id);

  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    dispatch(getApiKeys({ page, userId }));
  }, [page]);

  const handleOpenModal = () => {
    dispatch(showModal());
  };

  const handleInvalidate = (id: number) => {
    dispatch(invalidateApiKey(id));
  };

  const handleInvalidateClick = (data: ApiKey) => async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Tem certeza?',
      html: `<span>Tem certeza que deseja invalidar a chave <b>${data.name}</b> terminada em '<i>${data.lastDigits}</i>''? Esta chave não poderá ser usada novamente.</span>`,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Invalidar',
      icon: 'warning',
      showCancelButton: true,
    });

    if (isConfirmed) handleInvalidate(data.id);
  };

  return (
    <Box p={2}>
      <Box display="flex" flex={1} justifyContent="space-between">
        <Typography variant="h5">API Keys</Typography>
        <Button startIcon={<Add />} color="primary" variant="contained" onClick={handleOpenModal}>
          Novo
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(({ label, key }) => (
              <TableCell key={key}>{label}</TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {(!apiKeys.length && !loading) && (
            <TableRow>
              <TableCell colSpan={columns.length + 1} align="center">
                Nenhuma API Key cadastrada
              </TableCell>
            </TableRow>
          )}
          {loading
            ? (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                  <CircularProgress color="primary" />
                </TableCell>
              </TableRow>
            )
            : apiKeys.map((apiKey) => (
              <TableRow key={apiKey.id}>
                {columns.map(({ key, formatter }) => (
                  <TableCell key={`${key}${apiKey.id}`}>
                    {formatter ? formatter(apiKey[key]) : apiKey[key]}
                  </TableCell>
                ))}
                <TableCell>
                  {
                    apiKey.active && (
                      <Button
                        variant="outlined"
                        startIcon={<CancelOutlined />}
                        color="secondary"
                        onClick={handleInvalidateClick(apiKey)}
                      >
                        Invalidar
                      </Button>
                    )
                  }
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={columns.length + 1} align="center">
              <Pagination
                onChangePage={setPage}
                page={page}
                pages={pages}
                total={total}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <ApiKeyModal />
    </Box>
  );
};

export default ApiKeys;
