import React from 'react';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CancelOutlined, CheckCircleOutline } from '@material-ui/icons';
import { Order, OrderProduct } from '../../../../interfaces/orders';
import { RootState } from '../../../../interfaces/redux';
import { getOrder } from '../../../../redux/actions/order';
import {
  markAsLookingForSupplier,
  markAsShortage,
  update,
} from '../../../../services/OrderProducts';
import Alert from '../../../../sharedComponents/Alert';
import Toastify from '../../../../utils/Toastify';
import FormField from '../FormField';
import { OrderProductColumn, OrderProductWithSupplier } from '../interfaces';
import {
  ActionButton,
  CancelButton,
  CancelIcon,
  Cell,
  ChangeOrderButton,
  ChangeOrderIcon,
  RetryButton,
  RetryIcon,
  Row,
  SavingIcon,
} from './styles';

const MAXIMUM_RATE = 0.3;

const ConditionalTooltip: React.FC<React.PropsWithChildren<{
  percentage: string;
  supplier: string;
  render: boolean;
}>> = ({ percentage, render, supplier, children }) =>
  render ? (
    <Tooltip
      PopperProps={{
        style: {
          marginTop: 14,
          display: parseInt(percentage) === 100 ? 'none' : undefined,
        },
      }}
      arrow
      title={
        <>
          <p>Diferença de {percentage}%</p>
          <p>Fornecedor: {supplier}</p>
        </>
      }
    >
      <span>{children}</span>
    </Tooltip>
  ) : (
    <>{children}</>
  );

const ProductRow: React.FC<{
  product: OrderProductWithSupplier;
  columns: OrderProductColumn[];
  hideActions?: boolean;
}> = ({ product, columns, hideActions }) => {
  const [data, setData] = React.useState<OrderProductWithSupplier>(product);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [
    menuAnchorElement,
    setMenuAnchorElement,
  ] = React.useState<null | HTMLElement>(null);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [editing, setEditing] = React.useState<boolean>(false);
  const contentRef = React.useRef<HTMLTableRowElement>(null);
  const actionRef = React.useRef<HTMLTableCellElement>(null);

  React.useEffect(() => {
    if (!product) return;
    setData(product);
  }, [product]);

  const dispatch = useDispatch();

  const order = useSelector((state: RootState) => state.order) as Order;

  const { isNegotiatedTooLow, percentage } = React.useMemo(() => {
    const rate =
      1 - (data.unitNegotiatedPrice || 0) / (data.unitPlatformPrice as number);
    return {
      isNegotiatedTooLow: rate > MAXIMUM_RATE,
      percentage: (rate * 100).toLocaleString('pt-BR', {
        maximumFractionDigits: 2,
      }),
    };
  }, [data.unitNegotiatedPrice, data.unitPlatformPrice]);

  const handleSave = async (
    dataToSave: Partial<OrderProduct>,
    refresh: boolean,
  ) => {
    setSaving(true);
    const { ok, error } = await update(dataToSave);
    setSaving(false);
    if (ok) {
      setHasError(false);
      if (refresh) dispatch(getOrder(order.id));
      return;
    }
    if (!refresh) setHasError(true);
    Toastify.addError(error);
  };

  const handleTry = async () => {
    const dataToSave = (({
      id,
      quantity,
      unitNegotiatedPrice,
      unitTypedPrice,
      unitCostPrice,
    }: OrderProduct) => ({
      id,
      quantity,
      unitNegotiatedPrice,
      unitTypedPrice,
      unitCostPrice,
    }))(data);
    await handleSave(dataToSave, false);
    setEditing(false);
  };

  const handleBlur: React.FocusEventHandler<HTMLTableCellElement> = (e) => {
    const { relatedTarget } = e;
    if (
      (contentRef.current?.contains(relatedTarget) &&
        !actionRef.current?.contains(relatedTarget)) ||
      !editing
    ) {
      return;
    }
    handleTry();
  };

  const handleChangeOrderClick: React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    setMenuAnchorElement(e.currentTarget);
  };

  const handleCloseSupplierMenu = () => {
    setMenuAnchorElement(null);
  };

  const handleChangeOrder = (id: number) => () => {
    handleSave({ id: product.id, supplierOrderId: id }, true);
    handleCloseSupplierMenu();
  };

  const handleLookingForSupplier = async () => {
    const { ok, error } = await markAsLookingForSupplier(product.id);
    if (ok) {
      setHasError(false);
      dispatch(getOrder(order.id));
      return;
    }
    setHasError(true);
    Toastify.addError(error);
    handleCloseSupplierMenu();
  };

  const handleShortage = async () => {
    const { ok, error } = await markAsShortage(product.id);
    if (ok) {
      setHasError(false);
      dispatch(getOrder(order.id));
      return;
    }
    setHasError(true);
    Toastify.addError(error);
    handleCloseSupplierMenu();
  };

  const handleChange = (
    e:
      | React.ChangeEvent<{ name?: string; value: unknown }>
      | { target: { name?: string; value: unknown } },
  ) => {
    const { name, value } = e.target;
    if (!name) return;
    setData((currentState) => ({
      ...currentState,
      [name]: value,
    }));
    setEditing(true);
  };

  const handleCancel = async () => {
    const response = await Alert(
      'Tem certeza?',
      'O produto será cancelado e não estará mais visível.',
    );
    if (!response?.confirm) return;

    handleSave({ id: product.id, status: 'CANCELED' }, true);
  };

  return (
    <Row key={product.id} ref={contentRef}>
      {columns.map(({ key, subKey, input }) => (
        <Cell
          key={key}
          className={subKey ? 'with-sub-value' : ''}
          onBlur={handleBlur}
        >
          <ConditionalTooltip
            render={key === 'unitNegotiatedPrice'}
            percentage={percentage}
            supplier={data.unitNegotiatedSupplier || 'N/A'}
          >
            {key === 'status' ? (
              <>
                {data[key] === 'CANCELED' ? (
                  <CancelOutlined color="error" />
                ) : (
                  <CheckCircleOutline style={{ color: 'green' }} />
                )}
              </>
            ) : (
              <FormField
                name={key}
                value={data[key] as string}
                input={input}
                subValue={subKey && (data[subKey] as string)}
                subValueColor="#3B3F41"
                onChange={handleChange}
                error={
                  key === 'unitNegotiatedPrice' &&
                  isNegotiatedTooLow &&
                  !!data.unitNegotiatedPrice
                }
              />
            )}
          </ConditionalTooltip>
        </Cell>
      ))}
      {!hideActions && (
        <Cell className="actions" ref={actionRef}>
          {saving && (
            <ActionButton>
              <SavingIcon />
            </ActionButton>
          )}
          {hasError && (
            <Tooltip title="Tentar novamente">
              <RetryButton onClick={handleTry}>
                <RetryIcon />
              </RetryButton>
            </Tooltip>
          )}
          <Tooltip title="Trocar Fornecedor">
            <ChangeOrderButton onClick={handleChangeOrderClick}>
              <ChangeOrderIcon />
            </ChangeOrderButton>
          </Tooltip>
          <Menu
            onClose={handleCloseSupplierMenu}
            open={!!menuAnchorElement}
            anchorEl={menuAnchorElement}
          >
            {order.supplierOrders
              .filter(({ supplierDocument }) => supplierDocument !== '0')
              .map(({ supplier, id }) => (
                <MenuItem key={id} onClick={handleChangeOrder(id)} button>
                  {supplier?.tradingName}
                </MenuItem>
              ))}
            <MenuItem button onClick={handleLookingForSupplier}>
              Buscando Fornecedor
            </MenuItem>
            <MenuItem button onClick={handleShortage}>
              Ruptura
            </MenuItem>
          </Menu>
          <Tooltip title="Cancelar">
            <CancelButton onClick={handleCancel}>
              <CancelIcon />
            </CancelButton>
          </Tooltip>
        </Cell>
      )}
    </Row>
  );
};

export default ProductRow;
