import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, useStyles, FilterArea, WarningPanel, Link } from './styles';
import { getOrders } from '../../redux/actions/orders';
import { RootState } from '../../interfaces/redux';
import Card from '../../sharedComponents/OrderCard';
import { Order } from '../../interfaces/orders';
import { Company } from '../../interfaces/companies';
import { getCompanies } from '../../redux/actions/companies';
import { formatOrders } from '../../utils/formatOrder';
import Spinner from '../../sharedComponents/Spinner';
import CompanySelector from '../../sharedComponents/CompanySelector';
import SubHeader from '../../sharedComponents/SubHeader';
import Pagination from '../../sharedComponents/Pagination';
import PATHS from '../../config/routePaths';
import { ExpandIcon } from '../../sharedComponents/OrderCard/styles';

const orderFields: { [key: string]: string } = {
  formattedOrderDate: 'Data',
  formattedFinalTotalValue: 'Total',
  company: 'Panificador',
  'paymentPlan.displayName': 'Pagamento',
};

const Orders: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const companies: Company[] = useSelector(
    (state: RootState) => state.companies?.docs || state.companies,
  );

  const company: Company = useSelector((state: RootState) => state.company);

  const orders: Order[] = useSelector((state: RootState) =>
    formatOrders(state.orders?.docs || [], companies, true),
  );
  const {
    total,
    pages,
    limit,
  }: { total: number; pages: number; limit: number } = useSelector(
    (state: RootState) => state.orders,
  );

  const loading: boolean = useSelector(
    (state: RootState) => state.ui.ordersLoading,
  );

  useEffect(() => {
    dispatch(
      getOrders({
        page,
        limit: 20,
        'filter[companyDocument]': company.document,
        'filter[ordersWithProducts]': true,
      }),
    );
  }, [company, page]);

  useEffect(() => {
    if (companies && companies.length > 0) return;
    dispatch(getCompanies({ listAll: true }));
  }, [companies]);

  return (
    <Container>
      <SubHeader
        title="Acompanhamento de pedidos"
        subtitle={
          <FilterArea>
            <CompanySelector />
          </FilterArea>
        }
      />
      {loading ? (
        <Spinner color="#133DA5" />
      ) : (
        <>
          <WarningPanel>
            <h4>Olá, parceiro!</h4>
            <p>
              Estamos fazendo algumas alterações, caso precise de informação
              sobre algum pedido anterior entre em contato no nosso canal de
              atendimento oficial:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/5527996393006"
              >
                +55 27 99639-3006
              </a>
              .
            </p>
            <p>Agradecemos a compreensão!</p>
          </WarningPanel>
          <div className={classes.root}>
            {orders.map((order) => (
              <Link to={`${PATHS.ORDER}/${order.id}`}>
                <Card
                  key={order.id}
                  order={order}
                  fields={orderFields}
                  ActionComponent={() => <ExpandIcon>chevron_right</ExpandIcon>}
                />
              </Link>
            ))}
          </div>
          <Pagination
            page={page}
            total={total}
            onChangePage={setPage}
            pages={pages}
            showing={orders.length}
            limit={limit}
          />
        </>
      )}
    </Container>
  );
};

export default Orders;
