import {
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_ERROR,
  FETCH_PAYMENT_METHOD_SUCCESS,
  FETCH_PAYMENT_METHOD_ERROR,
  GET_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD,
  updateObjectPaymentMethod,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/paymentMethod';
import { getPaymentMethods } from '../actions/paymentMethods';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'paymentMethods';

export const createPaymentMethodFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === CREATE_PAYMENT_METHOD) {
          dispatch(
            apiRequest(
              'POST',
              URL,
              action.payload,
              UPDATE_PAYMENT_METHOD_SUCCESS,
              UPDATE_PAYMENT_METHOD_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const updatePaymentMethodFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === UPDATE_PAYMENT_METHOD) {
          dispatch(
            apiRequest(
              'PUT',
              URL,
              action.payload,
              UPDATE_PAYMENT_METHOD_SUCCESS,
              UPDATE_PAYMENT_METHOD_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const getPaymentMethodFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_PAYMENT_METHOD) {
          const { _id } = action.payload;
          dispatch(
            apiRequest(
              'GET-id',
              `${URL}/index`,
              { _id },
              FETCH_PAYMENT_METHOD_SUCCESS,
              FETCH_PAYMENT_METHOD_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processPaymentMethodCollection =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_PAYMENT_METHOD_SUCCESS) {
          dispatch(updateObjectPaymentMethod(action.payload));
          dispatch(hideSpinner());
        } else if (action.type === UPDATE_PAYMENT_METHOD_SUCCESS) {
          dispatch(hideModal());
          dispatch(hideSpinner());
          const { page, limit } = getState().paymentMethods;
          dispatch(getPaymentMethods({ page, limit }));
          Toastify.addSuccess('Operação concluída com sucesso.');
        } else if (action.type === UPDATE_PAYMENT_METHOD_ERROR) {
          dispatch(hideSpinner());

          Toastify.addError(action.payload.message ||
              'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
          );
        }
      };

export const paymentMethodMdl = [
  createPaymentMethodFlow,
  updatePaymentMethodFlow,
  getPaymentMethodFlow,
  processPaymentMethodCollection,
];
