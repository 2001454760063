/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import { cpf } from 'cpf-cnpj-validator';

import Date from 'yup/lib/date';
import moment from 'moment';
import {
  AddAddressButton,
  AddUserButton,
  CheckboxContent,
  ContainerInfor,
  ContainerToggle,
  ContentName,
  ContentStatus,
  DateContainer,
  DateContainerStatus,
  DateMainContainer,
  DividerForm,
  GreenSwitch,
  InputContent,
  InputFieldTitle,
  InputSelect,
  LabelInput,
  StyledEditIcon,
  SubmitButton,
  useStyles,
} from './styles';
import Alert from '../../sharedComponents/Alert/index';
import CurrencyField from '../../sharedComponents/CurrencyField';
import Spinner from '../../sharedComponents/Spinner/index';
import {
  cnpjValidation,
  hasPermission,
  sanitizeCNPJ,
  sanitizeCPF,
} from '../../utils/globalFunctions';
import { ISO_WEEKDAY_MAP } from '../../config/consts';
import {
  addManyCompanyUsers,
  addObjectCompanyUser,
  updateObjectCompanyUser,
} from '../../redux/actions/companyUsers';
import { updateObjectCompanyAdminUser } from '../../redux/actions/companyAdminUser';
import {
  createCompany,
  getCompany,
  updateCompany,
} from '../../redux/actions/company';
import { getGroups } from '../../redux/actions/groups';
import { getUsers } from '../../redux/actions/users';
import { getRoles } from '../../redux/actions/roles';
import { getPaymentPlans } from '../../redux/actions/paymentPlans';
import User from './user';
import PATHS from '../../config/routePaths';
import borderColorIcon from '../../assets/images/border_color.svg';
import Address from './address';
import {
  addManyAddresses,
  addObjectAddress,
  updateObjectAddress,
} from '../../redux/actions/address';

function NumberFormatCustom(props) {
  const { inputRef, onChange, name, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      suffix="%"
    />
  );
}

const Company = ({
  company,
  allGroups,
  companyAdminUser,
  companyUsers,
  addresses,
  users,
  roles,
  companySpinner,
  allPaymentPlansFetched,
}) => {
  const { id } = useParams();
  const history = useHistory();

  const classes = useStyles();
  const dispatch = useDispatch();
  const [active, setActive] = useState(true);
  const [atTest, setAtTest] = useState(false);
  const [atSelfService, setAtSelfService] = useState(true);
  const [subscriptionDate, setSubscriptionDate] = useState(null);
  const subscriptionDateRef = useRef(null);
  const [responsableBakerId, setResponsableBakerId] = useState(null);
  const [responsableBakerIdError, setResponsableBakerIdError] = useState(false);
  const [partnershipStartDate, setPartnershipStartDate] = useState(null);
  const [partnershipStartDateError, setPartnershipStartDateError] = useState(
    false,
  );
  const partnershipStartDateRef = useRef(null);
  const [churnDate, setChurnDate] = useState(null);
  const churnDateRef = useRef(null);
  const [document, setDocument] = useState('');
  const [documentError, setDocumentError] = useState('');
  const [fantasyName, setFantasyName] = useState('');
  const [fantasyNameError, setFantasyNameError] = useState(false);
  const [groupId, setGroupId] = useState('');
  const [groupIdError, setGroupIdError] = useState(false);
  const [corporateName, setCorporateName] = useState('');
  const [corporateNameError, setCorporateNameError] = useState(false);
  const [cnpjOpeningDate, setCnpjOpeningDate] = useState(null);
  const [cnpjOpeningDateError, setCnpjOpeningDateError] = useState(false);
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [legalResponsable, setLegalResponsable] = useState('');
  const [legalResponsableError, setLegalResponsableError] = useState(false);
  const [legalResponsableCpf, setLegalResponsableCpf] = useState('');
  const [legalResponsableCpfError, setLegalResponsableCpfError] = useState(
    false,
  );
  const [financialEmail, setFinancialEmail] = useState('');
  const [financialEmailError, setFinancialEmailError] = useState(false);
  const [numberEmployees, setNumberEmployees] = useState(0);
  const [numberEmployeesError, setNumberEmployeesError] = useState(false);
  const [paymentPlans, setPaymentPlans] = useState([]);
  const [paymentPlansError, setPaymentPlansError] = useState(false);
  const [estimateRevenue, setEstimateRevenue] = useState(0);
  const [estimateRevenueError, setEstimateRevenueError] = useState(false);
  const [cmvMode, setCmvMode] = useState('Faturamento');
  const [bankAccount, setBankAccount] = useState('');
  const [bankAccountError, setBankAccountError] = useState(false);
  const [bankBranch, setBankBranch] = useState('');
  const [bankBranchError, setBankBranchError] = useState(false);
  const [bankName, setBankName] = useState('');
  const [bankNameError, setBankNameError] = useState(false);
  const [pix, setPix] = useState('');
  const [pixError, setPixError] = useState(false);
  const [isIcmsContributor, setIsIcmsContributor] = useState(true);
  const [stateRegistration, setStateRegistration] = useState('');
  const [creditLimit, setCreditLimit] = useState(0);
  const [receivableMode, setReceivableMode] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [weekDay, setWeekDay] = useState('');
  const [farmerRoleId, setFarmerRoleId] = useState(null);
  const [farmers, setFarmers] = useState([]);
  const [alternateAddress, setAlternateAddress] = useState(false);
  const [serviceFee, setServiceFee] = useState('01.99');
  const [serviceFeeError, setServiceFeeError] = useState(false);
  const [disableInput, setDisableInput] = useState(true);

  const handleChangeServiceFee = (event) => {
    setServiceFee(event.target.value);
  };

  const getSelectedLabel = (_id) => {
    if (allPaymentPlansFetched && allPaymentPlansFetched.length > 0) {
      const paymentPlanFound = allPaymentPlansFetched.find(
        (item) => item.id === _id,
      );
      return paymentPlanFound.displayName;
    }
    return '';
  };

  const dateConvert = (dat) => {
    const date = moment(dat, 'DD/MM/YYYY HH:mm');
    const jsDate = date.toDate();
    return jsDate;
  };

  const handleAddUser = () => {
    dispatch(
      addObjectCompanyUser({
        id: undefined,
        name: '',
        sex: '',
        birthDate: new Date(),
        cpf: '',
        email: '',
        phone: '',
      }),
    );
  };

  const checkUserError = (user, isAdmin, index) => {
    const userError = {
      nameUserError: !user.name,
      sexUserError: !user.sex,
      birthDateUserError: !user.birthDate,
      cpfUserError: !cpf.isValid(user.cpf),
      emailUserError: !user.email,
      phoneUserError: !user.phone,
    };

    if (isAdmin) {
      dispatch(
        updateObjectCompanyAdminUser({
          ...user,
          ...userError,
        }),
      );
    } else {
      dispatch(
        updateObjectCompanyUser({
          index,
          data: {
            ...user,
            ...userError,
          },
        }),
      );
    }

    let userHasError = false;
    Object.keys(userError).forEach((key) => {
      if (userError[key] === true) userHasError = true;
    });
    return userHasError;
  };

  const handleAddAddress = () => {
    setAlternateAddress(true);
    dispatch(
      addObjectAddress({
        id: undefined,
        street: '',
        number: '',
        district: '',
        uf: '',
        city: '',
        cep: '',
        reference_point: '',
        delivery: false,
      }),
    );
  };

  const checkAddressError = (address, index) => {
    const addressesWithDelivery = addresses.filter(
      (_address) => _address.delivery === true,
    );

    const addressError = {
      streetError: !address.street,
      numberError: !address.number,
      districtError: !address.district,
      deliveryError: addressesWithDelivery.length !== 1,
      ufError: !address.uf,
      cityError: !address.city,
      cepError: !address.cep,
      reference_pointError: !address.reference_point,
    };

    dispatch(
      updateObjectAddress({
        index,
        data: {
          ...address,
          ...addressError,
        },
      }),
    );

    let addressHasError = false;
    Object.keys(addressError).forEach((key) => {
      if (addressError[key] === true) addressHasError = true;
    });
    return addressHasError;
  };

  const handleSave = async () => {
    let err = false;

    setResponsableBakerIdError(!responsableBakerId);
    setPartnershipStartDateError(!partnershipStartDate);
    setCnpjOpeningDateError(!cnpjOpeningDate);
    setDocumentError(!cnpjValidation(document));
    setFantasyNameError(!fantasyName);
    setGroupIdError(!groupId);
    setCorporateNameError(!corporateName);
    setPhoneError(!phone);
    setLegalResponsableError(!legalResponsable);
    setLegalResponsableCpfError(!cpf.isValid(legalResponsableCpf));
    setFinancialEmailError(!financialEmail);
    setNumberEmployeesError(!numberEmployees);
    setPaymentPlansError(!paymentPlans.length);
    setEstimateRevenueError(!estimateRevenue);
    setBankAccountError(!bankAccount);
    setBankBranchError(!bankBranch);
    setBankNameError(!bankName);
    setPixError(!pix);
    setNameError(!name);
    setServiceFeeError(!serviceFee);
    const adminUserError = checkUserError(companyAdminUser, true);
    let usersError = false;
    let addressesError = false;

    companyUsers.forEach((_user, index) => {
      const userError = checkUserError(_user, false, index);
      if (userError) {
        usersError = true;
      }
    });

    addresses.forEach((_address, index) => {
      const addressError = checkAddressError(_address, index);
      if (addressError) {
        addressesError = true;
      }
    });

    err =
      usersError ||
      addressesError ||
      adminUserError ||
      responsableBakerIdError ||
      partnershipStartDateError ||
      documentError ||
      fantasyNameError ||
      groupIdError ||
      corporateNameError ||
      phoneError ||
      legalResponsableError ||
      legalResponsableCpfError ||
      financialEmailError ||
      numberEmployeesError ||
      paymentPlansError ||
      estimateRevenueError ||
      bankAccountError ||
      bankBranchError ||
      bankNameError ||
      pixError ||
      nameError ||
      serviceFeeError;

    if (!err) {
      const response = await Alert();

      const parsedCompanyUsers = companyUsers.map((_companyUser) => ({
        isAdmin: false,
        id: _companyUser.id || undefined,
        birthDate: dateConvert(_companyUser.birthDate),
        roleName: _companyUser.roleName,
        cpf: _companyUser.cpf,
        email: _companyUser.email,
        name: _companyUser.name,
        phone: _companyUser.phone,
        sex: _companyUser.sex,
      }));

      parsedCompanyUsers.push({
        isAdmin: true,
        id: companyAdminUser.id || undefined,
        birthDate: dateConvert(companyAdminUser.birthDate),
        roleName: 'Administrador',
        cpf: companyAdminUser.cpf,
        email: companyAdminUser.email,
        name: companyAdminUser.name,
        phone: companyAdminUser.phone,
        sex: companyAdminUser.sex,
      });

      const parsedAddresses = addresses.map((_addresses) => ({
        id: _addresses.id || undefined,
        street: _addresses.street,
        number: _addresses.number,
        district: _addresses.district,
        uf: _addresses.uf,
        city: _addresses.city,
        cep: _addresses.cep,
        reference_point: _addresses.reference_point,
        delivery: _addresses.delivery,
      }));

      if (response && response.confirm) {
        const companyData = {
          active,
          at_test: atTest,
          at_self_service: atSelfService,
          subscription_date: dateConvert(subscriptionDate),
          responsable_baker_id: responsableBakerId,
          partnership_start_date: dateConvert(partnershipStartDate),
          churn_date: dateConvert(churnDate),
          document,
          fantasy_name: fantasyName,
          group_id: groupId,
          corporate_name: corporateName,
          cnpj_opening_date: dateConvert(cnpjOpeningDate),
          phone,
          legal_responsable: legalResponsable,
          legal_responsable_cpf: legalResponsableCpf,
          financial_email: financialEmail,
          number_employees: numberEmployees,
          payment_plans: paymentPlans,
          estimate_revenue: estimateRevenue,
          cmv_mode: cmvMode,
          bank_account: bankAccount,
          bank_branch: bankBranch,
          bank_name: bankName,
          pix,
          is_icms_contributor: isIcmsContributor,
          state_registration: stateRegistration,
          credit_limit: creditLimit,
          receivable_mode: receivableMode,
          best_shopping_week_day: weekDay,
          companyUsers: parsedCompanyUsers,
          addresses: parsedAddresses,
          name,
          serviceFee,
        };

        if (!id) {
          dispatch(createCompany(companyData));
        } else {
          dispatch(
            updateCompany({
              ...companyData,
              id,
            }),
          );
        }
        history.push(PATHS.SETTINGS_COMPANIES);
      }
    }
  };

  const handleDocumentErrorMessage = document
    ? 'CNPJ inválido!'
    : 'CNPJ é obrigatório';

  useEffect(() => {
    dispatch(getGroups({ listAll: true }));
    dispatch(getUsers({ listAll: true }));
    dispatch(getRoles({ listAll: true }));
    dispatch(getPaymentPlans({ listAll: true, enabledToBakeries: true }));
  }, []);
  useEffect(() => {
    if (roles.docs && roles.docs.length > 0) {
      const findFarmerRoleIndex = roles.docs.findIndex(
        (_role) => _role.name === 'FARMER',
      );
      setFarmerRoleId(roles.docs[findFarmerRoleIndex].id);
    }
  }, [roles]);

  useEffect(() => {
    if (users.docs && farmerRoleId) {
      const findFarmers = users.docs.filter(
        (_user) => _user.roleId === farmerRoleId,
      );
      setFarmers(findFarmers);
    }
  }, [users, farmerRoleId]);

  useEffect(() => {
    dispatch(addManyCompanyUsers([]));
    dispatch(addManyAddresses([]));

    if (!id) {
      dispatch(
        updateObjectCompanyAdminUser({
          id: undefined,
          name: '',
          sex: '',
          birthDate: new Date(),
          cpf: '',
          email: '',
          phone: '',
        }),
      );

      dispatch(
        addObjectAddress({
          id: undefined,
          street: '',
          number: '',
          district: '',
          uf: '',
          city: '',
          cep: '',
          reference_point: '',
          delivery: false,
        }),
      );
    }

    if (id) {
      dispatch(getCompany(id));
    }
  }, [id]);

  useEffect(() => {
    if (company && id) {
      setActive(company.active);
      setAtTest(company.at_test);
      setAtSelfService(company.at_self_service);
      setName(company.name);
      setDocument(company.document || '');
      setGroupId(company.group_id);
      setCorporateName(company.corporate_name || '');
      setFantasyName(company.fantasy_name || '');
      setStateRegistration(company.state_registration || '');
      setFinancialEmail(company.financial_email || '');
      setPhone(company.phone || '');
      setBankAccount(company.bank_account || '');
      setBankBranch(company.bank_branch || '');
      setBankName(company.bank_name || '');
      setResponsableBakerId(company.responsable_baker_id || null);
      setPartnershipStartDate(
        company.partnership_start_date === null && undefined
          ? null
          : moment(company.partnership_start_date).format('DD/MM/YYYY'),
      );
      setChurnDate(
        company.churn_date === null && undefined
          ? null
          : moment(company.churn_date).format('DD/MM/YYYY'),
      );
      setCnpjOpeningDate(
        company.cnpj_opening_date === null && undefined
          ? null
          : moment(company.cnpj_opening_date).format('DD/MM/YYYY'),
      );
      setSubscriptionDate(
        company.subscription_date === null && undefined
          ? null
          : moment(company.subscription_date).format('DD/MM/YYYY'),
      );
      setLegalResponsable(company.legal_responsable || '');
      setLegalResponsableCpf(company.legal_responsable_cpf || '');
      setNumberEmployees(company.number_employees || 0);
      setEstimateRevenue(company.estimate_revenue || 0);
      setServiceFee(company.serviceFee || '01.99');
      setPix(company.pix || '');
      setCreditLimit(company.credit_limit || 0);
      setReceivableMode(company.receivable_mode || '');
      setWeekDay(company.best_shopping_week_day || null);

      const paymentPlansParsed = company.paymentPlansOnCompany?.map(
        (_paymentPlanOnCompany) => _paymentPlanOnCompany.paymentPlanId,
      );

      if (paymentPlansParsed && paymentPlansParsed.length > 0) {
        setPaymentPlans(paymentPlansParsed);
      }

      if (company.companyUsers && company.companyUsers.length > 0) {
        const companyUsersFetched = [...company.companyUsers];
        const adminUserIndex = companyUsersFetched.findIndex(
          (_companyUser) => _companyUser.isAdmin === true,
        );

        for (const u of companyUsersFetched) {
          if (u.birthDate === null || u.birthDate === undefined) {
            u.birthDate = null;
          } else {
            u.birthDate = moment(u.birthDate).format('DD/MM/YYYY');
          }
        }

        const adminUserFetched = companyUsersFetched.splice(adminUserIndex, 1);

        dispatch(updateObjectCompanyAdminUser(...adminUserFetched));
        dispatch(addManyCompanyUsers(companyUsersFetched));
      }

      if (company.addresses && company.addresses.length > 0) {
        const addressesFetched = [...company.addresses];
        dispatch(addManyAddresses(addressesFetched));

        if (company.addresses.length >= 2) setAlternateAddress(true);
        else setAlternateAddress(false);
      }
    }
  }, [company]);

  useEffect(() => {
    if (addresses?.length === 1) setAlternateAddress(false);
  }, [addresses]);

  const handleEnableInput = () => {
    setDisableInput(false);
    setTimeout(() => {
      window.document.getElementById('name-p-input').focus();
    }, 100);
  };

  const handleDisableInput = () => {
    setDisableInput(true);
  };

  return (
    <>
      {companySpinner && <Spinner overlay />}
      <div
        className={classes.root}
        style={{ opacity: companySpinner ? 0.5 : 1 }}
      >
        <Paper className={classes.paper}>
          <div className={classes.wrapper}>
            <div>
              <ContentName>
                <InputFieldTitle
                  id="name-p-input"
                  placeholder={id ? 'Editar Padaria' : 'Nova Padaria'}
                  type="text"
                  value={name}
                  error={nameError}
                  onChange={(e) => setName(e.target.value)}
                  disabled={disableInput}
                  onBlur={handleDisableInput}
                />
                <StyledEditIcon
                  onClick={handleEnableInput}
                  src={borderColorIcon}
                />
              </ContentName>
              <ContainerInfor>
                <div>
                  <LabelInput>Baker Responsável*</LabelInput>
                  <InputSelect
                    labelId="select-label-baker"
                    id="select-label-component-baker"
                    value={responsableBakerId}
                    variant="outlined"
                    onChange={(e) => {
                      setResponsableBakerId(e.target.value);
                    }}
                    error={responsableBakerIdError}
                    fullWidth
                  >
                    {farmers.map((_responsableBaker) => (
                      <MenuItem
                        key={_responsableBaker.id}
                        value={_responsableBaker.id}
                      >
                        {_responsableBaker.name}
                      </MenuItem>
                    ))}
                  </InputSelect>
                  {responsableBakerIdError && (
                    <FormHelperText>
                      Nome do responsável é obrigatório
                    </FormHelperText>
                  )}
                </div>
                <div>
                  <DateContainer>
                    <LabelInput>Início da parceria*</LabelInput>
                    <InputMask
                      mask="99/99/9999"
                      type="text"
                      value={partnershipStartDate}
                      onChange={(e) => setPartnershipStartDate(e.target.value)}
                      disabled={false}
                      maskChar=" "
                    >
                      {() => (
                        <TextField
                          type="text"
                          placeholder="dd/mm/aaaa"
                          variant="outlined"
                          fullWidth
                          error={partnershipStartDateError}
                          helperText={
                            partnershipStartDateError &&
                            'Início da parceria é obrigatório'
                          }
                          dateRef={partnershipStartDateRef}
                        />
                      )}
                    </InputMask>
                  </DateContainer>
                </div>
                <div>
                  <DateContainer>
                    <LabelInput>Data de Churn</LabelInput>
                    <InputMask
                      mask="99/99/9999"
                      type="text"
                      value={churnDate}
                      onChange={(e) => setChurnDate(e.target.value)}
                      disabled={false}
                      maskChar=" "
                    >
                      {() => (
                        <TextField
                          type="text"
                          placeholder="dd/mm/aaaa"
                          variant="outlined"
                          fullWidth
                          dateRef={churnDateRef}
                        />
                      )}
                    </InputMask>
                  </DateContainer>
                </div>
              </ContainerInfor>
            </div>
            <ContentStatus>
              <h2>Status da padaria</h2>
              <FormGroup>
                <ContainerToggle>
                  <CheckboxContent>
                    <FormControlLabel
                      control={
                        <GreenSwitch
                          checked={active}
                          onChange={(e) => setActive(e.target.checked)}
                        />
                      }
                      label="Ativo"
                      labelPlacement="end"
                    />
                  </CheckboxContent>
                  <CheckboxContent>
                    <FormControlLabel
                      control={
                        <GreenSwitch
                          checked={atTest}
                          onChange={(e) => setAtTest(e.target.checked)}
                        />
                      }
                      label="Em teste"
                      labelPlacement="end"
                    />
                  </CheckboxContent>
                </ContainerToggle>
                <ContainerToggle>
                  <CheckboxContent>
                    <FormControlLabel
                      control={
                        <GreenSwitch
                          checked={atSelfService}
                          onChange={(e) => setAtSelfService(e.target.checked)}
                        />
                      }
                      label="Self service"
                      labelPlacement="end"
                    />
                  </CheckboxContent>
                  <DateContainerStatus>
                    <LabelInput>Desde de</LabelInput>
                    <InputMask
                      mask="99/99/9999"
                      type="text"
                      value={subscriptionDate}
                      onChange={(e) => setSubscriptionDate(e.target.value)}
                      disabled={false}
                      maskChar=" "
                    >
                      {() => (
                        <TextField
                          type="text"
                          placeholder="dd/mm/aaaa"
                          variant="outlined"
                          fullWidth
                          dateRef={subscriptionDateRef}
                        />
                      )}
                    </InputMask>
                  </DateContainerStatus>
                </ContainerToggle>
              </FormGroup>
            </ContentStatus>
          </div>
          <DividerForm />
          <div>
            <h2>Dados Gerais</h2>

            <Grid container>
              <Grid item xs={12} md={5}>
                <InputContent>
                  <LabelInput>Nome fantasia*</LabelInput>
                  <TextField
                    id="name-input"
                    type="text"
                    error={fantasyNameError}
                    helperText={
                      fantasyNameError && 'Nome fantasia é obrigatório'
                    }
                    variant="outlined"
                    value={fantasyName}
                    onChange={(e) => setFantasyName(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={3}>
                <InputContent>
                  <LabelInput>CNPJ*</LabelInput>
                  <InputMask
                    mask="99.999.999/9999-99"
                    type="text"
                    value={document}
                    onChange={(e) => setDocument(sanitizeCNPJ(e.target.value))}
                    disabled={false}
                    maskChar=" "
                  >
                    {() => (
                      <TextField
                        id="document-input"
                        type="text"
                        variant="outlined"
                        fullWidth
                        error={documentError}
                        helperText={documentError && handleDocumentErrorMessage}
                      />
                    )}
                  </InputMask>
                </InputContent>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputContent>
                  <FormControl variant="outlined" fullWidth>
                    <LabelInput id="select-label-2">
                      Grupo de Empresas
                    </LabelInput>
                    <Select
                      labelId="select-label-2"
                      id="group-select"
                      value={groupId}
                      error={groupIdError}
                      helperText={
                        groupIdError && 'Grupo de empresas é obrigatório'
                      }
                      variant="outlined"
                      onChange={(e) => setGroupId(e.target.value)}
                      fullWidth
                    >
                      {allGroups &&
                        allGroups.map((r) => (
                          <MenuItem value={r.id}>{r.name}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </InputContent>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={8}>
                <InputContent>
                  <LabelInput>Razão social*</LabelInput>
                  <TextField
                    id="corporate-name-input"
                    type="text"
                    variant="outlined"
                    value={corporateName}
                    onChange={(e) => setCorporateName(e.target.value)}
                    error={corporateNameError}
                    helperText={
                      corporateNameError && 'Razão social é obrigatório'
                    }
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={2}>
                <DateMainContainer>
                  <LabelInput>Abertura do CNPJ*</LabelInput>
                  <InputMask
                    mask="99/99/9999"
                    type="text"
                    value={cnpjOpeningDate}
                    onChange={(e) => setCnpjOpeningDate(e.target.value)}
                    disabled={false}
                    maskChar=" "
                    error={cnpjOpeningDateError}
                  >
                    {() => (
                      <TextField
                        type="text"
                        placeholder="dd/mm/aaaa"
                        variant="outlined"
                        fullWidth
                        dateRef={subscriptionDateRef}
                      />
                    )}
                  </InputMask>
                </DateMainContainer>
              </Grid>
              <Grid item xs={12} md={2}>
                <InputContent>
                  <LabelInput>Telefone da padaria*</LabelInput>
                  <TextField
                    id="phone-input"
                    type="text"
                    variant="outlined"
                    value={phone}
                    error={phoneError}
                    helperText={phoneError && 'Telefone é obrigatório'}
                    onChange={(e) => setPhone(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={7}>
                <InputContent>
                  <LabelInput>Responsável Legal*</LabelInput>
                  <TextField
                    id="legal-responsable-input"
                    type="text"
                    variant="outlined"
                    value={legalResponsable}
                    onChange={(e) => setLegalResponsable(e.target.value)}
                    error={legalResponsableError}
                    helperText={
                      legalResponsableError && 'Responsável Legal é obrigatório'
                    }
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={2}>
                <InputContent>
                  <LabelInput>CPF*</LabelInput>
                  <InputMask
                    mask="999.999.999-99"
                    type="text"
                    value={legalResponsableCpf}
                    onChange={(e) =>
                      setLegalResponsableCpf(sanitizeCPF(e.target.value))
                    }
                    disabled={false}
                    maskChar=" "
                  >
                    {() => (
                      <TextField
                        id="legal-responsable-cpf-input"
                        type="text"
                        variant="outlined"
                        fullWidth
                        error={legalResponsableCpfError}
                        helperText={legalResponsableCpfError && 'CPF inválido'}
                      />
                    )}
                  </InputMask>
                </InputContent>
              </Grid>
              <Grid item xs={12} md={3}>
                <InputContent>
                  <LabelInput>Email da padaria*</LabelInput>
                  <TextField
                    id="email-input"
                    type="text"
                    variant="outlined"
                    error={financialEmailError}
                    value={financialEmail}
                    helperText={financialEmailError && 'Email é obrigatório'}
                    onChange={(e) => setFinancialEmail(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12}>
                <InputContent>
                  <FormControl variant="outlined" fullWidth>
                    <LabelInput id="select-label-icms-regime">
                      Melhor dia de compra
                    </LabelInput>
                    <Select
                      labelId="select-label-week-day"
                      id="select-label-component-week-day"
                      value={weekDay}
                      variant="outlined"
                      onChange={(e) => {
                        setWeekDay(e.target.value);
                      }}
                      fullWidth
                    >
                      {Object.keys(ISO_WEEKDAY_MAP).map((day) => (
                        <MenuItem key={day} value={day}>
                          {ISO_WEEKDAY_MAP[day]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </InputContent>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={2}>
                <InputContent>
                  <LabelInput>Nº de funcionários*</LabelInput>
                  <TextField
                    id="number-employees-responsable-input"
                    type="number"
                    variant="outlined"
                    value={numberEmployees}
                    onChange={(e) =>
                      setNumberEmployees(parseInt(e.target.value, 10))
                    }
                    error={numberEmployeesError}
                    helperText={
                      numberEmployeesError && 'Nº de funcionários é obrigatório'
                    }
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={2}>
                <InputContent>
                  <LabelInput>Faturamento Estimado*</LabelInput>
                  <CurrencyField
                    value={estimateRevenue}
                    error={estimateRevenueError}
                    name="estimateRevenue"
                    helperText={
                      estimateRevenueError && 'Informe o valor do faturamento.'
                    }
                    placeholder="0,00"
                    required
                    onChange={(e, value) => setEstimateRevenue(value)}
                  />
                  {/* <TextField
                    value={estimateRevenue}
                    error={estimateRevenueError}
                    type="number"
                    variant="outlined"
                    name="estimateRevenue"
                    helperText={
                      estimateRevenueError && 'Informe o valor do faturamento.'
                    }
                    placeholder="0,00"
                    onChange={(e, value) => setEstimateRevenue(value)}
                    fullWidth
                  /> */}
                </InputContent>
              </Grid>
              <Grid item xs={12} md={2}>
                <InputContent>
                  <FormControl variant="outlined" fullWidth>
                    <LabelInput id="select-label-cmv">Modo de CMV</LabelInput>
                    <Select
                      labelId="select-label-cmv"
                      id="select-label-component-cmv"
                      value={cmvMode}
                      variant="outlined"
                      onChange={(e) => {
                        setCmvMode(e.target.value);
                      }}
                      fullWidth
                    >
                      {['Faturamento', 'Nº de funcionários'].map((_cmvMode) => (
                        <MenuItem key={_cmvMode} value={_cmvMode}>
                          {_cmvMode}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </InputContent>
              </Grid>
              <Grid item xs={12} md={2} style={{ textAlign: 'center' }}>
                <InputContent>
                  <LabelInput>CMV estimado</LabelInput>
                  <h3>
                    R${' '}
                    {cmvMode === 'Faturamento'
                      ? estimateRevenue / 2
                      : numberEmployees * 5500}
                  </h3>
                </InputContent>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputContent>
                  <FormControl variant="outlined" fullWidth>
                    <LabelInput htmlFor="outlined-age-native-simple">
                      Formas de pagamento autorizadas*
                    </LabelInput>
                    <InputSelect
                      multiple
                      value={paymentPlans}
                      onChange={(e) => setPaymentPlans(e.target.value)}
                      error={paymentPlansError}
                      renderValue={(selected) => (
                        <Grid container>
                          {selected.map((item) => (
                            <Chip key={item} label={getSelectedLabel(item)} />
                          ))}
                        </Grid>
                      )}
                      style={{ maxWidth: '80vw' }}
                    >
                      {allPaymentPlansFetched &&
                        allPaymentPlansFetched?.map((_paymentPlan) => (
                          <MenuItem
                            key={_paymentPlan.id}
                            value={_paymentPlan.id}
                          >
                            {_paymentPlan.displayName}
                          </MenuItem>
                        ))}
                    </InputSelect>
                    {paymentPlansError && (
                      <span style={{ color: 'red', fontSize: '0.75rem' }}>
                        Selecione ao menos um método de pagamento
                      </span>
                    )}
                  </FormControl>
                </InputContent>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={1}>
                <InputContent>
                  <LabelInput>Taxa de Serviço</LabelInput>

                  <TextField
                    id="formatted-numberformat-input"
                    value={serviceFee}
                    onChange={handleChangeServiceFee}
                    name="numberformat"
                    variant="outlined"
                    error={serviceFeeError}
                    helperText={
                      serviceFeeError && 'Taxa de serviço obrigatória'
                    }
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={2}>
                <InputContent>
                  <LabelInput>Conta*</LabelInput>
                  <TextField
                    id="bankAccount-input"
                    type="text"
                    variant="outlined"
                    error={bankAccountError}
                    value={bankAccount}
                    helperText={bankAccountError && 'Conta é obrigatório'}
                    onChange={(e) => setBankAccount(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={2}>
                <InputContent>
                  <LabelInput>Agência*</LabelInput>
                  <TextField
                    id="bankBranch-input"
                    type="text"
                    variant="outlined"
                    error={bankBranchError}
                    value={bankBranch}
                    onChange={(e) => setBankBranch(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={3}>
                <InputContent>
                  <LabelInput>Banco*</LabelInput>
                  <TextField
                    id="bankName-input"
                    type="text"
                    variant="outlined"
                    error={bankNameError}
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputContent>
                  <LabelInput>Chave Pix (conta jurídica)*</LabelInput>
                  <TextField
                    id="pix-input"
                    type="text"
                    variant="outlined"
                    error={pixError}
                    value={pix}
                    onChange={(e) => setPix(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={2}>
                <InputContent>
                  <FormControl variant="outlined" fullWidth>
                    <LabelInput id="select-label-icms-regime">
                      Contribuinte ICMS
                    </LabelInput>
                    <Select
                      labelId="select-label-icms"
                      id="select-label-component-icms"
                      value={isIcmsContributor}
                      variant="outlined"
                      onChange={(e) => {
                        setIsIcmsContributor(e.target.value);
                      }}
                      fullWidth
                    >
                      <MenuItem value>Sim</MenuItem>
                      <MenuItem value={false}>Não</MenuItem>
                    </Select>
                  </FormControl>
                </InputContent>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputContent>
                  <LabelInput>Incrição Estadual</LabelInput>
                  <TextField
                    id="state-subscription-input"
                    type="text"
                    variant="outlined"
                    value={stateRegistration}
                    onChange={(e) => setStateRegistration(e.target.value)}
                    fullWidth
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={3}>
                <InputContent>
                  <LabelInput>Limite de crédito</LabelInput>
                  <CurrencyField
                    value={creditLimit}
                    name="estimateRevenue"
                    placeholder="0,00"
                    onChange={(e, value) => setCreditLimit(value)}
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} md={3}>
                <InputContent>
                  <FormControl variant="outlined" fullWidth>
                    <LabelInput id="select-label-icms">
                      Modo de recebível*
                    </LabelInput>
                    <Select
                      labelId="select-label-icms-regime"
                      id="select-label-component-icms-regime"
                      value={receivableMode}
                      variant="outlined"
                      onChange={(e) => {
                        setReceivableMode(e.target.value);
                      }}
                      fullWidth
                    >
                      <MenuItem value="POS">POS</MenuItem>
                      <MenuItem value="TEF">TEF</MenuItem>
                    </Select>
                  </FormControl>
                </InputContent>
              </Grid>
            </Grid>
          </div>

          <div>
            <h2>Endereço do estabelecimento</h2>
            <Grid container>
              {addresses?.map((_address, index) => (
                <Address address={_address} index={index} key={index} />
              ))}
            </Grid>

            {alternateAddress === false && (
              <Grid container>
                <AddAddressButton type="submit" onClick={handleAddAddress}>
                  <span>Adicionar Endereço alternativo</span>
                </AddAddressButton>
              </Grid>
            )}
          </div>

          <div>
            <h2>Usuários</h2>
            <User user={companyAdminUser} isAdmin />
            {companyUsers.map((_user, index) => (
              <User user={_user} index={index} key={index} />
            ))}
          </div>
          <div className={classes.buttonsContainer}>
            <AddUserButton type="submit" onClick={handleAddUser}>
              <span>Adicionar usuário</span>
            </AddUserButton>
            <SubmitButton
              type="submit"
              disabled={
                !(id && hasPermission(PATHS.SETTINGS_COMPANIES, 'update')) &&
                !(!id && hasPermission(PATHS.SETTINGS_COMPANIES, 'create'))
              }
              onClick={handleSave}
            >
              <span>Atualizar cadastro</span>
            </SubmitButton>
          </div>
        </Paper>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  allGroups: state.groups.docs,
  companyAdminUser: state.companyAdminUser,
  companyUsers: state.companyUsers,
  addresses: state.addresses,
  users: state.users,
  roles: state.roles,
  company: state.company,
  companySpinner: state.ui.companyLoading,
  allPaymentPlansFetched: state.paymentPlans.docs,
});

export default connect(mapStateToProps)(Company);
