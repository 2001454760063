/* eslint-disable @typescript-eslint/camelcase */
export const authApiUrl =
  process.env.AUTH_API_BASE_URL || process.env.REACT_APP_AUTH_API_BASE_URL;
export const apiGatewayUrl =
  process.env.API_GATEWAY_BASE_URL || process.env.REACT_APP_API_GATEWAY_BASE_URL;
export const apiUrlV2 =
  process.env.REACT_APP_API_V2_BASE_URL || `${apiGatewayUrl}/painel/v2`;
export const marketplaceApiUrl =
  process.env.REACT_APP_MARKETPLACE_API_BASE_URL || `${apiGatewayUrl}/marketplace/v1`;

export const documentationUrl = process.env.REACT_APP_DOCUMENTATION_URL;

export const sessionStorageKey = 'user@bakery';

export const SALES_FUNCTIONS = ['ONLINE', 'OFFLINE'];

export const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const WEEKDAYS = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

export const ISO_WEEKDAY_MAP = {
  1: 'Segunda-Feira',
  2: 'Terça-Feira',
  3: 'Quarta-Feira',
  4: 'Quinta-Feira',
  5: 'Sexta-Feira',
  6: 'Sábado',
  7: 'Domingo',
};

export const transferKey2CommissioningKind = {
  physical_store_credit: '[OFFLINE] CARTAO DE CREDITO',
  physical_store_debit: '[OFFLINE] CARTAO DE DEBITO',
  physical_store_pix: '[OFFLINE] PIX',
  physical_store_pic_pay: '[OFFLINE] PICPAY',
  physical_store_voucher: '[OFFLINE] VOUCHER',
  online_store_cash: '[ONLINE] DINHEIRO', // vem do Epadoca
  online_store_card: '[ONLINE] CARTAO', // vem do Epadoca
  online_store_online: '[ONLINE] EPADOCA', // vem do Epadoca
  online_store_payment_machine: '[ONLINE] CARTAO', // vem do PagSeguro
};

export const PAYMENT_KINDS = [
  ...new Set(Object.values(transferKey2CommissioningKind)),
];

export const statusMap = {
  ACTIVE: 'ATIVO',
  RESERVED: 'RESERVA',
  FAULTY: 'COM DEFEITO',
  DISABLED: 'DESATIVADO',
};

export const competitorKindMap = {
  BAKERY: 'Padaria',
  WHOLESALE: 'Atacarejo',
  SUPERMARKET: 'Supermercado',
};

export const statusColorMap = {
  ACTIVE: {
    text: '#192d4d',
    background: '#192d4d33',
  },
  DISABLED: {
    text: '#FF5B5B',
    background: '#FF5B5B26',
  },
  FAULTY: {
    text: '#FFBB54',
    background: '#F9B87033',
  },
  RESERVED: {
    text: '#00A389',
    background: '#2ED6A326',
  },
};
export const ICMS_CONTRIBUTION_OPTIONS = ['Sim', 'Não', 'Isento'];

export const COMPANY_CATEGORIES = ['A', 'B', 'C'];
