import {
  UPDATE_OFFER_SUCCESS,
  UPDATE_OFFER_ERROR,
  FETCH_OFFER_SUCCESS,
  FETCH_OFFER_ERROR,
  GET_OFFER,
  CREATE_OFFER,
  UPDATE_OFFER,
  updateObjectOffer,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/offer';
import { getOffers } from '../actions/offers';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'offers';

export const createOfferFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === CREATE_OFFER) {
          dispatch(
            apiRequest(
              'POST',
              URL,
              action.payload,
              UPDATE_OFFER_SUCCESS,
              UPDATE_OFFER_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const updateOfferFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === UPDATE_OFFER) {
          dispatch(
            apiRequest(
              'PUT',
              URL,
              action.payload,
              UPDATE_OFFER_SUCCESS,
              UPDATE_OFFER_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const getOfferFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_OFFER) {
          const { _id } = action.payload;
          dispatch(
            apiRequest(
              'GET-id',
              `${URL}/index`,
              { _id },
              FETCH_OFFER_SUCCESS,
              FETCH_OFFER_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processOfferCollection =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_OFFER_SUCCESS) {
          dispatch(updateObjectOffer(action.payload));
          dispatch(hideSpinner());
        } else if (action.type === UPDATE_OFFER_SUCCESS) {
          dispatch(updateObjectOffer(action.payload));
          dispatch(hideModal());
          dispatch(hideSpinner());
          const { page, limit } = getState().offers;
          dispatch(getOffers({ page, limit }));
          Toastify.addSuccess('Operação concluída com sucesso.');
        } else if (action.type === UPDATE_OFFER_ERROR) {
          dispatch(hideSpinner());
          Toastify.addError(
            'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
          );
          console.error(action.payload);
        }
      };

export const offerMdl = [
  createOfferFlow,
  updateOfferFlow,
  getOfferFlow,
  processOfferCollection,
];
