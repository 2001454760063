/* eslint-disable react/require-default-props */
import React from 'react';
import {
  Box,
  Collapse,
  TableContainer,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Add, Close } from '@material-ui/icons';
import { useDebounce } from '../../../../hooks';
import {
  Button,
  Divider,
  Row,
  SearchIcon,
  Table,
  TableBody,
  TableHead,
  Cell,
  DetailsBar,
  DetailItem,
  DetailLabel,
  DetailValue,
  ExpandIcon,
  ExpandButton,
  SubOrderDetailsContainer,
  Title,
  DetailsDivider,
  Grid,
  Field,
  Label,
  SearchInput,
  DetailsGrid,
  GridItem,
  MobileLabel,
  SeeMoreButton,
  StatusIndicator,
} from './styles';
import { OrderProductColumn, OrderProductWithSupplier } from '../interfaces';
import {
  supplierStatusColorMap,
  supplierStatusMap,
  SupplierOrder,
  SupplierOrderStatus,
} from '../../../../interfaces/orders';
import { FieldType } from './interfaces';
import FormField from '../FormField';
import { updateSupplierOrder } from '../../../../redux/actions/order';
import ProductRow from '../ProductRow';
import NewOrderProduct from '../NewOrderProduct';
import { getPaymentPlans } from '../../../../redux/actions/paymentPlans';
import { RootState } from '../../../../interfaces/redux';
import {
  PaymentMethod,
  PaymentPlans,
} from '../../../../interfaces/paymentMethods';

const statusOptions = Object.keys(supplierStatusMap).map((value) => ({
  value,
  label: supplierStatusMap[value as SupplierOrderStatus],
}));

const columns: OrderProductColumn[] = [
  { label: 'Descrição', key: 'productDescription', subKey: 'eanCode' },
  { label: 'Status', key: 'status' },
  { label: 'Id da oferta', key: 'offerId' },
  { label: 'Fornecedor', key: 'supplier', onlyAllProductsView: true },
  { label: 'Quant.', key: 'quantity', input: 'number' },
  { label: 'Preço Original', key: 'formattedUnitPlatformPrice' },
  { label: 'Preço Negociado', key: 'unitNegotiatedPrice', input: 'currency' },
  { label: 'Total', key: 'formattedNegotiatedTotalValue' },
  { label: 'Preço Nota Fiscal', key: 'unitTypedPrice', input: 'currency' },
  { label: 'Preço de custo', key: 'unitCostPrice', input: 'currency' },
];

const SubOrder: React.FC<{
  products?: OrderProductWithSupplier[];
  subOrder?: SupplierOrder;
}> = ({ products, subOrder }) => {
  const [input, setInput] = React.useState<string>('');
  const [productsToRender, setProductsToRender] = React.useState<
    OrderProductWithSupplier[]
  >([]);
  const [detailsExpanded, setDetailsExpanded] = React.useState<boolean>(false);
  const [formState, setFormState] = React.useState<
    Partial<SupplierOrder> | undefined
  >(subOrder);
  const [addingProduct, setAddingProduct] = React.useState<boolean>(false);

  const plans = useSelector(
    (state: RootState) => state.paymentPlans?.docs || [],
  );
  const methods = useSelector(
    (state: RootState) => state.paymentMethods?.docs || [],
  );

  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const debouncedInput = useDebounce(input, 250);

  React.useEffect(() => {
    dispatch(getPaymentPlans({ enabledToSuppliers: true, listAll: true }));
  }, []);

  React.useEffect(() => {
    if (!subOrder) return;
    setFormState(subOrder);
  }, [subOrder]);

  React.useEffect(() => {
    const productList = products || subOrder?.orderProducts || [];
    if (!debouncedInput) {
      setProductsToRender(productList);
      return;
    }
    setProductsToRender(
      productList.filter(
        ({ productDescription, eanCode }) =>
          productDescription
            .toUpperCase()
            .includes(debouncedInput.toUpperCase()) ||
          eanCode?.toUpperCase()?.includes(debouncedInput.toUpperCase()),
      ),
    );
  }, [products, subOrder?.orderProducts, debouncedInput]);

  const detailsFields: {
    label: string;
    key: keyof SupplierOrder;
    customClass?: string;
  }[] = React.useMemo(
    () => [
      {
        label: 'Status do pedido',
        key: 'formattedStatus',
        customClass: 'highlight',
      },
      {
        label: 'Número do pedido',
        key: 'supplierOrderNumber',
        customClass: 'highlight',
      },
      ...(subOrder?.deliveryDate
        ? [
            {
              label: 'Data de entrega',
              key: 'formattedDeliveryDate' as keyof SupplierOrder,
            },
          ]
        : [
            {
              label: 'Previsão de entrega',
              key: 'formattedEstimatedDeliveryDate' as keyof SupplierOrder,
            },
          ]),
      { label: 'Valor total', key: 'formattedTotalValue' },
      { label: 'Preço Nota Fiscal', key: 'typedTotal' },
    ],
    [subOrder?.deliveryDate],
  );

  const expandedFields: FieldType[] = React.useMemo(
    () => [
      {
        key: 'supplierOrderNo',
        label: 'Número do pedido no fornecedor',
        columns: 3,
        input: 'string',
      },
      {
        key: 'status',
        label: 'Status do pedido',
        columns: 3,
        options: statusOptions,
        input: 'select',
      },
      {
        key: 'formattedEstimatedDeliveryDate',
        label: 'Previsão de entrega',
        columns: 3,
      },
      {
        key: 'deliveryDate',
        label: 'Data de entrega',
        columns: 3,
        input: 'date',
      },
      {
        key: 'paymentMethod',
        label: 'Forma de pagamento',
        columns: 3,
        input: 'select',
        options: methods
          .filter(({ id }: PaymentMethod) =>
            plans.some(
              ({ paymentMethodId }: PaymentPlans) => paymentMethodId === id,
            ),
          )
          .map(({ id, name }: PaymentMethod) => ({
            value: id,
            label: name,
          })),
      },
      {
        key: 'paymentPlanId',
        label: 'Plano de pagamento',
        columns: 3,
        options: (plans as PaymentPlans[])
          .filter(
            ({ paymentMethodId }) =>
              !formState?.paymentMethod ||
              paymentMethodId === formState?.paymentMethod,
          )
          .map(({ id, displayName }) => ({
            value: id!.toString(),
            label: displayName,
          })),
        input: 'select',
      },
      {
        key: 'receipt',
        label: 'Comprovante de compra',
        columns: 3,
        input: 'string',
      },
      {
        key: 'invoiceNumber',
        label: 'Número da nota',
        columns: 3,
        input: 'string',
      },
      {
        key: 'invoiceKey',
        label: 'Chave da nota fiscal',
        columns: 6,
        input: 'string',
      },
      {
        key: 'observation',
        label: 'Observações',
        columns: 6,
        rows: 3,
        multiline: true,
        input: 'string',
      },
      {
        key: 'ticket',
        label: 'Linha digitável do boleto',
        columns: 6,
        input: 'string',
      },
    ],
    [plans, formState?.paymentMethod],
  );

  const handleInput: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setInput(value);
  };

  const handleChange = (
    e:
      | React.ChangeEvent<{ name?: string; value: unknown }>
      | { target: { name?: string; value: unknown } },
  ) => {
    const { name, value } = e.target;
    if (!name) return;
    setFormState((currentState) => ({
      ...(currentState as SupplierOrder),
      [name]: value,
    }));
  };

  const handleToggleDetails = () => {
    setDetailsExpanded((currentState) => !currentState);
  };

  const columnsToRender = columns.filter(
    ({ onlyAllProductsView }) =>
      !onlyAllProductsView || (onlyAllProductsView && products),
  );

  const handleSave = () => {
    const data = (({
      id,
      deliveryDate,
      status,
      supplierOrderNo,
      receipt,
      observation,
      paymentPlanId,
      invoiceNumber,
      invoiceKey,
      ticket,
    }: SupplierOrder) => ({
      id,
      deliveryDate,
      status,
      supplierOrderNo,
      receipt,
      observation,
      paymentPlanId,
      invoiceNumber,
      invoiceKey,
      ticket,
    }))(formState as SupplierOrder);
    dispatch(updateSupplierOrder(data));
  };
  const toggleAddingProduct = () => {
    setAddingProduct((currentState) => !currentState);
  };

  return (
    <>
      {subOrder && (
        <>
          <DetailsBar open={detailsExpanded}>
            {isMobile ? (
              <DetailsGrid>
                <GridItem columns={2} className="divider-bottom align-left">
                  <MobileLabel alignLeft>
                    #{subOrder.supplierOrderNumber}
                  </MobileLabel>
                </GridItem>
                <GridItem columns={1} className="divider-after">
                  <MobileLabel>
                    <StatusIndicator
                      color={
                        supplierStatusColorMap[
                          subOrder.status as SupplierOrderStatus
                        ]
                      }
                    />{' '}
                    Status do pedido
                  </MobileLabel>
                  <DetailValue>{subOrder.status}</DetailValue>
                </GridItem>
                <GridItem columns={1}>
                  <MobileLabel>Previsão de entrega</MobileLabel>
                  <DetailValue>
                    {subOrder.formattedEstimatedDeliveryDate}
                  </DetailValue>
                </GridItem>
                <GridItem columns={1} className="divider-after divider-bottom">
                  <MobileLabel>Valor Total</MobileLabel>
                  <DetailValue>{subOrder.formattedTotalValue}</DetailValue>
                </GridItem>
                <GridItem columns={1} className="divider-bottom">
                  <MobileLabel>Valor Digitado</MobileLabel>
                  <DetailValue>{subOrder.typedTotal}</DetailValue>
                </GridItem>
                <SeeMoreButton
                  onClick={handleToggleDetails}
                  endIcon={
                    <ExpandIcon
                      fontSize="small"
                      className={detailsExpanded ? 'open' : ''}
                    />
                  }
                >
                  Ver detalhes
                </SeeMoreButton>
              </DetailsGrid>
            ) : (
              <>
                {detailsFields.map(({ label, key, customClass }) => (
                  <DetailItem
                    status={subOrder.status as SupplierOrderStatus}
                    key={key}
                    className={customClass}
                  >
                    <DetailLabel>{label}</DetailLabel>
                    <DetailValue>{subOrder[key] as string}</DetailValue>
                  </DetailItem>
                ))}
                <ExpandButton onClick={handleToggleDetails}>
                  <ExpandIcon
                    fontSize="small"
                    className={detailsExpanded ? 'open' : ''}
                  />
                </ExpandButton>
              </>
            )}
          </DetailsBar>
          <Collapse in={detailsExpanded}>
            <SubOrderDetailsContainer>
              <Title>Detalhes do pedido</Title>
              <DetailsDivider />
              <Grid>
                {expandedFields.map(
                  ({
                    key,
                    columns: cols,
                    rows,
                    label,
                    multiline,
                    input: inputType,
                    options,
                    disabled,
                  }) => (
                    <Field key={key} columns={isMobile ? 12 : cols} rows={rows}>
                      <Label>{label}</Label>
                      <FormField
                        input={inputType}
                        value={formState?.[key] as string}
                        multiline={multiline}
                        name={key}
                        onChange={handleChange}
                        error=""
                        options={options}
                        disabled={disabled}
                      />
                    </Field>
                  ),
                )}
              </Grid>
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                <Button onClick={handleSave} variant="contained">
                  Salvar Alterações
                </Button>
              </Box>
            </SubOrderDetailsContainer>
          </Collapse>
          <Divider />
        </>
      )}
      <SearchInput
        onChange={handleInput}
        value={input}
        endAdornment={<SearchIcon />}
        disableUnderline
        placeholder="Pesquisar produto"
        fullWidth
      />
      <Divider />
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <Row>
              {columnsToRender.map(({ label, key }, index) => (
                <Cell key={key} className={!index ? 'wide' : ''}>
                  {label}
                </Cell>
              ))}
              <Cell>Ações</Cell>
            </Row>
          </TableHead>
          <TableBody>
            {productsToRender
              .sort((a) => (a.status === 'CANCELED' ? 1 : -1))
              .filter(
                (product) =>
                  !['POS_SHORTAGE', 'PRE_SHORTAGE'].includes(
                    product?.status || '',
                  ),
              )
              .map((product) => (
                <ProductRow
                  key={product.id}
                  product={product}
                  columns={columnsToRender}
                />
              ))}
            {subOrder && addingProduct && (
              <NewOrderProduct supplierOrderId={subOrder.id} />
            )}
            {subOrder && (
              <Row>
                <Cell colSpan={columnsToRender.length + 1}>
                  <Button
                    onClick={toggleAddingProduct}
                    color={addingProduct ? 'secondary' : 'default'}
                    startIcon={addingProduct ? <Close /> : <Add />}
                  >
                    {addingProduct ? 'Cancelar' : 'Adicionar'} Produto
                  </Button>
                </Cell>
              </Row>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SubOrder;
