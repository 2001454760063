import {
  FETCH_COMPETITORS_SUCCESS,
  FETCH_COMPETITORS_ERROR,
  GET_COMPETITORS,
  updateCompetitors,
  showSpinner,
  hideSpinner,
} from '../actions/competitors';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'competitors';

export const getCompetitorsFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_COMPETITORS) {
          dispatch(
            apiRequest(
              'GET',
              URL,
              action.payload,
              FETCH_COMPETITORS_SUCCESS,
              FETCH_COMPETITORS_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processCompetitorsCollection =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_COMPETITORS_SUCCESS) {
          dispatch(
            updateCompetitors(
              action.payload.docs ? action.payload : { docs: action.payload },
            ),
          );
          dispatch(hideSpinner());
        } else if (action.type === FETCH_COMPETITORS_ERROR) {
          dispatch(hideSpinner());
          Toastify.addError(
            'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
          );
        }
      };

export const competitorsMdl = [getCompetitorsFlow, processCompetitorsCollection];
