import React from 'react';
import {
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../interfaces/redux';
import {
  createPaymentMethod,
  getPaymentMethod,
  hideModal,
  updatePaymentMethod,
} from '../../../redux/actions/paymentMethod';
import DialogContainer from '../../../sharedComponents/DialogContainer';
import Input from '../../../sharedComponents/Input';
import { Props } from './interfaces';
import {
  Container,
  Title,
  Divider,
  ConfirmButton,
  CancelButton,
  ButtonContainer,
} from './styles';

const PaymentMethodModal: React.FC<Props> = ({ editing }) => {
  const [name, setName] = React.useState<string>('');
  const [active, setActive] = React.useState<boolean>(true);

  const methodToEdit = useSelector((state: RootState) => state.paymentMethod);

  const open = useSelector((state: RootState) => state.ui.paymentMethodModal);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!open) {
      setName('');
      setActive(true);
      return;
    }
    dispatch(getPaymentMethod(editing));
  }, [editing, open]);

  React.useEffect(() => {
    if (!methodToEdit.id) return;
    setName(methodToEdit.name);
    setActive(methodToEdit.active);
  }, [methodToEdit]);

  const handleClose = () => {
    dispatch(hideModal());
  };

  const handleChange = (e: {
    target: {
      name?: string | undefined;
      value: string | number[];
      checked?: boolean;
    };
  }) => {
    const { name: key, value, checked } = e.target;
    if (!key) return;
    switch (key) {
      case 'name':
        setName(value as string);
        break;
      case 'active':
        setActive(checked || false);
        break;
      default:
        break;
    }
  };

  const handleSave: React.FormEventHandler = (e) => {
    e.preventDefault();
    if (editing === undefined) {
      dispatch(createPaymentMethod({ name, active }));
    } else {
      dispatch(
        updatePaymentMethod({
          id: editing,
          name,
          active,
        }),
      );
    }
  };

  return (
    <DialogContainer open={open} onClose={handleClose}>
      <Container>
        <Title>
          {editing === undefined ? 'Novo' : 'Editar'} Método de Pagamento
        </Title>
        <Divider />
        <form onSubmit={handleSave}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  onChange={handleChange}
                  name="active"
                />
              }
              label="Ativo"
            />
          </FormGroup>
          <Input
            style={{ marginBottom: 8 }}
            fullWidth
            name="name"
            label="Método"
            icon="payments"
            value={name}
            onChange={handleChange}
          />
          <ButtonContainer>
            <CancelButton onClick={handleClose}>Cancelar</CancelButton>
            <ConfirmButton type="submit">Salvar</ConfirmButton>
          </ButtonContainer>
        </form>
      </Container>
    </DialogContainer>
  );
};

export default PaymentMethodModal;
