import {
  UPDATE_SUPPLIER_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_SUPPLIER_ORDER_SUCCESS,
  UPDATE_ORDER_ERROR,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_ERROR,
  GET_ORDER,
  CREATE_ORDER,
  UPDATE_ORDER,
  CREATE_SUPPLIER_ORDER,
  updateObjectOrder,
  showSpinner,
  hideSpinner,
  hideModal,
  getOrder,
} from '../actions/order';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'orders';

export const createOrderFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_ORDER) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_ORDER_SUCCESS,
        UPDATE_ORDER_ERROR,
        {},
        'apiV2',
      ),
    );
    dispatch(showSpinner());
  }
};

export const createSupplierOrderFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_SUPPLIER_ORDER) {
    dispatch(
      apiRequest(
        'POST',
        `${URL}/${action.payload.orderId}/supplierOrders`,
        action.payload,
        UPDATE_SUPPLIER_ORDER_SUCCESS,
        UPDATE_ORDER_ERROR,
        {},
        'apiV2',
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateOrderFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_ORDER) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_ORDER_SUCCESS,
        UPDATE_ORDER_ERROR,
        {},
        'apiV2',
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateSupplierOrderFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_SUPPLIER_ORDER) {
    dispatch(
      apiRequest(
        'PUT',
        'supplierOrders',
        action.payload,
        UPDATE_SUPPLIER_ORDER_SUCCESS,
        UPDATE_ORDER_ERROR,
        {},
        'apiV2',
      ),
    );
    dispatch(showSpinner());
  }
};

export const getOrderFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_ORDER) {
    const { id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { id },
        FETCH_ORDER_SUCCESS,
        FETCH_ORDER_ERROR,
        {},
        'apiV2',
      ),
    );
    dispatch(showSpinner());
  }
};

export const processOrderCollection = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === FETCH_ORDER_SUCCESS) {
    dispatch(updateObjectOrder(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_ORDER_SUCCESS) {
    dispatch(updateObjectOrder(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    // To reload the page
    const { id } = getState().order;
    dispatch(getOrder(id));
    Toastify.addSuccess('Pedido atualizado.');
  } else if (action.type === UPDATE_SUPPLIER_ORDER_SUCCESS) {
    dispatch(hideSpinner());
    // To reload the page
    const { id } = getState().order;
    dispatch(getOrder(id));
    Toastify.addSuccess('Pedido atualizado.');
  } else if (action.type === UPDATE_ORDER_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Erro ao atualizar pedido');
  }
};

export const orderMdl = [
  createOrderFlow,
  updateOrderFlow,
  getOrderFlow,
  createSupplierOrderFlow,
  updateSupplierOrderFlow,
  processOrderCollection,
];
