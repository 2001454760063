import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  TextField,
  FormGroup,
  Button,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import Alert from '../../../sharedComponents/Alert/index';
import Spinner from '../../../sharedComponents/Spinner/index';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
  StyledPaper,
} from './styles';

import {
  getModule,
  createModule,
  updateModule,
} from '../../../redux/actions/module';

const styles = {
  formButton: {
    margin: 2,
  },
};

const ModuleModal = ({
  id,
  dispatch,
  module,
  moduleModal,
  handleClose,
  moduleSpinner,
}) => {
  const [active, setActive] = useState(true);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [key, setKey] = useState('');
  const [keyError, setKeyError] = useState(false);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getModule(id));
    }
  }, [id]);

  useEffect(() => {
    if (module && id !== 'new') {
      setActive(module.active);
      setName(module.name);
      setKey(module.key);
    }
  }, [module]);

  useEffect(() => {
    // Close the modal after update the modules list
    if (!moduleModal) {
      handleClose();
    }
  }, [moduleModal]);

  const handleSave = async () => {
    let err = false;
    setNameError(!name);
    setKeyError(!key);
    err = !name || !key;

    if (!err) {
      const response = await Alert();
      if (response?.confirm) {
        if (id === 'new') {
          dispatch(
            createModule({
              active,
              name,
              key,
            }),
          );
        } else {
          dispatch(
            updateModule({
              id,
              active,
              name,
              key,
            }),
          );
        }
      }
    }
  };

  return (
    <>
      {moduleSpinner && <Spinner overlay />}
        <StyledPaper
          style={{ opacity: moduleSpinner ? 0.5 : 1 }}
        >
          <ModalTitle>
            {id === 'new' ? 'Adicionar' : 'Editar'} Módulo do Sistema
          </ModalTitle>
          <FormGroup>
            <CheckboxContent>
              <FormControlLabel
                control={
                  <Switch
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                  />
                }
                label="Ativo"
                labelPlacement="start"
              />
            </CheckboxContent>
            <InputContent>
              <TextField
                id="outlined-password-input"
                label="Nome"
                type="text"
                error={nameError}
                helperText={nameError && 'Nome é obrigatório'}
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </InputContent>
            <InputContent>
              <TextField
                id="outlined-password-input"
                label="Código"
                type="text"
                error={keyError}
                helperText={keyError && 'O código é obrigatório'}
                variant="outlined"
                value={key}
                onChange={(e) => setKey(e.target.value)}
                fullWidth
              />
            </InputContent>
            <ButtonContainer>
              <Button
                color="secondary"
                onClick={handleClose}
                variant="contained"
                style={styles.formButton}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={handleSave}
                variant="contained"
                style={styles.formButton}
              >
                Salvar
              </Button>
            </ButtonContainer>
          </FormGroup>
        </StyledPaper>
    </>
  );
};

export default connect((state) => ({
  module: state.module,
  moduleModal: state.ui.moduleModal,
  moduleSpinner: state.ui.moduleLoading,
}))(ModuleModal);
