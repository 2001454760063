import styled from 'styled-components';
import MuiDivider from '@material-ui/core/Divider';
import MuiButton from '@material-ui/core/Button';

export const Title = styled.h2`
  color: #133da5;
`;

export const Container = styled.div`
  padding: 16px;
`;

export const Divider = styled(MuiDivider)`
  margin: 8px 0 16px 0;
`;

export const ConfirmButton = styled(MuiButton)`
  background-color: #133da5;
  color: #fff;
`;

export const CancelButton = styled(MuiButton)`
  background-color: #f95f52;
  color: #fff;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 8px;
`;
