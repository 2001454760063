import {
  Input as MuiInput,
  Table as MuiTable,
  TableRow,
  TableCell,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TextField,
} from '@material-ui/core';
import { KeyboardArrowDown, SearchRounded } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import styled, { css } from 'styled-components';
import { completeSupplierStatusColorMap } from '../../../../interfaces/orders';

export const FilterBox = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
  @media (max-width: 959px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const SearchInput = withStyles((theme) => ({
  root: {
    border: '1px solid #1A2D78',
    borderRadius: 8,
    borderShadow: '1px 1px 4px rgba(0, 0, 0, 0.08)',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    backgroundColor: '#FFF',
  },
}))(MuiInput);

export const Select = withStyles((theme) => ({
  root: {
    marginTop: 3,
    height: 34,
    justifyContent: 'center',
    backgroundColor: '#F3F2F7',
    border: '1px solid #1A2D78',
    borderRadius: 8,
    borderShadow: '1px 1px 4px rgba(0, 0, 0, 0.08)',
    width: 216,
    '& .MuiInputBase-root': {
      color: '#345493',
      marginTop: 0,
      '& .MuiSelect-root': {
        paddingLeft: 0,
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        '& .MuiSelect-icon': {
          right: 16,
        },
      },
    },
    '& .MuiFormLabel-root': {
      transform: `translate(${theme.spacing(4)}px, ${theme.spacing(1)}px)`,
      color: '#345493',
    },
  },
}))(TextField);

export const SearchIcon = withStyles({
  root: {
    color: '#1A2D78',
  },
})(SearchRounded);

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid #f3f2f7;
  margin: 16px 0;
`;

export const Table = withStyles({})(MuiTable);
export const Row = withStyles({
  root: {
    background: '#FFF',
    '&:nth-of-type(odd)': {
      background: '#F3F2F7',
    },
  },
})(TableRow);
export const Cell = withStyles({
  root: {
    color: '#3B3F41',
    fontSize: 12,
    borderBottom: 0,
    textAlign: 'center',
    '&.wide': {
      minWidth: 304,
    },
    '&.with-sub-value': {
      fontSize: 15,
      fontWeight: 600,
      textAlign: 'left',
    },
  },
})(TableCell);
export const TableBody = withStyles({})(MuiTableBody);
export const TableHead = withStyles({
  root: {
    '& th': { fontSize: 15, fontWeight: 600, textAlign: 'center' },
    '& tr': { background: '#FFF !important' },
  },
})(MuiTableHead);

export const DetailsBar = styled.div`
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: hidden;
  background-color: #f3f2f7;
  flex: 1;
  border-bottom: 0px solid #d1d4d6;
  transition: border-bottom ease-in-out 1s;
  transition: border-radius ease-in-out 1s;
`;

export const DetailItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-left: 1px solid #d1d4d6;
  padding: 3px 0;
  flex: 1;

  &:nth-child(2) {
    border-left: none;
  }
`;

export const Highlight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-left: 1px solid #d1d4d6;
  padding: 16px 0;
  background-color: ${completeSupplierStatusColorMap.CANCELED.main};
  border-radius: 8px;
  width: 192px;
`;

export const DetailLabel = styled.span`
  display: block;
  font-weight: 600;
  font-size: 14px;
  color: #6d7173;
  text-align: center;
  .highlight & {
    color: #252729;
  }
`;

export const DetailValue = styled.span`
  font-size: 14px;
  color: #6d7173;
  text-align: center;
  .highlight & {
    color: #252729;
  }
`;

export const MobileLabel = styled.span<{ alignLeft?: boolean }>`
  font-size: 14px;
  font-weight: 600;
  display: flex;
  text-align: center;
  align-items: center;
  ${({ alignLeft }) =>
    alignLeft &&
    css`
      text-align: left;
      align-items: flex-start;
    `}
`;

export const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

export const GridItem = styled.div<{ columns: number }>`
  grid-column: span ${({ columns }) => columns};
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  position: relative;
  &.align-left {
    text-align: left;
    align-items: flex-start;
  }
  &.divider-after::after {
    content: '';
    height: 85%;
    border-right: 1px solid #d1d4d6;
    position: absolute;
    right: 0;
  }

  &.divider-bottom {
    border-bottom: 1px solid #d1d4d6;
  }
`;

export const StatusIndicator = styled.div<{ color: string }>`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin-right: 4px;
  background-color: ${({ color }) => color};
  box-shadow: 0px 0px 4px ${({ color }) => color};
`;

export const SelectIcon = styled(KeyboardArrowDown)`
  color: #133da5;
`;
