import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Cancel, CheckCircle, Edit } from '@material-ui/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentCondition } from '../../interfaces/paymentMethods';
import { RootState } from '../../interfaces/redux';
import { showModal } from '../../redux/actions/paymentCondition';
import { getPaymentConditions } from '../../redux/actions/paymentConditions';
import Container from '../../sharedComponents/Container';
import Pagination from '../../sharedComponents/Pagination';
import SectionContent from '../../sharedComponents/SectionContent';
import SectionHeader from '../../sharedComponents/SectionHeader';
import PaymentConditionModal from '../Modals/PaymentConditionModal';
import { AddButton, AddIcon, Paper } from './styles';

const PaymentConditions: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [editing, setEditing] = React.useState<undefined | number>();
  const dispatch = useDispatch();
  const { docs: paymentConditions, total, pages, limit } = useSelector(
    (state: RootState) => state.paymentConditions,
  );

  React.useEffect(() => {
    dispatch(getPaymentConditions({ page, limit: 10 }));
  }, [page]);

  const handleClick = () => {
    setEditing(undefined);
    dispatch(showModal());
  };

  const handleEditClick = (id: number) => () => {
    setEditing(id);
    dispatch(showModal());
  };

  return (
    <Container>
      <SectionHeader
        title={
          <span>
            Condições de Pagamento{' '}
            <Tooltip title="Nova Condição de Pagamento">
              <AddButton size="small" onClick={handleClick}>
                <AddIcon />
              </AddButton>
            </Tooltip>
          </span>
        }
        hideCompanySelector
        hideDatePicker
      />
      <SectionContent>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ativo</TableCell>
                <TableCell>Condição</TableCell>
                <TableCell>Prazos</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(paymentConditions as PaymentCondition[]).map(
                ({ name, deadlines, active, id }: PaymentCondition) => (
                  <TableRow key={id}>
                    <TableCell>
                      {active ? (
                        <CheckCircle htmlColor="#48B748" />
                      ) : (
                        <Cancel htmlColor="#F95F52" />
                      )}
                    </TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>
                      <Box display="flex" flexWrap="wrap" style={{ gap: 4 }}>
                        {deadlines?.map((deadline) => (
                          <Chip key={deadline} label={deadline} />
                        ))}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Editar">
                        <IconButton onClick={handleEditClick(id as number)}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
          <Pagination
            page={page}
            pages={pages}
            total={total}
            limit={limit}
            onChangePage={setPage}
            showing={paymentConditions.length}
          />
        </Paper>
      </SectionContent>
      <PaymentConditionModal editing={editing} />
    </Container>
  );
};

export default PaymentConditions;
