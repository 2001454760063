import React from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Cancel, CheckCircle, Edit } from '@material-ui/icons';
import currency from 'currency.js';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentPlans } from '../../interfaces/paymentMethods';
import { RootState } from '../../interfaces/redux';
import { showModal } from '../../redux/actions/paymentPlan';
import { getPaymentPlans } from '../../redux/actions/paymentPlans';
import Container from '../../sharedComponents/Container';
import Pagination from '../../sharedComponents/Pagination';
import SectionContent from '../../sharedComponents/SectionContent';
import SectionHeader from '../../sharedComponents/SectionHeader';
import { AddButton, AddIcon, Paper } from './styles';
import PaymentPlanModal from '../Modals/PaymentPlanModal';

const PaymentMethods: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [editing, setEditing] = React.useState<undefined | number>();
  const dispatch = useDispatch();
  const { docs: paymentPlans, total, pages, limit } = useSelector(
    (state: RootState) => state.paymentPlans,
  );

  React.useEffect(() => {
    dispatch(getPaymentPlans({ page, limit: 10 }));
  }, [page]);

  const handleClick = () => {
    setEditing(undefined);
    dispatch(showModal());
  };

  const handleEditClick = (id: number) => () => {
    setEditing(id);
    dispatch(showModal());
  };

  return (
    <Container>
      <SectionHeader
        title={
          <span>
            Planos de Pagamento{' '}
            <Tooltip title="Novo Plano de Pagamento">
              <AddButton size="small" onClick={handleClick}>
                <AddIcon />
              </AddButton>
            </Tooltip>
          </span>
        }
        hideCompanySelector
        hideDatePicker
      />
      <SectionContent>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Padarias</TableCell>
                <TableCell>Fornecedores</TableCell>
                <TableCell>Apelido</TableCell>
                <TableCell>Método</TableCell>
                <TableCell>Condições</TableCell>
                <TableCell>Tarifa/Desconto</TableCell>
                <TableCell>Gateway</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentPlans.map(
                ({
                  displayName,
                  enabledToBakeries,
                  enabledToSuppliers,
                  taxFees,
                  paymentCondition,
                  paymentMethod,
                  id,
                  usesGateway,
                }: PaymentPlans) => (
                  <TableRow key={id}>
                    <TableCell>
                      {enabledToBakeries ? (
                        <CheckCircle htmlColor="#48B748" />
                      ) : (
                        <Cancel htmlColor="#F95F52" />
                      )}
                    </TableCell>
                    <TableCell>
                      {enabledToSuppliers ? (
                        <CheckCircle htmlColor="#48B748" />
                      ) : (
                        <Cancel htmlColor="#F95F52" />
                      )}
                    </TableCell>
                    <TableCell>{displayName}</TableCell>
                    <TableCell>{paymentMethod?.name}</TableCell>
                    <TableCell>{paymentCondition?.name}</TableCell>
                    <TableCell>
                      {currency(taxFees || 0, { precision: 4 })
                        .multiply(100)
                        .value.toLocaleString('pt-BR')}
                      %
                    </TableCell>
                    <TableCell>
                      {usesGateway ? (
                        <CheckCircle htmlColor="#48B748" />
                      ) : (
                        <Cancel htmlColor="#F95F52" />
                      )}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Editar">
                        <IconButton onClick={handleEditClick(id as number)}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
          <Pagination
            page={page}
            pages={pages}
            total={total}
            limit={limit}
            onChangePage={setPage}
            showing={paymentPlans.length}
          />
        </Paper>
      </SectionContent>
      <PaymentPlanModal editing={editing} />
    </Container>
  );
};

export default PaymentMethods;
