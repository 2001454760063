import {
  UPDATE_PASSWORD,
  SUCCESS_UPDATE_PASSWORD,
  ERROR_UPDATE_PASSWORD,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/password';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify/index';

const URL = 'users/update-password';

export const updatePasswordFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_PASSWORD) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        SUCCESS_UPDATE_PASSWORD,
        ERROR_UPDATE_PASSWORD,
        { loggedIn: true },
        'auth',
      ),
    );
    dispatch(showSpinner());
  }
};

export const processUpdatePassword = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === SUCCESS_UPDATE_PASSWORD) {
    dispatch(hideModal());
    dispatch(hideSpinner());
    Toastify.addSuccess('Senha atualizada com sucesso');
  } else if (action.type === ERROR_UPDATE_PASSWORD) {
    Toastify.addError('Erro ao atualizar senha');
    console.error(action.payload);
    dispatch(hideSpinner());
  }
};

export const passwordMdl = [updatePasswordFlow, processUpdatePassword];
