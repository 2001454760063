import { CashClubSetting } from '../../interfaces/cashClubSettings';
import Service from '../Service';

export const getCashClubRecords = (companyId: number) => {
  const route = `companies/${companyId}/cashClubSettings`;
  const api = new Service({ apiName: 'apiV2', route });
  return api
    .get({ listAll: true })
    .then(({ data, ok, message }) => ({
      ok,
      data: data as {
        docs: CashClubSetting[];
        page: number;
        total: number;
        pages: number;
        limit: number;
      },
      error: message,
    }))
    .catch((e) => ({
      ok: false,
      data: undefined,
      error: e?.response?.data?.message,
    }));
};
