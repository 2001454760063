import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Container,
  useStyles,
  PrimaryButton,
  Link,
  ChildContainer,
} from './styles';
import { RootState } from '../../interfaces/redux';
import Card from '../../sharedComponents/OrderCard';
import { Company } from '../../interfaces/companies';
import { getCompanies } from '../../redux/actions/companies';
import { formatOrder } from '../../utils/formatOrder';
import Spinner from '../../sharedComponents/Spinner';
import SubHeader from '../../sharedComponents/SubHeader';
import { getOrder } from '../../redux/actions/order';
import SupplierCard from './components/SupplierCard';
import { download } from '../../services/Download';
import { Order, OrderProduct } from '../../interfaces/orders';
import ShortageCard from './components/ShortageCard';

const orderFields: {
  [key: string]:
    | string
    | { label: string; ifEmpty?: string; hideIfEmpty?: boolean };
} = {
  formattedOrderDate: 'Data do Pedido',
  formattedDeliveryDate: { label: 'Data de entrega', hideIfEmpty: true },
  formattedEstimatedDeliveryDate: {
    label: 'Previsão de entrega',
    ifEmpty: 'deliveryDate',
  },
  discount: 'Desconto',
  formattedFinalTotalValue: 'Total',
  'paymentPlan.displayName': 'Pagamento',
};

const shortage = (
  order: Order,
): {
  pre: OrderProduct[];
  pos: OrderProduct[];
} => {
  const pre: OrderProduct[] = [];
  const pos: OrderProduct[] = [];

  order.supplierOrders.map(({ orderProducts }) =>
    orderProducts.map((orderProduct) => {
      if (orderProduct.status === 'PRE_SHORTAGE') {
        pre.push(orderProduct);
      } else if (orderProduct.status === 'POS_SHORTAGE') {
        pos.push(orderProduct);
      }
    }),
  );

  return { pre, pos };
};

const SupplierOrdersList: React.FC<{
  order: Order;
  isOpen: boolean;
}> = ({ order, isOpen }) => (
  <ChildContainer>
    {order.supplierOrders &&
      order.supplierOrders.map((supplierOrder) =>
        supplierOrder.orderProducts.some(({ status }) => status === null) ? (
          <SupplierCard
            key={supplierOrder.id}
            corporateNamePrefix={
              supplierOrder.supplier?.document === '0' ? 'Buscando' : null
            }
            supplierOrder={supplierOrder}
            isOpen={isOpen}
          />
        ) : null,
      )}
    {order.supplierOrders && shortage(order).pre.length > 0 && (
      <ShortageCard
        key="ruptura-pre"
        orderProducts={shortage(order).pre}
        status="PRE_SHORTAGE"
        infoValue="Já descontado do valor do pedido"
        isOpen={isOpen}
      />
    )}
    {order.supplierOrders && shortage(order).pos.length > 0 && (
      <ShortageCard
        key="ruptura-pos"
        orderProducts={shortage(order).pos}
        status="POS_SHORTAGE"
        infoValue="A ser ressarcido por cupom  de desconto"
        isOpen={isOpen}
      />
    )}
  </ChildContainer>
);

const Orders: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const companies: Company[] = useSelector(
    (state: RootState) => state.companies?.docs || state.companies,
  );

  const order: Order | {} = useSelector((state: RootState) =>
    state.order.id ? formatOrder(state.order, companies, true) : {},
  );

  const loading: boolean = useSelector(
    (state: RootState) => state.ui.orderLoading,
  );

  const printRef = React.useRef(null);

  const downloadCallback = () =>
    download(
      `orders/${id}/report`,
      {},
      {
        responseType: 'blob',
        filename: `ordersReport-${new Date().toISOString()}`,
        fileExtension: 'pdf',
        forceDefaultDownloaded: true,
      },
    );

  useEffect(() => {
    if (!id) return;
    dispatch(getOrder(id));
  }, [id]);

  useEffect(() => {
    if (companies && companies.length > 0) return;
    dispatch(getCompanies({ listAll: true }));
  }, [companies]);

  return (
    <Container ref={printRef}>
      <SubHeader title="Acompanhamento de pedidos" />
      {loading ? (
        <Spinner color="#133DA5" />
      ) : (
        <>
          <div className={classes.root}>
            <Card
              order={order as Order}
              fields={orderFields}
              disableTransition
              ActionComponent={() => (
                <div>
                  <PrimaryButton
                    onClick={downloadCallback}
                    variant="contained"
                    style={{ marginRight: '15px' }}
                  >
                    Imprimir
                  </PrimaryButton>
                  <Link to="/finances/payments">
                    <PrimaryButton variant="contained">
                      Boletos e NFs
                    </PrimaryButton>
                  </Link>
                </div>
              )}
            />
          </div>
          <SupplierOrdersList order={order as Order} isOpen />
        </>
      )}
    </Container>
  );
};

export default Orders;
