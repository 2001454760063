import { Button, TableHead, TableRow } from "@material-ui/core";
import { ErrorOutlineRounded } from "@material-ui/icons";
import styled, { css } from "styled-components";

export const Container = styled.div`
  padding: 16px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  p {
    text-align: center;
  }
`;

export const ErrorIcon = styled(ErrorOutlineRounded)`
  color: #1a2d78;
  font-size: 96px;
`

export const TableHeader = styled(TableHead)`
  th {
    font-weight: 600;
  }
`

export const Row = styled(TableRow)<{join?: boolean; leave?: boolean;}>`
  td:first-child {
    width: 208px;
  }

  ${({join, leave}) => (join || leave) && css`
    td{
      border-bottom: 0;
    }
  `}
  ${({join}) => join && css`background-color: #AFD49E;`}
  ${({leave}) => leave && css`background-color: #F8BCAE;`}
  
`

export const GenericButton = styled(Button)`
  text-transform: none;
  border-radius: 8px;
  display: flex;
  padding: 8px 16px;
`

export const DeactivateButton = styled(GenericButton)`
  background-color: #f3f2f7;
  border: 1px solid #133da5;
  color: #133da5;  
`;

export const ReactivateButton = styled(GenericButton)`
  background-color: #133da5;
  color: #fff;
`
export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: 8px;
`;