import React from 'react';
import {
  Box,
  Collapse,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import currency from 'currency.js';
import { useDispatch, useSelector } from 'react-redux';
import { Order, OrderStatus, statusMap } from '../../../../interfaces/orders';
import { RootState } from '../../../../interfaces/redux';
import { download } from '../../../../services/Download';
import { userIsAdmin } from '../../../../utils/globalFunctions';
import { FieldType } from './interfaces';
import {
  Divider,
  Field,
  Grid,
  Label,
  Paper,
  Title,
  ExpandIcon,
  Button,
  ItemContainer,
  CalculationDivider,
  DetailsValueTitle,
} from './styles';
import FormField from '../FormField';
import { updateOrder } from '../../../../redux/actions/order';
import { Company } from '../../../../interfaces/companies';
import {
  PaymentMethod,
  PaymentPlans,
} from '../../../../interfaces/paymentMethods';
import { getPlans } from '../../../../services/PaymentPlans';
import OrderValuesDetails from '../OrderValuesDetails';

const statusOptions = Object.keys(statusMap).map((value) => ({
  value,
  label: statusMap[value as OrderStatus],
}));

const OrderDetails: React.FC<{ order: Order | undefined }> = ({ order }) => {
  const [formState, setFormState] = React.useState<Order | undefined>(order);
  const [open, setOpen] = React.useState<boolean>(false);
  const [plans, setPlans] = React.useState<PaymentPlans[]>([]);
  const [errors, setErrors] = React.useState<{
    [key: string]: string | undefined;
  }>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const loggedUser = useSelector((state: RootState) => state.loggedUser);
  const company = (useSelector(
    (state: RootState) => state.companies?.docs || state.companies || [],
  ) as Company[]).find(({ document }) => document === order?.companyDocument);
  const methods = useSelector(
    (state: RootState) => state.paymentMethods?.docs || [],
  );
  const isAdmin = useSelector(
    (state: RootState) => state.loggedUser.role.name === 'ADMIN',
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!company?.paymentPlansOnCompany) return;
    if (!formState?.paymentPlanId) return;
    if (
      company.paymentPlansOnCompany.some(
        (item) =>
          item.paymentPlanId.toString() === formState.paymentPlanId?.toString(),
      )
    ) {
      setErrors((currentErrors) => ({
        ...currentErrors,
        paymentPlanId: undefined,
      }));
      return;
    }

    setErrors((currentErrors) => ({
      ...currentErrors,
      paymentPlanId:
        'Plano de pagamento selecionado não é permitido à padaria atual.',
    }));
  }, [company, formState?.paymentPlanId]);

  React.useEffect(() => {
    if (!order) return;
    setFormState(order);
  }, [order]);

  React.useEffect(() => {
    getPlans({ listAll: true, enabledToBakeries: true }).then((response) =>
      setPlans(response.data?.docs || []),
    );
  }, []);

  const fields: (company?: Company) => FieldType[] = (company) => [
    {
      key: 'company',
      label: 'Padaria:',
      columns: 12,
      subKey: 'documentWithCorporateName',
    },
    {
      key: 'discountCoupon',
      label: 'Cupom de desconto',
      columns: 8,
      input: 'string',
    },
    { key: 'discount', label: 'Desconto', columns: 4, input: 'currency' },
    {
      key: isAdmin ? 'wordpressOrderDate' : 'formattedOrderDate',
      label: 'Data do pedido',
      columns: 4,
      input: isAdmin ? 'date' : undefined,
    },
    {
      key: 'formattedEstimatedDeliveryDate',
      label: 'Previsão de entrega',
      columns: 4,
    },
    { key: 'formattedDeliveryDate', label: 'Data de entrega', columns: 4 },
    {
      key: 'paymentMethodId',
      label: 'Forma de recebimento',
      columns: 6,
      input: 'select',
      options: methods
        .filter(({ id }: PaymentMethod) =>
          plans.some(
            ({ paymentMethodId }: PaymentPlans) => paymentMethodId === id,
          ),
        )
        .map(({ id, name }: PaymentMethod) => ({
          value: id,
          label: name,
        })),
    },
    {
      key: 'paymentPlanId',
      label: 'Prazo de recebimento',
      columns: 6,
      options: (plans as PaymentPlans[])
        .filter(
          ({ paymentMethodId }) =>
            !formState?.paymentMethodId ||
            paymentMethodId === formState?.paymentMethodId,
        )
        .map(({ id, displayName }) => ({
          value: id!.toString(),
          label: displayName,
        })),
      input: 'select',
      tooltip: company?.paymentPlansOnCompany && (
        <Box padding={1}>
          Métodos de pagamento permitidos:
          <br />
          <ul>
            {company?.paymentPlansOnCompany?.map((item) => (
              <li key={item.paymentPlanId}>{item.paymentPlan.displayName}</li>
            ))}
          </ul>
        </Box>
      ),
    },
    {
      key: 'status',
      label: 'Status do pedido',
      columns: 12,
      input: 'select',
      options: statusOptions,
    },
  ];

  const toggle = () => setOpen((currentState) => !currentState);

  const handleExport = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    download(
      '/orderProducts/export',
      {
        'filter[supplierOrderId]': order?.supplierOrders?.map(({ id }) => id),
        isAdmin: userIsAdmin(loggedUser),
      },
      {
        fileExtension: 'xlsx',
        forceDefaultDownloaded: true,
        responseType: 'blob',
      },
    );
  };

  const handleChange = (
    e:
      | React.ChangeEvent<{ name?: string; value: unknown }>
      | { target: { name?: string; value: unknown } },
  ) => {
    const { name, value } = e.target;
    if (!name) return;
    setFormState((currentState) => ({
      ...(currentState as Order),
      [name]: value,
    }));
  };

  const handleSave = () => {
    const data = (({
      id,
      wordpressOrderId,
      wordpressOrderDate,
      companyDocument,
      paymentPlanId,
      discountCoupon,
      discount,
      observation,
      status,
    }: Order) => ({
      id,
      wordpressOrderId,
      wordpressOrderDate,
      companyDocument,
      paymentPlanId: paymentPlanId
        ? parseInt(paymentPlanId?.toString())
        : undefined,
      discountCoupon,
      discount,
      observation,
      status,
    }))(formState as Order);

    dispatch(updateOrder(data));
  };

  const realCurrencyConfiguration = {
    separator: '.',
    decimal: ',',
    symbol: 'R$',
  };

  return (
    <Paper elevation={0}>
      <Title isMobile={isMobile}>
        Detalhes do pedido
        <Button variant="contained" onClick={handleExport}>
          Exportar Pedido
        </Button>
        {isMobile && (
          <IconButton onClick={toggle} size="small">
            <ExpandIcon className={open ? 'open' : ''} />
          </IconButton>
        )}
      </Title>
      <Collapse in={isMobile ? open : true}>
        <Divider isMobile={isMobile} />
        <Grid columns={isMobile ? 1 : 2}>
          <Grid>
            {fields(company).map(
              ({
                key,
                label,
                columns,
                rows,
                input,
                multiline,
                subKey,
                options,
                tooltip,
              }) => (
                <Field key={key} columns={isMobile ? 12 : columns} rows={rows}>
                  <Label>{label}</Label>
                  <FormField
                    input={input}
                    value={formState?.[key] as string}
                    multiline={multiline}
                    subValue={subKey && (order?.[subKey] as string)}
                    name={key}
                    onChange={handleChange}
                    options={options}
                    error={errors?.[key]}
                    tooltip={tooltip}
                  />
                </Field>
              ),
            )}
          </Grid>
          <Grid>
            <ItemContainer>
              {!!(
                formState?.calculations?.tax ||
                formState?.calculations?.negotiatedDiscount ||
                formState?.discount ||
                formState?.calculations?.preShortageDiscount
              ) && (
                <DetailsValueTitle gap="0">
                  <span> Total Original </span>
                  <span> {formState?.formattedOriginalTotalValue || 0} </span>
                </DetailsValueTitle>
              )}
              {!!formState?.calculations?.negotiatedDiscount && (
                <OrderValuesDetails
                  label="Desconto por valor negociado"
                  value={formState?.formattedNegotiatedDiscount}
                />
              )}
              {!!formState?.discount && (
                <OrderValuesDetails
                  label="Desconto"
                  value={formState?.formattedDiscount}
                />
              )}
              {!!formState?.calculations?.preShortageDiscount && (
                <OrderValuesDetails
                  label="Ruptura pré-faturamento"
                  value={formState?.formattedPreShortageDiscount}
                />
              )}
              {!!(
                formState?.calculations?.tax &&
                (formState?.calculations?.negotiatedDiscount ||
                  formState?.discount ||
                  formState?.calculations?.preShortageDiscount)
              ) && (
                <>
                  <CalculationDivider isMobile={isMobile} />
                  <DetailsValueTitle>
                    <span> Subtotal </span>
                    <span> {formState?.formattedTotalValue || 0} </span>
                  </DetailsValueTitle>
                </>
              )}
              {!!formState?.calculations?.tax && (
                <OrderValuesDetails
                  label={
                    (formState?.calculations?.tax || 0) > 0
                      ? `Acréscimo por método de pagamento (${(
                          ((formState?.paymentPlan?.taxFees as number) || 0) *
                          100
                        )?.toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                        })}%)`
                      : `Dedução por método de pagamento (${(
                          ((formState?.paymentPlan?.taxFees as number) || 0) *
                          100
                        )?.toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                        })}%)`
                  }
                  value={formState?.formattedTax}
                />
              )}
              {!!(
                formState?.calculations?.tax ||
                formState?.calculations?.negotiatedDiscount ||
                formState?.discount ||
                formState?.calculations?.preShortageDiscount
              ) && <CalculationDivider isMobile={isMobile} />}
              <DetailsValueTitle size="18px">
                <span> Valor Final </span>
                <span> {formState?.formattedFinalTotalValue} </span>
              </DetailsValueTitle>
            </ItemContainer>

            <Field key="observation" columns={12} rows={3}>
              <Label>Observações</Label>
              <FormField
                input="string"
                value={formState?.['observation'] as string}
                multiline
                name="observation"
                onChange={handleChange}
              />
            </Field>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" marginTop={2}>
          <Button variant="contained" onClick={handleSave}>
            Salvar Alterações
          </Button>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default OrderDetails;
