import {
  INVALIDATE_API_KEY_SUCCESS,
  INVALIDATE_API_KEY_ERROR,
  FETCH_API_KEY_SUCCESS,
  FETCH_API_KEY_ERROR,
  GET_API_KEY,
  CREATE_API_KEY,
  INVALIDATE_API_KEY,
  updateObjectApiKey,
  showSpinner,
  hideSpinner,
  CREATE_API_KEY_SUCCESS,
} from '../actions/apiKey';
import { getApiKeys } from '../actions/apiKeys';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';
import history from '../../services/history';

const URL = 'apiKeys';

export const createApiKeyFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === CREATE_API_KEY) {
          dispatch(
            apiRequest(
              'POST',
              URL,
              action.payload,
              CREATE_API_KEY_SUCCESS,
              INVALIDATE_API_KEY_ERROR,
              {},
              'auth',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const invalidateApiKeyFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === INVALIDATE_API_KEY) {
          dispatch(
            apiRequest(
              'PUT',
              URL,
              action.payload,
              INVALIDATE_API_KEY_SUCCESS,
              INVALIDATE_API_KEY_ERROR,
              {},
              'auth',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const getApiKeyFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_API_KEY) {
          const { _id } = action.payload;
          dispatch(
            apiRequest(
              'GET-id',
              URL,
              { _id },
              FETCH_API_KEY_SUCCESS,
              FETCH_API_KEY_ERROR,
              {},
              'auth',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processApiKeyCollection =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_API_KEY_SUCCESS) {
          dispatch(updateObjectApiKey(action.payload));
          dispatch(hideSpinner());
        } else if (action.type === INVALIDATE_API_KEY_SUCCESS) {
          dispatch(hideSpinner());
          const { page, limit } = getState().apiKeys;
          const { id } = getState().loggedUser;
          dispatch(getApiKeys({ page, limit, userId: id }));
          Toastify.addSuccess('Operação concluída com sucesso.');
        } else if (action.type === CREATE_API_KEY_SUCCESS) {
          dispatch(hideSpinner());
          const { page, limit } = getState().apiKeys;
          const { id } = getState().loggedUser;
          dispatch(getApiKeys({ page, limit, userId: id }));
          dispatch(updateObjectApiKey(action.payload.resources));
          Toastify.addSuccess('API Key criada com sucesso.');
        } else if (action.type === INVALIDATE_API_KEY_ERROR) {
          dispatch(hideSpinner());
          Toastify.addError(action.payload.message ||
              'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
          );
        }
      };

export const apiKeyMdl = [
  createApiKeyFlow,
  invalidateApiKeyFlow,
  getApiKeyFlow,
  processApiKeyCollection,
];
