import { stringify } from 'qs';
import { MarketplaceList } from '../../interfaces/apiResponses';
import { Job } from '../../interfaces/jobs';
import { marketplaceApi } from '../../services/Api';

type FilterOperator = 'equals' | 'not' | 'in' | 'notIn' | 'lt' | 'lte' | 'gt' | 'gte' | 'contains' |
'startsWith' | 'endsWith' | 'is' | 'isNot';

export const getJobs = async (params: {page: number; filters?: {[key: string]: {[operator in FilterOperator]?: unknown}}}): Promise<MarketplaceList<Job>> => {
  const paramsStr = stringify(params, { encode: false });
  const response = await marketplaceApi.get<MarketplaceList<Job>>(`/jobs?${paramsStr}`);

  return response.data;
};
