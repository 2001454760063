export const GET_ORDER = '[order] GET';
export const UPDATE_ORDER = '[order] UPDATE';
export const CREATE_ORDER = '[order] CREATE';
export const DELETE_ORDER = '[order] DELETE';
export const UPDATE_OBJECT_ORDER = '[order] UPDATE OBJECT ORDER';
// UI
export const SHOW_ORDER_MODAL = '[order] SHOW MODAL';
export const HIDE_ORDER_MODAL = '[order] HIDE MODAL';
// Spinner
export const UPDATE_ORDER_SUCCESS = '[order] Update success';
export const UPDATE_ORDER_ERROR = '[order] Update Error';
export const FETCH_ORDER_SUCCESS = '[order] Fetch success';
export const FETCH_ORDER_ERROR = '[order] Fetch Error';
export const SHOW_ORDER_SPINNER = '[order - ui] show spinner';
export const HIDE_ORDER_SPINNER = '[order - ui] hide spinner';

// Supplier Order
export const UPDATE_SUPPLIER_ORDER = '[supplier_order] UPDATE';
export const CREATE_SUPPLIER_ORDER = '[supplier_order] CREATE';
export const UPDATE_SUPPLIER_ORDER_SUCCESS = '[supplier_order] Update success';

export const getOrder = (id) => ({
  type: GET_ORDER,
  payload: { id },
});

export const createOrder = (data) => ({
  type: CREATE_ORDER,
  payload: data,
});

export const createSupplierOrder = (data) => ({
  type: CREATE_SUPPLIER_ORDER,
  payload: data,
});

export const updateOrder = (data) => ({
  type: UPDATE_ORDER,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_ORDER_MODAL,
});

export const hideModal = () => ({
  type: HIDE_ORDER_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_ORDER_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_ORDER_SPINNER,
});

export const updateObjectOrder = (data) => ({
  type: UPDATE_OBJECT_ORDER,
  payload: data,
});

export const updateSupplierOrder = (payload) => ({
  type: UPDATE_SUPPLIER_ORDER,
  payload,
});
