import { ApiKey } from '../../interfaces/apiKeys';
import {
  UPDATE_OBJECT_API_KEY,
} from '../actions/apiKey';

export function apiKeyReducer(apiKey: ApiKey | null = null, action: {type: string; payload: ApiKey | null}) {
  switch (action.type) {
    case UPDATE_OBJECT_API_KEY:
      return action.payload;

    default:
      return apiKey;
  }
}
