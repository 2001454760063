import { documentationUrl } from './consts';

const PATHS = {
  HOME: '/',
  PROFILE: '/profile',
  MARKETPLACE: '/marketplace',
  MARKETPLACE_CHECKOUT: '/marketplace/checkout',
  INDICATORS_PURCHASES: '/metrics/purchases',
  INDICATORS_CLIENTS: '/metrics/clients',
  SALES_INTELLIGENCE_PRODUCTS: '/sales/products',
  SALES_INTELLIGENCE_PRICES: '/sales/prices',
  SALES_INTELLIGENCE_NOTABLES: '/sales/notables',
  SALES_INTELLIGENCE_MARKUPS: '/sales/markups',
  SALES_INTELLIGENCE_WHOLERETAIL: '/sales/wholeretail',
  FINANCE_DRE: '/finances/dre',
  FINANCE_EXTRACTS: '/finances/extracts',
  AUDIT_DAILY_PAYMENTS: '/finances/auditDailyPayments',
  AUDIT_RECEIVED_PAYMENTS: '/finances/auditReceivedPayments',
  AUDIT_SPLIT_REPORT: '/finances/splitReport',
  AUDIT_TRANSITORY_VALUES: '/finances/transitoryValues',
  FINANCE_TRANSFERS: '/finances/transfers',
  FINANCE_CASHIER_CLOSURE: '/finances/cashier',
  FINANCE_LOANS: '/finances/loans',
  FINANCE_PAYMENTS: '/finances/payments',
  AUDIT_REPORT_GMV: '/audit/reports/reportGmv',
  AUDIT_REPORT_INCOME: '/audit/reports/reportIncome',
  AUDIT_REPORT_GROSS_LIQUID_INCOME: '/audit/reports/reportGrossVsLiquidIncome',
  PEOPLE_MANAGEMENT: '/people',
  TRANSFORMATION_PROVIDERS: '/physical/providers',
  TRANSFORMATION_ITEMS: '/physical/items',
  INTEGRATION_SALES_INTELLIGENCE: '/integrations/areaCentral',
  INTEGRATION_ONLINE_SALES: '/integrations/epadoca',
  INTEGRATION_PRODUCTION: '/integrations/mrsistemas',
  SETTINGS: '/settings',
  SETTINGS_MODULES: '/settings/modules',
  SETTINGS_GROUPS: '/settings/groups',
  SETTINGS_COMPANIES: '/settings/companies',
  SETTINGS_COMPANY: '/settings/company',
  SETTINGS_ROLES: '/settings/roles',
  SETTINGS_USERS: '/settings/users',
  SETTINGS_PAYMENT_MACHINES: '/settings/paymentMachines',
  SETTINGS_PIC_PAY_OPERATORS: '/settings/picPayOperators',
  SETTINGS_COMMISSIONING: '/settings/commissioning',
  SETTINGS_ADD_INFO: '/settings/additionalInformationHistory',
  SETTINGS_EMBEDDED_RESOURCES: '/settings/embeddedResources',
  SETTINGS_PRODUCTS: '/settings/products',
  SETTINGS_SUPPLIERS: '/settings/suppliers',
  SETTINGS_OFFERS: '/settings/offers',
  SETTINGS_WEEKLY_TAXES: '/settings/weeklyTaxes',
  SETTINGS_COMPETITORS: '/settings/competitors',
  SETTINGS_ORDERS: '/settings/orders',
  SETTINGS_ORDER: '/settings/order',
  SETTINGS_PAYMENT_METHODS: '/settings/paymentMethods',
  SETTINGS_PAYMENT_CONDITIONS: '/settings/paymentConditions',
  SETTINGS_PAYMENT_PLANS: '/settings/paymentPlans',
  SETTINGS_CASH_CLUB: '/settings/cashClub',
  IMPORTS_PIC_PAY: '/import/picPay',
  IMPORTS_VOUCHER: '/import/voucher',
  IMPORTS_ABC_ANALYSES: '/import/abcAnalyses',
  IMPORTS_ORDERS: '/import/orders',
  IMPORTS_COMPANIES: '/import/companies',
  ORDERS: '/orders',
  ORDER: '/order',
  API_KEYS: '/apiKeys',
  JOBS: '/jobs',
  DOCUMENTATION: '/apiDocumentation',

  // PUBLIC ROUTES
  LOGIN: '/login',
  RESET_PASSWORD: '/resetPassword',
  NOT_FOUND: '/notFound',
};

export const INTEGRATIONS = {
  SALES_INTELLIGENCE: '',
  ONLINE_SALES: 'https://portal.epadoca.com/Usuario/Entrar',
  PRODUCTION: 'https://mrsistemaonline.com.br/sistemaonline/cliente/index.php',
  MARKETPLACE: 'https://compras-v1.bakerytech.com.br/',
  DOCUMENTATION: documentationUrl,
};

export default PATHS;
