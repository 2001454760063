export const SHOW_PASSWORD_MODAL = '[PASSWORD] Show modal';
export const HIDE_PASSWORD_MODAL = '[PASSWORD] Hide modal';
export const SHOW_PASSWORD_SPINNER = '[PASSWORD] Show SPINNER';
export const HIDE_PASSWORD_SPINNER = '[PASSWORD] Hide SPINNER';
export const UPDATE_PASSWORD = '[PASSWORD] Update';
export const SUCCESS_UPDATE_PASSWORD = '[PASSWORD] Update Success';
export const ERROR_UPDATE_PASSWORD = '[PASSWORD] Update Error';

export function updatePassword(payload) {
  return { type: UPDATE_PASSWORD, payload };
}

export function showModal() {
  return { type: SHOW_PASSWORD_MODAL };
}

export function hideModal() {
  return { type: HIDE_PASSWORD_MODAL };
}

export function showSpinner() {
  return { type: SHOW_PASSWORD_SPINNER };
}

export function hideSpinner() {
  return { type: HIDE_PASSWORD_SPINNER };
}
