import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  CancelOutlined,
  CheckCircleOutline,
  Publish,
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useJobs } from '../../http/hooks/useJobs';
import Pagination from '../../sharedComponents/Pagination';
import { Job } from '../../interfaces/jobs';
import JobModal from '../Modals/Job';
import { RootState } from '../../interfaces/redux';

const StatusMap = {
  undefined: <Icon className="material-icons-outlined" style={{ color: '#EBCD0C' }}>pending_outlined</Icon>,
  null: <Icon className="material-icons-outlined" style={{ color: '#EBCD0C' }}>pending_outlined</Icon>,
  false: <CancelOutlined color="error" />,
  true: <CheckCircleOutline htmlColor="#4caf50" />,
};

const columns: {
  key: keyof Job;
  label: string;
  formatter?: (value: unknown) => React.ReactNode;
}[] = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'createdAt',
    label: 'Criado em',
    formatter: (value) =>
      format(new Date(value as string), "dd/MM/yyyy 'às' HH:mm"),
  },
  {
    key: 'finishedAt',
    label: 'Executado em',
    formatter: (value) => (value ?
      format(new Date(value as string), "dd/MM/yyyy 'às' HH:mm") : '---'),
  },
  {
    key: 'hasSuccess',
    label: 'Sucesso',
    formatter: (value) => StatusMap[`${value as (boolean | undefined | null)}`],
  },
];

const Jobs = () => {
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);

  const loggedUser = useSelector((state: RootState) => state.loggedUser);

  const { data, isFetching, refetch } = useJobs({
    page,
    filters: { createdBy: { equals: loggedUser.id } },
    options: { refetchOnWindowFocus: false },
  });

  const resources = data?.resources;

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <Box p={2}>
      <Box display="flex" flex={1} justifyContent="space-between">
        <Typography variant="h5">Importação de Notas Fiscais</Typography>
        <Button
          startIcon={<Publish />}
          color="primary"
          onClick={handleOpenModal}
          variant="contained"
        >
          Novo
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(({ label, key }) => (
              <TableCell key={key}>{label}</TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {!resources?.results.length && !isFetching && (
            <TableRow>
              <TableCell colSpan={columns.length} align="center">
                Nenhum arquivo importado
              </TableCell>
            </TableRow>
          )}
          {isFetching ? (
            <TableRow>
              <TableCell colSpan={columns.length} align="center">
                <CircularProgress color="primary" />
              </TableCell>
            </TableRow>
          ) : (
            resources?.results.map(
              (job) =>
                job && (
                  <TableRow key={job.id}>
                    {columns.map(({ key, formatter }) => (
                      <TableCell key={`${key}${job.id}`}>
                        {formatter ? formatter(job[key]) : (job[key] as string)}
                      </TableCell>
                    ))}
                  </TableRow>
                ),
            )
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={columns.length} align="center">
              <Pagination
                onChangePage={setPage}
                page={page}
                pages={resources?.totalPages || 0}
                total={resources?.totalResults || 0}
                showing={resources?.results?.length}
                limit={20}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <JobModal open={open} onClose={handleCloseModal} onSuccess={refetch} />
    </Box>
  );
};

export default Jobs;
