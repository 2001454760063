import { store } from '../../redux/store';
import { obj2QueryString, userIsAdmin } from '../../utils/globalFunctions';
import Service from '../Service';

export const getSumByKeyBySalesFunction = async (data) => {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const queryString = obj2QueryString({ ...data, isAdmin });
  const apiService = new Service({
    route: `/extracts/aggregated?${queryString}`,
    apiName: 'apiV2',
  });
  const response = await apiService.store({});
  if (!response.ok) {
    throw Error(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: response.data.status,
  };
};

export const getOnlineValuesByPaymentKind = async (data) => {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const queryString = obj2QueryString({ ...data, isAdmin });
  const apiService = new Service({
    route: `/extracts/aggregatedOnlineOrders?${queryString}`,
    apiName: 'apiV2',
  });
  const response = await apiService.store({});
  if (!response.ok) {
    throw Error(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: response.data.status,
  };
};

export const getFlatRates = async (data) => {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const queryString = obj2QueryString({ ...data, isAdmin });
  const apiService = new Service({
    route: `/tasks?${queryString}`,
    apiName: 'apiV2',
  });
  const response = await apiService.get({});
  if (!response.ok) {
    throw Error(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: response.data.status,
  };
};

export const updateTransfers = async (queryData, bodyData) => {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const queryString = obj2QueryString({ ...queryData, isAdmin });
  const apiService = new Service({
    route: `/transfers?${queryString}`,
    apiName: 'apiV2',
  });
  const response = await apiService.put(null, bodyData);
  if (!response.ok) {
    throw Error(response.message);
  }
  return {
    data: response.data,
    ok: true,
    status: response.data.status,
  };
};
