import { store } from '../../redux/store';
import { userIsAdmin } from '../../utils/globalFunctions';
import Toastify from '../../utils/Toastify';
import Service from '../Service';

export default class EmbeddedResourcesService {
  static create = async (data) => {
    try {
      const isAdmin = userIsAdmin(store.getState().loggedUser);
      const apiService = new Service({
        route: '/embeddedResources',
        apiName: 'apiV2',
      });
      const response = await apiService.store({ ...data, isAdmin });
      if (!response.ok) {
        throw Error(response.message);
      }
      return {
        data: response.data,
        ok: true,
        status: response.data.status,
      };
    } catch (error) {
      Toastify.addError(
        'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
        error,
      );
      return {
        ok: false,
        message: error.message,
      };
    }
  };

  static update = async (id, data) => {
    try {
      const isAdmin = userIsAdmin(store.getState().loggedUser);
      const apiService = new Service({
        route: '/embeddedResources',
        apiName: 'apiV2',
      });
      const response = await apiService.put(id, { ...data, isAdmin });
      if (!response.ok) {
        throw Error(response.message);
      }
      return {
        data: response.data,
        ok: true,
        status: response.data.status,
      };
    } catch (error) {
      Toastify.addError(
        'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
        error,
      );
      return {
        ok: false,
        message: error.message,
      };
    }
  };
}
