import { UPDATE_PAYMENT_CONDITIONS } from '../actions/paymentConditions';

const initState = {
  docs: [],
  page: 1,
  limit: 50,
  totalDocs: 0,
  searchText: '',
};

export function paymentConditionsReducer(paymentConditions = initState, action) {
  switch (action.type) {
    case UPDATE_PAYMENT_CONDITIONS:
      return action.payload;

    default:
      return paymentConditions;
  }
}
