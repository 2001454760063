import React from 'react';
import { FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../interfaces/redux';
import {
  createPaymentCondition,
  getPaymentCondition,
  hideModal,
  updatePaymentCondition,
} from '../../../redux/actions/paymentCondition';
import DialogContainer from '../../../sharedComponents/DialogContainer';
import Input from '../../../sharedComponents/Input';
import { Props } from './interfaces';
import {
  Container,
  Title,
  Divider,
  ConfirmButton,
  CancelButton,
  ButtonContainer,
  ChipInput
} from './styles';

const PaymentConditionModal: React.FC<Props> = ({ editing }) => {
  const [name, setName] = React.useState<string>('');
  const [active, setActive] = React.useState<boolean>(true);
  const [deadlines, setDeadlines] = React.useState<number[]>([]);

  const conditionToEdit = useSelector(
    (state: RootState) => state.paymentCondition,
  );

  const open = useSelector(
    (state: RootState) => state.ui.paymentConditionModal,
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!open) {
      setName('');
      setActive(true);
      setDeadlines([]);
      return;
    }
    dispatch(getPaymentCondition(editing));
  }, [editing, open]);

  React.useEffect(() => {
    if (!conditionToEdit.id) return;
    setName(conditionToEdit.name);
    setActive(conditionToEdit.active);
    setDeadlines(conditionToEdit.deadlines);
  }, [conditionToEdit]);

  const handleClose = () => {
    dispatch(hideModal());
  };

  const handleChange = (e: {
    target: {
      name?: string | undefined;
      value: string | null;
      checked?: boolean;
    };
  }) => {
    const { name: key, value, checked } = e.target;
    if (!key) return;
    switch (key) {
      case 'name':
        setName(value as string);
        break;
      case 'active':
        setActive(checked || false);
        break;
      default:
        break;
    }
  };

  const handleSave: React.FormEventHandler = (e) => {
    e.preventDefault();
    if (editing === undefined) {
      dispatch(
        createPaymentCondition({
          name,
          active,
          deadlines,
        }),
      );
    } else {
      dispatch(
        updatePaymentCondition({
          id: editing,
          name,
          active,
          deadlines,
        }),
      );
    }
  };
  const handleDelete = (_: number, i: number) =>
    setDeadlines((currentState) => {
      const copy = [...currentState];
      copy.splice(i, 1);
      return copy;
    });

    const handleAdd = (value: string) => {
      setDeadlines(currentState => [...currentState, parseFloat(value)])
    }
    const handleInput = (e: React.KeyboardEvent) => {
      const value = e.key;
      if(value.match(/[^\.]/) && value.match(/^\D$/)) e.preventDefault();
      
    }

  return (
    <DialogContainer open={open} onClose={handleClose}>
      <Container>
        <Title>
          {editing === undefined ? 'Nova' : 'Editar'} Condição de Pagamento
        </Title>
        <Divider />
        <form onSubmit={handleSave}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  onChange={handleChange}
                  name="active"
                />
              }
              label="Ativo"
            />
          </FormGroup>
          <Input
            style={{ marginBottom: 8 }}
            fullWidth
            name="name"
            label="Condição"
            icon="currency_exchange"
            value={name}
            onChange={handleChange}
          />
          <ChipInput
            value={deadlines}
            onAdd={handleAdd}
            onDelete={handleDelete}
            newChipKeys={[' ', '/', ',', 'Enter']}
            inputMode='numeric'
            onKeyDownCapture={handleInput}
            fullWidth
            variant="outlined"
            placeholder='Prazos'
            clearInputValueOnChange
            InputLabelProps={{ shrink: false }}
          />
          <ButtonContainer>
            <CancelButton onClick={handleClose}>Cancelar</CancelButton>
            <ConfirmButton type="submit">Salvar</ConfirmButton>
          </ButtonContainer>
        </form>
      </Container>
    </DialogContainer>
  );
};

export default PaymentConditionModal;
