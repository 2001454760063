export const GET_CASH_CLUB_SETTINGS = '[cash club settings] GET';
export const FETCH_CASH_CLUB_SETTINGS_SUCCESS = '[cash club settings] Fetch success';
export const FETCH_CASH_CLUB_SETTINGS_ERROR = '[cash club settings] Fetch Error';
export const UPDATE_CASH_CLUB_SETTINGS = '[cash club settings] Update';
export const SHOW_CASH_CLUB_SETTINGS_SPINNER = '[cash club settings - ui] show spinner';
export const HIDE_CASH_CLUB_SETTINGS_SPINNER = '[cash club settings - ui] hide spinner';

export const getCashClubSettings = (filter) => ({
  type: GET_CASH_CLUB_SETTINGS,
  payload: {
    ...filter,
  },
});

export const updateCashClubSettings = (data) => ({
  type: UPDATE_CASH_CLUB_SETTINGS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_CASH_CLUB_SETTINGS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_CASH_CLUB_SETTINGS_SPINNER,
});
