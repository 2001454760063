/* eslint-disable @typescript-eslint/camelcase */
import {
  FETCH_LOGIN_ERROR,
  UPDATE_LOGGED_USER,
  GET_LOGGED_USER,
  REFRESH_LOGIN_SUCCESS,
} from '../actions/loggedUser';
import Service from '../../services/Service';
import { sessionStorageKey } from '../../config/consts';

import { authApi, apiV2, marketplaceApi } from '../../services/Api';

const permissionsApiUrl = 'permissions';
const companiesApiUrl = 'companies';

export const getLoggedUserFlow = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === GET_LOGGED_USER) {
    const { loggedUser } = getState();
    if (Object.keys(loggedUser.role?.permissions || {}).length) {
      return;
    }

    const rolePermissionService = new Service({
      route: `${permissionsApiUrl}/${loggedUser.roleId}`,
      apiName: 'apiV2',
    });
    const rolePromise = rolePermissionService.get({ type: 'role' });

    let groupPromise;
    if (loggedUser.groupId) {
      const groupPermissionService = new Service({
        route: `${permissionsApiUrl}/${loggedUser.groupId}`,
        apiName: 'apiV2',
      });
      groupPromise = groupPermissionService.get({ type: 'group' });
    } else {
      groupPromise = Promise.resolve({});
    }

    const companiesService = new Service({
      route: companiesApiUrl,
      apiName: 'apiV2',
    });
    const companiesPromise = companiesService.get({
      group_id: loggedUser.groupId,
      isAdmin: loggedUser.role?.isAdmin,
      listAll: true,
    });
    Promise.all([groupPromise, rolePromise, companiesPromise])
      .then(
        ([
          { data: groupData },
          { data: roleData },
          { data: companiesData },
        ]) => {
          const payload = {
            ...loggedUser,
            role: {
              ...loggedUser.role,
              permissions: roleData?.permissions,
            },
            group: {
              ...loggedUser.group,
              permissions: groupData?.permissions,
            },
            companies: companiesData,
          };
          dispatch({ type: REFRESH_LOGIN_SUCCESS, payload });
          dispatch({ type: UPDATE_LOGGED_USER, payload });
        },
      )
      .catch((error) => {
        dispatch({ type: FETCH_LOGIN_ERROR, payload: error });
      });
  }
};

export const updateLoggedUser = () => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_LOGGED_USER) {
    const { token } = localStorage.getItem(sessionStorageKey)
      ? JSON.parse(localStorage.getItem(sessionStorageKey))
      : {};
    authApi.defaults.headers.common.Authorization = `Bearer ${token}`;
    apiV2.defaults.headers.common.Authorization = `Bearer ${token}`;
    marketplaceApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

export const loggedUserMdl = [updateLoggedUser, getLoggedUserFlow];
