/* eslint-disable indent */
import React from 'react';
import {
  BULK_CREATE_TRANSFER_SUCCESS,
  UPDATE_TRANSFER_SUCCESS,
  UPDATE_TRANSFER_ERROR,
  FETCH_TRANSFER_SUCCESS,
  FETCH_TRANSFER_ERROR,
  GET_TRANSFER,
  CREATE_TRANSFER,
  BULK_CREATE_TRANSFER,
  UPDATE_TRANSFER,
  updateObjectTransfer,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/transfer';
import Toastify from '../../utils/Toastify';
import { getTransfers } from '../actions/transfers';
import { apiRequest } from '../actions/api';

const URL = 'transfers';

export const createTransferFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_TRANSFER) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_TRANSFER_SUCCESS,
        UPDATE_TRANSFER_ERROR,
        {},
        'apiV2',
      ),
    );
    dispatch(showSpinner());
  }
};

export const bulkCreateTransferFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === BULK_CREATE_TRANSFER) {
    dispatch(
      apiRequest(
        'POST',
        `${URL}/bulk`,
        action.payload.payload,
        BULK_CREATE_TRANSFER_SUCCESS,
        UPDATE_TRANSFER_ERROR,
        {},
        'apiV2',
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateTransferFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_TRANSFER) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_TRANSFER_SUCCESS,
        UPDATE_TRANSFER_ERROR,
        {},
        'apiV2',
      ),
    );
    dispatch(showSpinner());
  }
};

export const getTransferFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_TRANSFER) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { _id },
        FETCH_TRANSFER_SUCCESS,
        FETCH_TRANSFER_ERROR,
        {},
        'apiV2',
      ),
    );
    dispatch(showSpinner());
  }
};

export const processTransferCollection = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === FETCH_TRANSFER_SUCCESS) {
    dispatch(updateObjectTransfer(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_TRANSFER_SUCCESS) {
    dispatch(updateObjectTransfer(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    Toastify.addSuccess('Operação concluída com sucesso.');
  } else if (action.type === BULK_CREATE_TRANSFER_SUCCESS) {
    const { transfer } = getState();
    dispatch(getTransfers(transfer?.metadata?.filterState));
    Toastify.addSuccess(
      <span>
        Operação concluída com sucesso.
        <br />
        Foram adicionados(s) {action.payload?.count} repasse(s).
      </span>,
    );
  } else if (action.type === UPDATE_TRANSFER_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(
      'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
    );
    console.error(action.payload);
  }
};

export const transferMdl = [
  createTransferFlow,
  bulkCreateTransferFlow,
  updateTransferFlow,
  getTransferFlow,
  processTransferCollection,
];
