import { TableHead, Table as MuiTable } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  width: 100%;
`;

export const Content = styled.div`
  margin: 24px 0;
  padding: 24px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

export const TableSpacer = styled.tr`
  margin: 0;
  padding: 0;
  height: 4px;
`;

const Status = styled.span`
  display: inline-block;
  width: 96px;
  padding: 4px;
  border-radius: 8px;
  color: #252729;
`;

export const ActiveStatus = styled(Status)`
  background-color: #afd49e;
`;
export const InactiveStatus = styled(Status)`
  background-color: #f8bcae;
`;

export const TableHeader = styled(TableHead)`
  th {
    &:first-child {
      text-align: left;
    }
    text-align: center;
    font-weight: 600;
    font-size: 16px;
  }
`;

export const Table = styled(MuiTable)`
  border-spacing: 0 1px;
  border-collapse: separate;
`;
