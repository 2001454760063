import React from 'react';
import { MenuItem, IconButton, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCashClubSetting,
  hideModal,
} from '../../../../redux/actions/cashClubSetting';
import DialogContainer from '../../../../sharedComponents/DialogContainer';
import {
  Container,
  Title,
  ConfirmButton,
  CancelButton,
  ButtonContainer,
  CloseIcon,
  FormContainer,
  CompanySelect,
  SelectIcon,
  Label,
  CompanyFormGroup,
  RetentionRateFormGroup,
  CashBackRateFormGroup,
  EffectiveDateFormGroup,
  Header,
  Input,
} from '../styles';
import { CashClubSetting } from '../../../../interfaces/cashClubSettings';
import { RootState } from '../../../../interfaces/redux';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { CompanyContainer } from './styles';

interface DataAction {
  field: keyof CashClubSetting;
  value: unknown;
}

const dataReducer: React.Reducer<CashClubSetting, DataAction> = (
  state,
  { field, value },
) => ({ ...state, [field]: value });

const CashClubSettingModal: React.FC<{ lastRecord: CashClubSetting; open: boolean; onClose: () => void }> = ({
  lastRecord, open, onClose
}) => {
  const [data, setData] = React.useReducer<
    React.Reducer<CashClubSetting, DataAction>
  >(dataReducer, {
    effectiveDate: null,
    activityType: 'UPDATE',
    retentionRate: (lastRecord.retentionRate || 0) * 100,
    cashBackRate: (lastRecord.cashBackRate || 0) * 100,
    companyId: lastRecord.companyId,
  });
  const [buttonEnabled, setButtonEnabled] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (
      !data.effectiveDate ||
      (parseFloat(`${data.retentionRate}`) ===
        (lastRecord.retentionRate || 0) * 100 &&
        parseFloat(`${data.cashBackRate}`) ===
          (lastRecord.cashBackRate || 0) * 100)
    ) {
      setButtonEnabled(false);
    } else {
      setButtonEnabled(true);
    }
  }, [data]);

  const handleChange = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    const { name, value } = e.target;
    if (!name) return;
    setData({ field: name as keyof CashClubSetting, value });
  };

  const handleEffectiveDate = (value: MaterialUiPickersDate) =>
    setData({ field: 'effectiveDate', value });

  const handleSave: React.FormEventHandler = (e) => {
    e.preventDefault();
    dispatch(
      createCashClubSetting({
        ...data,
        retentionRate: parseFloat(`${data.retentionRate}`) / 100,
        cashBackRate: parseFloat(`${data.cashBackRate}`) / 100,
      }),
    );
    onClose();
  };

  return (
    <DialogContainer open={open} onClose={onClose}>
      <Container>
        <Header>
          <Title>Alterar Configuração</Title>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Header>
        <form onSubmit={handleSave}>
          <FormContainer>
            <CompanyContainer>
              <Label>Padaria</Label>
              {lastRecord.company?.name}
            </CompanyContainer>
            <RetentionRateFormGroup>
              <Label>Porcentagem de retenção</Label>
              <Input
                type="number"
                inputProps={{
                  step: 0.01,
                  type: 'number',
                  min: 0,
                  required: true,
                }}
                name="retentionRate"
                onChange={handleChange}
                value={data.retentionRate}
                InputProps={{ endAdornment: '%', disableUnderline: true }}
                required
              />
            </RetentionRateFormGroup>
            <CashBackRateFormGroup>
              <Label>Porcentagem de cashback</Label>
              <Input
                InputProps={{ endAdornment: '%', disableUnderline: true }}
                type="number"
                inputProps={{
                  step: 0.01,
                  type: 'number',
                  min: 0,
                  required: true,
                }}
                name="cashBackRate"
                onChange={handleChange}
                value={data.cashBackRate}
                required
              />
            </CashBackRateFormGroup>
            <EffectiveDateFormGroup>
              <Label>Válido a partir de</Label>
              <DatePicker
                required
                name="effectiveDate"
                onChange={handleEffectiveDate}
                value={data.effectiveDate}
                placeholder={'  /  /    '}
                InputProps={{ disableUnderline: true }}
                format="dd/MM/yyyy"
                TextFieldComponent={(props) => <Input {...props} />}
                minDate={new Date(lastRecord.effectiveDate as string)}
                maxDate={new Date()}
              />
            </EffectiveDateFormGroup>
          </FormContainer>
          <ButtonContainer>
            <CancelButton onClick={onClose}>Cancelar</CancelButton>
            <ConfirmButton disabled={!buttonEnabled} type="submit">
              Atualizar
            </ConfirmButton>
          </ButtonContainer>
        </form>
      </Container>
    </DialogContainer>
  );
};

export default CashClubSettingModal;
