import styled from 'styled-components';
import MuiChipInput from 'material-ui-chip-input';
import MuiDivider from '@material-ui/core/Divider';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

export const Title = styled.h2`
  color: #133da5;
`;

export const Container = styled.div`
  padding: 16px;
`;

export const Divider = styled(MuiDivider)`
  margin: 8px 0 16px 0;
`;

export const ConfirmButton = styled(MuiButton)`
  background-color: #133da5;
  color: #fff;
`;

export const CancelButton = styled(MuiButton)`
  background-color: #f95f52;
  color: #fff;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 16px;
`;

export const ChipInput = withStyles({
  root: {
    color: 'var(--primary-text-color)',
    minWidth: '8.313rem',
    fontWeight: 500,
    '& .MuiInputBase-root': {
      display:'flex',
      alignItems: 'center',
      paddingTop: 8,
    },
    '& .MuiInputBase-input':{
      margin: 0,
      padding: '0 0 8px 0',
    },
    '& .MuiInputBase-input::placeholder': {
      color:'var(--primary-text-color)',
      opacity: 1,
    }
  },
  outlined: {
    minHeight: 40,
    '& fieldset': {
    borderColor: 'var(--primary-text-color)',
    borderRadius: '1rem',
    borderWidth: 2,}
  },
})(MuiChipInput);
