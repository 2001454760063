import { exportComponentAsPNG } from 'react-component-export-image';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import { IconButton } from '@material-ui/core';
import { CloudDownloadRounded, ImageRounded } from '@material-ui/icons';
import axios, { CancelTokenSource } from 'axios';
import SectionContent from './components/SectionContent';
import { Container } from './styles';
import { getReportSplitValues } from '../../redux/actions/reportSplitValues';
import { download } from '../../services/Download';
import { userIsAdmin } from '../../utils/globalFunctions';
import SectionHeader from '../../sharedComponents/SectionHeader';
import { Page } from '../../interfaces/pages';
import { RootState } from '../../interfaces/redux';
import {
  SplitValueReportTotals,
  SplitValuesReport,
  SplitValuesReportItem,
} from '../../interfaces/reports';
import { RangeHandler } from '../../sharedComponents/SectionHeader/interfaces';

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const AuditReportSplitValues: React.FC<Page> = ({ loading, loggedUser }) => {
  const printRef = useRef(null);
  const [source, setSource] = useState<CancelTokenSource>();

  const handleExportComponentAsImage = () => {
    if (printRef) {
      exportComponentAsPNG(printRef);
    }
  };
  const reportSplitValues: SplitValuesReport = useSelector(
    (state: RootState) => state.reportSplitValues,
  );

  const [formatedValues, setFormatedValues] = useState<SplitValuesReportItem[]>(
    [],
  );
  const [reportTotals, setReportTotals] = useState<SplitValueReportTotals>({
    reportTotalCreditsSum: 0,
    reportTotalLoanSum: 0,
    reportTotalPurchasesSum: 0,
    reportTotalValueSum: 0,
    reportTotalWeeklyRateSum: 0,
    reportTotalAdjustmentsSum: 0,
    reportTotalClubCashRetentionSum: 0,
  });

  const [dateRange, setDateRange] = useState({
    startDate: new Date(
      moment()
        .subtract(2, 'days')
        .startOf('day')
        .toString(),
    ),
    endDate: new Date(
      moment()
        .subtract(2, 'days')
        .endOf('day')
        .toString(),
    ),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (reportSplitValues && reportSplitValues.docs) {
      setReportTotals({
        ...reportSplitValues.totals,
      });

      setFormatedValues(
        reportSplitValues.docs.map((item) => ({
          ...item,
          formatedName: item.fantasyName
            ? `${item.name} (${item.fantasyName})`
            : item.name,
          formatedAdjustments: currencyFormatter.format(
            parseFloat(item.values.totalAdjustmentsSum.toString()),
          ),
          formatedWeeklyRate: currencyFormatter.format(
            parseFloat(item.values.totalWeeklyRateSum.toString()),
          ),
          formatedCredits: currencyFormatter.format(
            parseFloat(item.values.totalCreditsSum.toString()),
          ),
          formatedLoan: currencyFormatter.format(
            parseFloat(item.values.totalLoanSum.toString()),
          ),
          formatedPurchases: currencyFormatter.format(
            parseFloat(item.values.totalPurchasesSum.toString()),
          ),
          formatedClubCash: currencyFormatter.format(
            parseFloat(item.values.totalClubCashRetentionSum.toString()),
          ),
          formatedTotal: currencyFormatter.format(
            parseFloat(item.values.totalValueSum.toString()),
          ),
          formatedFinalBalance: currencyFormatter.format(
            parseFloat(item.values.lastFinalBalance.toString()),
          ),
        })),
      );
    }
  }, [reportSplitValues]);

  const handleDateRange: RangeHandler = (field) => (date) => {
    setDateRange((currentRange) => ({ ...currentRange, [field]: date }));
  };

  const handleSearch = () => {
    source?.cancel('Filter params change');
    const newSource = axios.CancelToken.source();
    setSource(newSource);
    dispatch(
      getReportSplitValues({
        dateGte: dateRange.startDate.toDateString(),
        dateLte: dateRange.endDate.toDateString(),
        cancelToken: newSource?.token,
      }),
    );
  };

  const handleFilter = () => {
    handleSearch();
  };

  const downloadCallback = () =>
    download(
      'audit/transactionalSplitValues',
      {
        dateGte: dateRange.startDate.toDateString(),
        dateLte: dateRange.endDate.toDateString(),
        isAdmin: userIsAdmin(loggedUser),
      },
      {
        filename: `transfersByClient-${new Date().toISOString()}`,
        fileExtension: 'xlsx',
        callback: () =>
          formatedValues.map((item) => ({
            Padaria: item.formatedName,
            'Total Repasse': item.values.totalValueSum,
            Split: item.values.totalCreditsSum,
            'Parcela de Dívida': item.values.totalPurchasesSum,
            'Retenção Clube Cash': item.values.totalClubCashRetentionSum,
            'Desconto UPP': item.values.totalLoanSum,
            Ajustes: item.values.totalAdjustmentsSum,
            'Taxa Semanal': item.values.totalWeeklyRateSum,
            'Saldo Final': item.values.lastFinalBalance,
          })),
      },
    );

  return (
    <>
      <Container ref={printRef}>
        <SectionHeader
          title={
            <span>
              Transferência por padaria
              <IconButton
                onClick={downloadCallback}
                size="small"
                style={{ marginLeft: '0.5rem' }}
              >
                <CloudDownloadRounded />
              </IconButton>
              <IconButton
                onClick={handleExportComponentAsImage}
                size="small"
                style={{ marginLeft: '0.5rem' }}
              >
                <ImageRounded />
              </IconButton>
            </span>
          }
          hideCompanySelector
          iconButtonStyle={{ padding: '10px' }}
          refDate={dateRange}
          onSelectDateCallback={handleDateRange}
          showDateRange
          style={{ marginBottom: 0 }}
          defaultComponentsContainerStyle={{ width: '100%' }}
          subtitle=""
          hideDatePicker={false}
          subHeaderStyle={{}}
          showAcquirers={false}
          handleSelectAcquirer={null}
          handleFilter={handleFilter}
        />

        <SectionContent
          loading={loading}
          columnsMap={{
            Padaria: 'formatedName',
            'Total Repasse': 'formatedTotal',
            Split: 'formatedCredits',
            'Parcela de Dívida': 'formatedPurchases',
            'Retenção Clube Cash': 'formatedClubCash',
            'Desconto UPP': 'formatedLoan',
            Ajustes: 'formatedAdjustments',
            'Taxa Semanal': 'formatedWeeklyRate',
            'Saldo Final': 'formatedFinalBalance',
          }}
          rows={formatedValues}
          reportTotals={reportTotals}
        />
      </Container>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  loggedUser: state.loggedUser,
  loading: state.ui.reportSplitValuesLoading,
});

export default connect(mapStateToProps)(AuditReportSplitValues);
