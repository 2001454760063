import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { marketplaceApi } from '../../services/Api';

interface CreateJobBody {
  files: string[];
  createdBy: string;
  delayUntil?: string;
  jobTypeId: number;
}

interface CreateJobError {
  data: {
    resources: {
      message: string;
    };
  };
}

interface CreateJobReturn {
  data: {
    message: string;
    resources: {
      totalFailed: number;
      totalSucceeded: number;
      failed: { file: string; error: any }[];
    };
  };
}

export function useCreateJob({
  options,
}: {
  options?: UseMutationOptions<
  CreateJobReturn,
  CreateJobError,
  CreateJobBody,
  string[]
  >;
}): UseMutationResult<
  CreateJobReturn,
  CreateJobError,
  CreateJobBody,
  unknown
  > {
  return useMutation(
    (data: CreateJobBody) =>
      marketplaceApi.post('/jobs/receipts', data),
    options,
  );
}
