import { store } from '../../redux/store';
import { userIsAdmin } from '../../utils/globalFunctions';
import Service from '../Service';

export async function getSerialNumbers() {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const apiService = new Service({
    route: '/paymentMachines/registers',
    apiName: 'apiV2',
  });
  const response = await apiService.get({ listAll: true, isAdmin });
  if (!response.ok) {
    throw Error(response.message);
  }
  return {
    data: response.data.map(({ serial_number }) => serial_number),
    ok: true,
    status: response.data.status,
  };
}

export async function updateRegister(id, data) {
  const isAdmin = userIsAdmin(store.getState().loggedUser);
  const apiService = new Service({
    route: '/paymentMachines/registers',
    apiName: 'apiV2',
  });
  const response = await apiService.put(id, { ...data, isAdmin });
  if (!response.ok) {
    throw Error(response.message);
  }
  return {
    data: response.data,
    ok: response.data.ok,
    status: response.data.status,
  };
}
