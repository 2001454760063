import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
} from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';
import {
  ActivityType,
  CashClubSetting,
} from '../../../../interfaces/cashClubSettings';
import Spinner from '../../../../sharedComponents/Spinner';
import {
  CashClubSettingDeactivateModal,
  CashClubSettingReactivateModal,
} from '../../../Modals/CashClubSettingModal';
import { CompanyColumn } from '../../interfaces';
import {
  ButtonContainer,
  Container,
  DeactivateButton,
  ErrorContainer,
  ErrorIcon,
  ReactivateButton,
  Row,
  TableHeader,
} from './styles';

const activityTypeMap: { [key in ActivityType]: string } = {
  JOIN: 'Entrou no Clube Cash',
  UPDATE: 'Alteração de taxas',
  LEAVE: 'Saída do Clube Cash',
};

const columns: CompanyColumn[] = [
  {
    label: 'Tipo de atividade',
    value: (item) => activityTypeMap[item.activityType as ActivityType],
  },
  {
    label: 'Retenção',
    value: (item) =>
      item.retentionRate
        ? (parseFloat(`${item.retentionRate}`) * 100).toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
          }) + '%'
        : '-',
  },
  {
    label: 'Cashback',
    value: (item) =>
      item.cashBackRate
        ? (parseFloat(`${item.cashBackRate}`) * 100).toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
          }) + '%'
        : '-',
  },
  {
    label: 'Data da modificação',
    value: (item) => format(new Date(item.createdAt as string), 'dd/MM/yyyy'),
  },
  {
    label: 'Válido a partir de',
    value: (item) =>
      format(new Date(item.effectiveDate as string), 'dd/MM/yyyy'),
  },
  {
    label: 'Válido até',
    value: (item, nextItem) =>
      nextItem
        ? format(new Date(nextItem.effectiveDate as string), 'dd/MM/yyyy')
        : '-',
  },
];

const RecordList: React.FC<{
  loading: boolean;
  error: boolean;
  records: CashClubSetting[];
}> = ({ loading, error, records }) => {
  const [deactivateModalOpen, setDeactivateModalOpen] = React.useState<boolean>(
    false,
  );
  const [reactivateModalOpen, setReactivateModalOpen] = React.useState<boolean>(
    false,
  );

  const toggleDeactivateModal = () => {
    setDeactivateModalOpen((currentState) => !currentState);
  };
  const toggleReactivateModal = () => {
    setReactivateModalOpen((currentState) => !currentState);
  };

  return (
    <Container>
      {loading && <Spinner parent="Table" color="#1a2d78" />}
      {error && !loading && (
        <ErrorContainer>
          <ErrorIcon />
          <p>Ocorreu um erro, por favor tente novamente.</p>
          <p>Se o problema persistir, contate o suporte.</p>
        </ErrorContainer>
      )}
      {!(error || loading) && records.length && (
        <>
          <Table>
            <TableHeader>
              <TableRow>
                {columns.map(({ label }) => (
                  <TableCell key={label}>{label}</TableCell>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {records.map((item, index) => (
                <Row
                  join={item.activityType === 'JOIN'}
                  leave={item.activityType === 'LEAVE'}
                >
                  {columns.map(({ label, value }) => (
                    <TableCell key={label}>
                      {value(item, records[index - 1])}
                    </TableCell>
                  ))}
                </Row>
              ))}
            </TableBody>
          </Table>
          <ButtonContainer>
            {records[0].activityType === 'LEAVE' ? (
              <ReactivateButton onClick={toggleReactivateModal}>
                Reativar Clube Cash
              </ReactivateButton>
            ) : (
              <DeactivateButton onClick={toggleDeactivateModal}>
                Inativar Clube Cash
              </DeactivateButton>
            )}
          </ButtonContainer>
          <CashClubSettingDeactivateModal
            lastRecord={records[0]}
            open={deactivateModalOpen}
            onClose={toggleDeactivateModal}
          />
          <CashClubSettingReactivateModal
            lastRecord={records[0]}
            open={reactivateModalOpen}
            onClose={toggleReactivateModal}
          />
        </>
      )}
    </Container>
  );
};
export default RecordList;
