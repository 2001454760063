import {
  FETCH_TRANSITORY_VALUES_SUCCESS,
  FETCH_TRANSITORY_VALUES_ERROR,
  GET_TRANSITORY_VALUES,
  updateTransitoryValues,
  showSpinner,
  hideSpinner,
} from '../actions/transitoryValues';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'audit/transitionalValues';

export const getTransitoryValuesFlow =
    ({ dispatch }) =>
      (next) =>
        (action) => {
          next(action);
          if (action.type === GET_TRANSITORY_VALUES) {
            dispatch(
              apiRequest(
                'GET',
                URL,
                action.payload,
                FETCH_TRANSITORY_VALUES_SUCCESS,
                FETCH_TRANSITORY_VALUES_ERROR,
                {},
                'apiV2',
              ),
            );
            dispatch(showSpinner());
          }
        };

export const processTransitoryValuesCollection =
    ({ dispatch }) =>
      (next) =>
        (action) => {
          next(action);
          if (action.type === FETCH_TRANSITORY_VALUES_SUCCESS) {
            dispatch(updateTransitoryValues(action.payload));
            dispatch(hideSpinner());
          }
          if (action.type === FETCH_TRANSITORY_VALUES_ERROR) {
            Toastify.addError(
              'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
            );
            dispatch(hideSpinner());
          }
        };

export const transitoryValuesMdl = [getTransitoryValuesFlow, processTransitoryValuesCollection];
