import {
  FETCH_SELLERS_SUCCESS,
  FETCH_SELLERS_ERROR,
  GET_SELLERS,
  updateSellers,
  showSpinner,
  hideSpinner,
} from '../actions/sellers';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'sellers';

export const getSellersFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_SELLERS) {
          dispatch(
            apiRequest(
              'GET',
              URL,
              action.payload,
              FETCH_SELLERS_SUCCESS,
              FETCH_SELLERS_ERROR,
              {},
              'marketplace',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processSellersCollection =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_SELLERS_SUCCESS) {
          dispatch(
            updateSellers(action.payload.resources),
          );
          dispatch(hideSpinner());
        } else if (action.type === FETCH_SELLERS_ERROR) {
          Toastify.addError(
            'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
          );
          dispatch(hideSpinner());
        }
      };

export const sellersMdl = [getSellersFlow, processSellersCollection];
