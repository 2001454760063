import {
  UPDATE_PICPAY_OPERATOR_SUCCESS,
  UPDATE_PICPAY_OPERATOR_ERROR,
  FETCH_PICPAY_OPERATOR_SUCCESS,
  FETCH_PICPAY_OPERATOR_ERROR,
  GET_PICPAY_OPERATOR,
  CREATE_PICPAY_OPERATOR,
  UPDATE_PICPAY_OPERATOR,
  updateObjectPicPayOperator,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/picPayOperator';
import { getPicPayOperators } from '../actions/picPayOperators';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'picPayOperators';

export const createPicPayOperatorFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === CREATE_PICPAY_OPERATOR) {
          dispatch(
            apiRequest(
              'POST',
              URL,
              action.payload,
              UPDATE_PICPAY_OPERATOR_SUCCESS,
              UPDATE_PICPAY_OPERATOR_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const updatePicPayOperatorFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === UPDATE_PICPAY_OPERATOR) {
          dispatch(
            apiRequest(
              'PUT',
              URL,
              action.payload,
              UPDATE_PICPAY_OPERATOR_SUCCESS,
              UPDATE_PICPAY_OPERATOR_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const getPicPayOperatorFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_PICPAY_OPERATOR) {
          const { _id } = action.payload;
          dispatch(
            apiRequest(
              'GET-id',
              URL,
              { _id },
              FETCH_PICPAY_OPERATOR_SUCCESS,
              FETCH_PICPAY_OPERATOR_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processPicPayOperatorCollection =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_PICPAY_OPERATOR_SUCCESS) {
          dispatch(updateObjectPicPayOperator(action.payload));
          dispatch(hideSpinner());
        } else if (action.type === UPDATE_PICPAY_OPERATOR_SUCCESS) {
          dispatch(updateObjectPicPayOperator(action.payload));
          dispatch(hideModal());
          dispatch(hideSpinner());
          // To reload the page
          const { page, limit } = getState().picPayOperators;
          dispatch(getPicPayOperators({ page, limit }));
          Toastify.addSuccess('Operação concluída com sucesso.');
        } else if (action.type === UPDATE_PICPAY_OPERATOR_ERROR) {
          dispatch(hideSpinner());
          Toastify.addError(
            'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
          );
          console.error(action.payload);
        }
      };

export const picPayOperatorMdl = [
  createPicPayOperatorFlow,
  updatePicPayOperatorFlow,
  getPicPayOperatorFlow,
  processPicPayOperatorCollection,
];
