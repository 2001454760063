import React from 'react';
import { AddRounded, Close, KeyboardArrowDown } from '@material-ui/icons';
import SubHeader from '../../../../sharedComponents/SubHeader';
import { HeaderContainer, AddButton, Select } from './styles';
import { IconButton, MenuItem } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../../redux/actions/cashClubSetting';

const Header: React.FC<{
  textFilter?: string;
  statusFilter?: string;
  setStatusFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
  setTextFilter: React.Dispatch<React.SetStateAction<string>>;
}> = ({ textFilter, setTextFilter, statusFilter, setStatusFilter }) => {
  const dispatch = useDispatch();

  const handleStatusFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatusFilter(e.target.value);
  };

  const handleTextFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextFilter(e.target.value);
  };

  const handleCreateClick = () => {
    dispatch(showModal());
  };

  const handleClearStatusFilter = () => {
    setStatusFilter(undefined);
  };

  return (
    <SubHeader
      title={
        <HeaderContainer>
          Clube Cash
          <AddButton onClick={handleCreateClick}>
            <AddRounded />
          </AddButton>
        </HeaderContainer>
      }
    >
      <HeaderContainer>
        <Select
          label={statusFilter ? '' : 'Status'}
          InputLabelProps={{ shrink: false }}
          select
          SelectProps={{
            disableUnderline: true,
            IconComponent: KeyboardArrowDown,
            startAdornment: statusFilter && (
              <IconButton size="small" onClick={handleClearStatusFilter}>
                <Close />
              </IconButton>
            ),
          }}
          value={statusFilter || ''}
          onChange={handleStatusFilterChange}
        >
          <MenuItem value="ACTIVE">Ativo</MenuItem>
          <MenuItem value="INACTIVE">Inativo</MenuItem>
        </Select>
        <Select
          label={textFilter ? '' : 'Pesquisar por padaria'}
          InputLabelProps={{ shrink: false }}
          InputProps={{
            disableUnderline: true,
          }}
          value={textFilter}
          onChange={handleTextFilterChange}
        />
      </HeaderContainer>
    </SubHeader>
  );
};

export default Header;
