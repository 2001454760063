import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import PATHS from './config/routePaths';
import ABCAnalysesImport from './pages/ABCAnalysesImport';
import AdditionalInformationHistory from './pages/AdditionalInformationHistory';
import AdminPanel from './pages/AdminPanel';
import CashierClosure from './pages/CashierClosure';
import Clients from './pages/Clients';
import Commissionings from './pages/Commissionings';
import Companies from './pages/Companies';
import Company from './pages/Company';
import EmbeddedResources from './pages/EmbeddedResources';
import Extracts from './pages/Extracts';
import Groups from './pages/Groups';
import Home from './pages/Home/index';
import Loans from './pages/Loans';
import Payments from './pages/Payments';
import Login from './pages/Login/index';
import Markups from './pages/Markups';
import Modules from './pages/Modules/index';
import NotableProducts from './pages/NotableProducts';
import NotFound from './pages/NotFound/index';
import PaymentMachines from './pages/PaymentMachines';
import PeopleManagementHome from './pages/PeopleManagementHome';
import PicPayImport from './pages/PicPayImport';
import PicPayOperators from './pages/PicPayOperators';
import PriceRelationships from './pages/PriceRelationships';
import Products from './pages/Products';
import Profile from './pages/Profile/index';
import Purchases from './pages/Purchases';
import ResetPassword from './pages/ResetPassword';
import Roles from './pages/Roles/index';
import SoldProducts from './pages/SoldProducts';
import Suppliers from './pages/Suppliers';
import Transfers from './pages/Transfers';
import Users from './pages/Users/index';
import VoucherImport from './pages/VoucherImport';
import WelcomePage from './pages/WelcomePage';
import Wholeretail from './pages/Wholeretail';
import history from './services/history';
import RouteWrapper from './sharedComponents/Route';
import Offers from './pages/Offers';
import WeeklyTaxes from './pages/WeeklyTaxes';
import Competitors from './pages/Competitors';
import AuditDailyPayments from './pages/AuditDailyPayments';
import AuditReportSplitValues from './pages/AuditReportSplitValues';
import AuditReceivedPayments from './pages/AuditReceivedPayments';
import ReportGMV from './pages/ReportGMV';
import ReportIncome from './pages/ReportIncome';
import ReportGrossVsLiquidIncome from './pages/ReportGrossVsLiquidIncome';
import AuditTransitoryValues from './pages/AuditTransitoryValues';
import OrdersImport from './pages/OrdersImport';
import Orders from './pages/Orders';
import Order from './pages/Order';
import PaymentConditions from './pages/PaymentConditions';
import PaymentPlans from './pages/PaymentPlans';
import PaymentMethods from './pages/PaymentMethods';
import ClientOrders from './pages/ClientOrders';
import ClientOrder from './pages/ClientOrder';
import CashClubSettings from './pages/CashClubSettings';
import CompaniesImport from './pages/CompaniesImport';
import ApiKeys from './pages/ApiKeys';
import Jobs from './pages/Jobs';

const routerWrapper = ({ showSimplifiedHome }) => (
  <Router history={history}>
    <Switch>
      <RouteWrapper
        path={PATHS.HOME}
        exact
        isPrivate
        wrapWithNavigation
        component={showSimplifiedHome ? WelcomePage : Home}
      />

      {/* ORDERS */}
      <RouteWrapper
        path={PATHS.ORDERS}
        isPrivate
        wrapWithNavigation
        component={ClientOrders}
      />
      <RouteWrapper
        path={`${PATHS.ORDER}/:id`}
        isPrivate
        wrapWithNavigation
        component={ClientOrder}
      />

      {/* PERFIL */}
      <RouteWrapper
        path={PATHS.PROFILE}
        isPrivate
        wrapWithNavigation
        component={Profile}
      />

      {/* INDICADORES */}
      <Route
        path="/metrics"
        exact
        component={() => <Redirect to={PATHS.INDICATORS_CLIENTS} />}
      />
      <RouteWrapper
        path={PATHS.INDICATORS_PURCHASES}
        isPrivate
        wrapWithNavigation
        component={Purchases}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.INDICATORS_CLIENTS}
        isPrivate
        wrapWithNavigation
        component={Clients}
        permission="read"
      />

      {/* MIX E PRECIFICAÇÃO */}
      <Route
        path="/sales"
        exact
        component={() => <Redirect to={PATHS.SALES_INTELLIGENCE_NOTABLES} />}
      />
      <RouteWrapper
        path={PATHS.SALES_INTELLIGENCE_PRODUCTS}
        isPrivate
        wrapWithNavigation
        component={SoldProducts}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SALES_INTELLIGENCE_PRICES}
        isPrivate
        wrapWithNavigation
        component={PriceRelationships}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SALES_INTELLIGENCE_NOTABLES}
        isPrivate
        wrapWithNavigation
        component={NotableProducts}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SALES_INTELLIGENCE_MARKUPS}
        isPrivate
        wrapWithNavigation
        component={Markups}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SALES_INTELLIGENCE_WHOLERETAIL}
        isPrivate
        wrapWithNavigation
        component={Wholeretail}
        permission="read"
      />

      {/* FINANCEIRO */}
      <Route
        path="/finances"
        exact
        component={() => <Redirect to={PATHS.FINANCE_EXTRACTS} />}
      />
      <RouteWrapper
        path={PATHS.FINANCE_DRE}
        isPrivate
        wrapWithNavigation
        component={Extracts}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.FINANCE_EXTRACTS}
        isPrivate
        wrapWithNavigation
        component={Extracts}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.AUDIT_DAILY_PAYMENTS}
        isPrivate
        wrapWithNavigation
        component={AuditDailyPayments}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.AUDIT_RECEIVED_PAYMENTS}
        isPrivate
        wrapWithNavigation
        component={AuditReceivedPayments}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.AUDIT_TRANSITORY_VALUES}
        isPrivate
        wrapWithNavigation
        component={AuditTransitoryValues}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.AUDIT_SPLIT_REPORT}
        isPrivate
        wrapWithNavigation
        component={AuditReportSplitValues}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.FINANCE_TRANSFERS}
        isPrivate
        wrapWithNavigation
        component={Transfers}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.FINANCE_CASHIER_CLOSURE}
        isPrivate
        wrapWithNavigation
        component={CashierClosure}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.FINANCE_LOANS}
        isPrivate
        wrapWithNavigation
        component={Loans}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.FINANCE_PAYMENTS}
        isPrivate
        wrapWithNavigation
        component={Payments}
        permission="read"
      />

      {/* Relatórios */}
      <RouteWrapper
        path={PATHS.AUDIT_REPORT_GMV}
        isPrivate
        wrapWithNavigation
        component={ReportGMV}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.AUDIT_REPORT_INCOME}
        isPrivate
        wrapWithNavigation
        component={ReportIncome}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.AUDIT_REPORT_GROSS_LIQUID_INCOME}
        isPrivate
        wrapWithNavigation
        component={ReportGrossVsLiquidIncome}
        permission="read"
      />

      {/* GESTÃO DE PESSOAS */}
      <RouteWrapper
        path={PATHS.PEOPLE_MANAGEMENT}
        isPrivate
        wrapWithNavigation
        component={PeopleManagementHome}
        permission="read"
      />

      {/* CONFIGURAÇÕES */}
      <RouteWrapper
        path={PATHS.SETTINGS}
        exact
        isPrivate
        wrapWithNavigation
        component={AdminPanel}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_MODULES}
        isPrivate
        wrapWithNavigation
        component={Modules}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_GROUPS}
        isPrivate
        wrapWithNavigation
        component={Groups}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_COMPANIES}
        isPrivate
        wrapWithNavigation
        component={Companies}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_COMPANY}
        exact
        isPrivate
        wrapWithNavigation
        component={Company}
        permission="read"
      />
      <RouteWrapper
        path={`${PATHS.SETTINGS_COMPANY}/:id`}
        exact
        isPrivate
        wrapWithNavigation
        component={Company}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_ROLES}
        isPrivate
        wrapWithNavigation
        component={Roles}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_USERS}
        isPrivate
        wrapWithNavigation
        component={Users}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_PAYMENT_MACHINES}
        isPrivate
        wrapWithNavigation
        component={PaymentMachines}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_PIC_PAY_OPERATORS}
        isPrivate
        wrapWithNavigation
        component={PicPayOperators}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_COMMISSIONING}
        isPrivate
        wrapWithNavigation
        component={Commissionings}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_ADD_INFO}
        isPrivate
        wrapWithNavigation
        component={AdditionalInformationHistory}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_EMBEDDED_RESOURCES}
        isPrivate
        wrapWithNavigation
        component={EmbeddedResources}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_PRODUCTS}
        isPrivate
        wrapWithNavigation
        component={Products}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_SUPPLIERS}
        isPrivate
        wrapWithNavigation
        component={Suppliers}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_OFFERS}
        isPrivate
        wrapWithNavigation
        component={Offers}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_WEEKLY_TAXES}
        isPrivate
        wrapWithNavigation
        component={WeeklyTaxes}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_COMPETITORS}
        isPrivate
        wrapWithNavigation
        component={Competitors}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_ORDERS}
        isPrivate
        wrapWithNavigation
        component={Orders}
        permission="read"
      />
      <RouteWrapper
        path={`${PATHS.SETTINGS_ORDER}/:id`}
        isPrivate
        wrapWithNavigation
        component={Order}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_PAYMENT_CONDITIONS}
        isPrivate
        wrapWithNavigation
        component={PaymentConditions}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_PAYMENT_METHODS}
        isPrivate
        wrapWithNavigation
        component={PaymentMethods}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_PAYMENT_PLANS}
        isPrivate
        wrapWithNavigation
        component={PaymentPlans}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.SETTINGS_CASH_CLUB}
        isPrivate
        wrapWithNavigation
        component={CashClubSettings}
        permission="read"
      />

      {/* IMPORTAÇÕES */}
      <Route
        path="/import"
        exact
        component={() => <Redirect to={PATHS.IMPORTS_PIC_PAY} />}
      />
      <RouteWrapper
        path={PATHS.IMPORTS_PIC_PAY}
        isPrivate
        wrapWithNavigation
        component={PicPayImport}
        permission="create"
      />
      <RouteWrapper
        path={PATHS.IMPORTS_VOUCHER}
        isPrivate
        wrapWithNavigation
        component={VoucherImport}
        permission="create"
      />
      <RouteWrapper
        path={PATHS.IMPORTS_ABC_ANALYSES}
        isPrivate
        wrapWithNavigation
        component={ABCAnalysesImport}
        permission="create"
      />
      <RouteWrapper
        path={PATHS.IMPORTS_ORDERS}
        isPrivate
        wrapWithNavigation
        component={OrdersImport}
        permission="create"
      />

      <RouteWrapper
        path={PATHS.IMPORTS_COMPANIES}
        isPrivate
        wrapWithNavigation
        component={CompaniesImport}
        permission="create"
      />

      {/* ERP */}
      <RouteWrapper
        path={PATHS.API_KEYS}
        isPrivate
        wrapWithNavigation
        component={ApiKeys}
        permission="read"
      />
      <RouteWrapper
        path={PATHS.JOBS}
        isPrivate
        wrapWithNavigation
        component={Jobs}
        permission="read"
      />

      {/* PUBLIC ROUTES */}
      <RouteWrapper path={PATHS.LOGIN} component={Login} />
      <RouteWrapper path={PATHS.RESET_PASSWORD} component={ResetPassword} />
      <RouteWrapper path={PATHS.NOT_FOUND} component={NotFound} />
      <Redirect to={PATHS.NOT_FOUND} />
    </Switch>
  </Router>
);

export default connect((state) => ({
  showSimplifiedHome: state.loggedUser.metadata?.showSimplifiedHome,
}))(routerWrapper);
