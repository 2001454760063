import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { Job } from '../../interfaces/jobs';
import { getJobs } from '../queries/getJobs';
import { MarketplaceList } from '../../interfaces/apiResponses';

type FilterOperator = 'equals' | 'not' | 'in' | 'notIn' | 'lt' | 'lte' | 'gt' | 'gte' | 'contains' |
'startsWith' | 'endsWith' | 'is' | 'isNot';

export function useJobs({
  page = 1,
  filters,
  options,
}: {
  page?: number;
  filters?: {[key: string]: {[operator in FilterOperator]?: unknown}};
  options?: UseQueryOptions<MarketplaceList<Job>, unknown, MarketplaceList<Job>, string[]>;
}): UseQueryResult<MarketplaceList<Job>> {
  return useQuery(['jobs', `${page}`], async () => getJobs({ page, filters }), options);
}
