import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Order } from '../../interfaces/orders';
import { RootState } from '../../interfaces/redux';
import { getCompanies } from '../../redux/actions/companies';
import { getOrder } from '../../redux/actions/order';
import { getPaymentMethods } from '../../redux/actions/paymentMethods';
import Spinner from '../../sharedComponents/Spinner';
import SubHeader from '../../sharedComponents/SubHeader';
import { formatOrder } from '../../utils/formatOrder';
import { OrderDetails, SubOrders } from './components';
import { Container } from './styles';

const OrderPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const companies = useSelector(
    (state: RootState) => state.companies?.docs || state.companies,
  );
  const order: Order | undefined = useSelector((state: RootState) =>
    state.order.id ? formatOrder(state.order, companies) : undefined,
  );

  const loading: boolean = useSelector(
    (state: RootState) => state.ui.orderLoading,
  );

  React.useEffect(() => {
    dispatch(getOrder(params.id));
  }, [params.id]);

  React.useEffect(() => {
    if (companies && companies.length) return;
    dispatch(getCompanies({ listAll: true }));
  }, [companies]);

  React.useEffect(() => {
    dispatch(getPaymentMethods({ listAll: true }));
  }, []);

  return (
    <Container>
      <SubHeader
        title={`Pedido${order?.status === 'CANCELED' ? 'Cancelado ' : ''} ${
          order?.id ? `- #${order?.id}` : ''
        } ${
          order?.wordpressOrderId
            ? `- WordPress ID #${order.wordpressOrderId}`
            : ''
        }`}
      />
      {loading ? (
        <Spinner color="#133DA5" />
      ) : (
        <>
          <OrderDetails order={order} />
          <SubOrders order={order} />
        </>
      )}
    </Container>
  );
};

export default OrderPage;
