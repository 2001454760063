import React, { useState } from 'react';
import {
  Paper,
  Button,
  Icon,
  makeStyles,
  FormHelperText,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import SubHeader from '../../sharedComponents/SubHeader';
import DropZone from '../../sharedComponents/DropZone';
import { download } from '../../services/Download';
import { Container, SubContainer } from './styles';
import { userIsAdmin } from '../../utils/globalFunctions';
import { importFile } from '../../services/Orders';
import Toastify from '../../utils/Toastify/index';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '1rem',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '1rem',
    padding: theme.spacing(1),
  },
  button: {
    display: 'flex',
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1),
  },
  errorMsg: {
    marginTop: -16,
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
}));

function OrdersImport() {
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();
  const location = useLocation();
  const loggedUser = useSelector(
    (state: { loggedUser: any }) => state.loggedUser,
  );
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState<string[]>([]);
  const history = useHistory();

  const handleDownload = () => {
    download(
      'orders/template',
      { isAdmin: userIsAdmin(loggedUser) },
      {
        responseType: 'blob',
        filename: 'Template_Importação_Pedidos_1.0.0',
        fileExtension: 'xlsx',
        forceDefaultDownloaded: true,
      },
    );
  };

  // eslint-disable-next-line consistent-return
  const handleImport = async () => {
    const tempErrors = [];
    if (!file) tempErrors.push('file');
    if (tempErrors.length) return setErrors(tempErrors);

    setLoading(true);

    const isAdmin = userIsAdmin(loggedUser);

    importFile({
      file,
      isAdmin,
    }).then((result) => {
        if (!result?.ok) {
          Toastify.addError(
            <span>
              Ocorreu um erro na importação dos dados.
              <br />
            </span>,
          );
          // eslint-disable-next-line consistent-return
          return;
        }

        Toastify.addSuccess(
          <span>
            Arquivo importado com sucesso.
            <br />
            Foram adicionados(s){' '}
            {
              (result as {
                ok: boolean;
                data: any;
                error: any;
              }).data.count
            }{' '}
            novo(s) registro(s).
          </span>,
        );
        history.goBack();
        setFile(null);
      })
      .catch(() => {
        Toastify.addError(
          <span>
            Ocorreu um erro na importação dos dados.
            <br />
          </span>,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <SubHeader title="Importar Pedidos">
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Icon>file_download</Icon>}
          onClick={handleDownload}
        >
          Baixar Template
        </Button>
      </SubHeader>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <SubContainer>
            <Box margin="auto">
              <DropZone file={file} onChangeFile={setFile} format="xlsx" />
              {errors.includes('file') && (
                <FormHelperText error className={classes.errorMsg}>
                  Selecione um arquivo para enviar
                </FormHelperText>
              )}
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleImport}
              className={classes.button}
              disabled={loading}
              startIcon={loading && <CircularProgress size={14} />}
            >
              Importar
            </Button>
          </SubContainer>
        </Paper>
      </div>
    </Container>
  );
}

export default OrdersImport;
