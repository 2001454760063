export const ADD_COMPANY_ADDRESS = '[company] ADD OBJECT COMPANY ADDRESS';
export const REMOVE_COMPANY_ADDRESS = '[company] REMOVE OBJECT COMPANY ADDRESS';
export const UPDATE_COMPANY_ADDRESS = '[company] UPDATE OBJECT COMPANY ADDRESS';
export const ADD_MANY_COMPANY_ADDRESSES = '[company] ADD MANY COMPANY ADDRESSES';

export const addObjectAddress = (data) => ({
  type: ADD_COMPANY_ADDRESS,
  payload: data,
});

export const removeObjectAddress = (data) => ({
  type: REMOVE_COMPANY_ADDRESS,
  payload: data,
});

export const updateObjectAddress = (data) => ({
  type: UPDATE_COMPANY_ADDRESS,
  payload: data,
});

export const addManyAddresses = (data) => ({
  type: ADD_MANY_COMPANY_ADDRESSES,
  payload: data,
});
