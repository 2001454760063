import { TableCell, TableRow } from '@material-ui/core';
import { BorderColor, KeyboardArrowDown } from '@material-ui/icons';
import styled, { css } from 'styled-components';

export const Row = styled(TableRow)<{ open?: boolean }>`
  td {
    border-bottom: 0;
    text-align: center;
  }
  td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 200px;
    text-align: left;
  }
  td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    min-width: 136px;
  }
  ${({ open }) =>
    !open
      ? css`
          &:nth-of-type(4n) td {
            background-color: #f3f2f7;
          }
          td.highlight {
            background-color: #e7e7ea;
          }
        `
      : css`
          .expand-icon {
            transform: rotate(-180deg);
          }
          td {
            border-top: 2px solid #e7e7ea;
          }
          td:first-child {
            border-left: 2px solid #e7e7ea;
            border-bottom-left-radius: 0;
          }
          td:last-child {
            border-right: 2px solid #e7e7ea;
            border-bottom-right-radius: 0;
          }
          & + tr > td {
            border: 2px solid #e7e7ea;
            border-top: 0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        `}
`;

export const EditIcon = styled(BorderColor)<{ disabled: boolean }>`
  color: #1a2d78;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.25;
    `}
`;

export const ExpandIcon = styled(KeyboardArrowDown)`
  color: #1a2d78;
  transition: transform 800ms ease-in-out;
`;

export const CollapsibleCell = styled(TableCell)`
  border-bottom: 0;
  padding: 0;
  margin: 0;
`;
