import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const Container = styled.div`
  padding: 20px;
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: '1rem',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '1rem',
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  categoryCell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
    alignSelf: 'center',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
  },
  addButton: {
    border: '2px solid var(--primary-text-color)',
    marginLeft: '1rem',
    color: 'var(--primary-text-color)',
    '&.secondary': {
      color: '#f4b169',
      border: '2px solid #f4b169',
    },
  },
}));
