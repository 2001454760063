import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Fab } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { getLoggedUser, logout } from '../../redux/actions/loggedUser';
import { theme } from '../../theme';
import ScrollTop from '../ScrollTop';
import { useStyles } from './styles';
import Footer from '../Footer';
import { PanelHeader } from './components/PanelHeader';
import { MenuDrawer } from './components/MenuDrawer';
import EmbeddedPanel from '../EmbeddedPanel';
import { getEmbeddedResources } from '../../redux/actions/embeddedResources';
import Spinner from '../Spinner';
import { getModules } from '../../redux/actions/modules';
import {
  getCompanyDocumentFilter,
  scrollToTop,
  userIsAdmin,
} from '../../utils/globalFunctions';

const Navigation = ({
  loggedUser,
  component,
  dispatch,
  company,
  loadingResources,
  resources,
}) => {
  const drawerRef = useRef();
  const menuButtonRef = useRef();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openByHover, setOpenByHover] = useState(false);
  const [closing, setClosing] = useState(false);
  const [sectionOpen, setSectionOpen] = useState(null);
  const { pathname } = useLocation();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverButton, setPopoverButton] = useState(null);
  const popoverButtonRef = useRef();
  const [drawerSize, setDrawerSize] = useState(432);
  const [mainSize, setMainSize] = useState(432);
  const [filteredResources, setFilteredResources] = useState([]);
  const mainRef = useRef();

  const drawerObserver = new ResizeObserver(([entry]) => {
    setDrawerSize(entry.contentBoxSize[0].blockSize);
  });
  const mainObserver = new ResizeObserver(([entry]) => {
    setMainSize(entry.contentBoxSize[0].blockSize);
  });

  useEffect(() => { dispatch(getModules({ listAll: true })); }, []);

  useEffect(() => {
    if (!drawerRef.current) return;
    drawerObserver.observe(drawerRef.current.children[0]);
  }, [drawerRef]);

  useEffect(() => {
    if (!mainRef.current) return;
    mainObserver.observe(mainRef.current);
  }, [mainRef]);

  function finishClosingProcess() {
    setClosing(false);
  }

  useEffect(() => {
    if (closing) {
      setTimeout(
        finishClosingProcess,
        theme.transitions.duration.leavingScreen,
      );
    }
  }, [closing]);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleClose = (event) => {
    scrollToTop(event);
    setClosing(true);
    setOpen(false);
  };

  const active = (item) => {
    if (pathname.includes('comingSoon')) return false;
    if (item.path === '/') return pathname === '/';
    return pathname.includes(item.path);
  };

  const handleOpenPopover = () => {
    setPopoverButton(popoverButtonRef.current);
    setPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
  };

  const handleMouseOverDrawer = () => {
    if (!closing) {
      if (!open) setOpenByHover(true);
      setOpen(true);
    }
  };

  const handleMouseOutDrawer = () => {
    if (openByHover) {
      setOpenByHover(false);
      setOpen(false);
    }
  };

  const handleSection = (section) => () => {
    setSectionOpen((currentSection) => {
      if (currentSection === section) return null;
      return section;
    });
  };

  const fetchEmbeddedResources = async () => {
    dispatch(getEmbeddedResources());
  };

  useEffect(() => {
    fetchEmbeddedResources();
  }, []);

  useEffect(() => {
    const relatedCompanies = getCompanyDocumentFilter(company, loggedUser);
    const isAdmin = userIsAdmin(loggedUser);
    const resourcesToFilter =
      resources?.filter(
        (resource) =>
          resource.active &&
          pathname.includes(resource.module_key) &&
          (relatedCompanies.indexOf(resource.company_document) >= 0 ||
            (company && Object.keys(company).length === 0 && isAdmin)),
      ) || [];
    setFilteredResources(resourcesToFilter);
  }, [resources, pathname, company]);

  useEffect(() => {
    dispatch(getModules({ listAll: true }));
  }, []);

  useEffect(() => {
    dispatch(getLoggedUser());
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <div className={classes.root}>
        <CssBaseline />

        <div style={{ height: 65 }} id="back-to-top-anchor">
          <PanelHeader
            handleClosePopover={handleClosePopover}
            handleOpenPopover={handleOpenPopover}
            handleLogout={handleLogout}
            loggedUser={loggedUser}
            menuButtonRef={menuButtonRef}
            open={open}
            popoverButtonRef={popoverButtonRef}
            popoverOpen={popoverOpen}
            popoverButton={popoverButton}
            setOpen={setOpen}
            setOpenByHover={setOpenByHover}
            setPopoverOpen={setPopoverOpen}
          />

          <MenuDrawer
            active={active}
            drawerRef={drawerRef}
            handleClose={handleClose}
            handleMouseOutDrawer={handleMouseOutDrawer}
            handleMouseOverDrawer={handleMouseOverDrawer}
            handleSection={handleSection}
            loggedUser={loggedUser}
            menuButtonRef={menuButtonRef}
            open={open}
            setOpen={setOpen}
            sectionOpen={sectionOpen}
          />
        </div>

        <main className={classes.content} ref={mainRef} id="main-container">
          {loadingResources ? (
            <Spinner color="#000" />
          ) : filteredResources?.length > 0 ? (
            <EmbeddedPanel
              resources={filteredResources}
              loggedUser={loggedUser}
              company={company}
            />
          ) : (
            component
          )}
        </main>

        <Footer mainSize={mainSize} drawerSize={drawerSize} />

        <ScrollTop>
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </div>
    </div>
  );
};

export default connect((state) => ({
  loggedUser: state.loggedUser,
  companies: state.companies?.docs,
  company: state.company,
  userModal: state.ui.userModal,
  companiesLoading: state.ui.companiesLoading,
  loadingResources: state.ui.resourcesLoading,
  resources: state.embeddedResources,
}))(Navigation);
