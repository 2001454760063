export const GET_API_KEY = '[API Key] GET';
export const INVALIDATE_API_KEY = '[API Key] UPDATE';
export const CREATE_API_KEY = '[API Key] CREATE';
export const DELETE_API_KEY = '[API Key] DELETE';
export const UPDATE_OBJECT_API_KEY = '[API Key] UPDATE OBJECT API_KEY';
// UI
export const SHOW_API_KEY_MODAL = '[API Key] SHOW MODAL';
export const HIDE_API_KEY_MODAL = '[API Key] HIDE MODAL';
// Spinner
export const INVALIDATE_API_KEY_SUCCESS = '[API Key] Update success';
export const CREATE_API_KEY_SUCCESS = '[API Key] Create success';
export const INVALIDATE_API_KEY_ERROR = '[API Key] Update Error';
export const FETCH_API_KEY_SUCCESS = '[API Key] Fetch success';
export const FETCH_API_KEY_ERROR = '[API Key] Fetch Error';
export const SHOW_API_KEY_SPINNER = '[API Key - ui] show spinner';
export const HIDE_API_KEY_SPINNER = '[API Key - ui] hide spinner';

export const getApiKey = (id) => ({
  type: GET_API_KEY,
  payload: { _id: id },
});

export const createApiKey = (data) => ({
  type: CREATE_API_KEY,
  payload: data,
});

export const invalidateApiKey = (id) => ({
  type: INVALIDATE_API_KEY,
  payload: { id: `${id}/invalidate` },
});

export const showModal = () => ({
  type: SHOW_API_KEY_MODAL,
});

export const hideModal = () => ({
  type: HIDE_API_KEY_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_API_KEY_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_API_KEY_SPINNER,
});

export const updateObjectApiKey = (data) => ({
  type: UPDATE_OBJECT_API_KEY,
  payload: data,
});
