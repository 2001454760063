/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  IconButton,
  Chip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  TransferModalProvider,
  TRANSFER_KINDS,
} from '../../../../providers/Transfers';
import {
  createTransfer,
  getTransfer,
  updateTransfer,
} from '../../../../redux/actions/transfer';
import Alert from '../../../../sharedComponents/Alert/index';
import Spinner from '../../../../sharedComponents/Spinner/index';
import {
  ButtonContainer,
  CheckboxContent,
  Container,
  InputContent,
  ModalTitle,
  StyledCurrencyTextField,
  SubTitle,
} from './styles';

const styles = {
  formButton: {
    margin: 2,
  },
};

const TransferModal = ({
  id,
  dispatch,
  transfer,
  transferModal,
  handleClose,
  transferSpinner,
  allCompanies,
  customer,
  totalValue,
}) => {
  const [approved, setApproved] = useState(false);
  const [refDate, setRefDate] = useState(null);
  const [transferDate, setTransferDate] = useState(null);
  const [companyDocument, setCompanyDocument] = useState(
    customer?.document || allCompanies[0]?.document,
  );
  const defaultValues = { adjusts: 0,
    fixed: 0,
    loan_installment: 0,
    purchase_installment: 0,
    club_cash_retention: 0,
   };
  TRANSFER_KINDS.forEach((transferKind) => {
    defaultValues[transferKind] = 0;
  });
  const [values, setValues] = useState(defaultValues);
  const [observation, setObservation] = useState('');
  const [debitsObservation, setDebitsObservation] = useState('');
  const [retentionsObservation, setRetentionsObservation] = useState('');
  const [localLoading, setLocalLoading] = useState(false);
  const [updateValues, setUpdateValues] = useState(false);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getTransfer(id));
    }
  }, [id]);

  useEffect(() => {
    if (companyDocument && refDate && (id === 'new' || updateValues)) {
      setLocalLoading(true);
      TransferModalProvider.fetchAutoCalculatedFields(
        companyDocument,
        refDate,
        setValues,
      ).finally(() => {
        setLocalLoading(false);
        setUpdateValues(false);
      });
    }
  }, [companyDocument, refDate, updateValues]);

  useEffect(() => {
    if (
      transfer &&
      transfer.id === id &&
      Object.keys(transfer).length &&
      id !== 'new'
    ) {
      setApproved(transfer.approved);
      setRefDate(new Date(transfer.reference_date));
      setTransferDate(
        transfer.transfer_date && new Date(transfer.transfer_date),
      );
      setCompanyDocument(transfer.company_document);
      setObservation(transfer.observation);
      setDebitsObservation(transfer.debitsObservation);
      setRetentionsObservation(transfer.retentionsObservation);
      if (typeof transfer.values === 'object') {
        Object.keys(transfer.values).forEach((valueKey) => {
          setValues((currentData) => ({
            ...currentData,
            [valueKey]: transfer.values[valueKey],
          }));
        });
      }
    }
  }, [transfer]);

  useEffect(() => {
    if (!transferModal) {
      handleClose();
    }
  }, [transferModal]);

  const handleSave = async () => {
    const response = await Alert();
    if (response && response.confirm) {
      if (id === 'new') {
        dispatch(
          createTransfer({
            company_document: companyDocument,
            reference_date: new Date(
              moment(refDate)
                .startOf('day')
                .utc(),
            ).toISOString(),
            transfer_date:
              transferDate &&
              new Date(
                moment(transferDate)
                  .startOf('day')
                  .utc(),
              ).toISOString(),
            approved,
            values,
            observation,
            debitsObservation,
            retentionsObservation,
          }),
        );
      } else {
        dispatch(
          updateTransfer({
            id,
            approved,
            values,
            observation,
            debitsObservation,
            retentionsObservation,
            reference_date: new Date(
              moment(refDate)
                .startOf('day')
                .utc(),
            ).toISOString(),
            transfer_date:
              transferDate &&
              new Date(
                moment(transferDate)
                  .startOf('day')
                  .utc(),
              ).toISOString(),
          }),
        );
      }
    }
  };

  const handleCurrency = (e, value) => {
    const { name } = e.target;
    setValues((currentData) => ({ ...currentData, [name]: value }));
  };

  const handleOnlineSalesDivergenceChip = () => {
    const divergence = values.online_store_payment_machine - values.online_store_card;
    if (Math.abs(divergence) < 0.01) {
      return null;
    }
    const message = `Houve ${
      divergence > 0 ? 'excesso' : 'falta'
    } de ${Math.abs(divergence).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    })} na POS de venda online`;
    return (
      <Chip
        style={{ backgroundColor: '#ffa726' }}
        color="warning"
        label={message}
      />
    );
  };

  return (
    <>
      {(transferSpinner || localLoading) && <Spinner overlay />}
      <Paper elevation={2} style={{ height: 'fit-content' }} fullWidth>
        <Container style={{ opacity: transferSpinner ? 0.5 : 1 }}>
          <ModalTitle>
            <span>
              {id === 'new' ? 'Adicionar' : 'Editar'} Transferência
              {!!totalValue && (
                <Chip
                  style={{ marginLeft: 20 }}
                  label={`Valor total: ${totalValue.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                  })}`}
                />
              )}
            </span>
            <div>
              {id !== 'new' && (
                <Button
                  color="secondary"
                  disabled={approved}
                  onClick={() => setUpdateValues(true)}
                  variant="contained"
                  style={styles.formButton}
                >
                  Atualizar Valores
                </Button>
              )}
              <IconButton
                onClick={handleClose}
                style={{ marginLeft: '0.5rem', padding: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </ModalTitle>

          <FormGroup>
            <CheckboxContent>
              <FormControlLabel
                control={
                  <Switch
                    checked={approved}
                    onChange={(e) => setApproved(e.target.checked)}
                  />
                }
                label="Aprovado"
                labelPlacement="start"
              />
            </CheckboxContent>
            <FormControl
              variant="outlined"
              style={{
                margin: '0.5rem 1rem',
                minWidth: 120,
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Padaria *
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={companyDocument || allCompanies[0]?.document}
                onChange={(event) => setCompanyDocument(event.target.value)}
                disabled={id !== 'new'}
              >
                {allCompanies.map((company) => (
                  <MenuItem value={company.document} key={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <DatePicker
                    label="Data de Referência *"
                    inputVariant="outlined"
                    value={refDate}
                    onChange={setRefDate}
                    ampm={false}
                    format="dd/MM/yyyy"
                    minDate={new Date('2021-01-01T00:00:00.000-03:00')}
                    disabled={id !== 'new'}
                    fullWidth
                    TextFieldComponent={(props) => <TextField {...props} />}
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <DatePicker
                    label="Data de Transferência"
                    inputVariant="outlined"
                    value={transferDate}
                    name="transferDate"
                    onChange={setTransferDate}
                    ampm={false}
                    format="dd/MM/yyyy"
                    minDate={new Date('2021-01-01T00:00:00.000-03:00')}
                    fullWidth
                    TextFieldComponent={(props) => <TextField {...props} />}
                  />
                </InputContent>
              </Grid>
            </Grid>

            <SubTitle>Dados Loja Física</SubTitle>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <StyledCurrencyTextField
                    label="Pagseguro Débito"
                    variant="outlined"
                    value={values.physical_store_debit}
                    name="physical_store_debit"
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    disabled
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <StyledCurrencyTextField
                    label="Pagseguro Crédito"
                    variant="outlined"
                    value={values.physical_store_credit}
                    name="physical_store_credit"
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    disabled
                  />
                </InputContent>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <StyledCurrencyTextField
                    label="Pagseguro Pix"
                    variant="outlined"
                    value={values.physical_store_pix}
                    name="physical_store_pix"
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    disabled
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <StyledCurrencyTextField
                    label="PicPay"
                    variant="outlined"
                    value={values.physical_store_pic_pay}
                    name="physical_store_pic_pay"
                    onChange={handleCurrency}
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    disabled
                  />
                </InputContent>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <StyledCurrencyTextField
                    label="Voucher"
                    variant="outlined"
                    value={values.physical_store_voucher}
                    name="physical_store_voucher"
                    onChange={handleCurrency}
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    disabled
                  />
                </InputContent>
              </Grid>
            </Grid>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SubTitle>Dados Loja Online</SubTitle>
              {handleOnlineSalesDivergenceChip()}
            </div>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <StyledCurrencyTextField
                    label="Pagseguro Online" // soma de tudo que passou na máquina online
                    variant="outlined"
                    value={values.online_store_payment_machine}
                    name="online_store_payment_machine"
                    onChange={handleCurrency}
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    disabled
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <StyledCurrencyTextField
                    label="Dinheiro"
                    variant="outlined"
                    value={values.online_store_cash}
                    name="online_store_cash"
                    onChange={handleCurrency}
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    disabled
                  />
                </InputContent>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <StyledCurrencyTextField
                    label="Cartão" // soma de tudo que passou em cartão pelo epadoca
                    variant="outlined"
                    value={values.online_store_card}
                    name="online_store_card"
                    onChange={handleCurrency}
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    disabled
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <StyledCurrencyTextField
                    label="Online"
                    variant="outlined"
                    value={values.online_store_online}
                    name="online_store_online"
                    onChange={handleCurrency}
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    disabled
                  />
                </InputContent>
              </Grid>
            </Grid>

            <SubTitle>Outros</SubTitle>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <StyledCurrencyTextField
                    label="Fixo Semanal"
                    variant="outlined"
                    value={values.fixed}
                    name="fixed"
                    onChange={handleCurrency}
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    disabled
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <StyledCurrencyTextField
                    label="Ajustes"
                    variant="outlined"
                    value={values.adjusts}
                    name="adjusts"
                    onChange={handleCurrency}
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                  />
                </InputContent>
              </Grid>
            </Grid>
            <TextField
              label="Descrição de ajustes"
              type="text"
              variant="outlined"
              multiline
              value={observation}
              name="observation"
              onChange={(e) => setObservation(e.target.value)}
              style={{ margin: '0.5rem 1rem' }}
            />
            <SubTitle>Descontos</SubTitle>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <StyledCurrencyTextField
                    label="Parcela de empréstimo"
                    variant="outlined"
                    value={values.loan_installment}
                    name="loan_installment"
                    onChange={handleCurrency}
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                  />
                </InputContent>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputContent>
                  <StyledCurrencyTextField
                    label="Parcela de Dívida"
                    variant="outlined"
                    value={values.purchase_installment}
                    name="purchase_installment"
                    onChange={handleCurrency}
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                  />
                </InputContent>
              </Grid>
            </Grid>
            <TextField
              label="Descrição de descontos"
              type="text"
              variant="outlined"
              multiline
              value={debitsObservation}
              name="debitsObservation"
              onChange={(e) => setDebitsObservation(e.target.value)}
              style={{ margin: '0.5rem 1rem' }}
            />
            <SubTitle>Retenções</SubTitle>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <InputContent>
                  <StyledCurrencyTextField
                    label="Retenção de Clube Cash"
                    variant="outlined"
                    value={values.club_cash_retention}
                    name="club_cash_retention"
                    onChange={handleCurrency}
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                  />
                </InputContent>
              </Grid>
            </Grid>
            <TextField
              label="Descrição da retenção"
              type="text"
              variant="outlined"
              multiline
              value={retentionsObservation}
              name="retentionsObservation"
              onChange={(e) => setRetentionsObservation(e.target.value)}
              style={{ margin: '0.5rem 1rem' }}
            />
            <ButtonContainer>
              <Button
                color="secondary"
                onClick={handleClose}
                variant="contained"
                style={styles.formButton}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={handleSave}
                variant="contained"
                style={styles.formButton}
              >
                Salvar
              </Button>
            </ButtonContainer>
          </FormGroup>
        </Container>
      </Paper>
    </>
  );
};

export default connect((state) => ({
  transfer: state.transfer,
  transferModal: state.ui.transferModal,
  transferSpinner: state.ui.transferLoading,
  allCompanies: state.companies.docs || state.companies,
  customer: state.company,
}))(TransferModal);
