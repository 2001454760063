import Toastify from '../../utils/Toastify';
import { apiRequest } from '../actions/api';
import {
  CREATE_DAILY_EXPECTED_PAYMENTS,
  CREATE_DAILY_EXPECTED_PAYMENTS_ERROR,
  CREATE_DAILY_EXPECTED_PAYMENTS_SUCCESS,
  FETCH_DAILY_EXPECTED_PAYMENTS_ERROR,
  FETCH_DAILY_EXPECTED_PAYMENTS_SUCCESS,
  GET_DAILY_EXPECTED_PAYMENTS,
  showSpinner,
  hideSpinner,
  UPDATE_DAILY_EXPECTED_PAYMENTS,
  UPDATE_DAILY_EXPECTED_PAYMENTS_SUCCESS,
  UPDATE_DAILY_EXPECTED_PAYMENTS_ERROR,
} from '../actions/audit';

const URL = 'audit/dailyExpectedPayments';

export const getDailyExpectedPayments = ({ dispatch }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === GET_DAILY_EXPECTED_PAYMENTS) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_DAILY_EXPECTED_PAYMENTS_SUCCESS,
        FETCH_DAILY_EXPECTED_PAYMENTS_ERROR,
        {},
        'apiV2',
      ),
    );
    dispatch(showSpinner());
  }
  if (action.type === FETCH_DAILY_EXPECTED_PAYMENTS_SUCCESS) {
    dispatch(hideSpinner());
  }
};

export const createDailyExpectedPayment = ({ dispatch }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === CREATE_DAILY_EXPECTED_PAYMENTS) {
    dispatch(
      apiRequest(
        'POST',
        `${URL}`,
        action.payload,
        CREATE_DAILY_EXPECTED_PAYMENTS_SUCCESS,
        CREATE_DAILY_EXPECTED_PAYMENTS_ERROR,
        {},
        'apiV2',
      ),
    );
    dispatch(showSpinner());
  }
  if (action.type === CREATE_DAILY_EXPECTED_PAYMENTS_SUCCESS) {
    Toastify.addSuccess('Cadastrado com sucesso');
    dispatch(hideSpinner());
  }
  if (action.type === CREATE_DAILY_EXPECTED_PAYMENTS_ERROR) {
    Toastify.addError('Erro ao cadastrar');
    dispatch(hideSpinner());
  }
};

export const updateDailyExpectedPayment = ({ dispatch }) => (next) => (
  action,
) => {
  next(action);

  if (action.type === UPDATE_DAILY_EXPECTED_PAYMENTS) {
    dispatch(
      apiRequest(
        'PUT',
        `${URL}`,
        action.payload,
        UPDATE_DAILY_EXPECTED_PAYMENTS_SUCCESS,
        UPDATE_DAILY_EXPECTED_PAYMENTS_ERROR,
        {},
        'apiV2',
      ),
    );
    dispatch(showSpinner());
  }
  if (action.type === UPDATE_DAILY_EXPECTED_PAYMENTS_SUCCESS) {
    Toastify.addSuccess('Atualizado com sucesso');
    dispatch(hideSpinner());
  }
  if (action.type === UPDATE_DAILY_EXPECTED_PAYMENTS_ERROR) {
    Toastify.addError('Erro ao atualizar');
    dispatch(hideSpinner());
  }
};

export const auditMdl = [
  getDailyExpectedPayments,
  createDailyExpectedPayment,
  updateDailyExpectedPayment,
];
