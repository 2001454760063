export const GET_PAYMENT_CONDITION = '[payment condition] GET';
export const UPDATE_PAYMENT_CONDITION = '[payment condition] UPDATE';
export const CREATE_PAYMENT_CONDITION = '[payment condition] CREATE';
export const DELETE_PAYMENT_CONDITION = '[payment condition] DELETE';
export const UPDATE_OBJECT_PAYMENT_CONDITION = '[payment condition] UPDATE OBJECT PAYMENT_CONDITION';
// UI
export const SHOW_PAYMENT_CONDITION_MODAL = '[payment condition] SHOW MODAL';
export const HIDE_PAYMENT_CONDITION_MODAL = '[payment condition] HIDE MODAL';
// Spinner
export const UPDATE_PAYMENT_CONDITION_SUCCESS = '[payment condition] Update success';
export const UPDATE_PAYMENT_CONDITION_ERROR = '[payment condition] Update Error';
export const FETCH_PAYMENT_CONDITION_SUCCESS = '[payment condition] Fetch success';
export const FETCH_PAYMENT_CONDITION_ERROR = '[payment condition] Fetch Error';
export const SHOW_PAYMENT_CONDITION_SPINNER = '[payment condition - ui] show spinner';
export const HIDE_PAYMENT_CONDITION_SPINNER = '[payment condition - ui] hide spinner';

export const getPaymentCondition = (id) => ({
  type: GET_PAYMENT_CONDITION,
  payload: { _id: id },
});

export const createPaymentCondition = (data) => ({
  type: CREATE_PAYMENT_CONDITION,
  payload: data,
});

export const updatePaymentCondition = (data) => ({
  type: UPDATE_PAYMENT_CONDITION,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_PAYMENT_CONDITION_MODAL,
});

export const hideModal = () => ({
  type: HIDE_PAYMENT_CONDITION_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_PAYMENT_CONDITION_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_PAYMENT_CONDITION_SPINNER,
});

export const updateObjectPaymentCondition = (data) => ({
  type: UPDATE_OBJECT_PAYMENT_CONDITION,
  payload: data,
});
