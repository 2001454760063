import PATHS, { INTEGRATIONS } from '../../../../config/routePaths';

export default [
  {
    isCollapsible: false,
    modules: [
      {
        label: 'Início',
        icon: 'home',
        path: PATHS.HOME,
      },
    ],
  },
  {
    isCollapsible: false,
    modules: [
      {
        label: 'Pedidos',
        icon: 'grading',
        path: PATHS.ORDERS,
      },
    ],
  },
  {
    isIntegration: true,
    isCollapsible: false,
    modules: [
      {
        label: 'Comprador Digital',
        icon: 'store',
        path: PATHS.MARKETPLACE,
        integration: INTEGRATIONS.MARKETPLACE,
        isIntegration: true,
      },
    ],
  },
  {
    isCollapsible: true,
    label: 'indicators',
    icon: 'auto_graph',
    title: 'Indicadores',

    modules: [
      {
        path: PATHS.INDICATORS_PURCHASES,
        label: 'Compras',
        icon: 'shopping_cart',
      },
      {
        path: PATHS.INDICATORS_CLIENTS,
        label: 'Clientes',
        icon: 'assignment_ind',
      },
      {
        path: PATHS.INTEGRATION_SALES_INTELLIGENCE,
        integration: INTEGRATIONS.SALES_INTELLIGENCE,
        label: 'Inteligência de Compras',
        icon: 'psychology',
        isIntegration: true,
      },
    ],
  },
  {
    isCollapsible: true,
    label: 'shoppingIntelligence',
    icon: 'inventory',
    title: 'Mix e Precificação',
    modules: [
      {
        path: PATHS.SALES_INTELLIGENCE_PRODUCTS,
        label: 'Produtos Vendidos',
        icon: 'receipt',
      },
      {
        path: PATHS.SALES_INTELLIGENCE_NOTABLES,
        label: 'Produtos Notáveis',
        icon: 'production_quantity_limits',
      },
      {
        path: PATHS.SALES_INTELLIGENCE_PRICES,
        label: 'Produtos Icônicos',
        icon: 'compare_arrows',
      },
      {
        path: PATHS.SALES_INTELLIGENCE_MARKUPS,
        label: 'Markup Ideal',
        icon: 'local_offer',
      },
      {
        path: PATHS.SALES_INTELLIGENCE_WHOLERETAIL,
        label: 'Preços Atacarejos',
        icon: 'add_shopping_cart',
      },
    ],
  },
  {
    isCollapsible: true,
    label: 'financial',
    icon: 'account_balance_wallet',
    title: 'Financeiro',
    modules: [
      {
        path: PATHS.AUDIT_SPLIT_REPORT,
        label: 'Relatório Transferências',
        icon: 'summarize',
      },
      {
        path: PATHS.FINANCE_EXTRACTS,
        label: 'Extratos',
        icon: 'credit_card',
      },
      {
        path: PATHS.FINANCE_TRANSFERS,
        label: 'Transferências',
        icon: 'account_balance',
      },
      {
        path: PATHS.AUDIT_DAILY_PAYMENTS,
        label: 'Previsão de Recebimentos',
        icon: 'event_upcoming',
      },
      {
        path: PATHS.AUDIT_RECEIVED_PAYMENTS,
        label: 'Pagamentos Recebidos',
        icon: 'payments',
      },
      {
        path: PATHS.AUDIT_TRANSITORY_VALUES,
        label: 'Valores Transitórios',
        icon: 'currency_exchange',
      },
      {
        path: PATHS.FINANCE_DRE,
        label: 'DRE',
        icon: 'auto_graph',
        shouldShow: false,
      },
      {
        path: PATHS.FINANCE_CASHIER_CLOSURE,
        label: 'Fechamento de Caixa',
        icon: 'attach_money',
      },
      {
        path: PATHS.FINANCE_LOANS,
        label: 'Empréstimos',
        icon: 'credit_score',
      },
      {
        path: PATHS.FINANCE_PAYMENTS,
        label: 'Boletos e Pagamentos',
        icon: 'receipt_long',
      },
    ],
  },
  {
    isCollapsible: false,
    modules: [
      {
        path: PATHS.PEOPLE_MANAGEMENT,
        label: 'Gestão de Pessoas',
        icon: 'people',
      },
    ],
  },
  {
    isCollapsible: true,
    label: 'transformation',
    icon: 'construction',
    title: 'Transformação Física',
    modules: [
      {
        path: PATHS.TRANSFORMATION_PROVIDERS,
        label: 'Fornecedores',
        icon: 'local_shipping',
      },
      {
        path: PATHS.TRANSFORMATION_ITEMS,
        label: 'Orçamento',
        icon: 'request_quote',
      },
    ],
  },
  {
    isIntegration: true,
    isCollapsible: false,
    modules: [
      {
        path: PATHS.INTEGRATION_ONLINE_SALES,
        integration: INTEGRATIONS.ONLINE_SALES,
        label: 'Configuração Venda Online',
        icon: 'computer',
      },
      {
        path: PATHS.INTEGRATION_PRODUCTION,
        integration: INTEGRATIONS.PRODUCTION,
        label: 'Gestão de Produção',
        icon: 'microwave',
      },
    ],
  },
  {
    isCollapsible: false,
    modules: [
      { label: 'Importação de Notas Fiscais', icon: 'publish', path: PATHS.JOBS },
    ],
  },
  {
    isCollapsible: false,
    modules: [
      { label: 'API Keys', icon: 'key', path: PATHS.API_KEYS },
    ],
  },
  {
    isIntegration: true,
    isCollapsible: false,
    modules: [
      {
        label: 'Documentação de API',
        icon: 'data_object',
        integration: INTEGRATIONS.DOCUMENTATION,
        isIntegration: true,
        path: PATHS.DOCUMENTATION,
      },
    ],
  },
  {
    isCollapsible: false,
    modules: [
      {
        label: 'Painel Admin',
        icon: 'admin_panel_settings',
        path: PATHS.SETTINGS,
      },
    ],
  },
];

export const adminGridItems = [
  {
    path: PATHS.SETTINGS_MODULES,
    label: 'Módulos',
    icon: 'view_module',
    shouldShow: true, // conditionToRender('modules'),
  },
  {
    path: PATHS.SETTINGS_GROUPS,
    label: 'Grupo de Padarias',
    icon: 'add_business',
    shouldShow: true, // conditionToRender('groups'),
  },
  {
    path: PATHS.SETTINGS_COMPANIES,
    label: 'Padarias',
    icon: 'store',
    shouldShow: true, // conditionToRender('companies'),
  },
  {
    path: PATHS.SETTINGS_ROLES,
    label: 'Perfis',
    icon: 'admin_panel_settings',
    shouldShow: true, // conditionToRender('roles'),
  },
  {
    path: PATHS.SETTINGS_USERS,
    label: 'Usuários',
    icon: 'supervised_user_circle',
    shouldShow: true, // conditionToRender('users'),
  },
  {
    path: PATHS.SETTINGS_PAYMENT_MACHINES,
    label: 'Máquinas de cartão',
    icon: 'point_of_sale',
    shouldShow: true, // conditionToRender('paymentMachines'),
  },
  {
    path: PATHS.SETTINGS_WEEKLY_TAXES,
    label: 'Taxas Semanais',
    icon: 'receipt_long',
    shouldShow: true, // conditionToRender('weeklyTaxes'),
  },
  {
    path: PATHS.SETTINGS_PIC_PAY_OPERATORS,
    label: 'Operação PicPay',
    icon: 'contactless',
    shouldShow: true, // conditionToRender('picPayOperators'),
  },
  {
    path: PATHS.SETTINGS_COMMISSIONING,
    label: 'Comissionamentos',
    icon: 'call_split',
    shouldShow: true, // conditionToRender('commissionings'),
  },
  {
    path: PATHS.SETTINGS_ADD_INFO,
    label: 'Informações Extras',
    icon: 'info',
    shouldShow: true, // conditionToRender('additionalInformationHistory'),
  },
  {
    path: PATHS.IMPORTS_ABC_ANALYSES,
    label: 'Importação de Curva ABC',
    icon: 'show_chart',
    shouldShow: true,
  },
  {
    path: PATHS.SETTINGS_EMBEDDED_RESOURCES,
    label: 'Recursos Embedáveis',
    icon: 'bed',
    shouldShow: true, // conditionToRender('additionalInformationHistory'),
  },
  {
    path: PATHS.SETTINGS_PRODUCTS,
    label: 'Produtos',
    icon: 'breakfast_dining',
    shouldShow: true, // conditionToRender('additionalInformationHistory'),
  },
  {
    path: PATHS.SETTINGS_SUPPLIERS,
    label: 'Fornecedores',
    icon: 'local_shipping',
    shouldShow: true, // conditionToRender('additionalInformationHistory'),
  },
  {
    path: PATHS.SETTINGS_OFFERS,
    label: 'Ofertas',
    icon: 'local_offer',
    shouldShow: true, // conditionToRender('additionalInformationHistory'),
  },
  {
    path: PATHS.SETTINGS_COMPETITORS,
    label: 'Estabelecimentos',
    icon: 'store',
    shouldShow: true, // conditionToRender('additionalInformationHistory'),
  },
  {
    path: PATHS.SETTINGS_ORDERS,
    label: 'Pedidos',
    icon: 'grading',
    shouldShow: true, // conditionToRender('additionalInformationHistory'),
  },
  {
    path: PATHS.SETTINGS_PAYMENT_METHODS,
    label: 'Métodos de Pagamento',
    icon: 'payments',
    shouldShow: true, // conditionToRender('additionalInformationHistory'),
  },
  {
    path: PATHS.SETTINGS_PAYMENT_CONDITIONS,
    label: 'Condições de Pagamento',
    icon: 'currency_exchange',
    shouldShow: true, // conditionToRender('additionalInformationHistory'),
  },
  {
    path: PATHS.SETTINGS_PAYMENT_PLANS,
    label: 'Planos de Pagamento',
    icon: 'account_tree',
    shouldShow: true, // conditionToRender('additionalInformationHistory'),
  },
  {
    path: PATHS.SETTINGS_CASH_CLUB,
    label: 'Clube Cash',
    icon: 'savings',
    shouldShow: true, // conditionToRender('additionalInformationHistory'),
  },
];

export const auditGridItems = [];

export const reportGridItems = [
  {
    path: PATHS.AUDIT_REPORT_GMV,
    label: 'GMV',
    icon: 'bar_chart',
    shouldShow: true,
  },
  {
    path: PATHS.AUDIT_REPORT_INCOME,
    label: 'Receita Bakery',
    icon: 'bar_chart',
    shouldShow: true,
  },
  {
    path: PATHS.AUDIT_REPORT_GROSS_LIQUID_INCOME,
    label: 'Receita: Bruta X Líquida',
    icon: 'bar_chart',
    shouldShow: true,
  },
];
