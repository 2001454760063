import React from 'react';
import { Content } from './styles';

const OrderValuesDetails: React.FC<{ label: string; value: number | string | undefined }> = ({ label, value }) => {
  return (
    <>
      <Content>
        <span>{label}</span>
        <span>{value || 0}</span>
      </Content>
    </>
  );
};

export default OrderValuesDetails;
