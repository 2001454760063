import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Animated } from 'react-animated-css';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Spinner from '../../sharedComponents/ButtonSpinner/index';
import {
  Container,
  Form,
  Logo,
  Input,
  LoginButton,
  ForgotPasswordButton,
  VerificationCodeText,
  Text,
  SubText,
  ErrorIcon,
  InputGroup,
  HelperText,
} from './styles';
import logoImage from '../../assets/images/bt_logo_dark.svg';
import { updateLoggedUser } from '../../redux/actions/loggedUser';

import Service from '../../services/Service';
import Toastify from '../../utils/Toastify';

const Login = ({ dispatch, authenticated, loginSpinner }) => {
  const history = useHistory();
  const [emailForm, setEmailForm] = useState('');
  const [passwordForm, setPasswordForm] = useState('');
  const [codeForm, setCodeForm] = useState('');
  const [loginForm, setLoginForm] = useState(true);
  const [verificationForm, setVerificationForm] = useState(false);
  const [resetPasswordForm, setResetPasswordForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  React.useEffect(() => {
    if (!authenticated) return;
    history.replace('/');
  }, [authenticated]);

  function isEmailValid(email) {
    const regex =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    const isValid = regex.test(email);
    return isValid;
  }

  const handleForm = (selectedForm) => {
    switch (selectedForm) {
      case '#LoginForm': {
        setLoginForm(true);
        setVerificationForm(false);
        setResetPasswordForm(false);
        break;
      }
      case '#VerificationForm': {
        setLoginForm(false);
        setVerificationForm(true);
        setResetPasswordForm(false);
        break;
      }
      case '#ResetPasswordForm': {
        setLoginForm(false);
        setVerificationForm(false);
        setResetPasswordForm(true);
        break;
      }
      default:
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const validEmail = isEmailValid(emailForm);
    if (!validEmail) {
      setEmailError(true);
      return;
    }
    setLoading(true);
    try {
      const authService = new Service({
        route: '/reset-password',
        apiName: 'auth',
      });
      authService
        .store({ email: emailForm })
        .then((response) => {
          if (response.ok) {
            Toastify.addSuccess('Um email foi enviado para sua caixa de entrada!');
          } else {
            Toastify.addError(
              'Não foi possível solicitar sua redefinição de senha. Verifique se o usuário está cadastrado e ativo ou tente novamente mais tarde.',
            );
          }
        });
    } catch (error) {
      Toastify.addError(
        'Ocorreu um erro ao solicitar sua redefinição de senha!',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const validEmail = isEmailValid(emailForm);
    if (!validEmail) {
      setEmailError(true);
      return;
    }
    setLoading(true);
    const authService = new Service({ route: '/login', apiName: 'auth' });
    authService
      .store({ email: emailForm, password: passwordForm })
      .then((response) => {
        if (response.ok) {
          dispatch(updateLoggedUser({ ...response.data.resources, authenticated: true }));
          Toastify.addSuccess('Bem-vindo ao Painel BakeryTech');
          setLoginError(false);
        } else if (
          response.message ===
          'Por favor, cheque no seu email o código de segurança!'
        ) {
          setLoading(false);
          handleForm('#VerificationForm');
        } else {
          setLoading(false);
          setLoginError(true);
        }
      });
  };

  const handleVerificationCode = async (e) => {
    if (emailForm && passwordForm && codeForm) {
      e.preventDefault();
      setLoading(true);
      const apiService = new Service({ route: '/validate' });
      apiService
        .store({ email: emailForm, password: passwordForm, code: codeForm })
        .then((response) => {
          if (response.ok) {
            dispatch(
              updateLoggedUser({ ...response.data, authenticated: true }),
            );
            Toastify.addSuccess('Bem-vindo ao Painel BakeryTech');
            setLoading(false);
          } else {
            Toastify.addError(
              'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
            );
            setLoading(false);
          }
        })
        .catch((error) => {
          Toastify.addError(
            'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
          );
          console.error(error.message);
          setLoading(false);
        });
    }
  };

  function handleEmailBlur() {
    setEmailError(!isEmailValid(emailForm));
  }

  return (
    <>
      <Container>
        {loginForm && (
          <Animated
            isVisible={loginForm}
            animationIn="fadeInDown"
            animationOut="fadeOut"
            animationInDuration={2000}
            animationOutDuration={1000}
            style={{ display: 'flex' }}
          >
            <Form onSubmit={handleLogin}>
              <Grid container style={{ maxWidth: 440 }} spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  <Logo src={logoImage} />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  <Text>Bem vindo!</Text>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  <SubText>
                    Faça seu login abaixo para acessar o painel.
                  </SubText>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  {loginError && !emailError && (
                    <HelperText error>E-mail ou senha incorretos.</HelperText>
                  )}
                  {emailError && (
                    <HelperText error>
                      Este e-mail é inválido, confirme por favor.
                    </HelperText>
                  )}
                  <InputGroup>
                    {(loginError || emailError) && (
                      <ErrorIcon>report_gmailerrorred</ErrorIcon>
                    )}
                    <Input
                      placeholder="E-mail"
                      onChange={(e) => setEmailForm(e.target.value)}
                      className={(loginError || emailError) && 'error'}
                      onBlur={() => handleEmailBlur()}
                    />
                  </InputGroup>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  <Input
                    type="password"
                    placeholder="Senha"
                    onChange={(e) => setPasswordForm(e.target.value)}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  <LoginButton disabled={loginSpinner || loading} type="submit">
                    {loginSpinner || loading ? (
                      <Spinner color="#fff" />
                    ) : (
                      'Login'
                    )}
                  </LoginButton>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  <ForgotPasswordButton
                    type="butoon"
                    onClick={() => handleForm('#ResetPasswordForm')}
                  >
                    Esqueci minha senha
                  </ForgotPasswordButton>
                </Grid>
              </Grid>
            </Form>
          </Animated>
        )}
        {verificationForm && (
          <Animated
            isVisible={verificationForm}
            animationIn="fadeInDown"
            animationOut="fadeOut"
            animationInDuration={2000}
            animationOutDuration={1000}
            style={{ display: 'flex' }}
          >
            <Form onSubmit={handleVerificationCode}>
              <Grid container style={{ maxWidth: 440 }} spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  <Logo src={logoImage} />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  <VerificationCodeText>
                    Um código de acesso foi enviado para o seu email. <br />
                    Para acessar o sistema, digite o código recebido no campo
                    abaixo.
                  </VerificationCodeText>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  <Input
                    type="text"
                    placeholder="Código de confirmação"
                    onChange={(e) => setCodeForm(e.target.value)}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  <LoginButton disabled={loginSpinner || loading} type="submit">
                    {loginSpinner || loading ? (
                      <Spinner color="#fff" />
                    ) : (
                      'Confirmar o código'
                    )}
                  </LoginButton>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  <ForgotPasswordButton
                    type="butoon"
                    onClick={() => handleForm('#LoginForm')}
                  >
                    Back to Login
                  </ForgotPasswordButton>
                </Grid>
              </Grid>
            </Form>
          </Animated>
        )}
        {resetPasswordForm && (
          <Animated
            animationIn="fadeInUp"
            animationOut="fadeOut"
            animationInDuration={1000}
            animationOutDuration={1000}
            style={{ display: 'flex' }}
          >
            <Form>
              <Grid container style={{ maxWidth: 440 }} spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  <Logo src={logoImage} />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  <Text>Esqueci minha senha</Text>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  <SubText>
                    Escreva seu e-mail abaixo e enviaremos um link para criar
                    uma nova senha
                  </SubText>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  {emailError && (
                    <HelperText error>
                      Este e-mail é inválido, confirme por favor.
                    </HelperText>
                  )}
                  <InputGroup>
                    {(loginError || emailError) && (
                      <ErrorIcon>report_gmailerrorred</ErrorIcon>
                    )}
                    <Input
                      placeholder="E-mail"
                      type="email"
                      className={emailError && 'error'}
                      onBlur={() => handleEmailBlur()}
                      onChange={(e) => setEmailForm(e.target.value)}
                    />
                  </InputGroup>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  <LoginButton disabled={loading} onClick={handleResetPassword}>
                    {loading ? <Spinner color="#fff" /> : 'Redefinir senha'}
                  </LoginButton>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  <ForgotPasswordButton
                    type="butoon"
                    onClick={() => handleForm('#LoginForm')}
                  >
                    Ir para o Login
                  </ForgotPasswordButton>
                </Grid>
              </Grid>
            </Form>
          </Animated>
        )}
      </Container>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    authenticated: state.loggedUser.authenticated,
    loginSpinner: state.ui.loginLoading,
  };
}

export default connect(mapStateToProps)(Login);
