import {
  FETCH_TRANSFERS_SUCCESS,
  FETCH_TRANSFERS_ERROR,
  GET_TRANSFERS,
  updateTransfers,
  showSpinner,
  hideSpinner,
} from '../actions/transfers';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'transfers';

export const getTransfersFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_TRANSFERS) {
          dispatch(
            apiRequest(
              'GET',
              URL,
              action.payload,
              FETCH_TRANSFERS_SUCCESS,
              FETCH_TRANSFERS_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processTransfersCollection =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_TRANSFERS_SUCCESS) {
          dispatch(updateTransfers(action.payload));
          dispatch(hideSpinner());
        } else if (action.type === FETCH_TRANSFERS_ERROR) {
          dispatch(hideSpinner());
          Toastify.addError(
            'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
          );
          console.error(action.payload);
        }
      };

export const transfersMdl = [getTransfersFlow, processTransfersCollection];
