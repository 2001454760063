import {
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  GET_USER,
  CREATE_USER,
  UPDATE_USER,
  updateObjectUser,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/user';
import { getUsers } from '../actions/users';
import Toastify from '../../utils/Toastify/index';
import { getLoggedUser } from '../actions/loggedUser';
import Service from '../../services/Service';

const usersURL = 'users';
const companiesURL = 'companies';
const relationsURL = `${companiesURL}/user`;

export const createUserFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_USER) {
    const usersService = new Service({
      route: usersURL,
      apiName: 'auth',
    });
    const userRelationsService = new Service({
      route: relationsURL,
      apiName: 'apiV2',
    });

    const { companies, ...payload } = action.payload;
    usersService.post(payload)
      .then((firstResponse) => {
        if (firstResponse.ok) {
          if (companies.length === 0) {
            dispatch({
              type: UPDATE_USER_SUCCESS,
              payload: {},
            });
          } else {
            userRelationsService.post(
              { companies },
              {},
              firstResponse.data.resources.id,
            )
              .then((secondResponse) => {
                if (secondResponse.ok) {
                  dispatch({
                    type: UPDATE_USER_SUCCESS,
                    payload: {},
                  });
                } else {
                  throw new Error(secondResponse.message);
                }
              })
              .catch((error) => {
                dispatch({ type: UPDATE_USER_ERROR, payload: error });
              });
          }
        } else {
          throw new Error(firstResponse.message);
        }
      })
      .catch((error) => {
        dispatch({ type: UPDATE_USER_ERROR, payload: error });
      });
    dispatch(showSpinner());
  }
};

export const updateUserFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_USER) {
    const usersService = new Service({
      route: usersURL,
      apiName: 'auth',
    });
    const userRelationsService = new Service({
      route: relationsURL,
      apiName: 'apiV2',
    });

    const { id, companies, ...payload } = action.payload;
    usersService.put(id, payload)
      .then((firstResponse) => {
        if (firstResponse.ok) {
          userRelationsService.post(
            { companies },
            {},
            id,
          )
            .then((secondResponse) => {
              if (secondResponse.ok) {
                dispatch({
                  type: UPDATE_USER_SUCCESS,
                  payload: {},
                });
              } else {
                throw new Error(secondResponse.message);
              }
            })
            .catch((error) => {
              dispatch({ type: UPDATE_USER_ERROR, payload: error });
            });
        } else {
          throw new Error(firstResponse.message);
        }
      })
      .catch((error) => {
        dispatch({ type: UPDATE_USER_ERROR, payload: error });
      });
    dispatch(showSpinner());
  }
};

export const getUserFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_USER) {
    const { _id } = action.payload;
    const usersService = new Service({
      route: `${usersURL}/${_id}`,
      apiName: 'auth',
    });
    const companiesService = new Service({
      route: companiesURL,
      apiName: 'apiV2',
    });

    usersService.get()
      .then((firstResponse) => {
        if (firstResponse.ok) {
          companiesService.get({ listAll: true, userIdFilter: _id })
            .then((secondResponse) => {
              if (secondResponse.ok) {
                dispatch({
                  type: FETCH_USER_SUCCESS,
                  payload: {
                    ...firstResponse.data.resources,
                    companies: secondResponse.data,
                  },
                });
              } else {
                throw new Error(secondResponse.message);
              }
            })
            .catch((error) => {
              dispatch({ type: FETCH_USER_ERROR, payload: error });
            });
        } else {
          throw new Error(firstResponse.message);
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_USER_ERROR, payload: error });
      });
    dispatch(showSpinner());
  }
};

export const processUserCollection = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === FETCH_USER_SUCCESS) {
    dispatch(updateObjectUser(action.payload.resources || action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_USER_SUCCESS) {
    dispatch(updateObjectUser(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    dispatch(getLoggedUser());
    // To reload the page
    const { page, limit } = getState().users;
    dispatch(getUsers({ page, limit }));
    Toastify.addSuccess('Operação concluída com sucesso.');
  } else if (action.type === UPDATE_USER_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(
      'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
    );
    console.error(action.payload);
  }
};

export const userMdl = [
  createUserFlow,
  updateUserFlow,
  getUserFlow,
  processUserCollection,
];
