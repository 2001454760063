import currency from 'currency.js';
import { format } from 'date-fns-tz';
import { Company } from '../interfaces/companies';
import {
  companyStatusMap,
  companySupplierStatusMap,
  Order,
  OrderProduct,
  orderProductStatusMap,
  OrderStatus,
  statusMap,
  SupplierOrder,
  SupplierOrderStatus,
  supplierStatusMap,
} from '../interfaces/orders';

const tz = { timeZone: 'America/Brasilia' };

const realCurrencyConfiguration = {
  fromCents: true,
  separator: '.',
  decimal: ',',
  symbol: 'R$',
};

const realCurrencyConfigurationFromReal = {
  separator: '.',
  decimal: ',',
  symbol: 'R$',
};

export const formatOrderProducts = (product: OrderProduct) => {
  const formattedUnitPlatformPrice = currency(
    product.unitPlatformPrice,
    realCurrencyConfiguration,
  ).format();

  const unitPlatformPrice = currency(
    product.unitPlatformPrice,
    realCurrencyConfiguration,
  ).value;

  const unitNegotiatedPriceCurrency = product.unitNegotiatedPrice
    ? currency(product.unitNegotiatedPrice, realCurrencyConfiguration)
    : undefined;

  const formattedUnitNegotiatedPrice = unitNegotiatedPriceCurrency
    ? unitNegotiatedPriceCurrency.format()
    : undefined;

  const quantityCurrency = product.quantity
    ? currency(product.quantity, {
      ...realCurrencyConfiguration,
      precision: 3,
      symbol: '',
    })
    : undefined;
  const unitCostPriceCurrency = product.unitCostPrice
    ? currency(product.unitCostPrice, realCurrencyConfiguration)
    : undefined;
  const unitTypedPriceCurrency = product.unitTypedPrice
    ? currency(product.unitTypedPrice, realCurrencyConfiguration)
    : undefined;

  const formattedUnitCostPrice = unitCostPriceCurrency?.format();
  const formattedUnitTypedPrice = unitTypedPriceCurrency?.format();
  const formattedQuantity = quantityCurrency?.format();
  const formattedOriginalTotalValue = currency(
    product.calculations.originalTotalValue,
    realCurrencyConfigurationFromReal,
  ).format();
  const formattedNegotiatedTotalValue = currency(
    product.calculations.negotiatedTotalValue,
    realCurrencyConfigurationFromReal,
  ).format();

  const unitNegotiatedPrice = unitNegotiatedPriceCurrency?.value;

  const sanitizedStatus = product.status
    ? orderProductStatusMap[product.status]
    : null;

  return {
    ...product,
    formattedUnitPlatformPrice,
    unitPlatformPrice,
    formattedQuantity,
    formattedUnitNegotiatedPrice,
    formattedUnitCostPrice,
    formattedUnitTypedPrice,
    formattedOriginalTotalValue,
    formattedNegotiatedTotalValue,
    quantity: quantityCurrency?.value,
    unitNegotiatedPrice,
    unitCostPrice: unitCostPriceCurrency?.value,
    unitTypedPrice: unitTypedPriceCurrency?.value,
    price: currency(
      unitNegotiatedPrice || unitPlatformPrice || 0,
      realCurrencyConfigurationFromReal,
    ).format(),
    sanitizedStatus,
  } as OrderProduct;
};

export const formatSupplierOrder = (order: Order, hideCanceled?: boolean) => (
  supplierOrder: SupplierOrder,
) => {
  const supplierOrderStatus = supplierStatusMap[supplierOrder.status as SupplierOrderStatus];
  const companyStatus = companySupplierStatusMap[supplierOrder.status as SupplierOrderStatus];

  const typedTotalCurrency = supplierOrder.orderProducts.reduce(
    (total, product) =>
      ((product.status !== 'CANCELED'
        && product.status !== 'PRE_SHORTAGE'
        && product.status !== 'POS_SHORTAGE'
      )
        ? total.add(
          currency(
            product.unitTypedPrice || 0,
            realCurrencyConfiguration,
          ).multiply(
            currency(product.quantity, {
              ...realCurrencyConfiguration,
              precision: 3,
            }),
          ) || 0,
        )
        : total),
    currency(0, realCurrencyConfiguration),
  );

  const supplierOrderNumber = `${order.wordpressOrderId || order.id}-${
    supplierOrder.code
  }`;

  const formattedDeliveryDate =
    supplierOrder.deliveryDate &&
    format(new Date(supplierOrder.deliveryDate), "dd/MM/yyyy 'às' HH:mm", tz);

  const filteredOrderProducts = hideCanceled
    ? supplierOrder.orderProducts.filter(({ status }) => status !== 'CANCELED')
    : supplierOrder.orderProducts;

  const orderProducts = filteredOrderProducts.map(formatOrderProducts);

  const formattedEstimatedDeliveryDate =
    supplierOrder.calculations?.estimatedDeliveryDate;
  const formattedOriginalTotalValue = currency(
    supplierOrder.calculations.originalTotalValue || 0,
    realCurrencyConfigurationFromReal,
  ).format();
  const formattedNegotiatedTotalValue = currency(
    supplierOrder.calculations.negotiatedTotalValue || 0,
    realCurrencyConfigurationFromReal,
  ).format();
  const formattedTotalValue = currency(
    supplierOrder.calculations.totalValue || 0,
    realCurrencyConfigurationFromReal,
  ).format();

  return {
    ...supplierOrder,
    formattedStatus: supplierOrderStatus,
    companyStatus,
    supplierOrderNumber,
    formattedDeliveryDate,
    orderProducts,
    typedTotal: typedTotalCurrency.format(),
    formattedEstimatedDeliveryDate,
    formattedOriginalTotalValue,
    formattedNegotiatedTotalValue,
    formattedTotalValue,
  };
};

export const formatOrder = (
  order: Order,
  companies: Company[],
  hideCanceled?: boolean,
) => {
  const companyObj = companies.find(
    ({ document }) => document === order.companyDocument,
  );

  const company = companyObj?.name;

  const documentWithCorporateName = `${order.companyDocument} ${
    companyObj && companyObj.corporate_name ? '-' : ''
  } ${companyObj?.corporate_name || ''}`;

  const formattedStatus = statusMap[order.status as OrderStatus];

  const companyStatus = companyStatusMap[order.status as OrderStatus];

  const filteredSupplierOrders = hideCanceled
    ? order.supplierOrders?.filter((supOrder) => supOrder.status !== 'CANCELED')
    : order.supplierOrders;

  const supplierOrders = filteredSupplierOrders?.map(
    formatSupplierOrder(order, hideCanceled),
  );

  const formattedOriginalTotalValue = currency(
    order.calculations?.originalTotalValue || 0,
    realCurrencyConfigurationFromReal,
  ).format();
  const formattedFinalTotalValue = currency(
    order.calculations?.finalTotalValue || 0,
    realCurrencyConfigurationFromReal,
  ).format();
  const formattedNegotiatedDiscount = currency(
    order.calculations?.negotiatedDiscount || 0,
    { ...realCurrencyConfigurationFromReal, pattern: '+!#' },
  ).multiply(-1).format();
  const formattedPreShortageDiscount = currency(
    order.calculations?.preShortageDiscount || 0,
    { ...realCurrencyConfigurationFromReal, pattern: '+!#' },
  )
    .multiply(-1)
    .format();
  const formattedTax = currency(order.calculations?.tax || 0, {
    ...realCurrencyConfigurationFromReal,
    pattern: '+!#',
  }).format();
  const formattedTotalValue = currency(
    order.calculations?.totalValue || 0,
    realCurrencyConfigurationFromReal,
  ).format();
  const formattedDeliveryDate =
    order.deliveryDate &&
    format(new Date(order.deliveryDate), 'dd/MM/yyyy', tz);

  const formattedEstimatedDeliveryDate =
    order.calculations?.estimatedDeliveryDate;

  const formattedOrderDate = format(
    new Date(order.wordpressOrderDate || order.createdAt),
    "dd/MM/yyyy 'às' HH:mm",
    tz,
  );

  const discountCurrency = currency(order.discount || 0, {
    ...realCurrencyConfiguration,
    pattern: '+!#',
  });

  const discount = (discountCurrency as currency)?.value;

  const formattedDiscount = (discountCurrency as currency)
    ?.multiply(-1)
    ?.format();

  return {
    ...order,
    company,
    formattedOrderDate,
    formattedStatus,
    companyStatus,
    supplierOrders,
    orderNumber: order.wordpressOrderId || order.id,
    formattedDeliveryDate,
    discount,
    formattedEstimatedDeliveryDate,
    formattedDiscount,
    documentWithCorporateName,
    formattedFinalTotalValue,
    formattedOriginalTotalValue,
    formattedNegotiatedDiscount,
    formattedPreShortageDiscount,
    formattedTax,
    formattedTotalValue,
  };
};

export const formatOrders = (
  orders: Order[],
  companies: Company[],
  hideCanceled?: boolean,
) => {
  const filteredOrders = hideCanceled
    ? orders.filter(({ status }) => status !== 'CANCELED')
    : orders;
  return filteredOrders.map((order) =>
    formatOrder(order, companies, hideCanceled),
  );
};
