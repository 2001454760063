import { authApi, apiV2, marketplaceApi } from './Api';

const apiSelect = (name) => {
  switch (name) {
    case 'auth':
      return authApi;
    case 'marketplace':
      return marketplaceApi;
    default:
      return apiV2;
  }
};

class Service {
  constructor({ route, apiName }) {
    this.route = route;
    this.api = apiSelect(apiName);
  }

  async post(data, params, id) {
    return this.store(data, params, id);
  }

  async store(data, queryParams, id) {
    try {
      const route = id ? `${this.route}/${id}` : this.route;
      const response = await this.api.post(
        route,
        data,
        { params: queryParams },
      );
      return {
        data: response.data,
        ok: true,
        status: response.data.status || response.status,
      };
    } catch (error) {
      return {
        ok: false,
        status: error.response?.status || error.response?.data?.status,
        statusCode:
          error.response?.statusCode || error.response?.data?.statusCode,
        message:
          error.response?.data?.message ||
          error.response?.data?.error ||
          error.message ||
          'Route not found',
      };
    }
  }

  async delete(query = {}, params = [], options = {}) {
    const queryString = [];
    const _query = { ...query };
    if (params.length) {
      params.forEach((paramKey) => {
        _query[paramKey] = _query.filter[paramKey];
      });
      delete _query.filter;
    }
    Object.keys(_query).forEach((paramKey) => {
      let paramToAdd = _query[paramKey];
      if (!paramToAdd || paramToAdd.length === 0) {
        return;
      }
      if (typeof paramToAdd === 'object') {
        paramToAdd = JSON.stringify(paramToAdd);
      }
      queryString.push(`${paramKey}=${paramToAdd}`);
    });

    const url = queryString.length
      ? `${this.route}?${queryString.join('&')}`
      : `${this.route}`;

    try {
      const response = await this.api.delete(url, options);
      return {
        data: response.data,
        ok: true,
        status: response.data.status,
      };
    } catch (error) {
      return {
        ok: false,
        status: error.response?.status || error.response?.data?.status,
        statusCode:
          error.response?.statusCode || error.response?.data?.statusCode,
        message:
          error.response?.data?.message ||
          error.response?.data?.error ||
          error.message ||
          'Route not found',
      };
    }
  }

  async get(query = {}, params = [], options = {}) {
    const queryString = [];
    const _query = { ...query };
    if (params.length) {
      params.forEach((paramKey) => {
        _query[paramKey] = _query.filter[paramKey];
      });
      delete _query.filter;
    }
    Object.keys(_query).forEach((paramKey) => {
      let paramToAdd = _query[paramKey];
      if (!paramToAdd || paramToAdd.length === 0) {
        return;
      }
      if (typeof paramToAdd === 'object') {
        paramToAdd = JSON.stringify(paramToAdd);
      }
      queryString.push(`${paramKey}=${paramToAdd}`);
    });

    const url = queryString.length
      ? `${this.route}?${queryString.join('&')}`
      : `${this.route}`;

    try {
      const response = await this.api.get(url, options);
      return {
        data: response.data,
        ok: true,
        status: response.data.status,
      };
    } catch (error) {
      return {
        ok: false,
        status: error.response?.status || error.response?.data?.status,
        statusCode:
          error.response?.statusCode || error.response?.data?.statusCode,
        message:
          error.response?.data?.message ||
          error.response?.data?.error ||
          error.message ||
          'Route not found',
      };
    }
  }

  async put(id, data, params) {
    try {
      const fullRoute = id ? `${this.route}/${id}` : `${this.route}`;
      const response = await this.api.put(
        fullRoute,
        data,
        { params },
      );
      return {
        data: response.data.docs || response.data,
        ok: true,
        status: response.data.status,
      };
    } catch (error) {
      return {
        ok: false,
        status: error.response?.status || error.response?.data?.status,
        statusCode:
          error.response?.statusCode || error.response?.data?.statusCode,
        message:
          error.response?.data?.message ||
          error.response?.data?.error ||
          error.message ||
          'Route not found',
      };
    }
  }
}

export default Service;
