import { Icon } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Order,
  OrderStatus,
  statusColorMap,
  SupplierOrder,
} from '../../interfaces/orders';
import { RootState } from '../../interfaces/redux';
import { download } from '../../services/Download';
import { userIsAdmin } from '../../utils/globalFunctions';
import Block from './components/Block';
import { OrderCardProps } from './interfaces';
import {
  Container,
  ExpandIcon,
  StatusIndicator,
  Title,
  Value,
  ActionContainer,
  ChildContainer,
  ExportButton,
  ContainerBody,
  ContainerHeader,
} from './styles';

const getValue = (obj: { [key: string]: unknown }, field: string) => {
  const fields = field.split('.');
  let currentObj: unknown = { ...obj };
  fields.forEach((currentField) => {
    currentObj = (currentObj as { [key: string]: unknown })?.[currentField];
  });
  return (currentObj as unknown) as string | undefined;
};

const Card: React.FC<OrderCardProps> = ({
  order,
  fields,
  ActionComponent = () => (
    <ExpandIcon className="expandIcon">expand_more</ExpandIcon>
  ),
  ChildComponent = Card,
  childProp,
  onClick,
  childFields,
  disableTransition,
  admin,
}) => {
  const loggedUser = useSelector((state: RootState) => state.loggedUser);
  const [open, setOpen] = React.useState<boolean>(false);

  const accessibleOrder: { [key: string]: unknown } = { ...order };

  const toggle = () => setOpen((currentState) => !currentState);

  const handleExport = (id: number) => (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    download(
      '/orderProducts/export',
      {
        'filter[supplierOrderId]': id.toString(),
        isAdmin: userIsAdmin(loggedUser),
      },
      {
        fileExtension: 'xlsx',
        forceDefaultDownloaded: true,
        responseType: 'blob',
      },
    );
  };

  const renderField = (field: string) => {
    const item = fields[field];
    if (typeof item === 'string') {
      return (
        <Block key={field}>
          <Title>{item}</Title>
          <Value>{getValue(accessibleOrder, field) ?? '-'}</Value>
        </Block>
      );
    }
    if (item.hideIfEmpty && !getValue(accessibleOrder, field)) return null;
    if (item.ifEmpty && getValue(accessibleOrder, item.ifEmpty)) return null;
    return (
      <Block key={field}>
        <Title>{item.label}</Title>
        <Value>{getValue(accessibleOrder, field)}</Value>
      </Block>
    );
  };

  return (
    <>
      <Container
        onClick={onClick || toggle}
        style={childProp || onClick ? {} : { cursor: 'default' }}
      >
        <ContainerHeader elevation={0} style={{ marginBottom: 8 }}>
          <Value>
            Pedido {`${order.id}`.padStart(8, '0')}
            {(admin && (order as Order).wordpressOrderId)
              ? ` / ${(order as Order).wordpressOrderId}`
              : ''}
          </Value>
          <Title>
            <StatusIndicator
              color={statusColorMap[accessibleOrder.status as OrderStatus]}
            />{' '}
          </Title>
          <Value>{admin ? order.formattedStatus : order.companyStatus}</Value>
        </ContainerHeader>

        <ContainerBody elevation={0}>
          {Object.keys(fields).map(renderField)}
          <ActionContainer open={disableTransition ? false : open}>
            <ActionComponent />
          </ActionContainer>
        </ContainerBody>
      </Container>
      {childProp && childFields && (
        <ChildContainer in={open}>
          {(accessibleOrder[childProp] as SupplierOrder[]).map(
            (supplierOrder) => (
              <ChildComponent
                key={supplierOrder.id}
                order={supplierOrder}
                fields={childFields}
                disableTransition
                ActionComponent={() => (
                  <ExportButton
                    startIcon={<Icon>download</Icon>}
                    onClick={handleExport(supplierOrder.id as number)}
                  >
                    Exportar
                  </ExportButton>
                )}
              />
            ),
          )}
        </ChildContainer>
      )}
    </>
  );
};

export default Card;
