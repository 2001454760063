import styled from 'styled-components';
import MuiPaper from '@material-ui/core/Paper';
import { AddRounded } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

export const Paper = styled(MuiPaper)`
  padding: 16px;
  border-radius: 16px;
`;

export const AddButton = styled(IconButton)`
border: 2px solid #133da5;
margin-left: 8px;
`;

export const AddIcon = styled(AddRounded)`
  color: #133da5;
`;
