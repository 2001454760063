import { UPDATE_CASH_CLUB_SETTINGS } from '../actions/cashClubSettings';

const initState = {
  docs: [],
  page: 1,
  limit: 50,
  totalDocs: 0,
  searchText: '',
};

export function cashClubSettingsReducer(cashClubSettings = initState, action) {
  switch (action.type) {
    case UPDATE_CASH_CLUB_SETTINGS:
      return action.payload;

    default:
      return cashClubSettings;
  }
}
