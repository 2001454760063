import {
  UPDATE_OBJECT_PAYMENT_METHOD,
} from '../actions/paymentMethod';

export function paymentMethodReducer(paymentMethod = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_PAYMENT_METHOD:
      return action.payload;

    default:
      return paymentMethod;
  }
}
