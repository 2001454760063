export const GET_PAYMENT_PLAN = '[payment plan] GET';
export const UPDATE_PAYMENT_PLAN = '[payment plan] UPDATE';
export const CREATE_PAYMENT_PLAN = '[payment plan] CREATE';
export const DELETE_PAYMENT_PLAN = '[payment plan] DELETE';
export const UPDATE_OBJECT_PAYMENT_PLAN = '[payment plan] UPDATE OBJECT PAYMENT_PLAN';
// UI
export const SHOW_PAYMENT_PLAN_MODAL = '[payment plan] SHOW MODAL';
export const HIDE_PAYMENT_PLAN_MODAL = '[payment plan] HIDE MODAL';
// Spinner
export const UPDATE_PAYMENT_PLAN_SUCCESS = '[payment plan] Update success';
export const UPDATE_PAYMENT_PLAN_ERROR = '[payment plan] Update Error';
export const FETCH_PAYMENT_PLAN_SUCCESS = '[payment plan] Fetch success';
export const FETCH_PAYMENT_PLAN_ERROR = '[payment plan] Fetch Error';
export const SHOW_PAYMENT_PLAN_SPINNER = '[payment plan - ui] show spinner';
export const HIDE_PAYMENT_PLAN_SPINNER = '[payment plan - ui] hide spinner';

export const getPaymentPlan = (id) => ({
  type: GET_PAYMENT_PLAN,
  payload: { _id: id },
});

export const createPaymentPlan = (data) => ({
  type: CREATE_PAYMENT_PLAN,
  payload: data,
});

export const updatePaymentPlan = (data) => ({
  type: UPDATE_PAYMENT_PLAN,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_PAYMENT_PLAN_MODAL,
});

export const hideModal = () => ({
  type: HIDE_PAYMENT_PLAN_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_PAYMENT_PLAN_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_PAYMENT_PLAN_SPINNER,
});

export const updateObjectPaymentPlan = (data) => ({
  type: UPDATE_OBJECT_PAYMENT_PLAN,
  payload: data,
});
