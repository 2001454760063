import {
  UPDATE_OBJECT_CASH_CLUB_SETTING,
} from '../actions/cashClubSetting';

export function cashClubSettingReducer(cashClubSetting = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_CASH_CLUB_SETTING:
      return action.payload;

    default:
      return cashClubSetting;
  }
}
