import {
  ADD_COMPANY_USER,
  REMOVE_COMPANY_USER,
  UPDATE_COMPANY_USER,
  ADD_MANY_COMPANY_USERS,
} from '../actions/companyUsers';

export function companyUsersReducer(companyUsers = [], action) {
  switch (action.type) {
    case ADD_COMPANY_USER: {
      const companyUsersCopy = [...companyUsers];
      companyUsersCopy.push(action.payload);
      return companyUsersCopy;
    }

    case REMOVE_COMPANY_USER: {
      const companyUsersCopy = [...companyUsers];
      companyUsersCopy.splice(action.payload, 1);
      return companyUsersCopy;
    }

    case UPDATE_COMPANY_USER: {
      const companyUsersCopy = [...companyUsers];
      const { index, data } = action.payload;
      companyUsersCopy[index] = data;
      return companyUsersCopy;
    }

    case ADD_MANY_COMPANY_USERS: {
      return action.payload;
    }

    default:
      return companyUsers;
  }
}
