export const GET_ROLE = '[role] GET';
export const UPDATE_ROLE = '[role] UPDATE';
export const CREATE_ROLE = '[role] CREATE';
export const DELETE_ROLE = '[role] DELETE';
export const UPDATE_OBJECT_ROLE = '[role] UPDATE OBJECT ROLE';
// UI
export const SHOW_ROLE_MODAL = '[role] SHOW MODAL';
export const HIDE_ROLE_MODAL = '[role] HIDE MODAL';
// Spinner
export const UPDATE_ROLE_SUCCESS = '[role] Update success';
export const UPDATE_ROLE_ERROR = '[role] Update Error';
export const FETCH_ROLE_SUCCESS = '[role] Fetch success';
export const FETCH_ROLE_ERROR = '[role] Fetch Error';
export const SHOW_ROLE_SPINNER = '[role - ui] show spinner';
export const HIDE_ROLE_SPINNER = '[role - ui] hide spinner';

export const getRole = (id) => ({
  type: GET_ROLE,
  payload: { id },
});

export const createRole = (data) => ({
  type: CREATE_ROLE,
  payload: data,
});

export const updateRole = (data) => ({
  type: UPDATE_ROLE,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_ROLE_MODAL,
});

export const hideModal = () => ({
  type: HIDE_ROLE_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_ROLE_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_ROLE_SPINNER,
});

export const updateObjectRole = (data) => ({
  type: UPDATE_OBJECT_ROLE,
  payload: data,
});
