/* eslint-disable quotes */
import React from 'react';
import {
  Grid,
  TextField, FormHelperText,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import {
  InputContent, UserRoleRemove, LabelInput, CheckBoxDelivery, HeaderAddress,
} from './styles';
import { removeObjectAddress, updateObjectAddress } from '../../redux/actions/address';

const Address = ({ address, index }) => {
  const dispatch = useDispatch();

  const sanitizeCEP = (cep) => (
    cep.replace(/-/g, '')
  );

  const handleChangedInput = (e) => {
    const { name, value } = e.target;

    dispatch(
      updateObjectAddress({
        index,
        data: {
          ...address,
          [name]: name === 'cep' ? sanitizeCEP(value) : value,
        },
      }),
    );
  };

  const handleChangedCheckbox = (e) => {
    const { name, checked } = e.target;
    dispatch(
      updateObjectAddress({
        index,
        data: {
          ...address,
          [name]: checked,
        },
      }),
    );
  };

  const handleRemoveAddress = () => {
    dispatch(removeObjectAddress(index));
  };

  return (
    <div style={{ width: '100%' }}>
      {index > 0 && (<HeaderAddress><h2>Endereço alternativo</h2><UserRoleRemove onClick={handleRemoveAddress}>X</UserRoleRemove></HeaderAddress>)}

      <div style={{ width: '100%' }}>
        <div>
          <Grid container>
            <Grid item xs={12} md={9}>
              <InputContent>
                <LabelInput>Rua*</LabelInput>
                <TextField
                  id="addressStreet-input"
                  type="text"
                  variant="outlined"
                  name="street"
                  error={address.streetError}
                  value={address.street}
                  onChange={handleChangedInput}
                  fullWidth
                />
              </InputContent>
            </Grid>
            <Grid item xs={12} md={1}>
              <InputContent>
                <LabelInput>Número*</LabelInput>
                <TextField
                  id="address-number-input"
                  type="text"
                  variant="outlined"
                  name="number"
                  error={address.numberError}
                  value={address.number}
                  onChange={handleChangedInput}
                  fullWidth
                />
              </InputContent>
            </Grid>
            <Grid item xs={12} md={2} style={{ textAlign: 'center' }}>
              <InputContent>
                <LabelInput>Endereço de entrega*</LabelInput>
                <CheckBoxDelivery
                  key={`delivery${index}`}
                  type="checkbox"
                  id="delivery-input"
                  variant="outlined"
                  value={address.delivery}
                  checked={address.delivery}
                  name="delivery"
                  onChange={handleChangedCheckbox}
                  required={address.deliveryError}
                />
                <FormHelperText error>
                  {address.deliveryError ? "Somente UM endereço de entrega" : " "}
                </FormHelperText>
              </InputContent>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={4}>
              <InputContent>
                <LabelInput>Bairro*</LabelInput>
                <TextField
                  id="district-input"
                  type="text"
                  variant="outlined"
                  name="district"
                  error={address.districtError}
                  value={address.district}
                  onChange={handleChangedInput}
                  fullWidth
                />
              </InputContent>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputContent>
                <LabelInput>Cidade*</LabelInput>
                <TextField
                  id="city-input"
                  type="text"
                  variant="outlined"
                  name="city"
                  error={address.cityError}
                  value={address.city}
                  onChange={handleChangedInput}
                  fullWidth
                />
              </InputContent>
            </Grid>
            <Grid item xs={12} md={3}>
              <InputContent>
                <LabelInput>CEP*</LabelInput>
                <InputMask
                  mask="99999-999"
                  type="text"
                  value={address.cep}
                  onChange={handleChangedInput}
                  disabled={false}
                  maskChar=" "
                >
                  {() => <TextField
                    id="cep-input"
                    type="text"
                    variant="outlined"
                    name="cep"
                    error={address.cepError}
                    fullWidth
                  />}
                </InputMask>
              </InputContent>
            </Grid>
            <Grid item xs={12} md={1}>
              <InputContent>
                <LabelInput>UF*</LabelInput>
                <TextField
                  id="uf-input"
                  type="text"
                  variant="outlined"
                  name="uf"
                  value={address.uf}
                  error={address.ufError}
                  onChange={handleChangedInput}
                  fullWidth
                />
              </InputContent>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={12}>
              <InputContent>
                <LabelInput>Ponto de referência*</LabelInput>
                <TextField
                  id="address-reference-point-input"
                  type="text"
                  variant="outlined"
                  name="reference_point"
                  error={address.reference_pointError}
                  value={address.reference_point}
                  onChange={handleChangedInput}
                  fullWidth
                />
              </InputContent>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Address;
