import React from 'react';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import DialogContainer from '../../../sharedComponents/DialogContainer';
import MultiDropZone from '../../../sharedComponents/MultiDropZone';
import { useCreateJob } from '../../../http/mutations/useCreateJob';
import { toBase64 } from '../../../utils/fileFunctions';
import { RootState } from '../../../interfaces/redux';
import { useJobTypes } from '../../../http/hooks/useJobTypes';
import Toastify from '../../../utils/Toastify';

const JOB_TYPE_NAME = 'Nota fiscal de entrada (XML)';

const JobModal: React.FC<{ open: boolean; onClose: () => void; onSuccess: () => void }> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const [files, setFiles] = React.useState<File[]>([]);

  const loggedUser = useSelector((state: RootState) => state.loggedUser);

  const { data: jobTypesResponse } = useJobTypes({ options: { refetchOnWindowFocus: false } });

  const jobTypes = React.useMemo(() => {
    if (!jobTypesResponse) return undefined;
    return jobTypesResponse.resources || [];
  }, [jobTypesResponse]);

  const { isLoading, mutateAsync } = useCreateJob({});

  const handleClose = () => {
    setFiles([]);
    onClose();
  };

  const handleSubmit = async () => {
    try {
      if (!jobTypes || !jobTypes.length) {
        Toastify.addError('Erro ao recuperar dados, por favor, tente novamente ou entre em contato com suporte.');
        return;
      }
      const filesBase64 = await Promise.all(
        files.map(async (file) => (await toBase64(file)) as string),
      ).then((result) => result);

      const jobType = jobTypes.find(({ name }) => name === JOB_TYPE_NAME);

      if (!jobType) {
        Toastify.addError('Erro ao recuperar dados, por favor, tente novamente ou entre em contato com suporte.');
        return;
      }

      await mutateAsync({ files: filesBase64, createdBy: `${loggedUser.id}`, jobTypeId: jobType.id });
      Toastify.addSuccess('Requisição realizada com sucesso! Em breve, os arquivos serão processados e será possível visualizar o resultado.');
      onSuccess();
      handleClose();
    } catch (error) {
      Toastify.addError('Ocorreu um erro na importação, por favor, verifique os dados e tente novamente. Se o problema persistir, entre em contato com o suporte.');
    }
  };

  return (
    <DialogContainer open={open} onClose={handleClose}>
      <DialogTitle>Importar Notas Fiscais</DialogTitle>
      <DialogContent>
        <MultiDropZone files={files} format="xml" onChangeFiles={setFiles} />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose} disabled={isLoading}>
          Cancelar
        </Button>
        <Button
          color="primary"
          startIcon={
            isLoading && <CircularProgress size={12} color="inherit" />
          }
          onClick={handleSubmit}
        >
          Enviar
        </Button>
      </DialogActions>
    </DialogContainer>
  );
};

export default JobModal;
