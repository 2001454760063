import styled from 'styled-components';

export const Container = styled.span`
  margin: 8px .5rem;
`;

export const Shimmering = styled.div`
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 100%;
  border-radius: 8px;

  @-webkit-keyframes shimmer {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -1200px 0;
    }
    100% {
      background-position: 1200px 0;
    }
  }
`;

export const ShimmeringIcon = styled(Shimmering)`
  height: 40px;
  width: 40px;
  transform: translateX(-.5rem)
`;

export const ShimmeringText = styled(Shimmering)`
  height: 24px;
`;
