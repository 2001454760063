import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@material-ui/core';
import {
  Container,
  PrimaryButton,
  useStyles,
  Link,
  SecondaryButton,
  FilterArea,
} from './styles';
import { getOrders } from '../../redux/actions/orders';
import { RootState } from '../../interfaces/redux';
import Card from '../../sharedComponents/OrderCard';
import { Order } from '../../interfaces/orders';
import { Company } from '../../interfaces/companies';
import { getCompanies } from '../../redux/actions/companies';
import { formatOrders } from '../../utils/formatOrder';
import Spinner from '../../sharedComponents/Spinner';
import CompanySelector from '../../sharedComponents/CompanySelector';
import SubHeader from '../../sharedComponents/SubHeader';
import Pagination from '../../sharedComponents/Pagination';
import {
  ActionContainer,
  ExpandIcon,
  ExportButton,
} from '../../sharedComponents/OrderCard/styles';
import { userIsAdmin } from '../../utils/globalFunctions';
import { download } from '../../services/Download';
import PATHS from '../../config/routePaths';

const orderFields: {
  [key in keyof Partial<Order>]:
    | string
    | { label: string; ifEmpty?: string; hideIfEmpty?: boolean };
} = {
  company: 'Panificador',
  formattedOrderDate: 'Data',
  formattedDeliveryDate: { label: 'Data de entrega', hideIfEmpty: true },
  formattedEstimatedDeliveryDate: {
    label: 'Previsão de entrega',
    ifEmpty: 'deliveryDate',
  },
  formattedFinalTotalValue: 'Valor Total',
};

const Orders: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const companies: Company[] = useSelector(
    (state: RootState) => state.companies?.docs || state.companies,
  );
  const loggedUser = useSelector((state: RootState) => state.loggedUser);

  const company: Company = useSelector((state: RootState) => state.company);

  const orders: Order[] = useSelector((state: RootState) =>
    formatOrders(state.orders?.docs || [], companies),
  );

  const {
    total,
    pages,
    limit,
  }: { total: number; pages: number; limit: number } = useSelector(
    (state: RootState) => state.orders,
  );

  const loading: boolean = useSelector(
    (state: RootState) => state.ui.ordersLoading,
  );

  useEffect(() => {
    dispatch(
      getOrders({
        page,
        limit: 20,
        'filter[companyDocument]': company.document,
      }),
    );
  }, [company, page]);

  useEffect(() => {
    if (companies && companies.length > 0) return;
    dispatch(getCompanies({ listAll: true }));
  }, [companies]);

  const handleExport = (order: Order) => (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    download(
      '/orderProducts/export',
      {
        'filter[supplierOrderId]': order.supplierOrders.map(({ id }) => id),
        isAdmin: userIsAdmin(loggedUser),
      },
      {
        fileExtension: 'xlsx',
        forceDefaultDownloaded: true,
        responseType: 'blob',
      },
    );
  };

  const ActionComponent = (order: Order) => () => (
    <ActionContainer>
      <ExportButton
        onClick={handleExport(order)}
        startIcon={<Icon>download</Icon>}
      >
        Exportar
      </ExportButton>
      <ExpandIcon className="expandIcon">chevron_right</ExpandIcon>
    </ActionContainer>
  );

  return (
    <Container>
      <SubHeader
        title={
          <span>
            <Link to="/import/orders">
              <PrimaryButton variant="contained">Novo pedido</PrimaryButton>
            </Link>
          </span>
        }
        subtitle={
          <FilterArea>
            <CompanySelector />
          </FilterArea>
        }
      />
      {loading ? (
        <Spinner color="#133DA5" />
      ) : (
        <>
          <div className={classes.root}>
            {orders.map((order) => (
              <Link to={`${PATHS.SETTINGS_ORDER}/${order.id}`}>
                <Card
                  key={order.id}
                  order={order}
                  fields={orderFields}
                  ActionComponent={ActionComponent(order)}
                  admin
                />
              </Link>
            ))}
          </div>
          <Pagination
            page={page}
            total={total}
            onChangePage={setPage}
            pages={pages}
            showing={orders.length}
            limit={limit}
          />
        </>
      )}
    </Container>
  );
};

export default Orders;
