import { IconButton, TextField } from '@material-ui/core';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  color: #1A2D78;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const AddButton = styled(IconButton)`
  border: 1px solid #1A2D78;
  color: #1A2D78;
  margin-left: 8px;
  height: 32px;
  width: 32px;
`
export const Select = styled(TextField)`
  border: 1px solid #1A2D78;
  color: #1A2D78;
  border-radius: 24px;
  height: 34px;
  min-width: 160px;
  .MuiFormLabel-root {
    color: #1A2D78;
    transform: translate(8px, 8px);
  }
  .MuiButtonBase-root {
    margin-left: -8px;
  }
  .MuiInputBase-root {
    margin-top: 0;
    padding-left: 8px;
    padding-right: 8px;
    color: #1A2D78;
    .MuiSelect-icon {
      color: #1A2D78;
    }
  }
`