import {
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  FETCH_ROLE_SUCCESS,
  FETCH_ROLE_ERROR,
  GET_ROLE,
  CREATE_ROLE,
  UPDATE_ROLE,
  updateObjectRole,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/role';
import { getRoles } from '../actions/roles';
import Service from '../../services/Service';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';
import { getLoggedUser } from '../actions/loggedUser';
import { api2FrontInterface } from '../../utils/globalFunctions';

const rolesApiUrl = 'roles';
const permissionsApiUrl = 'permissions';

export const createRoleFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_ROLE) {
    const { permissions, ...data } = action.payload;
    const rolesService = new Service({
      route: rolesApiUrl,
      apiName: 'auth',
    });
    rolesService.post(api2FrontInterface(data))
      .then((response) => {
        if (response.ok) {
          const { id } = response.data.resources;
          dispatch(
            apiRequest(
              'POST',
              permissionsApiUrl,
              { id, permissions, type: 'role' },
              UPDATE_ROLE_SUCCESS,
              UPDATE_ROLE_ERROR,
              {},
              'apiV2',
            ),
          );
        } else {
          dispatch({ type: UPDATE_ROLE_ERROR, payload: response.message });
        }
      })
      .catch((error) => {
        dispatch({ type: UPDATE_ROLE_ERROR, payload: error });
      });
    dispatch(showSpinner());
  }
};

export const updateRoleFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_ROLE) {
    const { permissions, id, ...data } = action.payload;
    const rolesService = new Service({
      route: rolesApiUrl,
      apiName: 'auth',
    });
    rolesService.put(id, api2FrontInterface(data))
      .then((response) => {
        if (response.ok) {
          dispatch(
            apiRequest(
              'PUT',
              permissionsApiUrl,
              { id, permissions, type: 'role' },
              UPDATE_ROLE_SUCCESS,
              UPDATE_ROLE_ERROR,
              {},
              'apiV2',
            ),
          );
        } else {
          dispatch({ type: UPDATE_ROLE_ERROR, payload: response.message });
        }
      })
      .catch((error) => {
        dispatch({ type: UPDATE_ROLE_ERROR, payload: error });
      });
    dispatch(showSpinner());
  }
};

export const getRoleFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_ROLE) {
    const { id } = action.payload;
    const rolesService = new Service({
      route: `${rolesApiUrl}/${id}`,
      apiName: 'auth',
    });
    const permissionsService = new Service({
      route: `${permissionsApiUrl}/${id}`,
      apiName: 'apiV2',
    });
    rolesService.get()
      .then((response) => {
        if (response.ok) {
          permissionsService.get({ type: 'role' })
            .then(({ data }) => {
              const { permissions } = data;
              dispatch({
                type: FETCH_ROLE_SUCCESS,
                payload: {
                  ...response.data.resources,
                  permissions,
                },
              });
            })
            .catch((error) => {
              dispatch({ type: FETCH_ROLE_ERROR, payload: error });
            });
        } else {
          dispatch({ type: FETCH_ROLE_ERROR, payload: response.message });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ROLE_ERROR, payload: error });
      });
    dispatch(showSpinner());
  }
};

export const processRoleCollection = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === FETCH_ROLE_SUCCESS) {
    dispatch(updateObjectRole(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === FETCH_ROLE_ERROR) {
    dispatch(hideSpinner());
    dispatch(hideModal());
    Toastify.addError('Erro ao carregar perfil. Por favor, entre em contato com o suporte');
  } else if (action.type === UPDATE_ROLE_SUCCESS) {
    dispatch(updateObjectRole(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    dispatch(getLoggedUser());
    const { page, limit } = getState().roles;
    dispatch(getRoles({ page, limit }));
    Toastify.addSuccess('Operação concluída com sucesso.');
  } else if (action.type === UPDATE_ROLE_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(
      'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
    );
  }
};

export const roleMdl = [
  createRoleFlow,
  updateRoleFlow,
  getRoleFlow,
  processRoleCollection,
];
