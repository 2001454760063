import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import InputMask from 'react-input-mask';

import {
  InputContent,
  useStyles,
  UserRoleContainer,
  DateContainer,
  UserRoleRemove, LabelInput
} from './styles';
import { updateObjectCompanyAdminUser } from '../../redux/actions/companyAdminUser';
import {
  removeObjectCompanyUser,
  updateObjectCompanyUser,
} from '../../redux/actions/companyUsers';
import { sanitizeCPF } from '../../utils/globalFunctions';

const User = ({ user, isAdmin, index }) => {
  const classes = useStyles();
  const userRoleStyle = {
    color: '#fff',
    padding: '50px 10px',
    borderRadius: '8px',
    textAlign: 'center',
    backgroundColor: isAdmin ? '#1A2D78' : '#F28444',
    maxWidth: '15%',
  };
  const dispatch = useDispatch();
  const [age, setAge] = useState(0);

  const handleChangedInput = (e) => {
    const { name, value } = e.target;
    if (isAdmin) {
      dispatch(
        updateObjectCompanyAdminUser({
          ...user,
          [name]: value,
        }),
      );
    } else {
      dispatch(
        updateObjectCompanyUser({
          index,
          data: {
            ...user,
            [name]: value,
          },
        }),
      );
    }
  };

  const handleChangedCpfInput = (cpf) => {
    if (isAdmin) {
      dispatch(
        updateObjectCompanyAdminUser({
          ...user,
          cpf,
        }),
      );
    } else {
      dispatch(
        updateObjectCompanyUser({
          index,
          data: {
            ...user,
            cpf,
          },
        }),
      );
    }
  };

  const handleChangedDateInput = (birthDate) => {
    if (isAdmin) {
      dispatch(
        updateObjectCompanyAdminUser({
          ...user,
          birthDate,
        }),
      );
    } else {
      dispatch(
        updateObjectCompanyUser({
          index,
          data: {
            ...user,
            birthDate,
          },
        }),
      );
    }

    const dateDifference = moment().diff(birthDate, 'years');
    setAge(dateDifference);
  };

  const handleRemoveUser = () => {
    dispatch(removeObjectCompanyUser(index));
  };

  return (
    <div className={classes.userContainer}>
      {!isAdmin ? (
        <UserRoleRemove onClick={handleRemoveUser}>X</UserRoleRemove>
      ) : null}

      <div className={classes.wrapper}>
        <UserRoleContainer style={userRoleStyle}>
          {isAdmin ? (
            <h2>Administrador</h2>
          ) : (
            <TextField
              id="role-name-input"
              type="text"
              variant="standard"
              name="roleName"
              placeholder="Cargo"
              value={user.roleName}
              onChange={handleChangedInput}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.userRoleInput,
                },
              }}
              multiline
              minRows={2}
            />
          )}
        </UserRoleContainer>
        <div style={{ width: '100%', marginLeft: 20 }}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <InputContent>
                <LabelInput>Nome*</LabelInput>
                <TextField
                  id="name-input"
                  type="text"
                  variant="outlined"
                  name="name"
                  value={user.name}
                  error={user.nameUserError}
                  onChange={handleChangedInput}
                  fullWidth
                />
              </InputContent>
            </Grid>
            <Grid item xs={12} md={2}>
              <InputContent>
                <FormControl variant="outlined" fullWidth>
                  <LabelInput id="select-label-sex">Sexo*</LabelInput>
                  <Select
                    labelId="select-label-sex"
                    id="select-label-component-sex"
                    value={user.sex}
                    error={user.sexUserError}
                    variant="outlined"
                    name="sex"
                    onChange={handleChangedInput}
                    fullWidth
                  >
                    <MenuItem value="M">Masculino</MenuItem>
                    <MenuItem value="F">Feminino</MenuItem>
                  </Select>
                </FormControl>
              </InputContent>
            </Grid>
            <Grid item xs={12} md={3}>
              <DateContainer style={{ marginTop: 20 }}>
                <LabelInput id="select-label-nasci">Data de Nascimento*</LabelInput>
                <InputMask
                  mask="99/99/9999"
                  type="text"
                  value={user.birthDate}
                  onChange={(e) => handleChangedDateInput(e.target.value)}
                  disabled={false}
                  maskChar=" "
                >
                  {() => (<TextField
                    type="text"
                    placeholder="dd/mm/aaaa"
                    variant="outlined"
                    fullWidth
                    error={user.birthDateUserError}
                  />)}

                </InputMask>
              </DateContainer>
            </Grid>
            <Grid item xs={12} md={1} style={{ textAlign: 'center' }}>
              <InputContent>
                <LabelInput>Idade</LabelInput>
                <h2>{age}</h2>
              </InputContent>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={3}>
              <InputContent>
                <LabelInput>CPF*</LabelInput>
                <InputMask
                  mask="999.999.999-99"
                  type="text"
                  value={user.cpf}
                  onChange={(e) =>
                    handleChangedCpfInput(sanitizeCPF(e.target.value))
                  }
                  disabled={false}
                  maskChar=" "
                >
                  {() => (
                    <TextField
                      error={user.cpfUserError}
                      id="responsable-cpf-input"
                      type="text"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                </InputMask>
              </InputContent>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputContent>
                <LabelInput>Email*</LabelInput>
                <TextField
                  id="email-input"
                  type="text"
                  variant="outlined"
                  name="email"
                  error={user.emailUserError}
                  value={user.email}
                  onChange={handleChangedInput}
                  fullWidth
                />
              </InputContent>
            </Grid>
            <Grid item xs={12} md={3}>
              <InputContent>
                <LabelInput>Telefone do colaborador*</LabelInput>
                <TextField
                  id="phone-input"
                  type="text"
                  variant="outlined"
                  error={user.phoneUserError}
                  value={user.phone}
                  name="phone"
                  onChange={handleChangedInput}
                  fullWidth
                />
              </InputContent>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default User;
