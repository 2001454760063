import Service from '../Service';

export const importFile = ({ file, isAdmin }) => {
  const api = new Service({
    route: '/orders/import',
    apiName: 'apiV2',
  });
  const form = new FormData();
  form.append('file', file);
  return api
    .post(form, { isAdmin: `${isAdmin}` })
    .then(({ data, ok, message }) => ({
      ok,
      data,
      error: message,
    }))
    .catch((e) => ({ ok: false, error: e?.response?.data?.message }));
};
