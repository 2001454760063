import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { EditRounded, AddRounded } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { getWeeklyTaxes } from '../../redux/actions/weeklyTaxes';
import { showModal, hideModal } from '../../redux/actions/weeklyTax';
import ModalContainer from '../../sharedComponents/ModalContainer/index';
import EditModal from '../Modals/WeeklyTaxes';
import Spinner from '../../sharedComponents/Spinner/index';
import { Container, useStyles } from './styles';
import SubHeader from '../../sharedComponents/SubHeader';
import { hasPermission } from '../../utils/globalFunctions';
import PATHS from '../../config/routePaths';
import { ISO_WEEKDAY_MAP } from '../../config/consts';
import DateTimePicker from '../../sharedComponents/DateTimePicker';

const WeeklyTaxes = ({
  dispatch,
  weeklyTaxes,
  weeklyTaxModal,
  weeklyTaxesLoading,
}) => {
  const classes = useStyles();
  const [weeklyTaxesFormatted, setWeeklyTaxesFormatted] = useState([]);
  const [modalContentId, setModalContentId] = useState('new');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [total, setTotal] = useState(0);
  const [date, setDate] = useState(null);
  const dateRef = useRef(null);

  useEffect(() => {
    dispatch(
      getWeeklyTaxes({
        page: 1,
        limit,
        referenceISOString: date?.toISOString(),
        kind: 'CHARGE',
      }),
    );
  }, [page, limit, date]);

  useEffect(() => {
    if (weeklyTaxes.docs) {
      setWeeklyTaxesFormatted(weeklyTaxes.docs);
      setTotal(weeklyTaxes.total);
    }
  }, [weeklyTaxes]);

  const handleOpenModal = () => {
    dispatch(showModal());
  };

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  const handleClick = (id) => {
    setModalContentId(id);
    handleOpenModal();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formattedValue = (value) =>
    (!!(value || 0) &&
      `${value < 0 ? '-' : ''} ${Math.abs(value).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`) ||
    '-';

  return (
    <>
      <ModalContainer open={weeklyTaxModal} handleClose={handleCloseModal}>
        <EditModal
          id={modalContentId}
          handleClose={handleCloseModal}
          setDate={setDate}
        />
      </ModalContainer>
      <Container>
        <SubHeader
          title={
            <span>
              Taxas Semanais
              <IconButton
                className={classes.addButton}
                onClick={() => handleClick('new')}
                disabled={!hasPermission(PATHS.SETTINGS_WEEKLY_TAXES, 'create')}
                size="small"
              >
                <AddRounded />
              </IconButton>
            </span>
          }
        />
        <Box marginTop="1rem">
          <DateTimePicker
            value={date}
            label={date && 'Data de referência'}
            placeholder="Data de referência"
            onChange={setDate}
            dateRef={dateRef}
            adaptLayout
          />
        </Box>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>Nome da Padaria</TableCell>
                    <TableCell>Valor a ser cobrado</TableCell>
                    <TableCell>Dia da semana</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {weeklyTaxesLoading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="5" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!weeklyTaxesLoading &&
                    weeklyTaxesFormatted.map((weeklyTax) => (
                      <TableRow className={classes.row}>
                        <TableCell colSpan="2" className={classes.cell}>
                          <b>{weeklyTax.company?.name}</b>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <b>{formattedValue(weeklyTax.payload?.value)}</b>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <b>{ISO_WEEKDAY_MAP[weeklyTax.dayOfWeek]}</b>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <IconButton
                            onClick={() => handleClick(weeklyTax.id)}
                            disabled={
                              !hasPermission(
                                PATHS.SETTINGS_WEEKLY_TAXES,
                                'update',
                              )
                            }
                          >
                            <EditRounded />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 100, 200, 500, 1000]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Resultados por página"
            />
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  weeklyTaxesLoading: state.ui.weeklyTaxesLoading,
  weeklyTaxes: state.weeklyTaxes,
  weeklyTaxModal: state.ui.weeklyTaxModal,
});

export default connect(mapStateToProps)(WeeklyTaxes);
