import React from 'react';
import './index.css';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import RouterWrapper from './routerWrapper';
import { store } from './redux/store';
import { theme } from './theme';

export const App = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <React.StrictMode>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Provider store={store}>
                <RouterWrapper />
              </Provider>
            </MuiPickersUtilsProvider>
          </React.StrictMode>
        </ThemeProvider>
      </StylesProvider>
    </QueryClientProvider>
  );
};
