import {
  UPDATE_CASH_CLUB_SETTING_SUCCESS,
  UPDATE_CASH_CLUB_SETTING_ERROR,
  FETCH_CASH_CLUB_SETTING_SUCCESS,
  FETCH_CASH_CLUB_SETTING_ERROR,
  GET_CASH_CLUB_SETTING,
  CREATE_CASH_CLUB_SETTING,
  UPDATE_CASH_CLUB_SETTING,
  updateObjectCashClubSetting,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/cashClubSetting';
import { getCashClubSettings } from '../actions/cashClubSettings';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';
import { URL as CompanyURL } from './company';
import history from '../../services/history';

const URL = 'cashClubSettings';

export const createCashClubSettingFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === CREATE_CASH_CLUB_SETTING) {
          dispatch(
            apiRequest(
              'POST',
              `${CompanyURL}/${action.payload.companyId}/${URL}`,
              action.payload,
              UPDATE_CASH_CLUB_SETTING_SUCCESS,
              UPDATE_CASH_CLUB_SETTING_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const updateCashClubSettingFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === UPDATE_CASH_CLUB_SETTING) {
          dispatch(
            apiRequest(
              'PUT',
              URL,
              action.payload,
              UPDATE_CASH_CLUB_SETTING_SUCCESS,
              UPDATE_CASH_CLUB_SETTING_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const getCashClubSettingFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_CASH_CLUB_SETTING) {
          const { _id } = action.payload;
          dispatch(
            apiRequest(
              'GET-id',
              URL,
              { _id },
              FETCH_CASH_CLUB_SETTING_SUCCESS,
              FETCH_CASH_CLUB_SETTING_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processCashClubSettingCollection =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_CASH_CLUB_SETTING_SUCCESS) {
          dispatch(updateObjectCashClubSetting(action.payload));
          dispatch(hideSpinner());
        } else if (action.type === UPDATE_CASH_CLUB_SETTING_SUCCESS) {
          history.go(0);
          dispatch(hideModal());
          dispatch(hideSpinner());
          const { page, limit } = getState().cashClubSettings;
          dispatch(getCashClubSettings({ page, limit }));
          Toastify.addSuccess('Operação concluída com sucesso.');
        } else if (action.type === UPDATE_CASH_CLUB_SETTING_ERROR) {
          dispatch(hideSpinner());
          Toastify.addError(action.payload.message ||
              'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
          );
        }
      };

export const cashClubSettingMdl = [
  createCashClubSettingFlow,
  updateCashClubSettingFlow,
  getCashClubSettingFlow,
  processCashClubSettingCollection,
];
