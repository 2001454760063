import styled, { css } from 'styled-components';
import MuiPaper from '@material-ui/core/Paper';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import { KeyboardArrowDown } from '@material-ui/icons';

export const Paper = withStyles((theme) => ({
  root: {
    borderRadius: 8,
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
}))(MuiPaper);

export const Title = styled.h2<{ isMobile?: boolean }>`
  font-weight: 600;
  font-size: 18px;
  color: #252729;
  ${({ isMobile }) =>
    !isMobile &&
    css`
      margin-bottom: 24px;
    `}
  display: flex;
  justify-content: space-between;
`;

export const Divider = styled.div<{ isMobile?: boolean }>`
  border: 1px solid #d1d4d6;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 16px;
    `}
`;

export const CalculationDivider = styled.div<{ isMobile?: boolean }>`
  border: 1px solid #d1d4d6;
  height: 0;
  width: 20%;
  display: flex;
  align-self: flex-end;
  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 30%;
    `}
`;

export const Text = styled.span`
  font-size: 14px;
  line-height: 130%;
  color: #252729;
`;

export const Label = styled(Text)`
  color: #9ea3a5;
  margin-bottom: 8px;
`;

export const Field = styled.div<{ columns: number; rows?: number }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  grid-column: span ${({ columns }) => columns};
  ${({ rows }) =>
    rows &&
    css`
      grid-row: span ${rows};
    `};
`;

export const Grid = styled.div<{ columns?: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns ?? 12}, 1fr);
  grid-template-rows: repeat(4, auto);
  grid-gap: 16px;
`;

export const ExpandIcon = withStyles({
  root: {
    color: '#133DA5',
    transition: 'transform ease-in-out 500ms',
    '&.open': {
      transform: 'rotate(-180deg)',
    },
  },
})(KeyboardArrowDown);

export const Button = withStyles({
  root: { borderRadius: 8, padding: '8px 16px' },
  contained: {
    backgroundColor: '#133DA5',
    color: '#fff',
  },
})(MuiButton);

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  grid-column: span 12;
`;

export const DetailsValueTitle = styled.div<{ gap?: string; size?: string }>`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: ${({ size = '14px' }) => size};
  margin-top: ${({ gap = '1rem' }) => gap};
`;
