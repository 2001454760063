export const GET_PAYMENT_METHOD = '[payment method] GET';
export const UPDATE_PAYMENT_METHOD = '[payment method] UPDATE';
export const CREATE_PAYMENT_METHOD = '[payment method] CREATE';
export const DELETE_PAYMENT_METHOD = '[payment method] DELETE';
export const UPDATE_OBJECT_PAYMENT_METHOD = '[payment method] UPDATE OBJECT PAYMENT_METHOD';
// UI
export const SHOW_PAYMENT_METHOD_MODAL = '[payment method] SHOW MODAL';
export const HIDE_PAYMENT_METHOD_MODAL = '[payment method] HIDE MODAL';
// Spinner
export const UPDATE_PAYMENT_METHOD_SUCCESS = '[payment method] Update success';
export const UPDATE_PAYMENT_METHOD_ERROR = '[payment method] Update Error';
export const FETCH_PAYMENT_METHOD_SUCCESS = '[payment method] Fetch success';
export const FETCH_PAYMENT_METHOD_ERROR = '[payment method] Fetch Error';
export const SHOW_PAYMENT_METHOD_SPINNER = '[payment method - ui] show spinner';
export const HIDE_PAYMENT_METHOD_SPINNER = '[payment method - ui] hide spinner';

export const getPaymentMethod = (id) => ({
  type: GET_PAYMENT_METHOD,
  payload: { _id: id },
});

export const createPaymentMethod = (data) => ({
  type: CREATE_PAYMENT_METHOD,
  payload: data,
});

export const updatePaymentMethod = (data) => ({
  type: UPDATE_PAYMENT_METHOD,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_PAYMENT_METHOD_MODAL,
});

export const hideModal = () => ({
  type: HIDE_PAYMENT_METHOD_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_PAYMENT_METHOD_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_PAYMENT_METHOD_SPINNER,
});

export const updateObjectPaymentMethod = (data) => ({
  type: UPDATE_OBJECT_PAYMENT_METHOD,
  payload: data,
});
