export const GET_PAYMENT_METHODS = '[payment methods] GET';
export const FETCH_PAYMENT_METHODS_SUCCESS = '[payment methods] Fetch success';
export const FETCH_PAYMENT_METHODS_ERROR = '[payment methods] Fetch Error';
export const UPDATE_PAYMENT_METHODS = '[payment methods] Update';
export const SHOW_PAYMENT_METHODS_SPINNER = '[payment methods - ui] show spinner';
export const HIDE_PAYMENT_METHODS_SPINNER = '[payment methods - ui] hide spinner';

export const getPaymentMethods = (filter) => ({
  type: GET_PAYMENT_METHODS,
  payload: {
    ...filter,
  },
});

export const updatePaymentMethods = (data) => ({
  type: UPDATE_PAYMENT_METHODS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_PAYMENT_METHODS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_PAYMENT_METHODS_SPINNER,
});
