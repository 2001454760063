import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Container, ShimmeringIcon, ShimmeringText } from './styles';

const LoadingMenuItem: React.FC = () => {
  return (
    <Container>
      <ListItem>
        <ListItemIcon>
          <ShimmeringIcon />
        </ListItemIcon>
        <ListItemText>
          <ShimmeringText />
        </ListItemText>
      </ListItem>
    </Container>
  );
};

export default LoadingMenuItem;
