import { Box, Tooltip } from '@material-ui/core';
import currency from 'currency.js';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Order } from '../../../../interfaces/orders';
import { RootState } from '../../../../interfaces/redux';
import { getOrder } from '../../../../redux/actions/order';
import { checkIfOfferIsValid, create } from '../../../../services/OrderProducts';
import Toastify from '../../../../utils/Toastify';
import FormField from '../FormField';
import { OrderProductColumn, OrderProductWithSupplier } from '../interfaces';
import { Cell, Row } from '../ProductRow/styles';
import { ActionButton, SaveIcon } from './styles';

const columns: OrderProductColumn[] = [
  { label: 'Id da oferta', key: 'offerId', input: 'integer' },
  { label: 'Quant.', key: 'quantity', input: 'number' },
  {
    label: 'Preço Original',
    key: 'unitPlatformPrice',
    input: 'currency',
  },
  { label: 'Preço Negociado', key: 'unitNegotiatedPrice', input: 'currency' },
  { label: 'Total', key: 'formattedNegotiatedTotalValue' },
  { label: 'Preço Nota Fiscal', key: 'unitTypedPrice', input: 'currency' },
  { label: 'Preço de custo', key: 'unitCostPrice', input: 'currency' },
];

const NewOrderProduct: React.FC<{
  supplierOrderId: number;
}> = ({ supplierOrderId }) => {
  const [data, setData] = React.useState<Partial<OrderProductWithSupplier>>({});
  const dispatch = useDispatch();

  const order = useSelector((state: RootState) => state.order) as Order;

  const handleChange = (
    e:
    | React.ChangeEvent<{ name?: string; value: unknown }>
    | { target: { name?: string; value: unknown } },
  ) => {
    const { name, value } = e.target;

    if (!name) return;

    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  const handleCheckIfOfferIsValid = async (offerId?: number) => {
    if (!offerId) {
      Toastify.addError('Id da oferta é obrigatório');
      return false;
    }

    const { ok, error, status, data: offer } = await checkIfOfferIsValid(offerId);
    if (!ok) {
      if (status === 404) {
        Toastify.addError('Oferta não existe');
      } else {
        Toastify.addError(error);
      }
    }

    if (!offer.active) {
      Toastify.addError('Oferta inativa');
      return false;
    }
    return ok;
  };

  const handleSave = async () => {
    const isOfferValid = await handleCheckIfOfferIsValid(data.offerId);
    if (isOfferValid) {
      const { ok, error } = await create(supplierOrderId, data);
      if (ok) {
        dispatch(getOrder(order.id));
        return;
      }
      Toastify.addError(error);
    }
  };

  const getTotal = () => {
    const price = currency(
      data.unitNegotiatedPrice || data.unitPlatformPrice || 0,
      {
        separator: '.',
        decimal: ',',
        symbol: 'R$',
      },
    );
    return price.multiply(data.quantity || 0).format();
  };

  return (
    <Row>
      <Cell>
        <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap={4}>
          <FormField
            name="eanCode"
            value={data.eanCode as string}
            input="string"
            onChange={handleChange}
            placeholder="Código EAN"
          />
          <FormField
            name="productDescription"
            value={data.productDescription as string}
            input="string"
            onChange={handleChange}
            placeholder="Descrição"
          />
        </Box>
      </Cell>
      {columns.map(({ key, input, label }) => (
        <Cell key={key}>
          <FormField
            name={key}
            value={
              key === 'formattedNegotiatedTotalValue'
                ? getTotal()
                : (data[key] as string)
            }
            input={input}
            subValueColor="#3B3F41"
            onChange={handleChange}
            placeholder={label}
          />
        </Cell>
      ))}
      <Cell className="actions">
        <Tooltip title="Trocar Fornecedor">
          <ActionButton onClick={handleSave}>
            <SaveIcon />
          </ActionButton>
        </Tooltip>
      </Cell>
    </Row>
  );
};

export default NewOrderProduct;
