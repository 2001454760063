export const GET_API_KEYS = '[API Keys] GET';
export const FETCH_API_KEYS_SUCCESS = '[API Keys] Fetch success';
export const FETCH_API_KEYS_ERROR = '[API Keys] Fetch Error';
export const INVALIDATE_API_KEYS = '[API Keys] Update';
export const SHOW_API_KEYS_SPINNER = '[API Keys - ui] show spinner';
export const HIDE_API_KEYS_SPINNER = '[API Keys - ui] hide spinner';

export const getApiKeys = (filter) => ({
  type: GET_API_KEYS,
  payload: {
    ...filter,
  },
});

export const invalidateApiKeys = (data) => ({
  type: INVALIDATE_API_KEYS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_API_KEYS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_API_KEYS_SPINNER,
});
