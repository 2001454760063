import {
  FETCH_ACQUIRERS_SUCCESS,
  FETCH_ACQUIRERS_ERROR,
  GET_ACQUIRERS,
  updateAcquirers,
  showSpinner,
  hideSpinner,
} from '../actions/acquirers';
import { apiRequest } from '../actions/api';

const URL = 'audit/acquirers';

export const getAcquirers = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_ACQUIRERS) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_ACQUIRERS_SUCCESS,
        FETCH_ACQUIRERS_ERROR,
        {},
        'apiV2',
      ),
    );
    dispatch(showSpinner());
  }
  if (action.type === FETCH_ACQUIRERS_SUCCESS) {
    dispatch(hideSpinner());
  }
};

export const acquirersMdl = [getAcquirers];
