import {
  Paper as MuiPaper,
  Tabs as MuiTabs,
  Tab as MuiTab,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 24px;
`;

export const Paper = withStyles((theme) => ({
  root: {
    borderRadius: 0,
    padding: theme.spacing(2),
  },
}))(MuiPaper);

export const Tabs = withStyles({
  flexContainer: { gap: 12 },
  indicator: { display: 'none' },
})(MuiTabs);

export const Tab = withStyles({
  root: {
    background: '#E7E7EA',
    borderRadius: '16px 16px 0 0',
    color: '#3B3F41',
    textTransform: 'none',
    '&.shortage': {
      background: '#F8BCAE',
    },
    '&.hidden': {display: 'none'}
  },
  selected: {
    background: '#FFF',
  },
})(MuiTab);

export const CreateTab = styled.div<{creating: boolean}>`
  background: #E7E7EA;
  border-radius: 16px 16px 0 0;
  color: #133DA5;
  display: flex;
  align-self: flex-end;
  padding: 2px;
  min-width: 0;
  transition: min-width ease-in-out .5s;

  ${({ creating }) => creating && css`
    min-width: 302px;
  `}
`;

export const Content = styled.div<{ selected: boolean }>`
  display: ${({ selected }) => (selected ? 'block' : 'none')};
`;

export const EmptyMessage = styled.span`
  display: block;
  margin: 32px 0;
  text-align: center;
  font-weight: 500;
`;
