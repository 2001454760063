import React from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { DateTextField, StyledIcon, StyledIconButton, StyledIconAsButton } from './styles';

function DateTimePickerComponent({
  dateRef,
  value,
  label,
  name,
  format = 'dd/MM/yyyy HH:mm',
  onChange,
  ampm = false,
  adaptLayout,
  placeholder,
}) {
  return (
    <DateTimePicker
      ref={dateRef}
      disableToolbar
      variant="inline"
      format={format}
      ampm={ampm}
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      InputLabelProps={{
        hidden: true,
        style: {
          color: 'var(--primary-text-color)',
          backgroundColor: 'transparent',
        },
      }}
      fullWidth={!adaptLayout}
      PopoverProps={{ anchorEl: () => dateRef.current }}
      inputVariant="outlined"
      autoOk
      placeholder={placeholder}
      TextFieldComponent={(props) => (
        <DateTextField
          {...props}
          ref={dateRef}
          InputProps={{
            startAdornment: (
              <StyledIconButton style={{ paddingLeft: 0 }}>
                <StyledIcon
                  className="material-icons-outlined"
                >
                  calendar_today
                </StyledIcon>
              </StyledIconButton>
            ),
            endAdornment: <StyledIconAsButton>expand_more</StyledIconAsButton>,
          }}
        />
      )}
    />
  );
}

export default DateTimePickerComponent;
