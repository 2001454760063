import {
  UPDATE_PAYMENT_PLAN_SUCCESS,
  UPDATE_PAYMENT_PLAN_ERROR,
  FETCH_PAYMENT_PLAN_SUCCESS,
  FETCH_PAYMENT_PLAN_ERROR,
  GET_PAYMENT_PLAN,
  CREATE_PAYMENT_PLAN,
  UPDATE_PAYMENT_PLAN,
  updateObjectPaymentPlan,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/paymentPlan';
import { getPaymentPlans } from '../actions/paymentPlans';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'paymentPlans';

export const createPaymentPlanFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === CREATE_PAYMENT_PLAN) {
          dispatch(
            apiRequest(
              'POST',
              URL,
              action.payload,
              UPDATE_PAYMENT_PLAN_SUCCESS,
              UPDATE_PAYMENT_PLAN_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const updatePaymentPlanFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === UPDATE_PAYMENT_PLAN) {
          dispatch(
            apiRequest(
              'PUT',
              URL,
              action.payload,
              UPDATE_PAYMENT_PLAN_SUCCESS,
              UPDATE_PAYMENT_PLAN_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const getPaymentPlanFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_PAYMENT_PLAN) {
          const { _id } = action.payload;
          dispatch(
            apiRequest(
              'GET-id',
              URL,
              { _id },
              FETCH_PAYMENT_PLAN_SUCCESS,
              FETCH_PAYMENT_PLAN_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processPaymentPlanCollection =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_PAYMENT_PLAN_SUCCESS) {
          dispatch(updateObjectPaymentPlan(action.payload));
          dispatch(hideSpinner());
        } else if (action.type === UPDATE_PAYMENT_PLAN_SUCCESS) {
          dispatch(updateObjectPaymentPlan(action.payload));
          dispatch(hideModal());
          dispatch(hideSpinner());
          const { page, limit } = getState().paymentPlans;
          dispatch(getPaymentPlans({ page, limit }));
          Toastify.addSuccess('Operação concluída com sucesso.');
        } else if (action.type === UPDATE_PAYMENT_PLAN_ERROR) {
          dispatch(hideSpinner());
          Toastify.addError(action.payload.message ||
              'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
          );
        }
      };

export const paymentPlanMdl = [
  createPaymentPlanFlow,
  updatePaymentPlanFlow,
  getPaymentPlanFlow,
  processPaymentPlanCollection,
];
