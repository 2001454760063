export const GET_COMPANY = '[company] GET';
export const UPDATE_COMPANY = '[company] UPDATE';
export const CREATE_COMPANY = '[company] CREATE';
export const DELETE_COMPANY = '[company] DELETE';
export const UPDATE_OBJECT_COMPANY = '[company] UPDATE OBJECT COMPANY';
// Spinner
export const UPDATE_COMPANY_SUCCESS = '[company] Update success';
export const UPDATE_COMPANY_ERROR = '[company] Update Error';
export const FETCH_COMPANY_SUCCESS = '[company] Fetch success';
export const FETCH_COMPANY_ERROR = '[company] Fetch Error';
export const SHOW_COMPANY_SPINNER = '[company - ui] show spinner';
export const HIDE_COMPANY_SPINNER = '[company - ui] hide spinner';

export const getCompany = (id) => ({
  type: GET_COMPANY,
  payload: { id },
});

export const createCompany = (data) => ({
  type: CREATE_COMPANY,
  payload: data,
});

export const updateCompany = (data) => ({
  type: UPDATE_COMPANY,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_COMPANY_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_COMPANY_SPINNER,
});

export const updateObjectCompany = (data) => ({
  type: UPDATE_OBJECT_COMPANY,
  payload: data,
});
