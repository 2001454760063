import {
  FETCH_PAYMENT_PLANS_SUCCESS,
  FETCH_PAYMENT_PLANS_ERROR,
  GET_PAYMENT_PLANS,
  updatePaymentPlans,
  showSpinner,
  hideSpinner,
} from '../actions/paymentPlans';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'paymentPlans';

export const getPaymentPlansFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_PAYMENT_PLANS) {
          dispatch(
            apiRequest(
              'GET',
              URL,
              action.payload,
              FETCH_PAYMENT_PLANS_SUCCESS,
              FETCH_PAYMENT_PLANS_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processPaymentPlansCollection =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_PAYMENT_PLANS_SUCCESS) {
          dispatch(
            updatePaymentPlans(
              action.payload.docs ? action.payload : { docs: action.payload },
            ),
          );
          dispatch(hideSpinner());
        } else if (action.type === FETCH_PAYMENT_PLANS_ERROR) {
          Toastify.addError(
            'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
          );
          dispatch(hideSpinner());
        }
      };

export const paymentPlansMdl = [getPaymentPlansFlow, processPaymentPlansCollection];
