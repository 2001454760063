import styled from 'styled-components';
import MuiInput from '@material-ui/core/Input';
import MuiButton from '@material-ui/core/Button';
import { Close, KeyboardArrowDown } from '@material-ui/icons';
import { FormGroup, InputLabel, Select, TextField } from '@material-ui/core';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h2`
  color: #3b3f41;
  font-size: 15px;
  font-weight: 600;
`;

export const Container = styled.div`
  padding: 16px;
`;

export const ConfirmButton = styled(MuiButton)`
  background-color: #133da5;
  color: #fff;
  text-transform: none;
  border-radius: 8px;
  display: flex;
  flex: 1;
  &.Mui-disabled {
    background-color: #CED6DE;
    color: #FFF;
  }
`;

export const CancelButton = styled(MuiButton)`
  background-color: #f3f2f7;
  border: 1px solid #133da5;
  color: #133da5;
  text-transform: none;
  border-radius: 8px;
  display: flex;
  flex: 1;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
`;

export const CloseIcon = styled(Close)`
  color: #133da5;
`;

export const FormContainer = styled.div`
  background-color: #f3f2f7;
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  display: grid;
  grid-template:
    'company company'
    'retentionRate cashBackRate'
    'effectiveDate effectiveDate';
  gap: 16px;
`;

export const CompanySelect = styled(Select)`
  border: 1px solid #d1d4d6;
  border-radius: 8px;
  padding: 0;
  margin-top: 8px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  overflow: hidden;
  .MuiSelect-root:focus {
    background-color: #fff;
  }
  .MuiSelect-root {
    padding: 8px 16px;
  }
`;

export const SelectIcon = styled(KeyboardArrowDown)`
  color: #133da5;
`;

export const Label = styled(InputLabel)`
  color: #9ea3a5;
  font-size: 14px;
  font-weight: 400;
`;

export const RetentionRateFormGroup = styled(FormGroup)`
  grid-area: retentionRate;
`;
export const CashBackRateFormGroup = styled(FormGroup)`
  grid-area: cashBackRate;
`;
export const CompanyFormGroup = styled(FormGroup)`
  grid-area: company;
`;
export const EffectiveDateFormGroup = styled(FormGroup)`
  grid-area: effectiveDate;
`;

export const Input = styled(TextField)`
  border: 1px solid #d1d4d6;
  border-radius: 8px;
  margin-top: 8px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  overflow: hidden;
  padding: 2px 16px 1px 16px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
