import React from 'react';
import { MenuItem, IconButton, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCashClubSetting,
  hideModal,
} from '../../../../redux/actions/cashClubSetting';
import DialogContainer from '../../../../sharedComponents/DialogContainer';
import {
  Container,
  Title,
  ConfirmButton,
  CancelButton,
  ButtonContainer,
  CloseIcon,
  FormContainer,
  CompanySelect,
  SelectIcon,
  Label,
  CompanyFormGroup,
  RetentionRateFormGroup,
  CashBackRateFormGroup,
  EffectiveDateFormGroup,
  Header,
  Input,
} from '../styles';
import { CashClubSetting } from '../../../../interfaces/cashClubSettings';
import { RootState } from '../../../../interfaces/redux';
import { getCompanies } from '../../../../redux/actions/companies';
import { Company } from '../../../../interfaces/companies';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface DataAction {
  field: keyof CashClubSetting;
  value: unknown;
}

const dataReducer: React.Reducer<CashClubSetting, DataAction> = (
  state,
  { field, value },
) => ({ ...state, [field]: value });

const CashClubSettingModal: React.FC = () => {
  const [data, setData] = React.useReducer<
    React.Reducer<CashClubSetting, DataAction>
  >(dataReducer, { effectiveDate: null, activityType: 'JOIN' });

  const [buttonEnabled, setButtonEnabled] = React.useState<boolean>(false);

  const open = useSelector((state: RootState) => state.ui.cashClubSettingModal);

  const companies = useSelector(
    (state: RootState) =>
      (state.companies?.docs || state.companies || []) as Company[],
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getCompanies({ listAll: true, active: true }));
  }, []);

  React.useEffect(() => {
    if (!data.effectiveDate || !data.retentionRate || !data.cashBackRate) {
      setButtonEnabled(false);
    } else {
      setButtonEnabled(true);
    }
  }, [data]);

  const handleClose = () => {
    dispatch(hideModal());
  };

  const handleChange = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    const { name, value } = e.target;
    if (!name) return;
    setData({ field: name as keyof CashClubSetting, value });
  };

  const handleEffectiveDate = (value: MaterialUiPickersDate) =>
    setData({ field: 'effectiveDate', value });

  const handleSave: React.FormEventHandler = (e) => {
    e.preventDefault();
    dispatch(
      createCashClubSetting({
        ...data,
        retentionRate: parseFloat(`${data.retentionRate}`) / 100,
        cashBackRate: parseFloat(`${data.cashBackRate}`) / 100,
      }),
    );
  };

  return (
    <DialogContainer open={open} onClose={handleClose}>
      <Container>
        <Header>
          <Title>Cadastrar nova padaria</Title>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Header>
        <form onSubmit={handleSave}>
          <FormContainer>
            <CompanyFormGroup>
              <Label>Padaria</Label>
              <CompanySelect
                IconComponent={SelectIcon}
                disableUnderline
                name="companyId"
                onChange={handleChange}
                value={data.companyId}
                required
              >
                {companies.map(({ id, name }) => (
                  <MenuItem value={id}>{name}</MenuItem>
                ))}
              </CompanySelect>
            </CompanyFormGroup>
            <RetentionRateFormGroup>
              <Label>Porcentagem de retenção</Label>
              <Input
                type="number"
                inputProps={{
                  step: 0.01,
                  type: 'number',
                  min: 0,
                  required: true,
                }}
                name="retentionRate"
                onChange={handleChange}
                value={data.retentionRate}
                InputProps={{ endAdornment: '%', disableUnderline: true }}
                required
              />
            </RetentionRateFormGroup>
            <CashBackRateFormGroup>
              <Label>Porcentagem de cashback</Label>
              <Input
                InputProps={{ endAdornment: '%', disableUnderline: true }}
                type="number"
                inputProps={{
                  step: 0.01,
                  type: 'number',
                  min: 0,
                  required: true,
                }}
                name="cashBackRate"
                onChange={handleChange}
                value={data.cashBackRate}
                required
              />
            </CashBackRateFormGroup>
            <EffectiveDateFormGroup>
              <Label>Válido a partir de</Label>
              <DatePicker
                name="effectiveDate"
                onChange={handleEffectiveDate}
                value={data.effectiveDate}
                placeholder={'  /  /    '}
                InputProps={{ disableUnderline: true }}
                format="dd/MM/yyyy"
                TextFieldComponent={(props) => <Input {...props} />}
                maxDate={new Date()}
              />
            </EffectiveDateFormGroup>
          </FormContainer>
          <ButtonContainer>
            <CancelButton onClick={handleClose}>Cancelar</CancelButton>
            <ConfirmButton disabled={!buttonEnabled} type="submit">
              Salvar
            </ConfirmButton>
          </ButtonContainer>
        </form>
      </Container>
    </DialogContainer>
  );
};

export default CashClubSettingModal;
