import React from 'react';
import { Dialog } from '@material-ui/core';
import { Props } from './interfaces';

const ModalContainer: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  maxWidth = 'xl',
  children,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    closeAfterTransition
    maxWidth={maxWidth}
    scroll="body"
  >
    {children}
  </Dialog>
);

export default ModalContainer;
