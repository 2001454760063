export const ADD_COMPANY_USER = '[company] ADD OBJECT COMPANY USER';
export const REMOVE_COMPANY_USER = '[company] REMOVE OBJECT COMPANY USER';
export const UPDATE_COMPANY_USER = '[company] UPDATE OBJECT COMPANY USER';
export const ADD_MANY_COMPANY_USERS = '[company] ADD MANY COMPANY USERS';

export const addObjectCompanyUser = (data) => ({
  type: ADD_COMPANY_USER,
  payload: data,
});

export const removeObjectCompanyUser = (data) => ({
  type: REMOVE_COMPANY_USER,
  payload: data,
});

export const updateObjectCompanyUser = (data) => ({
  type: UPDATE_COMPANY_USER,
  payload: data,
});

export const addManyCompanyUsers = (data) => ({
  type: ADD_MANY_COMPANY_USERS,
  payload: data,
});
