import styled from 'styled-components'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import { Select, TextField } from '@material-ui/core'

export const Container = styled.div`
  padding: 20px;
`

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '1rem',
  },
  paper: {
    width: '100%',
    borderRadius: '1rem',
    padding: '1rem 4rem',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  userContainer: {
    border: '1px solid #9EA3A5',
    borderRadius: '8px',
    padding: '8px',
    margin: '10px 0px',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  userRoleInput: {
    fontSize: 21,
    fontWeight: 900,
    color: '#fff',
    textAlign: 'center',
  },
}))

export const CheckboxContent = styled.form`
  width: 100%;
  padding: 4px 8px;
`


export const InputContent = styled.form`
  width: 100%;
  padding: 20px 10px 10px 0;

  p {
    text-align: center;
  }

  .MuiInputBase-input {
    width: 89%;
  }

  .MuiOutlinedInput-input {
    padding: 10px 16px;
  }

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 8px;
    height: 40px;
    display: block;
    width: 100%;
    margin-top: 5px;
  }

  .MuiOutlinedInput-adornedStart {
    padding-left: 0;
  }

  .CurrencyTextField-textField-35 {
    text-align: center;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiOutlinedInput-root {

    fieldset {
      border: 1px solid #D1D4D6;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
      border-radius: 8px;

    }

    .material-icons-outlined {
      display: none;
    }
  }
  
  h2 {
    margin-top: 7px;
  }

  .MuiOutlinedInput-root fieldset {
    border-color: #D1D4D6 !important;
    border-radius: 8px !important;
    border-width: 1px !important;
}

`
export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3px;

  input {
    text-align: center;
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  .MuiInputBase-input {
    width: 89%;
  }

  .MuiOutlinedInput-input {
    padding: 10px 16px;
  }

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 8px;
    height: 40px;
    display: block;
    width: 100%;
    margin-top: 5px;
  }

  .MuiInputBase-root {
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    position: relative;
    font-size: 1rem;
    box-sizing: border-box;
    align-items: center;
    font-weight: 400;
    line-height: 1.1876em;
    border-radius: 8px;
    text-align: center;

}

  .MuiInput-underline:before {
    display: none !important;
}

.MuiInput-underline:after {
    display: none !important;
}
`

export const DateMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 22px 10px 0px 0px;
  margin-right: 5px !important;

  input {
    text-align: center;
  }

  .MuiInputBase-input {
    width: 89%;
  }

  .MuiOutlinedInput-input {
    padding: 10px 16px;
  }

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 8px;
    height: 40px;
    display: block;
    width: 100%;
    margin-top: 5px;
  }
`

export const ContainerInfor = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 1rem;
`

export const DateContainerStatus = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -50px;
  
  .MuiInputBase-input {
    text-align-last: center;
    width: 89%;
  }

  .MuiOutlinedInput-input {
    padding: 10px 16px;
  }

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 8px;
    height: 40px;
    display: block;
    width: 50%;
    margin-top: 5px;
  }
`

export const UserRoleContainer = styled.div`
  padding: 5px;
  height: 160px;
`

export const DividerForm = styled.div`
  width: 100%;
  border-top: 1px solid #D1D4D6;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

export const UserRoleRemove = styled.div`
  cursor: pointer;
  color: #000;
  width: 40px;
  text-align: center;
  color: #133da5;
  margin-left: auto;
  font-size: 16px;
`

export const AddUserButton = styled.button`
  width: 100%;
  color: #133da5;
  background-color: #f3f2f7;
  border: 1px solid #133da5;
  border-radius: 8px;
  padding: 16px 0px;
  margin: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Source Sans Pro';
`

export const AddAddressButton = styled.button`
  width: 100%;
  color: #133da5;
  background-color: #f3f2f7;
  border: 1px solid #133da5;
  border-radius: 8px;
  padding: 16px 0px;
  margin: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Source Sans Pro';
`

export const SubmitButton = styled.button`
  width: 100%;
  color: #fff;
  background-color: #133da5;
  border: 0;
  border-radius: 8px;
  padding: 16px 0px;
  margin: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Source Sans Pro';

  :disabled {
    background-color: #ced6de;
    cursor: default;
  }
`

export const StyledEditIcon = styled.img`
  cursor: pointer;
  height: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  @media only screen and (max-width: 1116px) {
    text-align: center;
  }
  @media (max-width: 583px) {
    margin-left: 0;
  }
`

export const ContentName = styled.div`
  display: flex;
  flex-direction: row;

  .MuiInputBase-input {
    width: 89%;
  }

  .MuiOutlinedInput-input {
    padding: 10px 16px;
  }

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 8px;
    height: 40px;
    display: block;
    margin-top: 5px;
  }
`

export const InputFieldTitle = styled(TextField)`
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
  color: #252729;
  font-weight: 600 !important;
  font-size: 32px;

  .MuiInput-root {
    position: relative;
    font-weight: 600 !important;
    font-size: 32px;
  }

  .MuiInput-underline:before {
    border-bottom: none;
  }

  :hover {
    .MuiInput-underline:before {
      border-bottom: none;
    }
`

export const ContentStatus = styled.div`
  border-left: 1px solid #9EA3A5;
  padding-left: 1rem;
  width: 37%;
  margin-top: 1rem;

  h2 {
    color: #4F5253;
    font-size: 14px;
    line-height: 130%;
  }
`

export const ContainerToggle = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`

export const LabelInput = styled.label`
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: #4F5253;
`

export const InputFieldsDate = styled.input`
  background: #FFFFFF;
  border: 1px solid #D1D4D6;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 8px 16px;
  height: 40px;
  margin-top: 4px;
`

export const InputSelect = styled(Select)`
  justify-content: center;
  align-items: flex-start;
  height: 40px;
  background: #FFFFFF;
  border-radius: 8px;
  margin-top: 5px;

  .MuiOutlinedInput-input {
    padding: 10px 16px;
  }

  .MuiInputBase-input {
    width: 95.2%;
  }

  .MuiChip-root {
    height: 20px;
    margin-right: 10px;
  }
`

export const CheckBoxDelivery = styled.input`

  padding: 8px 16px;
  width: 113px;
  height: 23px;
  margin-top: 12px;

  input[type='checkbox']::before {
    content: '';
    width: 10.4px !important;
    height: 10.4px !important;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 60ms transform ease-in-out;
    box-shadow: inset 1em 1em #8b8b8b;
    /* Windows High Contrast Mode */
    background-color: black;
}

  
`

export const InputDate = styled.input`
  background: #FFFFFF;
  border: 1px solid #D1D4D6;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 8px 16px;
  width: 133px;
  height: 40px;
  text-align: center;
`

export const HeaderAddress = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.75rem
`

export const GreenSwitch = withStyles({
  switchBase: {
    color: '#48B748',
    '&$checked': {
      color: '#48B748',
    },
    '&$checked + $track': {
      backgroundColor: '#48B748',
    },
  },
  checked: {},
  track: {},
})(Switch)
