import React from 'react';
import {
  Container,
  DropContainer,
  DropMessage,
  FileDisplayContainer,
  FileStatusBar,
  FileType,
  FileName,
  FileTypeLogo,
  FileSize,
  FileRemove,
} from './styles';

const formatToString = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: '.csv',
  txt: '.txt',
  image: 'image/*',
  xml: '*/xml',
};

interface Props {
  files: (File & {invalid?: boolean})[];
  onChangeFiles: (files: File[]) => void;
  format: keyof typeof formatToString;
}

const MultiDropZone: React.FC<Props> = ({ files = [], onChangeFiles, format }) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const dragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const dragEnter = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const dragLeave = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleFiles = (fileList: FileList) => {
    onChangeFiles([...files, ...Array.from(fileList)]);
  };

  const fileDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const { files: fileList } = e.dataTransfer;
    if (fileList.length) {
      handleFiles(fileList);
    }
  };

  const fileSize = (size: number) => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return `${parseFloat((size / k ** i).toFixed(2))} ${sizes[i]}`;
  };

  const fileType = (fileName: string) => fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
    fileName;

  const handleRemove = (index: number) => () => {
    const fileList = [...files];
    fileList.splice(index, 1);
    onChangeFiles(fileList);
  };

  const fileInputClicked = () => {
    fileInputRef.current?.click();
  };

  const filesSelected = () => {
    if (fileInputRef.current?.files?.length) {
      handleFiles(fileInputRef.current?.files || []);
    }
  };

  return (
    <Container>
      <DropContainer
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        onClick={fileInputClicked}
      >
        <DropMessage>
          <figure>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="17"
              viewBox="0 0 20 17"
            >
              <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
            </svg>
          </figure>
          Selecione ou arraste o arquivo para essa caixa{' '}
          {format && `(apenas arquivo do formato ${format})`}
        </DropMessage>
        <input
          ref={fileInputRef}
          type="file"
          accept={formatToString[format || 'xlsx']}
          onChange={filesSelected}
          multiple
        />
      </DropContainer>
      <FileDisplayContainer>
        {files.map((file, i) => (
          <FileStatusBar key={file.name}>
            <div>
              <FileTypeLogo>
                <svg
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z" />
                </svg>
              </FileTypeLogo>
              <FileType>{fileType(file.name)}</FileType>
              <FileName invalid={file.invalid}>{file.name}</FileName>
              <FileSize>({fileSize(file.size)})</FileSize>
            </div>
            <FileRemove onClick={handleRemove(i)}>X</FileRemove>
          </FileStatusBar>
        ))}
      </FileDisplayContainer>
    </Container>
  );
};

export default MultiDropZone;
