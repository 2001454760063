import {
  UPDATE_PAYMENT_CONDITION_SUCCESS,
  UPDATE_PAYMENT_CONDITION_ERROR,
  FETCH_PAYMENT_CONDITION_SUCCESS,
  FETCH_PAYMENT_CONDITION_ERROR,
  GET_PAYMENT_CONDITION,
  CREATE_PAYMENT_CONDITION,
  UPDATE_PAYMENT_CONDITION,
  updateObjectPaymentCondition,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/paymentCondition';
import { getPaymentConditions } from '../actions/paymentConditions';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'paymentConditions';

export const createPaymentConditionFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === CREATE_PAYMENT_CONDITION) {
          dispatch(
            apiRequest(
              'POST',
              URL,
              action.payload,
              UPDATE_PAYMENT_CONDITION_SUCCESS,
              UPDATE_PAYMENT_CONDITION_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const updatePaymentConditionFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === UPDATE_PAYMENT_CONDITION) {
          dispatch(
            apiRequest(
              'PUT',
              URL,
              action.payload,
              UPDATE_PAYMENT_CONDITION_SUCCESS,
              UPDATE_PAYMENT_CONDITION_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const getPaymentConditionFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_PAYMENT_CONDITION) {
          const { _id } = action.payload;
          dispatch(
            apiRequest(
              'GET-id',
              URL,
              { _id },
              FETCH_PAYMENT_CONDITION_SUCCESS,
              FETCH_PAYMENT_CONDITION_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processPaymentConditionCollection =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_PAYMENT_CONDITION_SUCCESS) {
          dispatch(updateObjectPaymentCondition(action.payload));
          dispatch(hideSpinner());
        } else if (action.type === UPDATE_PAYMENT_CONDITION_SUCCESS) {
          dispatch(updateObjectPaymentCondition(action.payload));
          dispatch(hideModal());
          dispatch(hideSpinner());
          const { page, limit } = getState().paymentConditions;
          dispatch(getPaymentConditions({ page, limit }));
          Toastify.addSuccess('Operação concluída com sucesso.');
        } else if (action.type === UPDATE_PAYMENT_CONDITION_ERROR) {
          dispatch(hideSpinner());

          Toastify.addError(action.payload.message ||
              'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
          );
        }
      };

export const paymentConditionMdl = [
  createPaymentConditionFlow,
  updatePaymentConditionFlow,
  getPaymentConditionFlow,
  processPaymentConditionCollection,
];
