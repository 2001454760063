import { Button } from '@material-ui/core';
import { SaveOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

export const ActionButton = withStyles({
  root: {
    borderRadius: 8,
    padding: 8,
    minWidth: 0,
  },
})(Button);
export const SaveIcon = withStyles({
  root: { color: '#133DA5' },
})(SaveOutlined);
