import {
  FETCH_OFFERS_SUCCESS,
  FETCH_OFFERS_ERROR,
  GET_OFFERS,
  updateOffers,
  showSpinner,
  hideSpinner,
} from '../actions/offers';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'offers';

export const getOffersFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_OFFERS) {
          dispatch(
            apiRequest(
              'GET',
              URL,
              action.payload,
              FETCH_OFFERS_SUCCESS,
              FETCH_OFFERS_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processOffersCollection =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_OFFERS_SUCCESS) {
          dispatch(
            updateOffers(
              action.payload.docs ? action.payload : { docs: action.payload },
            ),
          );
          dispatch(hideSpinner());
        } else if (action.type === FETCH_OFFERS_ERROR) {
          Toastify.addError(
            'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
          );
          dispatch(hideSpinner());
        }
      };

export const offersMdl = [getOffersFlow, processOffersCollection];
