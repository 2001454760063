import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import {
  CheckCircle,
  CancelRounded,
  EditRounded,
  AddRounded,
} from '@material-ui/icons';
import { useMediaQuery } from '@material-ui/core';
import { getRoles } from '../../redux/actions/roles';
import { showModal, hideModal } from '../../redux/actions/role';
import ModalContainer from '../../sharedComponents/ModalContainer/index';
import EditModal from '../Modals/Roles';
import Spinner from '../../sharedComponents/Spinner/index';
import { Container, useStyles } from './styles';
import SubHeader from '../../sharedComponents/SubHeader';
import { hasPermission } from '../../utils/globalFunctions';
import PATHS from '../../config/routePaths';

const Roles = ({ dispatch, roles, roleModal, rolesLoading }) => {
  const classes = useStyles();
  const [rolesFormatted, setRolesFormatted] = useState([]);
  const [modalContentId, setModalContentId] = useState('new');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');

  const isTablet = useMediaQuery('(max-width:812px)');
  const isMobile = useMediaQuery('(max-width:480px)');

  useEffect(() => {
    dispatch(getRoles({ page: page + 1, limit }));
  }, [page, limit]);

  useEffect(() => {
    if (roles.docs) {
      setRolesFormatted(roles.docs);
      setTotal(roles.total);
    }
  }, [roles]);

  const handleOpenModal = () => {
    dispatch(showModal());
  };

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  const handleClick = (id) => {
    setModalContentId(id);
    handleOpenModal();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getRoles({ page: 1, limit, query: searchText }));
  };

  return (
    <>
      <ModalContainer open={roleModal} handleClose={handleCloseModal}>
        <EditModal id={modalContentId} handleClose={handleCloseModal} />
      </ModalContainer>
      <Container>
        <SubHeader
          title={
            <span>
              Perfis
              <IconButton
                className={classes.addButton}
                onClick={() => handleClick('new')}
                disabled={!hasPermission(PATHS.SETTINGS_ROLES, 'create')}
                size="small"
              >
                <AddRounded />
              </IconButton>
            </span>
          }
        >
          <span
            style={{
              paddingTop: 4,
              width: isMobile ? '100%' : undefined,
            }}
          >
            <TextField
              style={{
                width: isTablet ? '100%' : 300,
              }}
              label={searchText ? '' : 'Pesquisar'}
              type="text"
              size="small"
              variant="outlined"
              value={searchText}
              name="role_name"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={(e) => (e.key === 'Enter' ? handleSearch(e) : null)}
              fullWidth
              InputProps={{
                style: {
                  borderRadius: '1.5rem',
                  border: '1px solid var(--primary-text-color)',
                  color: 'var(--primary-text-color)',
                },
              }}
              InputLabelProps={{
                shrink: false,
                style: { color: 'var(--primary-text-color)' },
              }}
            />
          </span>
        </SubHeader>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Ativo</TableCell>
                    <TableCell colSpan={2}>Nome</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rolesLoading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="5" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!rolesLoading &&
                    rolesFormatted.map((role) => (
                      <TableRow className={classes.row}>
                        <TableCell className={classes.cell}>
                          {role.active ? (
                            <CheckCircle className={classes.checkButton} />
                          ) : (
                            <CancelRounded className={classes.closeButton} />
                          )}
                        </TableCell>
                        <TableCell colSpan="2" className={classes.cell}>
                          <b>{role.name}</b>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <IconButton
                            onClick={() => handleClick(role.id)}
                            disabled={
                              !hasPermission(PATHS.SETTINGS_ROLES, 'update')
                            }
                          >
                            <EditRounded />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 100, 200, 500, 1000]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Resultados por página"
            />
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  rolesLoading: state.ui.rolesLoading,
  roles: state.roles,
  roleModal: state.ui.roleModal,
});

export default connect(mapStateToProps)(Roles);
