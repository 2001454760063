import { Button, Collapse, Icon, Paper, withStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Container = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(1),
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
}))(Paper);

export const ContainerHeader = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    width: 'fit-content',
    paddingRight: 24,
    paddingLeft: 24,
  },
}))(Paper);

export const ContainerBody = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
  },
}))(Paper);

export const Title = styled.span`
  font-weight: 600;
  color: #4f5253;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;

export const Value = styled.span`
  font-weight: 400;
  color: #4f5253;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;

export const StatusIndicator = styled.div<{ color: string }>`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin-right: 8px;
  margin-left: 8px;
  background-color: ${({ color }) => color};
  box-shadow: 0px 0px 4px ${({ color }) => color};
`;

export const ActionContainer = styled.div<{ open?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin: auto;
  .expandIcon {
    transition: transform .5s ease-in-out;
    transform: rotate(${({ open }) => (open ? '-180deg' : 0)});
  }
`;

export const ExpandIcon = withStyles({
  root: {
    color: '#133DA5',
  },
})(Icon);

export const ChildContainer = withStyles({
  root: { paddingLeft: 24, marginLeft: 32, borderLeft: '1px dotted #d1d4d6' },
})(Collapse);

export const ExportButton = withStyles({
  root: { color: '#1A2D78' },
})(Button);
