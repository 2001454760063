import {
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_ERROR,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_ERROR,
  GET_COMPANY,
  CREATE_COMPANY,
  UPDATE_COMPANY,
  updateObjectCompany,
  showSpinner,
  hideSpinner,
} from '../actions/company';
import { getCompanies } from '../actions/companies';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

export const URL = 'companies';

export const createCompanyFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === CREATE_COMPANY) {
          dispatch(
            apiRequest(
              'POST',
              URL,
              action.payload,
              UPDATE_COMPANY_SUCCESS,
              UPDATE_COMPANY_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const updateCompanyFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === UPDATE_COMPANY) {
          dispatch(
            apiRequest(
              'PUT',
              URL,
              action.payload,
              UPDATE_COMPANY_SUCCESS,
              UPDATE_COMPANY_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const getCompanyFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_COMPANY) {
          const { id } = action.payload;
          dispatch(
            apiRequest(
              'GET-id',
              URL,
              { id },
              FETCH_COMPANY_SUCCESS,
              FETCH_COMPANY_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processCompanyCollection =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_COMPANY_SUCCESS) {
          dispatch(updateObjectCompany(action.payload));
          dispatch(hideSpinner());
        } else if (action.type === FETCH_COMPANY_ERROR) {
          dispatch(hideSpinner());
          Toastify.addError('Erro ao carregar a padaria. Por favor, entre em contato com o suporte');
        } else if (action.type === UPDATE_COMPANY_SUCCESS) {
          dispatch(updateObjectCompany(action.payload));
          dispatch(hideSpinner());
          const { page, limit } = getState().companies;
          dispatch(getCompanies({ page, limit }));
          Toastify.addSuccess('Ação realizada com sucesso.');
        } else if (action.type === UPDATE_COMPANY_ERROR) {
          dispatch(hideSpinner());
          Toastify.addError(action.payload || 'Ocorreu um erro durante o salvamento.');
        }
      };

export const companyMdl = [
  createCompanyFlow,
  updateCompanyFlow,
  getCompanyFlow,
  processCompanyCollection,
];
