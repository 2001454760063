import {
  UPDATE_COMPANY_ADMIN_USER,
} from '../actions/companyAdminUser';

export function companyAdminUserReducer(companyAdminUser = {}, action) {
  switch (action.type) {
    case UPDATE_COMPANY_ADMIN_USER:
      return action.payload;

    default:
      return companyAdminUser;
  }
}
