import {
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_ERROR,
  GET_ORDERS,
  updateOrders,
  showSpinner,
  hideSpinner,
} from '../actions/orders';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'orders';

export const getOrdersFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_ORDERS) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        {
          ...action.payload,
          filter: action.payload?.filter,
        },
        FETCH_ORDERS_SUCCESS,
        FETCH_ORDERS_ERROR,
        [],
        'apiV2',
      ),
    );
    dispatch(showSpinner());
  }
};

export const processOrdersCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_ORDERS_SUCCESS) {
    dispatch(updateOrders(action.payload));
    dispatch(hideSpinner());
  }
  if (action.type === FETCH_ORDERS_ERROR) {
    Toastify.addError(
      'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
    );
    dispatch(hideSpinner());
  }
};

export const ordersMdl = [getOrdersFlow, processOrdersCollection];
