import {
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_PAYMENT_METHODS_ERROR,
  GET_PAYMENT_METHODS,
  updatePaymentMethods,
  showSpinner,
  hideSpinner,
} from '../actions/paymentMethods';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'paymentMethods';

export const getPaymentMethodsFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_PAYMENT_METHODS) {
          dispatch(
            apiRequest(
              'GET',
              URL,
              action.payload,
              FETCH_PAYMENT_METHODS_SUCCESS,
              FETCH_PAYMENT_METHODS_ERROR,
              {},
              'apiV2',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processPaymentMethodsCollection =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_PAYMENT_METHODS_SUCCESS) {
          dispatch(
            updatePaymentMethods(
              action.payload.docs ? action.payload : { docs: action.payload },
            ),
          );
          dispatch(hideSpinner());
        } else if (action.type === FETCH_PAYMENT_METHODS_ERROR) {
          Toastify.addError(
            'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
          );
          dispatch(hideSpinner());
        }
      };

export const paymentMethodsMdl = [getPaymentMethodsFlow, processPaymentMethodsCollection];
