import {
  GET_PAYMENT_CONDITION,
  UPDATE_PAYMENT_CONDITION,
  UPDATE_OBJECT_PAYMENT_CONDITION,
  CREATE_PAYMENT_CONDITION,
  DELETE_PAYMENT_CONDITION,
} from '../actions/paymentCondition';

export function paymentConditionReducer(paymentCondition = {}, action) {
  switch (action.type) {
    case GET_PAYMENT_CONDITION:
      return action.payload;
    case UPDATE_PAYMENT_CONDITION:
      return action.payload;
    case CREATE_PAYMENT_CONDITION:
      return action.payload;
    case DELETE_PAYMENT_CONDITION:
      return action.payload;
    case UPDATE_OBJECT_PAYMENT_CONDITION:
      return action.payload;

    default:
      return paymentCondition;
  }
}
