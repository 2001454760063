export const GET_PAYMENT_CONDITIONS = '[payment conditions] GET';
export const FETCH_PAYMENT_CONDITIONS_SUCCESS = '[payment conditions] Fetch success';
export const FETCH_PAYMENT_CONDITIONS_ERROR = '[payment conditions] Fetch Error';
export const UPDATE_PAYMENT_CONDITIONS = '[payment conditions] Update';
export const SHOW_PAYMENT_CONDITIONS_SPINNER = '[payment conditions - ui] show spinner';
export const HIDE_PAYMENT_CONDITIONS_SPINNER = '[payment conditions - ui] hide spinner';

export const getPaymentConditions = (filter) => ({
  type: GET_PAYMENT_CONDITIONS,
  payload: {
    ...filter,
  },
});

export const updatePaymentConditions = (data) => ({
  type: UPDATE_PAYMENT_CONDITIONS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_PAYMENT_CONDITIONS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_PAYMENT_CONDITIONS_SPINNER,
});
