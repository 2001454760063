import { combineReducers } from 'redux';
import { usersReducer } from './users';
import { userReducer } from './user';
import { bakeryAdditionalInformationsReducer } from './bakeryAdditionalInformations';
import { bakeryAdditionalInformationReducer } from './bakeryAdditionalInformation';
import { loggedUserReducer } from './loggedUser';
import { rolesReducer } from './roles';
import { roleReducer } from './role';
import { modulesReducer } from './modules';
import { moduleReducer } from './module';
import { companyReducer } from './company';
import { companyAdminUserReducer } from './companyAdminUser';
import { companyUsersReducer } from './companyUsers';
import { companiesReducer } from './companies';
import { competitorReducer } from './competitor';
import { competitorsReducer } from './competitors';
import { groupReducer } from './group';
import { groupsReducer } from './groups';
import { uiReducer } from './ui';
import { paymentMachineRegistersReducer } from './paymentMachineRegisters';
import { paymentMachinesReducer } from './paymentMachines';
import { paymentMachineReducer } from './paymentMachine';
import { picPayOperatorsReducer } from './picPayOperators';
import { picPayOperatorReducer } from './picPayOperator';
import { extractsReducer } from './extracts';
import { commissioningsReducer } from './commissionings';
import { commissioningReducer } from './commissioning';
import { transfersReducer } from './transfers';
import { transferReducer } from './transfer';
import { embeddedResourcesReducer } from './embeddedResources';
import { productsReducer } from './products';
import { productReducer } from './product';
import { marketStructuresReducer } from './marketStructures';
import { suppliersReducer } from './suppliers';
import { supplierReducer } from './supplier';
import { offersReducer } from './offers';
import { offerReducer } from './offer';
import { weeklyTaxesReducer } from './weeklyTaxes';
import { weeklyTaxReducer } from './weeklyTax';
import { auditReducer } from './audit';
import { reportSplitValuesReducer } from './reportSplitValues';
import { acquirersReducer } from './acquirers';
import { receivedPaymentsReducer } from './receivedPayments';
import { transitoryValuesReducer } from './transitoryValues';
import { ordersReducer } from './orders';
import { orderReducer } from './order';
import { paymentConditionsReducer } from './paymentConditions';
import { paymentConditionReducer } from './paymentCondition';
import { paymentPlansReducer } from './paymentPlans';
import { paymentPlanReducer } from './paymentPlan';
import { paymentMethodsReducer } from './paymentMethods';
import { paymentMethodReducer } from './paymentMethod';
import { addressesReducer } from './address';
import { cashClubSettingsReducer } from './cashClubSettings';
import { cashClubSettingReducer } from './cashClubSetting';
import { sellersReducer } from './sellers';
import { apiKeysReducer } from './apiKeys';
import { apiKeyReducer } from './apiKey';

export const reducers = combineReducers({
  users: usersReducer,
  user: userReducer,
  bakeryAdditionalInformations: bakeryAdditionalInformationsReducer,
  bakeryAdditionalInformation: bakeryAdditionalInformationReducer,
  loggedUser: loggedUserReducer,
  role: roleReducer,
  roles: rolesReducer,
  module: moduleReducer,
  modules: modulesReducer,
  company: companyReducer,
  companies: companiesReducer,
  companyAdminUser: companyAdminUserReducer,
  companyUsers: companyUsersReducer,
  addresses: addressesReducer,
  competitor: competitorReducer,
  competitors: competitorsReducer,
  group: groupReducer,
  groups: groupsReducer,
  ui: uiReducer,
  paymentMachines: paymentMachinesReducer,
  paymentMachine: paymentMachineReducer,
  paymentMachineRegisters: paymentMachineRegistersReducer,
  picPayOperators: picPayOperatorsReducer,
  picPayOperator: picPayOperatorReducer,
  extracts: extractsReducer,
  commissionings: commissioningsReducer,
  commissioning: commissioningReducer,
  transfers: transfersReducer,
  transfer: transferReducer,
  embeddedResources: embeddedResourcesReducer,
  products: productsReducer,
  product: productReducer,
  marketStructures: marketStructuresReducer,
  supplier: supplierReducer,
  suppliers: suppliersReducer,
  offer: offerReducer,
  offers: offersReducer,
  weeklyTax: weeklyTaxReducer,
  weeklyTaxes: weeklyTaxesReducer,
  audit: auditReducer,
  reportSplitValues: reportSplitValuesReducer,
  acquirers: acquirersReducer,
  receivedPayments: receivedPaymentsReducer,
  transitoryValues: transitoryValuesReducer,
  orders: ordersReducer,
  order: orderReducer,
  paymentConditions: paymentConditionsReducer,
  paymentCondition: paymentConditionReducer,
  paymentMethods: paymentMethodsReducer,
  paymentMethod: paymentMethodReducer,
  paymentPlans: paymentPlansReducer,
  paymentPlan: paymentPlanReducer,
  cashClubSettings: cashClubSettingsReducer,
  cashClubSetting: cashClubSettingReducer,
  sellers: sellersReducer,
  apiKeys: apiKeysReducer,
  apiKey: apiKeyReducer,
});
