import {
  UPDATE_WEEKLY_TAX_SUCCESS,
  UPDATE_WEEKLY_TAX_ERROR,
  FETCH_WEEKLY_TAX_SUCCESS,
  FETCH_WEEKLY_TAX_ERROR,
  GET_WEEKLY_TAX,
  CREATE_WEEKLY_TAX,
  UPDATE_WEEKLY_TAX,
  updateObjectWeeklyTax,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/weeklyTax';
import { getWeeklyTaxes } from '../actions/weeklyTaxes';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';
import { getLoggedUser } from '../actions/loggedUser';

const URL = 'tasks';

export const createWeeklyTaxFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_WEEKLY_TAX) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_WEEKLY_TAX_SUCCESS,
        UPDATE_WEEKLY_TAX_ERROR,
        {},
        'apiV2',
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateWeeklyTaxFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_WEEKLY_TAX) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_WEEKLY_TAX_SUCCESS,
        UPDATE_WEEKLY_TAX_ERROR,
        {},
        'apiV2',
      ),
    );
    dispatch(showSpinner());
  }
};

export const getWeeklyTaxFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_WEEKLY_TAX) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        `${URL}/index`,
        { _id },
        FETCH_WEEKLY_TAX_SUCCESS,
        FETCH_WEEKLY_TAX_ERROR,
        {},
        'apiV2',
      ),
    );
    dispatch(showSpinner());
  }
};

export const processWeeklyTaxCollection = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === FETCH_WEEKLY_TAX_SUCCESS) {
    dispatch(updateObjectWeeklyTax(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_WEEKLY_TAX_SUCCESS) {
    dispatch(updateObjectWeeklyTax(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    dispatch(getLoggedUser());
    const { page, limit } = getState().weeklyTaxes;
    dispatch(getWeeklyTaxes({ page, limit }));
    Toastify.addSuccess('Operação concluída com sucesso.');
  } else if (action.type === UPDATE_WEEKLY_TAX_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(
      'Ocorreu um erro durante a sua requisição, por favor recarregue a página e tente novamente.',
    );
    console.error(action.payload);
  }
};

export const weeklyTaxMdl = [
  createWeeklyTaxFlow,
  updateWeeklyTaxFlow,
  getWeeklyTaxFlow,
  processWeeklyTaxCollection,
];
