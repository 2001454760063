import { Collapse } from '@material-ui/core';
import React from 'react';
import { OrderProduct, OrderProductStatus, orderProductStatusMap, shortageColorStatus } from '../../../../interfaces/orders';
import {
  ActionContainer,
  ExpandIcon,
  Title,
  Value,
} from '../../../../sharedComponents/OrderCard/styles';
import {
  Container,
  Highlight,
  CommonData,
  SupplierName,
  HighlightContent,
  Table,
  TableHead,
  Row,
  Cell,
  TableBody,
  ProductsContainer,
  InfoValue,
} from './styles';

const productFields: { [key: string]: string } = {
  eanCode: 'Código EAN',
  quantity: 'Quantidade',
  price: 'Preço',
  total: 'Total',
};

const ShortageCard: React.FC<{ orderProducts: OrderProduct[]; status: OrderProductStatus; infoValue?: string|null; isOpen?: boolean }> = ({
  orderProducts, status, infoValue, isOpen,
}) => {
  const [open, setOpen] = React.useState<boolean>(isOpen || false);
  const toggle = () => {
    setOpen((currentState) => !currentState);
  };

  return (
    <>
      <Container onClick={toggle}>
        <Highlight
          backgroundColor={shortageColorStatus}
          textColor="#3B3F41"
        >
          <HighlightContent>
            <Title> Ruptura </Title>
          </HighlightContent>
        </Highlight>
        <Highlight
          backgroundColor={shortageColorStatus}
          textColor="#3B3F41"
        >
          <HighlightContent>
            {status && (
              <Value title={status}>
                <SupplierName>{ orderProductStatusMap[status] }</SupplierName>
              </Value>
            )}
          </HighlightContent>
        </Highlight>
        <CommonData>
          <ActionContainer open={open}>
            <InfoValue>
              {infoValue}
            </InfoValue>
            <ExpandIcon className="expandIcon">expand_more</ExpandIcon>
          </ActionContainer>
        </CommonData>
      </Container>
      <Collapse in={open}>
        <ProductsContainer>
          <Table stickyHeader>
            <TableHead>
              <Row>
                <Cell key="productDescription">
                  Descrição
                </Cell>
                {Object.keys(productFields).map((field) => (
                  <Cell key={field}>
                    {productFields[field]}
                  </Cell>
                ))}
              </Row>
            </TableHead>
            <TableBody>
              {orderProducts.map((product) => {
                const accessibleProduct: { [key: string]: unknown } = {
                  ...product,
                };
                return (
                  <Row key={product.id}>
                    <Cell>{product.productDescription}</Cell>
                    {Object.keys(productFields).map((field) => (
                      <Cell key={field}>
                        {accessibleProduct[field] as string}
                      </Cell>
                    ))}
                  </Row>
                );
              })}
            </TableBody>
          </Table>
        </ProductsContainer>
      </Collapse>
    </>
  );
};

export default ShortageCard;
