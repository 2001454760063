import React from 'react';
import {
  ActiveStatus,
  Container,
  Content,
  InactiveStatus,
  Table,
  TableHeader,
  TableSpacer,
} from './styles';
import Header from './components/Header';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import Pagination from '../../sharedComponents/Pagination';
import CompanyRow from './components/CompanyRow';
import { CashClubSettingCreateModal } from '../Modals/CashClubSettingModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../interfaces/redux';
import { getCashClubSettings } from '../../redux/actions/cashClubSettings';
import { CompanyColumn } from './interfaces';
import { CashClubSetting } from '../../interfaces/cashClubSettings';
import { format } from 'date-fns';
import { useDebounce } from '../../hooks';

const LIMIT = 20;

const columns: CompanyColumn[] = [
  { label: 'Padaria', value: (item) => item.company?.name as string },
  {
    label: 'Percentual retenção',
    value: (item) =>
      item.retentionRate
        ? (parseFloat(`${item.retentionRate}`) * 100).toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
          }) + '%'
        : '-',
    highlight: true,
  },
  {
    label: 'Percentual cashback',
    value: (item) =>
      item.cashBackRate
        ? (parseFloat(`${item.cashBackRate}`) * 100).toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
          }) + '%'
        : '-',
    highlight: true,
  },
  {
    label: 'Válido a partir de',
    value: (item) =>
      format(new Date(item.effectiveDate as string), 'dd/MM/yyyy'),
    highlight: true,
  },
  {
    label: 'Entrada Clube Cash',
    value: (item) => format(new Date(item.joiningDate as string), 'dd/MM/yyyy'),
  },
  {
    label: 'Saída Clube Cash',
    value: (item) =>
      item.activityType === 'LEAVE'
        ? format(new Date(item.effectiveDate as string), 'dd/MM/yyyy')
        : '-',
  },
  {
    label: 'Status',
    value: (item) =>
      item.activityType === 'LEAVE' ? (
        <InactiveStatus>Inativo</InactiveStatus>
      ) : (
        <ActiveStatus>Ativo</ActiveStatus>
      ),
  },
];

const CashClubSettings: React.FC = () => {
  const [page, setPage] = React.useState<number>(1);
  const [statusFilter, setStatusFilter] = React.useState<string | undefined>(
    undefined,
  );
  const [textFilter, setTextFilter] = React.useState('');
  const debouncedTextFilter = useDebounce(textFilter, 800);

  const dispatch = useDispatch();

  const { companies, total, pages } = useSelector((state: RootState) => ({
    companies: (state.cashClubSettings.docs ||
      state.cashClubSettings ||
      []) as CashClubSetting[],
    total: state.cashClubSettings.total || 0,
    pages: state.cashClubSettings.pages || 0,
  }));

  const getSettings = () => {
    dispatch(
      getCashClubSettings({
        page,
        'filter[status]': statusFilter || undefined,
        'filter[company]': textFilter || undefined,
        limit: LIMIT,
      }),
    );
  }

  React.useEffect(() => {
    if(page === 1) getSettings();
    else setPage(1);
  }, [statusFilter, debouncedTextFilter]);

  React.useEffect(() => {
    getSettings();
  }, [page]);

  return (
    <Container>
      <Header
        statusFilter={statusFilter}
        textFilter={textFilter}
        setStatusFilter={setStatusFilter}
        setTextFilter={setTextFilter}
      />
      <Content>
        <Table>
          <TableHeader>
            <TableRow>
              {columns.map(({ label }) => (
                <TableCell key={label}>{label}</TableCell>
              ))}
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableSpacer />
            {companies.map((item) => (
              <CompanyRow key={item.id} company={item} columns={columns} />
            ))}
          </TableBody>
        </Table>
        <Pagination
          showing={companies.length}
          page={page}
          total={total}
          onChangePage={setPage}
          limit={LIMIT}
          pages={pages}
        />
      </Content>
      <CashClubSettingCreateModal />
    </Container>
  );
};

export default CashClubSettings;
