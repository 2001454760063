export const GET_SELLERS = '[sellers] GET';
export const FETCH_SELLERS_SUCCESS = '[sellers] Fetch success';
export const FETCH_SELLERS_ERROR = '[sellers] Fetch Error';
export const UPDATE_SELLERS = '[sellers] Update';
export const SHOW_SELLERS_SPINNER = '[sellers - ui] show spinner';
export const HIDE_SELLERS_SPINNER = '[sellers - ui] hide spinner';

export const getSellers = (filter) => ({
  type: GET_SELLERS,
  payload: {
    ...filter,
  },
});

export const updateSellers = (data) => ({
  type: UPDATE_SELLERS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_SELLERS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_SELLERS_SPINNER,
});
