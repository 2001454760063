import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { JobTypeResponse } from '../../interfaces/jobs';
import { getJobTypes } from '../queries/getJobTypes';

export function useJobTypes({
  options,
}: {
  options?: UseQueryOptions<JobTypeResponse, unknown, JobTypeResponse, string[]>;
}): UseQueryResult<JobTypeResponse> {
  return useQuery(['jobs', 'jobTypes'], async () => getJobTypes(), options);
}
