import { Box, Button, IconButton, MenuItem } from '@material-ui/core';
import { Add, Close, SaveOutlined } from '@material-ui/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Order,
  SupplierOrderStatus,
  supplierStatusColorMap,
} from '../../../../interfaces/orders';
import { RootState } from '../../../../interfaces/redux';
import { createSupplierOrder } from '../../../../redux/actions/order';
import { getSuppliers } from '../../../../redux/actions/suppliers';
import { Select } from '../FormField/styles';
import { OrderProductWithSupplier } from '../interfaces';
import ShortageTab from '../ShortageTab';
import SubOrder from '../SubOrder';
import {
  Container,
  Paper,
  Tabs,
  Tab,
  Content,
  EmptyMessage,
  CreateTab,
} from './styles';

const SubOrders: React.FC<{ order: Order | undefined }> = ({ order }) => {
  const [tabSelected, setTabSelected] = React.useState<number>(0);
  const [creatingSupplierOrder, setCreatingSupplierOrder] = React.useState<
    boolean
  >(false);
  const [supplierSelected, setSupplierSelected] = React.useState<string>('');
  const suppliers = useSelector(
    (state: RootState) => state.suppliers?.docs || state.suppliers || [],
  )?.filter(({ document }: { document: string }) => document !== '0');

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!dispatch) return;
    dispatch(getSuppliers({ listAll: true, active: true }));
  }, [dispatch]);

  const lookingForSupplier = React.useMemo(
    () =>
      order?.supplierOrders
        ?.find(({ supplierDocument }) => supplierDocument === '0')
        ?.orderProducts?.map((product) => ({
          ...product,
          supplier: 'Buscando Fornecedor',
        })),
    [order?.supplierOrders],
  );

  const allProducts = React.useMemo(
    () =>
      order?.supplierOrders
        ?.reduce(
          (products: OrderProductWithSupplier[], supplierOrder) => [
            ...products,
            ...supplierOrder.orderProducts.map((product) => ({
              ...product,
              supplier: supplierOrder.supplier?.tradingName,
            })),
          ],
          [],
        )
        ?.sort((a, b) =>
          a.productDescription.localeCompare(b.productDescription),
        ) || [],
    [order?.supplierOrders],
  );

  const shortage: OrderProductWithSupplier[] = React.useMemo(
    () =>
      allProducts.filter(
        (product) =>
          product.status === 'POS_SHORTAGE' ||
          product.status === 'PRE_SHORTAGE',
      ),
    [allProducts],
  );

  const thereIsProductLookingForSupplier = React.useMemo(
    () => lookingForSupplier,
    [lookingForSupplier],
  );

  const thereIsProductInShortage = React.useMemo(() => shortage.length, [
    shortage,
  ]);

  const handleCancelCreation = () => {
    setCreatingSupplierOrder(false);
    setSupplierSelected('');
  };

  const handleCreateTabClick = () => {
    setCreatingSupplierOrder(true);
  };

  const handleChange = (_e: React.ChangeEvent<{}>, value: number) => {
    setTabSelected(value);
  };

  const regularSupplierOrders = order?.supplierOrders?.filter(
    ({ supplierDocument }) => supplierDocument !== '0',
  );

  const handleCreateSupplierOrder = () => {
    dispatch(
      createSupplierOrder({
        orderId: order?.id,
        supplierDocument: supplierSelected,
      }),
    );
  };

  const handleChangeSupplier = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const { value } = e.target;
    setSupplierSelected(value as string);
  };

  return (
    <Container>
      <Tabs
        value={tabSelected}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Todos os produtos" id="show-all-tab" />
        <Tab
          label="Ruptura"
          className={`shortage ${thereIsProductInShortage ? '' : 'hidden'}`}
        />
        <Tab
          label="Buscando Fornecedor"
          className={thereIsProductLookingForSupplier ? '' : 'hidden'}
        />
        {regularSupplierOrders?.map(({ supplier, status }) => (
          <Tab
            key={supplier?.id}
            label={supplier?.tradingName}
            style={{
              backgroundColor:
                supplierStatusColorMap[status as SupplierOrderStatus],
            }}
          />
        ))}
        <CreateTab className="MuiTab-root" creating={creatingSupplierOrder}>
          {creatingSupplierOrder ? (
            <Box display="flex" alignItems="center" flex="1">
              <Select
                onChange={handleChangeSupplier}
                disableUnderline
                fullWidth
                placeholder="Fornecedor"
              >
                {suppliers?.map((supplier: { [key: string]: string }) => (
                  <MenuItem key={supplier?.id} value={supplier?.document}>
                    {supplier?.corporate_name || supplier?.tradingName}
                  </MenuItem>
                ))}
              </Select>
              <IconButton
                onClick={handleCreateSupplierOrder}
                size="small"
                style={{ color: '#133DA5' }}
              >
                <SaveOutlined />
              </IconButton>
              <IconButton
                onClick={handleCancelCreation}
                size="small"
                style={{ color: '#F8BCAE' }}
              >
                <Close />
              </IconButton>
            </Box>
          ) : (
            <Button
              size="small"
              color="primary"
              startIcon={<Add />}
              onClick={handleCreateTabClick}
            >
              Novo Pedido
            </Button>
          )}
        </CreateTab>
      </Tabs>
      <Paper elevation={0}>
        <Content selected={tabSelected === 0}>
          {allProducts ? (
            <SubOrder products={allProducts} />
          ) : (
            <EmptyMessage>
              Nenhum produto encontrado, por favor, verifique se o pedido foi
              cancelado
            </EmptyMessage>
          )}
        </Content>
        <Content selected={tabSelected === 1}>
          <ShortageTab products={shortage} />
        </Content>
        <Content selected={tabSelected === 2}>
          <SubOrder products={lookingForSupplier || []} />
        </Content>

        {regularSupplierOrders?.map((subOrder, index) => (
          <Content key={subOrder.id} selected={index + 3 === tabSelected}>
            <SubOrder subOrder={subOrder} />
          </Content>
        ))}
      </Paper>
    </Container>
  );
};

export default SubOrders;
