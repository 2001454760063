import {
  FETCH_API_KEYS_SUCCESS,
  FETCH_API_KEYS_ERROR,
  GET_API_KEYS,
  invalidateApiKeys,
  showSpinner,
  hideSpinner,
} from '../actions/apiKeys';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'apiKeys';

export const getApiKeysFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === GET_API_KEYS) {
          dispatch(
            apiRequest(
              'GET',
              URL,
              action.payload,
              FETCH_API_KEYS_SUCCESS,
              FETCH_API_KEYS_ERROR,
              {},
              'auth',
            ),
          );
          dispatch(showSpinner());
        }
      };

export const processApiKeysCollection =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_API_KEYS_SUCCESS) {
          dispatch(
            invalidateApiKeys(
              action.payload,
            ),
          );
          dispatch(hideSpinner());
        } else if (action.type === FETCH_API_KEYS_ERROR) {
          Toastify.addError(
            'Ocorreu um erro durante o carregamento, por favor recarregue a página e tente novamente.',
          );
          dispatch(hideSpinner());
        }
      };

export const apiKeysMdl = [getApiKeysFlow, processApiKeysCollection];
