import { ApiKey } from '../../interfaces/apiKeys';
import { AuthList } from '../../interfaces/apiResponses';
import { INVALIDATE_API_KEYS } from '../actions/apiKeys';

const initState: AuthList<ApiKey> = {
  resources: {
    docs: [],
    page: 1,
    limit: 50,
    total: 0,
    pages: 0,
  },
};

export function apiKeysReducer(apiKeys = initState, action: {type: string; payload: unknown}) {
  switch (action.type) {
    case INVALIDATE_API_KEYS:
      return action.payload as AuthList<ApiKey>;

    default:
      return apiKeys;
  }
}
