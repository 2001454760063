import { apiRequest } from '../actions/api';
import {
  FETCH_REPORT_SPLIT_VALUES_ERROR,
  FETCH_REPORT_SPLIT_VALUES_SUCCESS,
  GET_REPORT_SPLIT_VALUES,
  showSpinner,
  hideSpinner,
} from '../actions/reportSplitValues';

const URL = 'audit/transactionalSplitValues';

export const getReportSplitValues = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_REPORT_SPLIT_VALUES) {
    const { cancelToken } = action.payload;
    const { payload } = action;
    delete payload.cancelToken;
    dispatch(
      apiRequest(
        'GET',
        URL,
        payload,
        FETCH_REPORT_SPLIT_VALUES_SUCCESS,
        FETCH_REPORT_SPLIT_VALUES_ERROR,
        {},
        'apiV2',
        null,
        { cancelToken },
      ),
    );
    dispatch(showSpinner());
  }

  if (action.type === FETCH_REPORT_SPLIT_VALUES_SUCCESS) {
    dispatch(hideSpinner());
  }
};

export const reportSplitValuesMdl = [getReportSplitValues];
