/* eslint-disable react/require-default-props */
import React from 'react';
import {
  FormControl,
  IconButton,
  MenuItem,
  TableContainer,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useDebounce } from '../../../../hooks';
import {
  Divider,
  Row,
  SearchIcon,
  Table,
  TableBody,
  TableHead,
  Cell,
  DetailsBar,
  DetailItem,
  DetailLabel,
  DetailValue,
  SearchInput,
  DetailsGrid,
  GridItem,
  MobileLabel,
  StatusIndicator,
  Highlight,
  Select,
  SelectIcon,
  FilterBox,
} from './styles';
import { OrderProductColumn, OrderProductWithSupplier } from '../interfaces';
import { Order, statusColorMap } from '../../../../interfaces/orders';
import ProductRow from '../ProductRow';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../interfaces/redux';
import { Close } from '@material-ui/icons';

const columns: OrderProductColumn[] = [
  { label: 'Descrição', key: 'productDescription', subKey: 'eanCode' },
  { label: 'Fornecedor', key: 'supplier' },
  { label: 'Quantidade', key: 'quantity' },
  { label: 'Preço Original', key: 'formattedUnitPlatformPrice' },
  { label: 'Preço Negociado', key: 'unitNegotiatedPrice' },
  { label: 'Total', key: 'formattedNegotiatedTotalValue' },
  { label: 'Tipo', key: 'sanitizedStatus' },
];

const ShortageTab: React.FC<{
  products?: OrderProductWithSupplier[];
}> = ({ products }) => {
  const [input, setInput] = React.useState<string>('');
  const [productsToRender, setProductsToRender] = React.useState<
    OrderProductWithSupplier[]
  >([]);
  const [typeFilter, setTypeFilter] = React.useState<string>('');
  const preShortageTotal = useSelector((state: RootState) =>
    (state.order as Order)?.calculations?.preShortageDiscount?.toLocaleString(
      'pt-BR',
      {
        style: 'currency',
        currency: 'BRL',
      },
    ),
  );

  const postShortageTotal = React.useMemo(
    () =>
      products
        ?.reduce(
          (value, { status, calculations }) =>
            status === 'POS_SHORTAGE'
              ? value + calculations.negotiatedTotalValue
              : value,
          0,
        )
        ?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
    [products],
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const debouncedInput = useDebounce(input, 250);

  React.useEffect(() => {
    const productList = products || [];
    if (!debouncedInput && !typeFilter) {
      setProductsToRender(productList);
      return;
    }

    const checkTextInput = (productDescription: string, eanCode?: string) =>
      productDescription.toUpperCase().includes(debouncedInput.toUpperCase()) ||
      eanCode?.toUpperCase()?.includes(debouncedInput.toUpperCase());

    setProductsToRender(
      productList.filter(
        ({ productDescription, eanCode, status }) =>
          (!debouncedInput || checkTextInput(productDescription, eanCode)) &&
          (!typeFilter || status === typeFilter),
      ),
    );
  }, [products, debouncedInput, typeFilter]);

  const handleInput: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setInput(value);
  };

  const handleTypeFilter = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    setTypeFilter(value as string);
  };

  const handleClearTypeFilter = () => {
    setTypeFilter('');
  };

  return (
    <>
      <DetailsBar>
        {isMobile ? (
          <DetailsGrid>
            <GridItem columns={2} className="divider-bottom">
              <MobileLabel>
                <StatusIndicator color={statusColorMap.CANCELED} /> Ruptura
              </MobileLabel>
            </GridItem>
            <GridItem columns={1} className="divider-after">
              <MobileLabel>Total pré-faturamento</MobileLabel>
              <DetailValue>{preShortageTotal}</DetailValue>
            </GridItem>
            <GridItem columns={1} >
              <MobileLabel>Total pós-faturamento</MobileLabel>
              <DetailValue>{postShortageTotal}</DetailValue>
            </GridItem>
          </DetailsGrid>
        ) : (
          <>
            <Highlight>
              <DetailLabel>Ruptura</DetailLabel>
            </Highlight>
            <DetailItem>
              <DetailLabel>Total pré-faturamento</DetailLabel>
              <DetailValue>{preShortageTotal}</DetailValue>
            </DetailItem>
            <DetailItem>
              <DetailLabel>Total pós-faturamento</DetailLabel>
              <DetailValue>{postShortageTotal}</DetailValue>
            </DetailItem>
          </>
        )}
      </DetailsBar>
      <Divider />
      <FilterBox>
        <SearchInput
          onChange={handleInput}
          value={input}
          endAdornment={<SearchIcon />}
          disableUnderline
          placeholder="Pesquisar produto"
          fullWidth
        />
        <Select
          select
          SelectProps={{
            IconComponent: (props) => <SelectIcon {...props} />,
            startAdornment: typeFilter && (
              <IconButton size="small" onClick={handleClearTypeFilter}>
                <Close />
              </IconButton>
            ),
          }}
          InputProps={{ disableUnderline: true }}
          value={typeFilter}
          onChange={handleTypeFilter}
          label={!typeFilter ? 'Filtrar por tipo' : ''}
          inputProps={{ id: 'typeFilterInput' }}
          InputLabelProps={{ shrink: false }}
        >
          <MenuItem value="PRE_SHORTAGE">Pré-faturamento</MenuItem>
          <MenuItem value="POS_SHORTAGE">Pós-faturamento</MenuItem>
        </Select>
      </FilterBox>
      <Divider />
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <Row>
              {columns.map(({ label, key }, index) => (
                <Cell key={key} className={!index ? 'wide' : ''}>
                  {label}
                </Cell>
              ))}
            </Row>
          </TableHead>
          <TableBody>
            {productsToRender.map((product) => (
              <ProductRow
                key={product.id}
                product={product}
                columns={columns}
                hideActions
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ShortageTab;
