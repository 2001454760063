import styled from "styled-components";

export const CompanyContainer = styled.div`
  grid-area: company;
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 18.2px;
  }
`