/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormGroup,
  MenuItem,
  Button,
  FormControlLabel,
  Switch,
  Chip,
  Icon,
  IconButton,
} from '@material-ui/core';
import Alert from '../../../sharedComponents/Alert/index';
import Spinner from '../../../sharedComponents/Spinner/index';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
} from './styles';

import { getUser, createUser, updateUser } from '../../../redux/actions/user';
import { getRoles } from '../../../redux/actions/roles';
import { getGroups } from '../../../redux/actions/groups';
import { getCompanies } from '../../../redux/actions/companies';
import { hasPermission } from '../../../utils/globalFunctions';
import PATHS from '../../../config/routePaths';
import { getSellers } from '../../../redux/actions/sellers';

const styles = {
  formButton: {
    margin: 2,
  },
  errorSpan: {
    color: 'red',
    fontSize: '0.75rem',
  },
};

const mapState = (state) => ({
  user: state.user,
  userModal: state.ui.userModal,
  userSpinner: state.ui.userLoading,
  allGroups: state.groups.docs,
  allRoles: state.roles.docs,
  loggedUser: state.loggedUser,
  allCompanies: state.companies.docs || state.companies,
  companiesSpinner: state.ui.companiesLoading,
  sellersSpinner: state.ui.sellersSpinner,
  sellers: state.sellers.results,
});

const UserModal = ({
  id,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const {
    user,
    userModal,
    userSpinner,
    allGroups,
    allRoles,
    loggedUser,
    allCompanies,
    companiesSpinner,
    sellers,
    sellersSpinner,
  } = useSelector(mapState);
  const [active, setActive] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [roleId, setRoleId] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [sellerId, setSellerId] = useState(null);
  const [sellerDocument, setSellerDocument] = useState(null);

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [rolesError, setRolesError] = useState(false);
  const [groupError, setGroupError] = useState(false && !sellerId);
  const [companiesError, setCompaniesError] = useState(false);
  const [sellerError, setSellerError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getUser(id));
    }
    dispatch(getRoles({ listAll: true }));
    dispatch(getGroups({ listAll: true }));
    dispatch(getSellers({ listAll: true }));
  }, [id]);

  useEffect(() => {
    if (user && id !== 'new') {
      setActive(user.active);
      setEmail(user.email);
      setName(user.name);
      setPhoneNumber(user.phone);
      setGroupId(user.groupId);
      setRoleId(user.roleId);
      setCompanies(user.companies?.map((company) => company.id) ?? []);
      setSellerId(user.sellerId);
      setSellerDocument(user.sellerDocument);
    }
  }, [user]);

  useEffect(() => {
    if (groupId) {
      setCompanies([]);
      dispatch(getCompanies({ group_id: groupId, listAll: true }));
    }
  }, [groupId]);

  useEffect(() => {
    if (groupId) {
      const alreadyRelatedCompanies =
        user.companies?.map((company) => company.document) || [];
      setCompanies(
        allCompanies
          .filter(
            (company) =>
              company.group_id === groupId &&
              alreadyRelatedCompanies.includes(company.document),
          )
          .map((company) => company.id) || [],
      );
    }
  }, [allCompanies]);

  useEffect(() => {
    if (!userModal) {
      handleClose();
    }
  }, [userModal]);

  const handleSave = async () => {
    let err = false;
    setNameError(!name);
    setEmailError(!email);
    setRolesError(!roleId);
    setPasswordError(id === 'new' && !password);
    err = !name || !email || !roleId || (id === 'new' && !password);

    if (!!groupId && !!sellerId) {
      setGroupError('Não é permitido atribuição de padaria e fornecedor ao mesmo usuário.');
      setCompaniesError('Não é permitido atribuição de padaria e fornecedor ao mesmo usuário.');
      setSellerError('Não é permitido atribuição de padaria e fornecedor ao mesmo usuário.');
      err = true;
    }

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(
            createUser({
              active,
              email,
              name,
              password,
              roleId,
              groupId,
              phone: phoneNumber,
              companies,
              sellerId,
              sellerDocument,
            }),
          );
        } else {
          dispatch(
            updateUser({
              id,
              active,
              name,
              email,
              roleId,
              groupId,
              phone: phoneNumber,
              companies,
              sellerId,
              sellerDocument,
            }),
          );
        }
      }
    }
  };

  const handleChange = (event) => {
    setCompanies(event.target.value);
  };

  const handleSeller = (event) => {
    const { value } = event.target;
    setSellerId(value);
    const seller = sellers?.find(({ id: _id }) => _id === value);
    setSellerDocument(seller?.document || null);
  };

  const getSelectedLabel = (value) => {
    if (allCompanies && allCompanies.length > 0) {
      return allCompanies.find((item) => item.id === value)?.name;
    }
    return '';
  };

  const handleClearGroup = () => {
    setGroupId(null);
    setCompanies([]);
  };

  const handleClearSeller = () => {
    setSellerDocument(null);
    setSellerId(null);
  };

  return (
    <>
      {userSpinner && <Spinner overlay />}
      <Paper elevation={2} style={{ height: 'fit-content' }} fullWidth>
        <Container style={{ opacity: userSpinner ? 0.5 : 1 }}>
          <ModalTitle>
            {id === 'new' ? 'Adicionar' : 'Editar'} Usuário
          </ModalTitle>
          <FormGroup>
            <CheckboxContent>
              <FormControlLabel
                control={
                  <Switch
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                  />
                }
                label="Ativo"
                labelPlacement="start"
              />
            </CheckboxContent>
            <InputContent>
              <TextField
                id="outlined-password-input"
                label="Nome*"
                type="text"
                error={nameError}
                helperText={nameError && 'Nome é obrigatório'}
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </InputContent>
            <InputContent>
              <TextField
                id="outlined-password-input"
                label="E-mail*"
                type="text"
                error={emailError}
                helperText={emailError && 'E-mail é obrigatório'}
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </InputContent>
            <InputContent>
              {hasPermission(PATHS.SETTINGS_USERS, 'update') ? (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Perfil*</InputLabel>
                  <Select
                    value={roleId}
                    error={rolesError}
                    helperText={rolesError && 'Perfil é obrigatório'}
                    variant="outlined"
                    onChange={(e) => setRoleId(e.target.value)}
                    fullWidth
                  >
                    {allRoles &&
                      allRoles.map((r) => (
                        <MenuItem value={r.id}>{r.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  disabled
                  value={loggedUser?.role?.name || ''}
                  fullWidth
                  label="Perfil"
                  variant="outlined"
                />
              )}
            </InputContent>
            <InputContent>
              <TextField
                label="Número de Telefone"
                type="text"
                variant="outlined"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                fullWidth
              />
            </InputContent>
            <InputContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>
                  {!groupId ? 'Grupo de Padarias' : ''}
                </InputLabel>
                <TextField
                  select
                  disabled={!!sellerId}
                  value={groupId || ''}
                  variant="outlined"
                  error={groupError}
                  helperText={groupError === true ? 'O grupo de padarias é obrigatório' : groupError}
                  onChange={(e) => setGroupId(e.target.value)}
                  fullWidth
                  InputProps={{
                    endAdornment: groupId ? (
                      <IconButton onClick={handleClearGroup}>
                        <Icon>close</Icon>
                      </IconButton>
                    ) : null,
                  }}
                >
                  {allGroups &&
                    allGroups.map((r) => (
                      <MenuItem value={r.id}>{r.name}</MenuItem>
                    ))}
                </TextField>
                {groupError && (
                  <span style={styles.errorSpan}>
                    Selecione ao menos um grupo de padaria
                  </span>
                )}
              </FormControl>
            </InputContent>
            <InputContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Padarias
                </InputLabel>
                <Select
                  multiple
                  disabled={
                    !hasPermission(PATHS.SETTINGS_USERS, 'update') ||
                    !groupId ||
                    groupId === '' ||
                    companiesSpinner
                  }
                  value={companies}
                  onChange={handleChange}
                  error={companiesError}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((item) => (
                        <Chip key={item} label={getSelectedLabel(item)} />
                      ))}
                    </div>
                  )}
                  style={{ maxWidth: '90vw' }}
                >
                  {allCompanies &&
                    allCompanies?.map(({ id: _id, name: _name }) => (
                      <MenuItem key={_id} value={_id}>
                        {_name}
                      </MenuItem>
                    ))}
                </Select>
                {companiesError && (
                  <span style={styles.errorSpan}>
                    Selecione ao menos uma padaria
                  </span>
                )}
              </FormControl>
            </InputContent>

            <InputContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>
                  Fornecedor
                </InputLabel>
                <Select
                  disabled={
                    !hasPermission(PATHS.SETTINGS_USERS, 'update')
                    || sellersSpinner
                    || !!groupId
                  }
                  value={sellerId || ''}
                  onChange={handleSeller}
                  style={{ maxWidth: '90vw' }}
                  error={!!sellerError}
                  helperText={sellerError}
                  endAdornment={!!sellerId && (
                    <IconButton onClick={handleClearSeller}>
                      <Icon>close</Icon>
                    </IconButton>
                  )}
                >
                  {sellers &&
                    sellers?.map(({ id: _id, companyName }) => (
                      <MenuItem key={_id} value={_id}>
                        {companyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </InputContent>

            <InputContent>
              {id === 'new' && (
                <TextField
                  id="outlined-password-input"
                  label="Senha*"
                  type="password"
                  helperText={passwordError && 'Senha é obrigatória'}
                  error={passwordError}
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                />
              )}
            </InputContent>
            <ButtonContainer>
              <Button
                color="secondary"
                onClick={handleClose}
                variant="contained"
                style={styles.formButton}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={handleSave}
                variant="contained"
                style={styles.formButton}
              >
                Salvar
              </Button>
            </ButtonContainer>
          </FormGroup>
        </Container>
      </Paper>
    </>
  );
};

export default UserModal;
